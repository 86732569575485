import React, { PropsWithChildren, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Dropdown, Input, NumberInput, PasswordInput, Radio } from '@column/column-ui-kit';

import { Option, RegisterContent } from '../../Questions';
import { FormElementHint } from '../Location';
import { DateInput, FileUpload, NotificationList } from '~/components';
import { useAddress } from '~/hooks';
import { GoogleFile, GoogleFileRepository, validDocumentExtensions } from '~/repositories';
import { useSessionStore } from '~/stores/Session';
import { FormElement, FormFields, FormLabel } from '~/styles';
import { validate9Digits, validateEmail, validateMinMax } from '~/util';

import { Address } from './Address';
import { Passport } from './Passport';

interface PersonProps {
  id: string;
  isBeneficialOwner?: boolean;
  isControlPerson?: boolean;
}

interface Fields {
  id: string;
  label: string;
  placeholder?: string;
  isOptional?: boolean;
  isDate?: boolean;
  isPercentage?: boolean;
  isEmail?: boolean;
}

const personalFields: Fields[] = [
  {
    id: 'firstName',
    label: 'First Name',
  },
  {
    id: 'middleName',
    label: 'Middle Name',
    isOptional: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
    placeholder: 'Your title',
  },
  {
    id: 'ownershipPercentage',
    label: 'Ownership Percentage',
    placeholder: '25',
    isPercentage: true,
  },
  {
    id: 'dateOfBirth',
    label: 'Date of Birth',
    isDate: true,
  },
  {
    id: 'email',
    label: 'Email',
    isEmail: true,
  },
];

export const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12px;
`;

const NumerInputPercent = styled.div<{ $isFilled?: boolean }>`
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px 16px;
  letter-spacing: -0.125px;
  line-height: var(--input-line-height);
  color: ${({ $isFilled, theme }) =>
    $isFilled ? theme.secondary.blendToBackground(800) : 'var(--input-placeholder-color)'};
  transition: color 0.15s;
  user-select: none;

  span {
    opacity: 0;
    min-width: 10px;
    display: inline-block;
    vertical-align: top;
    margin-right: 1px;
  }
`;

const PassportIcon = styled(Passport)`
  display: block;
  margin: 0 auto 4px auto;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

export const Person: React.FC<PropsWithChildren<PersonProps>> = ({
  children,
  id,
  isBeneficialOwner,
  isControlPerson,
}) => {
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);

  const { control, watch, setValue } = useFormContext();
  const { countries } = useAddress({ types: ['country'] });

  const setVerificationType = useCallback(
    (verificationType: string) => {
      if (verificationType !== 'ssn') {
        setValue(`${id}.ssn`, '');
      }
      if (verificationType !== 'itin') {
        setValue(`${id}.itin`, '');
      }

      setValue(`${id}.verificationType`, verificationType);
    },
    [id]
  );

  return (
    <RegisterContent>
      {children}
      <FormFields>
        {personalFields.map((obj: Fields) => {
          if (obj.id === 'jobTitle' && !isControlPerson) {
            return null;
          }
          if (obj.id === 'ownershipPercentage' && !isBeneficialOwner) {
            return null;
          }
          return (
            <FormElement key={obj.id}>
              <FormLabel>
                {obj.label} {obj.id === 'ownershipPercentage' && isBeneficialOwner && <small>(min. 25%)</small>}
              </FormLabel>
              <Controller
                name={`${id}.${obj.id}`}
                control={control}
                defaultValue=""
                rules={{
                  required: !obj.isOptional ? true : undefined,
                  validate: obj.isEmail
                    ? validateEmail
                    : obj.isPercentage
                      ? (value) => validateMinMax(value, isBeneficialOwner ? 25 : 0, 100)
                      : undefined,
                }}
                render={({ field, fieldState: { error, isTouched } }) => {
                  if (obj.isDate) {
                    return <DateInput hasError={!!error} {...field} />;
                  }

                  if (obj.isPercentage) {
                    field.value = field.value ?? '';
                    return (
                      <NumberInput
                        min={isBeneficialOwner ? 25 : 0}
                        max={100}
                        placeholder="0"
                        hasError={!!error}
                        {...field}
                      >
                        <NumerInputPercent $isFilled={field.value.toString().length > 0}>
                          <span>{field.value.toString().length > 0 ? field.value : '0'}</span>%
                        </NumerInputPercent>
                      </NumberInput>
                    );
                  }

                  return (
                    <Input placeholder={obj.placeholder ?? obj.label} hasError={isTouched && !!error} {...field} />
                  );
                }}
              />
            </FormElement>
          );
        })}
      </FormFields>
      <FormFields>
        <FormElement>
          <FormLabel>Are you a U.S. Person?</FormLabel>
          <FormElementHint>If not, we’ll collect ITIN or passport information.</FormElementHint>
          <Columns>
            <Option
              isActive={watch(`${id}.verificationType`) === 'ssn' || !watch(`${id}.verificationType`)}
              isSimple
              onClick={() => setVerificationType('ssn')}
            >
              <Radio
                isChecked={watch(`${id}.verificationType`) === 'ssn' || !watch(`${id}.verificationType`)}
                onCheckedChange={() => setVerificationType('ssn')}
                label="Yes"
              />
            </Option>
            <Option
              isActive={watch(`${id}.verificationType`) && watch(`${id}.verificationType`) !== 'ssn'}
              isSimple
              onClick={() => setVerificationType('itin')}
            >
              <Radio
                isChecked={watch(`${id}.verificationType`) && watch(`${id}.verificationType`) !== 'ssn'}
                onCheckedChange={() => setVerificationType('itin')}
                label="No"
              />
            </Option>
          </Columns>
        </FormElement>
        {(watch(`${id}.verificationType`) === 'ssn' || !watch(`${id}.verificationType`)) && (
          <FormElement>
            <FormLabel>Social security number</FormLabel>
            <Controller
              name={`${id}.ssn`}
              control={control}
              defaultValue=""
              rules={{
                required: watch(`${id}.verificationType`) === 'ssn' || !watch(`${id}.verificationType`),
                validate: validate9Digits,
              }}
              render={({ field, fieldState: { error, isTouched } }) => (
                <PasswordInput
                  placeholder="000-00-0000"
                  mask="###-##-####"
                  hasError={isTouched && !!error}
                  {...field}
                />
              )}
            />
          </FormElement>
        )}
        {(watch(`${id}.verificationType`) === 'itin' || watch(`${id}.verificationType`) === 'passport') && (
          <FormElement>
            <FormLabel>Do you have an Individual Taxpayer Identification Number&nbsp;(ITIN)</FormLabel>
            <Columns>
              <Option
                isActive={watch(`${id}.verificationType`) === 'itin'}
                isSimple
                onClick={() => setVerificationType('itin')}
              >
                <Radio
                  isChecked={watch(`${id}.verificationType`) === 'itin'}
                  onCheckedChange={() => setVerificationType('itin')}
                  label="Yes"
                />
              </Option>
              <Option
                isActive={watch(`${id}.verificationType`) && watch(`${id}.verificationType`) !== 'itin'}
                isSimple
                onClick={() => setVerificationType('passport')}
              >
                <Radio
                  isChecked={watch(`${id}.verificationType`) && watch(`${id}.verificationType`) !== 'itin'}
                  onCheckedChange={() => setVerificationType('passport')}
                  label="No"
                />
              </Option>
            </Columns>
          </FormElement>
        )}
        {watch(`${id}.verificationType`) === 'itin' && (
          <FormElement>
            <FormLabel>Individual Taxpayer Identification Number</FormLabel>
            <Controller
              name={`${id}.itin`}
              control={control}
              defaultValue=""
              rules={{
                required: watch(`${id}.verificationType`) === 'itin',
                validate: validate9Digits,
              }}
              render={({ field, fieldState: { error, isTouched } }) => (
                <PasswordInput
                  placeholder="000-00-0000"
                  mask="###-##-####"
                  hasError={isTouched && !!error}
                  {...field}
                />
              )}
            />
          </FormElement>
        )}
        {watch(`${id}.verificationType`) === 'passport' && (
          <FormFields>
            <FormElement>
              <FormLabel>Passport Issuing Country</FormLabel>
              <Controller
                name={`${id}.countryOfIssue`}
                control={control}
                defaultValue=""
                rules={{
                  required: watch(`${id}.verificationType`) === 'passport',
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Dropdown
                    onChange={onChange}
                    active={value}
                    hasError={!!error}
                    options={countries.map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                    search
                    fullWidth
                    variant="muted"
                  />
                )}
              />
            </FormElement>
            <FormElement>
              <FormLabel>Passport number</FormLabel>
              <Controller
                name={`${id}.passportNumber`}
                control={control}
                defaultValue=""
                rules={{
                  required: watch(`${id}.verificationType`) === 'passport',
                }}
                render={({ field, fieldState: { error, isTouched } }) => (
                  <Input placeholder="000000000" hasError={isTouched && !!error} {...field} />
                )}
              />
            </FormElement>
            <Columns>
              <FormElement>
                <FormLabel>Date of issue</FormLabel>
                <Controller
                  name={`${id}.dateOfIssue`}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: watch(`${id}.verificationType`) === 'passport',
                  }}
                  render={({ field, fieldState: { error, isTouched } }) => (
                    <DateInput hasError={isTouched && !!error} {...field} />
                  )}
                />
              </FormElement>
              <FormElement>
                <FormLabel>Date of expiration</FormLabel>
                <Controller
                  name={`${id}.dateOfExpiration`}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: watch(`${id}.verificationType`) === 'passport',
                  }}
                  render={({ field, fieldState: { error, isTouched } }) => (
                    <DateInput hasError={isTouched && !!error} {...field} />
                  )}
                />
              </FormElement>
            </Columns>
          </FormFields>
        )}
        <FormElement>
          <FormLabel>Upload ID document proof</FormLabel>
          <FormElementHint>
            {watch(`${id}.verificationType`) === 'ssn' || !watch(`${id}.verificationType`)
              ? "Please upload Driver's License, IRS SSN Card, or Passport to verify identity."
              : watch(`${id}.verificationType`) === 'itin'
                ? 'Please upload IRS ITIN letter to verify identity'
                : 'Please upload Passport to verify identity'}
          </FormElementHint>
          <NotificationList display={`${id}-id-document-proof`} />
          <FileUpload<GoogleFile>
            handleUpload={(file) =>
              GoogleFileRepository.upload(
                {
                  subfolderName: 'CIP',
                  file,
                },
                currentPlatformState?.id as string
              )
            }
            name={`id-document-proof-${id}`}
            description="ID document proof"
            onFileUpload={(file: GoogleFile) => setValue(`${id}.documentaryProof`, file)}
            onFileRemove={() => setValue(`${id}.documentaryProof`, undefined)}
            renderFileName={(file) => <>{file?.fileName}</>}
            allowedExtensions={validDocumentExtensions}
            file={watch(`${id}.documentaryProof`)}
          >
            <PassportIcon />
          </FileUpload>
        </FormElement>
      </FormFields>
      <FormFields>
        <Address id={`${id}.address`} />
      </FormFields>
    </RegisterContent>
  );
};
