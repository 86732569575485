import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

import { Button, Chip, Dropdown, Fade, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { EmptyState, PageHeader, Pagination } from '~/components';
import { useDialog } from '~/components/Dialog/Provider';
import { LogoLoading, RelativeTime } from '~/elements';
import { useDeleteTransferTemplate, useListTransferTemplates } from '~/hooks/useTransferTemplates';
import { useNavigate } from '~/lib/navigation';
import { TransferTemplateResponse, TransferTemplateType } from '~/repositories/Transfer/TransferTemplateRepository';
import { useNotificationStore } from '~/stores/Notification';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
`;

const TemplateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;
  padding: 24px;
`;

const Template = styled.div`
  background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};
  border-radius: 8px;
  padding: 0 16px 4px 16px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.gray.blendToBackground(150)};
  display: grid;
  grid-template-rows: min-content auto;
  cursor: pointer;
  transition:
    background-color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;

  &:hover {
    border-color: transparent;
    background-color: ${({ theme }) => theme.background};
    box-shadow:
      ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
        ${({ theme }) => theme.style.buttonSecondaryBorderWidth}
        ${({ theme }) => theme.style.buttonSecondaryBorderColor},
      0 1px 1px rgba(0, 0, 0, 0.1),
      0 1px 4px 0 transparent inset;
  }
`;

const TemplateHeader = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  gap: 8px;
  padding: 16px 0;
  align-items: center;
  line-height: 24px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.gray.blendToBackground(150)};
`;

const TemplateTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DefaultList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  li {
    font-size: 12px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid ${({ theme }) => theme.gray.blendToBackground(150)};

    &:last-child {
      border-bottom: 0;
    }
  }
`;

const Label = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
`;

const Value = styled.span`
  color: ${({ theme }) => theme.secondary.blendToBackground(900)};
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Logo = styled(LogoLoading)`
  top: calc(50vh - 130px);
`;

const ChipNumber = styled(Chip)`
  font-feature-settings: 'tnum';
`;

export const PageTransferTemplatesOverview: FC = () => {
  const { openAlert } = useDialog();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const {
    createRequest: fetchTemplates,
    response: templates,
    isLoading,
    queryParams,
    setQueryParams,
  } = useListTransferTemplates({
    initialQueryParams: {
      page: 1,
      limit: 9,
    },
    onSuccess: (response) => {
      if (!queryParams?.startingAfter && queryParams?.endingBefore) {
        setHasMore(true);
      } else {
        setHasMore(response.hasMore);
      }
    },
  });

  const { createRequest: deleteTemplate } = useDeleteTransferTemplate({
    onError: (error) => addDangerNotification({ content: error.message }),
    onSuccess: () => addSuccessNotification({ content: 'Template successfully deleted' }),
  });

  const navigate = useNavigate();

  const handleTemplateDelete = useCallback((template: TransferTemplateResponse) => {
    openAlert({
      headline: `Delete ${template.description}?`,
      content: "Are you sure you want to remove this transfer template and all it's related data?",
      confirmButton: {
        label: 'Delete',
        variant: 'danger',
      },
      onSuccess: () => {
        deleteTemplate({ transferTemplateId: template.transferTemplateId }).then(() => fetchTemplates());
      },
    });
  }, []);

  const handlePageNext = useCallback(() => {
    setQueryParams(
      {
        page: (Number(queryParams?.page ?? 1) + 1) as number,
        startingAfter: templates?.transferTemplates?.at(-1)?.transferTemplateId,
        endingBefore: undefined,
        limit: queryParams?.limit ?? 9,
      },
      true
    );
  }, [queryParams, setQueryParams, templates]);

  const handlePagePrev = useCallback(() => {
    setQueryParams(
      {
        page: (Number(queryParams?.page ?? 1) - 1) as number,
        endingBefore: templates?.transferTemplates?.at(0)?.transferTemplateId,
        startingAfter: undefined,
        limit: queryParams?.limit ?? 9,
      },
      true
    );
  }, [queryParams, setQueryParams, templates]);

  const getChipType = (template: TransferTemplateResponse) => {
    if (template.templateType === TransferTemplateType.ACH) {
      return 'info';
    }

    if (template.templateType === TransferTemplateType.Wire) {
      return 'success';
    }

    return 'purple';
  };

  const getChipLabel = (template: TransferTemplateResponse) => {
    if (template.templateType === TransferTemplateType.ACH) {
      return 'ACH Transfer';
    }

    if (template.templateType === TransferTemplateType.Wire) {
      return 'Wire Transfer';
    }

    if (template.templateType === TransferTemplateType.Book) {
      return 'Book Transfer';
    }
  };

  return (
    <>
      <PageHeader text="Transfer Templates">
        <Button size="small" icon={<Icon.Plus />} onClick={() => navigate(`${ROUTE.TRANSFERS}/templates/create`)}>
          Create Template
        </Button>
      </PageHeader>
      <Fade show={!!isLoading}>
        <Logo />
      </Fade>
      <Fade show={!isLoading}>
        {!!templates && templates.transferTemplates?.length > 0 ? (
          <Wrapper>
            <TemplateGrid>
              {templates.transferTemplates?.map((template) => {
                return (
                  <Template
                    key={template.transferTemplateId}
                    onClick={() => navigate(`${ROUTE.TRANSFERS}/templates/edit/${template.transferTemplateId}`)}
                  >
                    <TemplateHeader>
                      <TemplateTitle>{template.description}</TemplateTitle>
                      <Actions>
                        {template.templateType && <Chip type={getChipType(template)}>{getChipLabel(template)}</Chip>}
                        <Dropdown
                          positionRight
                          offsetY={4}
                          customLabel={<Button icon={<Icon.Dots />} size="tiny" variant="muted" />}
                          options={[
                            {
                              label: 'Edit',
                              onClick: () =>
                                navigate(`${ROUTE.TRANSFERS}/templates/edit/${template.transferTemplateId}`),
                            },
                            {
                              label: 'Review',
                              onClick: () =>
                                navigate(`${ROUTE.TRANSFERS}/templates/review/${template.transferTemplateId}`),
                            },
                            {
                              isDivider: true,
                            },
                            {
                              label: 'Delete',
                              isDanger: true,
                              onClick: () => handleTemplateDelete(template),
                            },
                          ]}
                        />
                      </Actions>
                    </TemplateHeader>
                    <DefaultList>
                      <li>
                        <Label>Number of transfers</Label>
                        <Value>
                          <ChipNumber size="small">
                            {(template?.achTransfers ?? []).length +
                              (template?.wireTransfers ?? []).length +
                              (template?.bookTransfers ?? []).length}
                          </ChipNumber>
                        </Value>
                      </li>
                      <li>
                        <Label>Times used</Label>
                        <Value>
                          <ChipNumber size="small">{template?.usageCount ?? 0}</ChipNumber>
                        </Value>
                      </li>
                      <li>
                        <Label>Last used</Label>
                        <Value>
                          {template.lastUsedAt ? (
                            <RelativeTime
                              preventHover
                              timestamp={new Date(template.lastUsedAt)}
                              format="month day hh:mm AM/PM"
                            />
                          ) : (
                            '-'
                          )}
                        </Value>
                      </li>
                    </DefaultList>
                  </Template>
                );
              })}
            </TemplateGrid>

            <PaginationWrapper>
              <Pagination
                current={queryParams?.page ?? 1}
                disableNext={!hasMore}
                disablePrev={queryParams?.page === 1}
                onNext={handlePageNext}
                onPrev={handlePagePrev}
              />
            </PaginationWrapper>
          </Wrapper>
        ) : (
          <EmptyState headline="No templates found" text="Create your first transfer template.">
            <Button size="small" icon={<Icon.Plus />} onClick={() => navigate(`${ROUTE.TRANSFERS}/templates/create`)}>
              Create Template
            </Button>
          </EmptyState>
        )}
      </Fade>
    </>
  );
};
