import { useCallback, useEffect, useMemo, useState } from 'react';

import { useBankAccount } from '~/hooks/useBankAccounts';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { BankAccountWithDetails } from '~/typings/API';

interface PinnedBankAccount {
  bankAccountId: string;
  order?: number;
}

interface UsePinBankAccountsReturn {
  pinnedAccounts: BankAccountWithDetails[];
  isPinned: (bankAccountId: string) => boolean;
  pinAccount: (bankAccountId: string, description: string) => Promise<void>;
  unpinAccount: (bankAccountId: string, description: string) => Promise<void>;
  togglePin: (bankAccountId: string, description: string) => Promise<void>;
  reorderAccounts: (newOrder: PinnedBankAccount[]) => Promise<void>;
  isLoading: boolean;
  error: Error | null;
}

export const usePinBankAccounts = (): UsePinBankAccountsReturn => {
  const { currentUser } = useSessionStore();
  const { createRequest: fetchBankAccount } = useBankAccount();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const [bankAccountsData, setBankAccountsData] = useState<(BankAccountWithDetails | void)[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchPinnedAccounts = useCallback(async () => {
    if (!currentUser?.getPlatformSettings?.pinnedBankAccounts?.length) {
      setBankAccountsData([]);

      setIsLoading(false);

      return;
    }

    try {
      const sortedPinnedAccounts = [...currentUser.getPlatformSettings.pinnedBankAccounts].sort(
        (a, b) => (a.order ?? 0) - (b.order ?? 0)
      );

      const promises = sortedPinnedAccounts.map((bankAccount) => fetchBankAccount({ id: bankAccount.bankAccountId }));

      const results = await Promise.all(promises);

      setBankAccountsData(results);

      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch pinned accounts'));
    } finally {
      setIsLoading(false);
    }
  }, [currentUser?.getPlatformSettings?.pinnedBankAccounts, fetchBankAccount]);

  useEffect(() => {
    fetchPinnedAccounts();
  }, [currentUser?.getPlatformSettings?.pinnedBankAccounts]);

  const pinnedAccounts = useMemo(
    () =>
      bankAccountsData
        .filter((account): account is BankAccountWithDetails => !!account)
        .sort(
          (a, b) =>
            (currentUser?.getPlatformSettings?.pinnedBankAccounts?.find((pin) => pin.bankAccountId === a.id)?.order ??
              0) -
            (currentUser?.getPlatformSettings?.pinnedBankAccounts?.find((pin) => pin.bankAccountId === b.id)?.order ??
              0)
        ),
    [bankAccountsData, currentUser?.getPlatformSettings?.pinnedBankAccounts]
  );

  const isPinned = useCallback(
    (bankAccountId: string): boolean =>
      currentUser?.getPlatformSettings?.pinnedBankAccounts?.some((pin) => pin.bankAccountId === bankAccountId) ?? false,
    [currentUser?.getPlatformSettings?.pinnedBankAccounts]
  );

  const pinAccount = useCallback(
    async (bankAccountId: string, description: string) => {
      if (!currentUser) {
        return;
      }

      const currentPinned = currentUser.getPlatformSettings?.pinnedBankAccounts ?? [];
      const maxOrder = Math.max(...currentPinned.map((pin) => pin.order ?? 0), -1);

      const newPinnedAccount = {
        bankAccountId,
        order: maxOrder + 1,
      };

      try {
        await currentUser.updatePlatformSettings({
          pinnedBankAccounts: [...currentPinned, newPinnedAccount],
        });

        await fetchPinnedAccounts();

        addSuccessNotification({
          content: `${description} pinned successfully`,
        });
      } catch (err) {
        addDangerNotification({
          content: err instanceof Error ? err.message : 'Failed to pin account',
        });
      }
    },
    [currentUser, fetchPinnedAccounts, addSuccessNotification, addDangerNotification]
  );

  const unpinAccount = useCallback(
    async (bankAccountId: string, description: string) => {
      if (!currentUser) return;

      const currentPinned = currentUser.getPlatformSettings?.pinnedBankAccounts ?? [];
      const filteredPins = currentPinned
        .filter((pin) => pin.bankAccountId !== bankAccountId)
        .map((pin, index) => ({ ...pin, order: index }));

      try {
        await currentUser.updatePlatformSettings({
          pinnedBankAccounts: filteredPins,
        });
        await fetchPinnedAccounts();
        addSuccessNotification({
          content: `${description} unpinned successfully`,
        });
      } catch (err) {
        addDangerNotification({
          content: err instanceof Error ? err.message : 'Failed to unpin account',
        });
      }
    },
    [currentUser, fetchPinnedAccounts, addSuccessNotification, addDangerNotification]
  );

  const togglePin = useCallback(
    async (bankAccountId: string, description: string) => {
      if (isPinned(bankAccountId)) {
        await unpinAccount(bankAccountId, description);
      } else {
        await pinAccount(bankAccountId, description);
      }
    },
    [isPinned, pinAccount, unpinAccount]
  );

  const reorderAccounts = useCallback(
    async (newOrder: PinnedBankAccount[]) => {
      if (!currentUser) return;

      try {
        await currentUser.updatePlatformSettings({
          pinnedBankAccounts: newOrder,
        });
        await fetchPinnedAccounts();
        addSuccessNotification({
          content: 'Pinned accounts reordered successfully',
        });
      } catch (err) {
        addDangerNotification({
          content: err instanceof Error ? err.message : 'Failed to reorder accounts',
        });
      }
    },
    [currentUser, fetchPinnedAccounts, addSuccessNotification, addDangerNotification]
  );

  return {
    pinnedAccounts,
    isPinned,
    pinAccount,
    unpinAccount,
    togglePin,
    reorderAccounts,
    isLoading,
    error,
  };
};
