import React, { useState } from 'react';
import styled from 'styled-components';

import { AmountInput, Button, Checkbox, CurrenciesUSD, Icon } from '@column/column-ui-kit';

import { NotificationList } from '~/components';
import { BookRepository } from '~/repositories';
import { ModalType, useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { Headline, ModalIconCircle, ModalText, Paragraph } from '~/styles';

import { ModalBase } from './Base';
import { Form } from './Counterparty';

const SandboxWrapper = styled(ModalBase)`
  --modal-width: 330px;
  padding: 24px;
`;

const Fields = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const Overdraft = styled.div`
  display: flex;
  grid-gap: 10px;
  padding: 11px 16px 11px 11px;
  border-radius: 8px;
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
  justify-self: start;
  align-self: end;
  color: ${({ theme }) => theme.secondary.background};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const Clock = styled(Icon.AnimationClock)`
  --icon-color: ${({ theme }) => theme.primary.background};
`;

export const ModalClearTransfer: React.FC = () => {
  const { closeModal, getModalData } = useModalStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const modalTypes: ModalType[] = ['ClearTransfer'];
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [currency, setCurrency] = useState<string>('USD');
  const [allowOverdraft, setAllowOverdraft] = useState<boolean | undefined>(undefined);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!getModalData()?.transferId) {
      return;
    }

    let data: any = {};
    if ((amount && amount > 0) || typeof allowOverdraft !== 'undefined') {
      data = { ...data, amount, allowOverdraft: allowOverdraft ?? false, currencyCode: currency };
    }
    BookRepository.clear(getModalData()?.transferId, data)
      .then(() => {
        closeModal();
        addSuccessNotification({
          content: 'Transfer cleared',
        });
        if (getModalData()?.callback) {
          getModalData()?.callback();
        }
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  return (
    <SandboxWrapper modalTypes={modalTypes}>
      <ModalIconCircle>
        <Clock />
      </ModalIconCircle>
      <ModalText>
        <Headline fullWidth size="small">
          Clear transfer
        </Headline>
        <Paragraph>Leave the following fields empty to clear the total amount.</Paragraph>
      </ModalText>
      <NotificationList display="popup" />
      <Form onSubmit={handleSubmit}>
        <Fields>
          <AmountInput
            onChange={setAmount}
            onCurrencyCodeChange={setCurrency}
            currencyCode={currency}
            currencyList={CurrenciesUSD}
            value={amount}
          />
          <Overdraft>
            <Checkbox isChecked={allowOverdraft} onCheckedChange={(value: boolean) => setAllowOverdraft(value)} />
            Allow Overdraft
          </Overdraft>
        </Fields>
        <Button>Clear</Button>
      </Form>
    </SandboxWrapper>
  );
};
