import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  Fade,
  Modal,
  ModalSize,
  SegmentedControl,
  SegmentedControlVariant,
  Tooltip,
} from '@column/column-ui-kit';

import { Mode } from '../types';
import { useEntitiesAndAccounts } from '../useEntitiesAndAccounts';
import { buildUpdateRequestForEntitiesAndAccountsSubset } from '../utils';
import { AdditionalPermissions } from '~/app/pages/Platform/Roles/_partials/AdditionalPermissions';
import { RoleInfo } from '~/app/pages/Platform/Roles/_partials/RoleInfo';
import { TransfersAndAccounts } from '~/app/pages/Platform/Roles/_partials/TransfersAndAccounts';
import { ROUTE } from '~/app/routes';
import { PageHeader } from '~/components';
import { LogoLoading } from '~/elements';
import { useDeleteCustomRole, useGetCustomRole, useUpdateCustomRole } from '~/hooks/useRoles';
import { useNavigate } from '~/lib/navigation';
import { RoleRepository } from '~/repositories/RoleRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Paragraph } from '~/styles';
import { UpdateCustomRoleRequestObject } from '~/typings/API';

enum Section {
  SectionRoleInfo = 'Role Info',
  SectionTransfersAndAccounts = 'Transfers & Accounts',
  SectionAdditionalPermissions = 'Additional Permissions',
}

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

const trimData = (data: UpdateCustomRoleRequestObject): UpdateCustomRoleRequestObject => {
  const output: UpdateCustomRoleRequestObject = {};

  output.name = data.name;
  output.description = data.description ? data.description : '';

  if (data.platformLevelPermissions) {
    output.platformLevelPermissions = Object.fromEntries(
      Object.entries(data.platformLevelPermissions).filter(([, v]) => v !== 'default')
    );
  }
  if (data.entityLevelOverrides) {
    output.entityLevelOverrides = (data.entityLevelOverrides ?? [])
      .map((entity) => {
        return Object.fromEntries(Object.entries(entity).filter(([k, v]) => k === 'entityId' || v !== 'default'));
      })
      .filter((entity) => Object.keys(entity).length > 1);
  }
  if (data.bankAccountLevelOverrides) {
    output.bankAccountLevelOverrides = (data.bankAccountLevelOverrides ?? [])
      .map((bankAccount) => {
        return Object.fromEntries(
          Object.entries(bankAccount).filter(([k, v]) => k === 'bankAccountId' || v !== 'default')
        );
      })
      .filter((entity) => Object.keys(entity).length > 1);
  }

  return output;
};

export const PageEditRole: React.FC = () => {
  const { currentPlatform } = useSessionStore();
  const { roleId } = useParams();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    createRequest: getRole,
    isLoading: isRoleLoading,
    response: role,
  } = useGetCustomRole({
    initialParams: roleId ? { roleId } : undefined,
    shouldNotLoad: !roleId,
    onError: (error) => {
      addDangerNotification({ content: error.message });
    },
  });
  useEffect(() => {
    if (roleId) {
      getRole({ roleId });
    }
  }, [roleId]);

  const updateRole = useUpdateCustomRole({
    onSuccess: () => {
      addSuccessNotification({ content: 'Role updated successfully' });
    },
    onError: (error) => {
      addDangerNotification({ content: error.message });
    },
  });
  const deleteRole = useDeleteCustomRole({
    onSuccess: () => {
      addSuccessNotification({ content: 'Role deleted successfully' });
      navigate(ROUTE.PLATFORM_ROLES);
    },
    onError: (error) => {
      addDangerNotification({ content: error.message });
    },
  });

  const existingBankAccountIDs = useMemo(
    () => role?.bankAccountLevelOverrides?.map((ba) => ba.bankAccountId).filter((id) => typeof id === 'string') ?? [],
    [role]
  );
  const existingEntityIDs = useMemo(
    () => role?.entityLevelOverrides?.map((e) => e.entityId).filter((id) => typeof id === 'string') ?? [],
    [role]
  );

  const sandboxEntitiesAndAccounts = useEntitiesAndAccounts({
    bankAccountIDs: existingBankAccountIDs,
    entityIds: existingEntityIDs,
    mode: Mode.Sandbox,
  });
  const liveEntitiesAndAccounts = useEntitiesAndAccounts({
    bankAccountIDs: existingBankAccountIDs,
    entityIds: existingEntityIDs,
    mode: Mode.Live,
  });

  const [visibleSection, setVisibleSection] = useState<Section>(Section.SectionRoleInfo);
  const sections = useMemo(() => {
    return Object.values(Section).map((section) => ({ label: section, value: section }));
  }, []);
  const handleCancel = useCallback(() => {
    navigate(ROUTE.PLATFORM_ROLES);
  }, []);
  const handleDelete = useCallback(() => {
    if (!roleId) return;
    deleteRole.createRequest({ roleId });
  }, [deleteRole]);

  const form = useForm<UpdateCustomRoleRequestObject>({
    values: role,
  });
  useEffect(() => {
    form.reset();
  }, [role]);

  const handleSubmit = form.handleSubmit((data: UpdateCustomRoleRequestObject) => {
    if (!roleId) return;
    const trimmedData = trimData(data);

    // Due to some limitations in the API (we can't add both sandbox and live entities/accounts in the same request,
    // we must perform two updates to 1. set the sandbox entities/accounts then 2. set the live entities/accounts.
    if (currentPlatform?.isLiveEnabled) {
      const sandboxData = buildUpdateRequestForEntitiesAndAccountsSubset(trimmedData, sandboxEntitiesAndAccounts);
      RoleRepository.update({ roleId, mode: 'sandbox', ...sandboxData })
        .then(() => updateRole.createRequest({ roleId, mode: 'live', ...trimmedData }))
        .catch((err) => addDangerNotification({ content: err.message }));
    } else {
      updateRole.createRequest({ roleId, mode: 'sandbox', ...trimmedData });
    }
  });

  return (
    <>
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} size={ModalSize.Small}>
        <Modal.IconCircle type="danger" icon="Warning" />
        <Modal.Content>
          <Paragraph>Are you sure you want to delete this role? This action cannot be reversed.</Paragraph>
        </Modal.Content>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete Role
          </Button>
        </Modal.Footer>
      </Modal>

      <Fade show={isRoleLoading || role === undefined} base={StyledLoading}>
        <LogoLoading />
      </Fade>
      <Fade show={!isRoleLoading && role !== undefined}>
        <PageHeader
          text={role?.name ?? ''}
          tabs={
            <SegmentedControl
              active={visibleSection}
              options={sections}
              onOptionChange={setVisibleSection}
              variant={SegmentedControlVariant.Secondary}
            />
          }
        >
          <Tooltip
            content={'This role is currently assigned to users. It cannot be deleted until it is unassigned.'}
            isOpen={(role?.userCount ?? 0) > 0 ? undefined : false}
            placement={'bottom'}
          >
            <Button
              isDisabled={(role?.userCount ?? 0) > 0}
              onClick={() => setIsDeleteModalOpen(true)}
              size="small"
              variant="secondary-danger"
            >
              Delete Role
            </Button>
          </Tooltip>
          <Button onClick={handleCancel} size="small" variant="secondary">
            Cancel
          </Button>
          <Button isLoading={!updateRole.isInitialLoad && updateRole.isLoading} onClick={handleSubmit} size="small">
            Save Role
          </Button>
        </PageHeader>
        <FormProvider {...form}>
          {visibleSection === Section.SectionRoleInfo && <RoleInfo />}
          {visibleSection === Section.SectionTransfersAndAccounts && (
            <TransfersAndAccounts
              sandboxEntitiesAndAccounts={sandboxEntitiesAndAccounts}
              liveEntitiesAndAccounts={liveEntitiesAndAccounts}
            />
          )}
          {visibleSection === Section.SectionAdditionalPermissions && <AdditionalPermissions />}
        </FormProvider>
      </Fade>
    </>
  );
};
