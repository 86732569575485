import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Chip, Icon, formatNumber } from '@column/column-ui-kit';

import { AmountInfo, AmountText } from '../../Transfers/View';
import { ROUTE } from '~/app/routes';
import { CopyInput, PageHeader, SectionHeader } from '~/components';
import { useNavigate } from '~/lib/navigation';
import { Loan, LoanDisbursement, LoanRepository } from '~/repositories';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { EditToolbar, FormElement, FormLabel, FormText, Grid, Inner } from '~/styles';
import { formatString, getDateLongUTC } from '~/util';

interface Params {
  id: string;
}

export const PageLoansDisbursementEdit: React.FC = () => {
  const openModal = useModalStore((state) => state.openModal);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [disbursement, setDisbursement] = useState<Partial<LoanDisbursement>>({});
  const [loan, setLoan] = useState<Partial<Loan>>({});

  const handleCancel = () => {
    LoanRepository.cancelDisbursement(id)
      .then(() => {
        fetchDisbursement();
        addSuccessNotification({
          content: 'Disbursement cancelled',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchDisbursement = () => {
    LoanRepository.getDisbursement(id)
      .then((response) => {
        setDisbursement(response);

        if (!response.loanId) {
          return;
        }

        LoanRepository.get(response.loanId)
          .then((loanResponse) => {
            setLoan(loanResponse);
          })
          .catch((error) => {
            navigate(`${ROUTE.LOANS}/disbursements`);
            addDangerNotification({
              content: error.message,
            });
          });
      })
      .catch((error) => {
        navigate(`${ROUTE.LOANS}/disbursements`);
        addDangerNotification({
          content: error.message,
        });
      });
  };

  useEffect(() => {
    fetchDisbursement();
  }, []);

  return (
    <>
      <PageHeader text={disbursement.description || 'Disbursement'}>
        {disbursement.status === 'hold' && (
          <>
            <Button
              onClick={() =>
                openModal('ClearDisbursement', {
                  disbursementId: id,
                  callback: fetchDisbursement,
                })
              }
            >
              Clear
            </Button>
            <Button
              onClick={() =>
                openModal('UpdateDisbursement', {
                  disbursementId: id,
                  callback: fetchDisbursement,
                })
              }
            >
              Update
            </Button>
            <Button variant="danger" onClick={handleCancel} icon={<Icon.CircleCross />}>
              Cancel
            </Button>
          </>
        )}
      </PageHeader>

      <Inner>
        <Grid>
          <AmountInfo>
            <AmountText>{formatNumber(disbursement?.amount)}</AmountText>

            <div>
              <Chip>{disbursement.status && formatString(disbursement.status)}</Chip>
            </div>
          </AmountInfo>
          <FormElement>
            <FormLabel>ID</FormLabel>
            <CopyInput value={id} />
          </FormElement>
        </Grid>
      </Inner>

      <SectionHeader text="Information" border />
      <Inner>
        <Grid>
          <FormElement>
            <FormLabel>Description</FormLabel>
            <FormText>{disbursement?.description ?? ''}</FormText>
          </FormElement>

          <FormElement>
            <FormLabel>Status</FormLabel>
            <FormText>
              <Chip>{disbursement.status && formatString(disbursement.status)}</Chip>
            </FormText>
          </FormElement>

          <FormElement>
            <FormLabel>Bank Account ID</FormLabel>
            <CopyInput value={disbursement.bankAccountId} />
          </FormElement>

          <FormElement>
            <FormLabel>Account Number ID</FormLabel>
            <CopyInput value={disbursement.accountNumberId} />
          </FormElement>

          <FormElement>
            <FormLabel>Created at</FormLabel>
            <FormText>{disbursement?.createdAt && getDateLongUTC(disbursement?.createdAt as any)}</FormText>
          </FormElement>
          <FormElement>
            <FormLabel>Effective at</FormLabel>
            <FormText>{disbursement?.effectiveAt && getDateLongUTC(disbursement.effectiveAt as any)}</FormText>
          </FormElement>
        </Grid>
      </Inner>

      <Inner>
        <EditToolbar>
          <Button onClick={() => navigate(-1)} variant="secondary" size="small">
            Back
          </Button>

          <Button
            onClick={() => navigate(`${ROUTE.LOANS}/edit/${loan.id}`)}
            variant="secondary"
            icon={<Icon.ChevronRight />}
            iconRight
            size="small"
          >
            Loan: {loan?.description}
          </Button>
        </EditToolbar>
      </Inner>
    </>
  );
};
