import { TimelineEntry, TimelineStatus } from '~/app/pages/Transfers/_components/Timeline';
import {
  buildTimelineEntryForTransferReviews,
  buildTransferParty,
  parseDate,
} from '~/app/pages/Transfers/_utils/timeline';
import { TransferStatus } from '~/lib/transfers';
import { Counterparty, WireTransfer } from '~/repositories';
import { AccountNumber, BankAccount } from '~/repositories/BankAccountRepository';

const wireEvents = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Pending Approval',
    field: 'preReviewAt',
  },
  {
    label: 'Initiated',
    field: 'initiatedAt',
  },
  {
    label: 'Pending Submission',
    field: 'pendingSubmissionAt',
    status: TimelineStatus.Pending,
  },
  {
    label: 'Submitted',
    field: 'submittedAt',
  },
  {
    label: 'Completed',
    field: 'completedAt',
    status: TimelineStatus.Success,
  },
  {
    label: 'Rejected',
    field: 'rejectedAt',
    status: TimelineStatus.Danger,
  },
];

export const buildWireEvents = ({
  transfer,
  account,
  counterparty,
}: {
  transfer: WireTransfer;
  account: {
    accountNumber: AccountNumber;
    bankAccount: BankAccount;
    entityType?: 'person' | 'business';
  };
  counterparty: Counterparty;
}): TimelineEntry[] => {
  const rejected = (transfer.status.toLowerCase() as TransferStatus) === TransferStatus.REJECTED;
  const events = wireEvents
    .filter(
      (event) =>
        event.field === 'createdAt' ||
        (!rejected && event.field === 'completedAt') ||
        !!transfer[event.field as keyof typeof transfer]
    )
    .map((event) => {
      if (event.field === 'preReviewAt') {
        return buildTimelineEntryForTransferReviews(
          transfer[event.field as keyof typeof transfer] as string | undefined,
          transfer.reviews
        );
      }

      let amount;
      if (transfer.amount) {
        amount = {
          value: typeof transfer.amount === 'string' ? parseFloat(transfer.amount) : transfer.amount,
          isAdding: event.field === 'completedAt',
        };
      }

      const date = parseDate(
        transfer[event.field as keyof typeof transfer] as string | undefined,
        rejected ? '' : 'Pending'
      );

      const partyData =
        event.field === 'createdAt' || event.field === 'completedAt'
          ? buildTransferParty({
              account,
              counterparty,
              isIncoming: transfer.isIncoming,
              isOriginator: event.field === 'createdAt',
            })
          : undefined;

      const defaultStatus = rejected ? TimelineStatus.Danger : TimelineStatus.Pending;
      const status = !!transfer[event.field as keyof typeof transfer] ? event.status : defaultStatus;

      return {
        label: event.label,
        amount,
        date,
        partyData,
        status,
      };
    });

  if (rejected) {
    events.push({
      label: 'Rejected',
      amount: transfer.amount
        ? { value: typeof transfer.amount === 'string' ? parseFloat(transfer.amount) : transfer.amount }
        : undefined,
      status: TimelineStatus.Danger,
      partyData: buildTransferParty({
        account,
        counterparty,
        isIncoming: transfer.isIncoming,
        isOriginator: false,
      }),
    });
  }

  return events;
};
