import React, { PropsWithChildren, useLayoutEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

import { useNavigate } from '~/lib/navigation';
import { useScrollStore } from '~/stores/Scroll';
import { Inner } from '~/styles';

interface PageHeaderProps {
  text: string;
  borderOnScroll?: boolean;
  border?: boolean;
  isScrolled?: boolean;
  tabs?: React.ReactNode;
  backButton?: boolean;
}

const Wrapper = styled.div<PageHeaderProps>`
  position: sticky;
  top: 0;
  z-index: 4;
  display: grid;
  background: ${({ theme }) => theme.background};
  padding: 0 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.gray.blendToBackground(150)};
  transition: border-color 0.1s ease-in-out;

  ${({ border }) =>
    border === false &&
    css`
      border-bottom: 0;
    `};

  ${({ borderOnScroll }) =>
    borderOnScroll &&
    css`
      margin-bottom: 1px;
      border-bottom: 1px solid transparent;
    `};

  ${({ isScrolled, borderOnScroll }) =>
    isScrolled === true &&
    borderOnScroll === true &&
    css`
      border-color: ${({ theme }) => theme.gray.blendToBackground(150)};
    `};
`;

const Grid = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
  position: relative;
  z-index: 1;
`;

const PageTitle = styled.h1`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: auto;
  max-height: 20px;

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 75%;
  }
`;

const StyledButton = styled(Button)`
  padding: 4px;
  margin-left: -8px;
  svg {
    --icon-size: 16px;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  max-height: 20px;
`;

const Tabs = styled.div`
  padding: 12px 24px 0;
`;

export const PageHeader: React.FC<PropsWithChildren<PageHeaderProps>> = (props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const pageFrameScroll = useScrollStore((s) => s.pageFrameScroll);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const ref = wrapperRef.current;

    if (!ref) {
      return;
    }

    ref.scrollTo({ top: 0 });
  }, [wrapperRef]);

  return (
    <Wrapper {...props} isScrolled={!!(pageFrameScroll ?? 0 > 0)}>
      <Inner pt={16} px={24} pb={0}>
        <Grid>
          <PageTitle>
            {props.backButton && (
              <StyledButton onClick={() => navigate(-1)} variant="muted" size="tiny">
                <Icon.ChevronLeft />
              </StyledButton>
            )}
            <span data-cy={`Header: ${props.text}`}>{props.text}</span>
          </PageTitle>
          {props.children && <Actions>{props.children}</Actions>}
        </Grid>
      </Inner>
      {props.tabs && <Tabs>{props.tabs}</Tabs>}
    </Wrapper>
  );
};
