import React, { useRef } from 'react';
import styled from 'styled-components';

import { Button, Chip, Icon, formatNumber } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import {
  EmptyState,
  PageHeader,
  PaginationWrapper,
  PaginationWrapperRefProps,
  PopoverFilterEntry,
  TableColumn,
} from '~/components';
import { ButtonGroupDropdown } from '~/components/ButtonGroupDropdown';
import { RelativeTime } from '~/elements';
import { useNavigate } from '~/lib/navigation';
import { LoanRepository } from '~/repositories';
import { Inner } from '~/styles';
import { adjustTimezone, formatString } from '~/util';

const Description = styled.div`
  display: grid;
  line-height: 20px;
`;

const Note = styled.div`
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const PageLoans: React.FC = () => {
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  const handleFetch = async (params: any) => {
    return LoanRepository.getAll(params).then((response) => {
      const entries: any = [];
      if (response.loans.length) {
        response.loans.map((entry) => {
          entries.push({
            id: entry.id,
            description: [entry.description, entry.maturityDate],
            status: entry.status,
            balance: parseInt(entry.balances?.principalOutstanding ?? '0', 10),
            created: entry.createdAt,
          });
        });
      }
      return { entries, hasMore: Boolean(response?.hasMore) };
    });
  };

  const columns: TableColumn[] = [
    {
      Header: 'Description',
      accessor: 'description',
      width: 'minmax(300px, auto)',
      Cell: (current) => (
        <Description>
          {current.value[0]}
          <Note>Maturity date: {adjustTimezone(new Date(current.value[1])).toLocaleDateString('en-US')}</Note>
        </Description>
      ),
    },
    {
      Header: 'Status',
      width: 'minmax(min-content, 100px)',
      Cell: (current) => (
        <Chip type={current.value === 'current' ? 'info' : 'default'}>{formatString(current.value)}</Chip>
      ),
      accessor: 'status',
    },
    {
      Header: 'Outstanding Balance',
      width: 'min-content',
      Cell: (current) => (
        <Inner px={0} py={4}>
          {formatNumber(current.value)}
        </Inner>
      ),
      accessor: 'balance',
    },
    {
      Header: 'Created',
      accessor: 'created',
      sortType: 'datetime',
      width: 'min-content',
      Cell: (current) => <RelativeTime timestamp={current.value} />,
    },
  ];

  const filter: PopoverFilterEntry[] = [
    {
      id: 'status',
      label: 'Status',
      type: 'select',
      list: [
        {
          value: 'current',
          label: 'Current',
        },
        {
          value: 'delinquent',
          label: 'Delinquent',
        },
        {
          value: 'charged_off',
          label: 'Charged off',
        },
        {
          value: 'in_dispute',
          label: 'In Dispute',
        },
        {
          value: 'canceled',
          label: 'Canceled',
        },
        {
          value: 'paid_off',
          label: 'Paid off',
        },
      ],
    },
    {
      id: 'created',
      label: 'Created',
      type: 'date',
    },
  ];

  return (
    <>
      <PageHeader text="Loans" border={false}>
        <Button
          onClick={() => navigate(`${ROUTE.LOANS}/disbursements`)}
          icon={<Icon.MoneyOut />}
          size="small"
          variant="secondary"
        >
          Disbursements
        </Button>

        <Button
          onClick={() => navigate(`${ROUTE.LOANS}/payments`)}
          icon={<Icon.MoneyIn />}
          size="small"
          variant="secondary"
        >
          Payments
        </Button>

        <ButtonGroupDropdown
          buttons={[
            {
              label: 'Create',
              icon: <Icon.Plus />,
              onClick: () => navigate(`${ROUTE.LOANS}/edit`),
            },
          ]}
          options={[
            {
              label: 'Create Disbursement',
              icon: <Icon.MoneyOut />,
              onClick: () => navigate(`${ROUTE.LOANS}/disbursements/create`),
            },
            {
              label: 'Create Payment',
              icon: <Icon.MoneyIn />,
              onClick: () => navigate(`${ROUTE.LOANS}/payments/create`),
            },
          ]}
        />
      </PageHeader>

      <PaginationWrapper
        tableId="loans"
        ref={paginationRef}
        fetch={handleFetch}
        columns={columns}
        filter={filter}
        empty={
          <EmptyState headline="No loans found" text="Create your first loan to get started.">
            <Button onClick={() => navigate(`${ROUTE.LOANS}/edit`)} size="small" icon={<Icon.Plus />}>
              Create Loan
            </Button>
          </EmptyState>
        }
        rowClick={(row) => {
          navigate(`${ROUTE.LOANS}/edit/${row.original.id}`);
        }}
      />
    </>
  );
};
