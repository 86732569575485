import React from 'react';

import { EditPage, Headline } from '~/styles';

export const PageTasks: React.FC = () => {
  return (
    <EditPage fullWidth>
      <Headline>Tasks</Headline>
      ...
    </EditPage>
  );
};
