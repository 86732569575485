import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Fade } from '@column/column-ui-kit';

import { LogoLoading } from '~/elements/LogoLoading';

interface Props {
  fullHeight?: boolean;
  isLoading: boolean;
}

const Container = styled.div`
  overflow: hidden;
  transition: height 300ms ease;
`;

const LoadingWrapper = styled.div<{ fullHeight?: boolean }>`
  display: flex;

  ${({ fullHeight }) =>
    fullHeight
      ? css`
          margin-top: 10%;
        `
      : css`
          height: 150px;
          margin-top: 30px;
        `};
`;

export const Loading: React.FC<React.PropsWithChildren<Props>> = ({ children, fullHeight, isLoading }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const spinnerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  const [isChildrenVisible, setChildrenVisible] = useState(!isLoading);
  useEffect(() => {
    if (isLoading) {
      setChildrenVisible(false);
      const timeoutId = setTimeout(() => setSpinnerVisible(true), 200);
      return () => clearTimeout(timeoutId);
    }
  }, [isLoading]);

  const [isSpinnerVisible, setSpinnerVisible] = useState(isLoading);
  useEffect(() => {
    if (!isLoading) {
      setSpinnerVisible(false);
      setChildrenVisible(true);
    }
  }, [isLoading]);

  const updateContainerHeight = () => {
    const newHeight = isLoading ? spinnerRef.current?.offsetHeight || 0 : childrenRef.current?.offsetHeight || 0;
    if (containerRef.current && newHeight !== 0) {
      containerRef.current.style.height = `${newHeight}px`;
    }
  };

  useLayoutEffect(() => {
    updateContainerHeight();
  }, [isChildrenVisible, isSpinnerVisible, children]);

  return (
    <Container ref={containerRef}>
      <Fade show={isSpinnerVisible} timeout={200} onEnter={updateContainerHeight}>
        <LoadingWrapper fullHeight={fullHeight} ref={spinnerRef}>
          <LogoLoading />
        </LoadingWrapper>
      </Fade>
      {children && (
        <Fade show={isChildrenVisible} timeout={200} onEnter={updateContainerHeight}>
          <div ref={childrenRef}>{children}</div>
        </Fade>
      )}
    </Container>
  );
};
