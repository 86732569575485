import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { Button, Chip, Dropdown, Icon, Tooltip } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import {
  EmptyState,
  PageHeader,
  PaginationWrapper,
  PaginationWrapperRefProps,
  PopoverFilterEntry,
  TableColumn,
} from '~/components';
import { ButtonGroupDropdown } from '~/components/ButtonGroupDropdown';
import { useDialog } from '~/components/Dialog/Provider';
import { useNavigate } from '~/lib/navigation';
import { EntityRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { formatString } from '~/util';

const Badge = styled(Tooltip)`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 3px 0 3px 4px;
  vertical-align: top;

  svg {
    --icon-size: 18px;
    --icon-color: ${({ theme }) => theme.primary.background};
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledDropdown = styled(Dropdown)`
  margin: -10px 0;
`;

export const PageEntities: FC = () => {
  const { currentUser, currentPlatform } = useSessionStore();
  const { openAlert } = useDialog();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  const getVerificationStatus = (status: string) => {
    if (status === 'VERIFICATION_STATUS_NONE') {
      return <Chip>None</Chip>;
    }
    if (status === 'UNVERIFIED') {
      return (
        <Chip type="warning" icon={<Icon.CircleQuestionmark />}>
          Unverified
        </Chip>
      );
    }
    if (status === 'PENDING') {
      return <Chip icon={<Icon.Clock />}>Pending</Chip>;
    }
    if (status === 'MANUAL_REVIEW') {
      return <Chip icon={<Icon.User />}>Manual review</Chip>;
    }
    if (status === 'VERIFIED') {
      return (
        <Chip type="success" icon={<Icon.CircleCheck />}>
          Verified
        </Chip>
      );
    }
    if (status === 'DENIED') {
      return (
        <Chip type="danger" icon={<Icon.CircleCross />}>
          Denied
        </Chip>
      );
    }
  };

  const handleDelete = (entry: any) => {
    openAlert({
      headline: 'Delete entity',
      content: 'This action cannot be undone.',
      confirmButton: {
        label: 'Delete',
        variant: 'danger',
      },
      onSuccess: () => {
        if (!currentUser) {
          return;
        }
        EntityRepository.delete(entry.id)
          .then(() => {
            addSuccessNotification({
              content: `${entry.name} deleted`,
            });
            if (paginationRef.current) {
              paginationRef.current.fetch();
            }
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
            });
          });
      },
    });
  };

  const handleFetch = async (params: any) => {
    return EntityRepository.getAll(params).then((response) => {
      const entries: any = [];
      if (response.entities.length) {
        response.entities.map((entry: any) => {
          entries.push({
            id: entry.id,
            name: [entry.name, entry.isRoot],
            isRoot: entry.isRoot,
            type: formatString(entry.type),
            status: entry.verificationStatus,
            action: (
              <StyledDropdown
                isInside
                positionRight
                options={[
                  {
                    label: 'Edit',
                    onClick: () => navigate(`${ROUTE.ENTITIES}/edit/${entry.type.toLowerCase()}/${entry.id}`),
                  },
                  {
                    label: 'Delete',
                    isDanger: true,
                    onClick: () => handleDelete(entry),
                  },
                ]}
                variant="dots"
              />
            ),
          });
        });
      }
      return { entries, hasMore: Boolean(response?.hasMore) };
    });
  };

  const filter: PopoverFilterEntry[] = [
    {
      id: 'isRoot',
      label: 'Root Entity',
      type: 'boolean',
    },
    {
      id: 'type',
      label: 'Type',
      type: 'select',
      list: [
        {
          value: 'PERSON',
          label: 'Person',
        },
        {
          value: 'BUSINESS',
          label: 'Business',
        },
      ],
    },
    {
      id: 'verification_status',
      label: 'Status',
      type: 'select',
      list: [
        {
          value: 'VERIFICATION_STATUS_NONE',
          label: 'None',
        },
        {
          value: 'UNVERIFIED',
          label: 'Unverified',
        },
        {
          value: 'PENDING',
          label: 'Pending',
        },
        {
          value: 'MANUAL_REVIEW',
          label: 'Manual Review',
        },
        {
          value: 'VERIFIED',
          label: 'Verified',
        },
        {
          value: 'DENIED',
          label: 'Denied',
        },
      ],
    },
    {
      id: 'created',
      label: 'Created',
      type: 'date',
    },
  ];

  const columns: TableColumn[] = [
    {
      Header: 'Name',
      width: 'minmax(300px, auto)',
      Cell: (current) => (
        <NameWrapper>
          {current.value[0]}
          {current.value[1] && (
            <Badge content="Root entity">
              <Icon.Badge />
            </Badge>
          )}
        </NameWrapper>
      ),
      accessor: 'name',
    },
    {
      Header: 'Type',
      width: 'min-content',
      Cell: (current) => (
        <Chip type="info" icon={current.value === 'Business' ? <Icon.Business /> : <Icon.User />}>
          {formatString(current.value)}
        </Chip>
      ),
      accessor: 'type',
    },
    {
      Header: 'Status',
      width: 'min-content',
      Cell: (current) => <>{getVerificationStatus(current.value)}</>,
      accessor: 'status',
    },
    {
      Header: '',
      width: 'min-content',
      accessor: 'action',
    },
  ];

  return (
    <>
      <PageHeader text="Entities" border={false}>
        <Button
          variant="secondary"
          size="small"
          onClick={() => {
            if (currentPlatform?.isLiveEnabled) {
              navigate(ROUTE.PLATFORM);
              return;
            }
            navigate(ROUTE.PLATFORM_ROOT_ENTITY);
          }}
        >
          Root Entity
        </Button>
        <ButtonGroupDropdown
          buttons={[
            {
              label: 'Create Entity',
              icon: <Icon.Plus />,
              onClick: () => navigate(`${ROUTE.ENTITIES}/edit/person`),
            },
          ]}
          options={[
            {
              label: 'Person',
              icon: <Icon.User />,
              onClick: () => navigate(`${ROUTE.ENTITIES}/edit/person`),
            },
            {
              label: 'Business',
              icon: <Icon.Business />,
              onClick: () => navigate(`${ROUTE.ENTITIES}/edit/business`),
            },
          ]}
        />
      </PageHeader>

      <PaginationWrapper
        tableId="entities"
        ref={paginationRef}
        fetch={handleFetch}
        columns={columns}
        filter={filter}
        empty={
          <EmptyState headline="No entities found" text="Add either a person or a business to your test environment.">
            <Button onClick={() => navigate(`${ROUTE.ENTITIES}/edit/person`)} size="small" icon={<Icon.User />}>
              Person
            </Button>
            <Button onClick={() => navigate(`${ROUTE.ENTITIES}/edit/business`)} size="small" icon={<Icon.Business />}>
              Business
            </Button>
          </EmptyState>
        }
        rowClick={(row) => {
          if (row.original.isRoot) {
            if (currentPlatform?.isLiveEnabled) {
              navigate(ROUTE.PLATFORM);
              return;
            }
            navigate(ROUTE.PLATFORM_ROOT_ENTITY);
            return;
          }
          navigate(
            `${ROUTE.ENTITIES}/edit/${(row.original.type || 'person').toString().toLowerCase()}/${row.original.id}`
          );
        }}
      />
    </>
  );
};
