import React, { FC, useCallback, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Currencies, SegmentedControl } from '@column/column-ui-kit';

import { Fields, FormData, Row } from '../index';
import { FormField } from '~/components/FormField';
import { InstitutionsField, InstitutionsFieldRef } from '~/components/InstitutionsField';
import { RoutingNumberInput } from '~/components/RoutingNumberInput';
import countryCodeJSON from '~/data/wires/country-codes.json';
import { useDebounce, useInstitution } from '~/hooks';
import { FinancialInstitution } from '~/repositories';
import { Divider, FormElement, FormLabel, InputSuccessIcon } from '~/styles';
import { titleCase, validateBIC, validateRoutingNumber } from '~/util';

export const ContentBank: FC = () => {
  const { createRequest: fetchInstitution } = useInstitution();
  const { setValue, watch } = useFormContext<FormData>();
  const institutionsFieldRef = useRef<InstitutionsFieldRef>(null);
  const [routingNumberError, setRoutingNumberError] = useState<string | boolean>(false);

  const handleInstitutionChange = useCallback(
    (entry: FinancialInstitution | null, isRoutingNumberField?: boolean) => {
      setValue('temporaryData.institution', entry);
      setValue('temporaryData.institutionSidebar', entry !== null);

      setRoutingNumberError(entry === null);

      const countryCodeEntry = countryCodeJSON.find((e) => e.countryCode === entry?.countryCode) ?? null;

      setValue('temporaryData.countryCodeEntry', countryCodeEntry);
      setValue(
        'temporaryData.currency',
        Currencies.find(({ code }) => code === countryCodeEntry?.currencyCode) ?? null
      );

      if (entry?.routingNumberType) {
        setValue('routingNumberType', entry.routingNumberType);
      }

      if (!isRoutingNumberField) {
        setValue('routingNumber', entry?.routingNumber ?? '');
      } else {
        if (entry?.fullName) {
          institutionsFieldRef.current?.setValue(titleCase(String(entry?.fullName)));
        } else {
          institutionsFieldRef.current?.setValue('');
        }
      }

      setValue('localBankCountryCode', entry?.countryCode ?? '');
      setValue('address.countryCode', entry?.countryCode ?? '');
    },
    [setValue, institutionsFieldRef]
  );

  const handleRoutingNumberValidate = useDebounce(
    async (value?: string) => {
      if (!value || value.length === 0) {
        handleInstitutionChange(null, true);

        setRoutingNumberError('Please enter a Routing Number / BIC');
        return;
      }

      if (validateRoutingNumber(value) || validateBIC(value)) {
        try {
          const institution = await fetchInstitution({ routingNumber: value });

          handleInstitutionChange(institution ?? null, true);

          setRoutingNumberError(!institution);
          return;
        } catch (error) {
          handleInstitutionChange(null, true);

          setRoutingNumberError('Please enter a valid Routing Number / BIC');
          return;
        }
      } else {
        handleInstitutionChange(null, true);

        setRoutingNumberError('Please enter a valid Routing Number / BIC');
        return;
      }
    },
    400,
    []
  );

  return (
    <Fields>
      <Row>
        <FormElement>
          <FormLabel>Search Financial Institution</FormLabel>
          <InstitutionsField ref={institutionsFieldRef} onChange={handleInstitutionChange} />
        </FormElement>
      </Row>

      <Row>
        <Divider>or</Divider>
      </Row>

      <FormField<FormData>
        id="routingNumberType"
        label="Routing Number Type"
        defaultValue="aba"
        tooltip="BIC codes (Bank Identifier Codes) are international codes used to identify banks worldwide, and are standard for international wire transfers. ABA routing numbers are used for banks within the United States."
        oneColumn
        rules={{ required: true }}
      >
        {({ value, onChange }) => (
          <SegmentedControl
            fullWidth
            active={value ?? 'aba'}
            onOptionChange={onChange}
            options={[
              {
                label: 'ABA',
                value: 'aba',
              },
              {
                label: 'BIC',
                value: 'bic',
              },
            ]}
          />
        )}
      </FormField>

      <FormField<FormData>
        id="routingNumber"
        label={watch('routingNumberType') === 'bic' ? 'BIC / Swift' : 'Routing Number'}
        oneColumn
      >
        {({ value, onChange, onBlur, ref }) => (
          <RoutingNumberInput
            value={value as string}
            onChange={(val) => {
              onChange(val);

              handleRoutingNumberValidate(val);

              if (val.length === 0) {
                handleInstitutionChange(null, true);
              }
            }}
            onBlur={onBlur}
            hasError={!!routingNumberError}
            ref={ref}
            placeholder={watch('routingNumberType') === 'bic' ? 'BIC / Swift' : 'Routing Number'}
          >
            <InputSuccessIcon $isSuccess={!routingNumberError && !!value && String(value).length > 0} />
          </RoutingNumberInput>
        )}
      </FormField>
    </Fields>
  );
};
