import chroma from 'chroma-js';
import React from 'react';
import styled from 'styled-components';

import { Chip, Icon, formatNumber } from '@column/column-ui-kit';

import { CopyInput, PageHeader } from '~/components';
import { TransferStatusChip } from '~/lib/transfers';
import { FormElement, FormLabel, Inner } from '~/styles';
import { getDateLongUTC } from '~/util';

export const TransferSummary = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const TransferAmount = styled.div<{ $isAdding?: boolean }>`
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: 600;
  display: flex;
  gap: 4px;
  align-items: baseline;
  color: ${({ theme, $isAdding }) =>
    $isAdding
      ? chroma(theme.success.background)
          .darken(theme.id !== 'dark' ? 0.75 : 0)
          .hex()
      : theme.foreground};
`;

export const TransferCurrency = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(500)};
`;

export const TransferMeta = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const TransferDate = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const TransferID = styled.div`
  min-width: 310px;
  max-width: 25%;
`;

export const InfoForm = styled.form`
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

interface TransferPageHeaderProps {
  amount: number | string;
  createdAt?: string;
  isIncoming: boolean;
  incomingText?: string;
  outgoingText?: string;
  isReturn?: boolean;
  status?: string;
  transferId: string;
  transferType: string;
  isAdding?: boolean;
}

export const TransferPageHeader: React.FC<React.PropsWithChildren<TransferPageHeaderProps>> = ({
  amount,
  children,
  createdAt,
  incomingText,
  isIncoming,
  isReturn,
  outgoingText,
  status,
  transferId,
  transferType,
  isAdding,
}) => {
  return (
    <>
      <PageHeader text="Transfer" backButton>
        {children}
      </PageHeader>
      <Inner>
        <TransferSummary>
          <div>
            <TransferAmount $isAdding={isAdding}>
              {isAdding === false && '-'}
              {formatNumber(amount)}
              <TransferCurrency>USD</TransferCurrency>
            </TransferAmount>
            <TransferMeta>
              <Chip>{transferType}</Chip>
              {isIncoming ? (
                <Chip icon={<Icon.CircleArrowDown />}>{incomingText ?? 'Incoming'}</Chip>
              ) : (
                <Chip icon={<Icon.CircleArrowUp />}>{outgoingText ?? 'Outgoing'}</Chip>
              )}
              {status && <TransferStatusChip isReturn={isReturn} status={status} />}
              <TransferDate>{createdAt ? getDateLongUTC(createdAt) : ''}</TransferDate>
            </TransferMeta>
          </div>

          <TransferID>
            <FormElement>
              <FormLabel>Transfer ID</FormLabel>
              <CopyInput value={transferId} size="small" />
            </FormElement>
          </TransferID>
        </TransferSummary>
      </Inner>
    </>
  );
};
