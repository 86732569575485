import React, { type FC } from 'react';
import styled from 'styled-components';

import { Icon, Tooltip } from '@column/column-ui-kit';

import { CopyInput } from '~/components';
import { Flex, Inner, Text } from '~/styles';

export const CopyRowWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 220px) auto;
  align-items: start;
  gap: 16px;
  line-height: 16px;
  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  &:first-child {
    border-top: none;
  }
`;

export const StyledCopyInput = styled(CopyInput)`
  background: transparent;
  padding: 8px;
  margin: 8px;
  height: 32px;
  font-size: 14px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  box-shadow: none;

  &:hover {
    background: ${({ theme }) => theme.secondary.blendToBackground(50)};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(300)};
  }
`;

const IconWarning = styled(Icon.Warning)`
  --icon-color: ${({ theme }) => theme.warning.background};
  --icon-size: 14px;
`;

interface CopyRowProps {
  label: string;
  value: string | React.ReactNode;
  warning?: string;
}

export const CopyRow: FC<CopyRowProps> = (props) => {
  return (
    <CopyRowWrapper>
      <Inner p={16}>
        <Flex gap={4} alignItems="center">
          <Text size={14} whiteSpace="nowrap" weight="medium">
            {props.label}
          </Text>
          {props.warning && (
            <Tooltip content={props.warning}>
              <IconWarning />
            </Tooltip>
          )}
        </Flex>
      </Inner>
      {typeof props.value === 'string' ? <StyledCopyInput value={props.value} /> : props.value}
    </CopyRowWrapper>
  );
};
