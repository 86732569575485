import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Currency, Dropdown, DropdownOption, Icon } from '@column/column-ui-kit';

import { useCounterparties, useCounterparty, useDebounce } from '~/hooks';
import { useFormErrorHandler } from '~/hooks/useFormErrorHandler';
import { useCounterpartyValidation } from '~/hooks/validate/useCounterpartyValidate';
import useIntlWireValidate from '~/hooks/validate/useIntlWireValidate';
import { Counterparty, CounterpartyType } from '~/repositories';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { ApiError } from '~/typings/API';

import { FormField, FormFieldProps } from './FormField';

export type CounterpartyModalType = 'ach' | 'wire' | 'intl-wire' | 'realtime';

export interface CounterpartyFieldProps {
  onLoaded?: () => void;
  type: CounterpartyModalType;
}

interface FormFieldCounterpartyProps<T extends FieldValues> extends FormFieldProps<T>, CounterpartyFieldProps {}

const CounterpartyField = styled.div`
  display: flex;
  gap: 8px;
`;

export const FormFieldCounterparty = <T extends FieldValues>({
  id,
  type,
  onLoaded,
  ...restProps
}: FormFieldCounterpartyProps<T>) => {
  const { createRequest: fetchCounterparties } = useCounterparties({
    onInitialLoad: onLoaded,
    onPlatformChange: (data) => {
      setCounterpartyList([...(data?.counterparties ?? [])]);
    },
  });
  const { createRequest: requestCounterparty } = useCounterparty();

  const { addSuccessNotification, addDangerNotification, clearNotifications } = useNotificationStore();
  const openModal = useModalStore((s) => s.openModal);
  const [counterpartyList, setCounterpartyList] = useState<Counterparty[]>([]);
  const [activeCounterparty, setActiveCounterparty] = useState<CounterpartyType | undefined>(undefined);

  const { setValue, reset } = useFormContext<T>();

  const { isCounterpartyValid } = useCounterpartyValidation(activeCounterparty?.localBankCountryCode ?? 'US');
  const { getCurrencyByCountryCode } = useIntlWireValidate();
  const { handleErrors } = useFormErrorHandler({ defaultDisplayType: 'page' });

  const handleSearchCounterparties = useDebounce(
    async (value?: string) => {
      let list: Counterparty[] = [];

      try {
        if (value && value.length > 0) {
          const isNumber = !isNaN(parseInt(value, 10));
          const results = await Promise.all([
            isNumber ? fetchCounterparties({ accountNumber: value }) : undefined,
            isNumber ? fetchCounterparties({ routingNumber: value }) : undefined,
            fetchCounterparties({ description: value }),
          ]);

          list = [...new Set(results.map((result) => result?.counterparties ?? []).flat())];
        } else {
          const result = await fetchCounterparties();

          list = [...new Set(result?.counterparties ?? [])];
        }
      } catch (error) {
        addDangerNotification({
          content: (error as ApiError).message as string,
        });
      }

      setCounterpartyList(list);
    },
    300,
    [fetchCounterparties]
  );

  const handleCounterpartyAdd = useCallback(
    (createdCounterparty: CounterpartyType, defaultCurrency?: Currency) => {
      clearNotifications('counterparty-errors');

      setActiveCounterparty(createdCounterparty);

      if (type === 'intl-wire') {
        setValue('intlWire.counterparty' as Path<T>, createdCounterparty as PathValue<T, Path<T>>);
        setValue('intlWire.defaultCurrency' as Path<T>, defaultCurrency as PathValue<T, Path<T>>);
      }

      fetchCounterparties()
        .then(() => {
          setTimeout(() => setCounterpartyList((prev) => [createdCounterparty, ...prev]), 0);

          setValue(id, createdCounterparty.id as PathValue<T, Path<T>>, {
            shouldValidate: true,
            shouldTouch: true,
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
          });
        });
    },
    [fetchCounterparties, setValue, id]
  );

  useEffect(() => {
    handleSearchCounterparties();

    return () => {
      clearNotifications('counterparty-errors');
      reset();
    };
  }, []);

  const counterpartyToDropdown = useCallback(
    (counterparty: Counterparty): DropdownOption => ({
      label:
        counterparty?.description && counterparty?.name
          ? `${counterparty.name} (${counterparty.description})`
          : counterparty?.description || counterparty?.name || counterparty?.wire?.beneficiaryName || 'Unnamed',
      small: `Rtn# ${counterparty.routingNumber} / Acc# ${counterparty.accountNumber}`,
      value: counterparty.id,
    }),
    [type]
  );

  const handleResetCounterparty = useCallback(() => {
    setActiveCounterparty(undefined);

    setValue(id, undefined as PathValue<T, Path<T>>, {
      shouldValidate: true,
      shouldTouch: true,
    });

    clearNotifications('counterparty-errors');

    if (type === 'intl-wire') {
      setValue('intlWire.counterparty' as Path<T>, undefined as PathValue<T, Path<T>>);
      setValue('intlWire.defaultCurrency' as Path<T>, undefined as PathValue<T, Path<T>>);
    }
  }, [setValue, id, type]);

  const handleEditCounterparty = useCallback(
    (oldCounterparty?: Counterparty) => {
      openModal('CounterpartyInternational', {
        values: oldCounterparty ?? activeCounterparty,
        callback: handleCounterpartyAdd,
      });
    },
    [openModal, handleCounterpartyAdd, activeCounterparty]
  );

  const handleCounterpartySelect = useCallback(
    (counterpartyId: string) => {
      if (counterpartyId === activeCounterparty?.id) {
        handleResetCounterparty();

        return;
      }

      clearNotifications('counterparty-errors');

      setTimeout(async () => {
        try {
          const newCounterparty = await requestCounterparty({ id: counterpartyId });

          if (!newCounterparty || !newCounterparty.routingNumber || !newCounterparty.accountNumber) {
            return;
          }

          const isValid = await isCounterpartyValid(newCounterparty);

          if (isValid !== true) {
            handleErrors(isValid, {
              display: 'counterparty-errors',
              headline: 'Counterparty has the following errors:',
              timeout: -1,
              actionButton: {
                label: 'Resolve',
                onClick: () => handleEditCounterparty(newCounterparty),
              },
            });
          } else {
            const defaultCurrency = getCurrencyByCountryCode(newCounterparty.localBankCountryCode ?? 'USD');

            setValue('intlWire.counterparty' as Path<T>, newCounterparty as PathValue<T, Path<T>>);
            setValue('intlWire.defaultCurrency' as Path<T>, defaultCurrency as PathValue<T, Path<T>>);

            setValue('intlWire.currencyCode' as Path<T>, defaultCurrency.code as PathValue<T, Path<T>>);

            addSuccessNotification({
              content: 'Counterparty selected successfully',
            });
          }
          setActiveCounterparty(newCounterparty);

          setValue(id as Path<T>, newCounterparty.id as PathValue<T, Path<T>>, {
            shouldValidate: true,
            shouldTouch: true,
          });
        } catch (error) {
          addDangerNotification({
            content: (error as ApiError).message,
          });
        }
      }, 0);
    },
    [getCurrencyByCountryCode, activeCounterparty, setValue, id, handleResetCounterparty, handleEditCounterparty]
  );

  return (
    <FormField<T> id={id} updateOn={id} {...restProps}>
      {({ value, onChange }, { error }) => (
        <CounterpartyField>
          <Dropdown
            options={counterpartyList?.map(counterpartyToDropdown) ?? []}
            active={value}
            fullWidth
            isDisabled={type === 'intl-wire' && !!activeCounterparty}
            search
            searchLabel="Search..."
            onSearchChange={handleSearchCounterparties}
            preventSelect={type === 'intl-wire'}
            onChange={(c) => {
              if (type !== 'intl-wire') {
                onChange(c);
              } else {
                handleCounterpartySelect(c);
              }
            }}
            hasError={!!error}
          />
          {type === 'intl-wire' && !!activeCounterparty ? (
            <CounterpartyField>
              <Button variant="secondary" onClick={() => handleEditCounterparty()} type="button">
                Edit
              </Button>
              <Button variant="secondary" onClick={handleResetCounterparty} type="button">
                Reset
              </Button>
            </CounterpartyField>
          ) : (
            <Button
              variant="secondary"
              icon={<Icon.Plus />}
              onClick={() =>
                type === 'intl-wire'
                  ? openModal('CounterpartyInternational', {
                      callback: handleCounterpartyAdd,
                    })
                  : openModal('Counterparty', {
                      type,
                      callback: handleCounterpartyAdd,
                    })
              }
              type="button"
            >
              New
            </Button>
          )}
        </CounterpartyField>
      )}
    </FormField>
  );
};
