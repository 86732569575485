import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Icon, PhoneInput, Tooltip } from '@column/column-ui-kit';

import { PendingIcon } from '~/elements';
import { useAddress } from '~/hooks';
import { FactorType, FactorTypeResponse } from '~/repositories';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FieldStatus, FormElementAction, FormFieldStatus, FormFieldStatusWrapper } from '~/styles';
import { log } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

interface Props {
  onPhoneNumberChange: (phoneNumber: string) => void;
}

export const SectionMfaSms: React.FC<Props> = ({ onPhoneNumberChange }) => {
  const openModal = useModalStore((state) => state.openModal);
  const { countries } = useAddress({ types: ['country'] });
  const { currentUser, refetchCurrentUser } = useSessionStore();
  const { addDangerNotification, addInfoNotification } = useNotificationStore();

  const { handleReCaptcha } = reCaptchaCheck();

  const [phoneNumber, setPhoneNumber] = useState<string>('');

  useEffect(() => {
    if (currentUser) {
      setPhoneNumber(currentUser.phoneNumber ?? '');
    }
  }, [currentUser]);

  const handleEnableSmsMfa = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!currentUser) {
        return;
      }

      if (!phoneNumber) {
        addDangerNotification({
          content: 'Add a valid phone number first.',
        });
        return;
      }

      handleReCaptcha(
        (reCaptchaToken) =>
          currentUser
            .update({ phoneNumber }, reCaptchaToken)
            .then(() => {
              handleReCaptcha(
                (reCaptchaTokenMfa) =>
                  currentUser
                    .setupMfa(reCaptchaTokenMfa, {
                      factorName: phoneNumber,
                      factorType: FactorType.SMS,
                    })
                    .then(() => {
                      log({
                        name: 'User enable MFA requested',
                        context: currentUser,
                      });

                      addInfoNotification({
                        content: 'Please confirm your phone number to finish 2FA setup.',
                      });

                      refetchCurrentUser();
                    })
                    .catch((error) => {
                      addDangerNotification({
                        content: error.message,
                      });
                    }),
                (err) => {
                  addDangerNotification({
                    content: err.message,
                  });
                }
              );
            })
            .catch((error) => {
              addDangerNotification({
                content: error.message,
              });
            }),
        (err) => {
          addDangerNotification({
            content: err.message,
          });
        }
      );
    },
    [currentUser, phoneNumber]
  );

  const handleConfirmMfa = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      openModal('Authenticate');
    },
    [openModal]
  );

  const isMfaPending = useMemo(
    () =>
      phoneNumber === currentUser?.phoneNumber &&
      currentUser?.factors.some((factor) => factor.factorType === FactorTypeResponse.SMS && !factor.factorVerified),
    [currentUser, phoneNumber]
  );

  const isMfaVerified = useMemo(
    () =>
      phoneNumber === currentUser?.phoneNumber &&
      currentUser?.factors.some((factor) => factor.factorType === FactorTypeResponse.SMS && factor.factorVerified),
    [currentUser, phoneNumber]
  );

  if (!currentUser) {
    return null;
  }

  return (
    <FormElementAction gap={!isMfaVerified || isMfaPending ? 8 : 0}>
      <FormFieldStatusWrapper>
        <PhoneInput
          value={phoneNumber}
          onChange={(value: string) => {
            onPhoneNumberChange(value);
            setPhoneNumber(value);
          }}
          placeholder="Phone number"
          countryList={countries}
        />
      </FormFieldStatusWrapper>

      {isMfaVerified ? (
        <FormFieldStatus>
          <FieldStatus show={isMfaVerified} color="success">
            Enabled
            <Icon.Checkmark />
          </FieldStatus>
        </FormFieldStatus>
      ) : isMfaPending ? (
        <FormFieldStatus>
          <FieldStatus show={isMfaPending}>
            Pending
            <PendingIcon />
          </FieldStatus>
        </FormFieldStatus>
      ) : (
        <FormFieldStatus>
          <FieldStatus color="warning">
            Not enabled
            <Icon.Warning />
          </FieldStatus>
        </FormFieldStatus>
      )}

      {isMfaPending ? (
        <Button onClick={handleConfirmMfa} icon={<Icon.CircleCheck />} variant="secondary" size="small">
          Confirm
        </Button>
      ) : (
        !isMfaVerified &&
        (currentUser.isEmailVerified ? (
          <Button onClick={handleEnableSmsMfa} icon={<Icon.PhoneAdd />} variant="secondary" size="small">
            Enable
          </Button>
        ) : (
          <Tooltip content="Verify email first">
            <Button icon={<Icon.PhoneAdd />} variant="secondary" isDisabled size="small">
              Enable
            </Button>
          </Tooltip>
        ))
      )}
    </FormElementAction>
  );
};
