import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Chip, Dropdown, Fade, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { PageHeader, Table, TableColumn } from '~/components';
import { LogoLoading, RelativeTime } from '~/elements';
import { useQueryParams } from '~/hooks/useQueryParams';
import { useAcceptPlatformInvite, useListPlatformInvites, useListPlatformRoles } from '~/hooks/useUsers';
import { useNavigate } from '~/lib/navigation';
import { Invite } from '~/repositories/InviteRepository';
import { getRoleLabel } from '~/repositories/RoleRepository';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';

import { AcceptPlatformInviteModal } from './AcceptPlatformInviteModal';

export interface PlatformList {
  id?: string;
  isInvite?: boolean;
  name?: string;
  role?: string;
  hasPermission?: boolean;
  created?: Date | undefined;
  action?: ReactNode;
}

interface InviteModalParams {
  invite: Invite;
  inviteCode?: string;
}

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

const AcceptInviteButton = styled(Button)`
  margin-left: auto;
`;

const RoleHelpButton = styled(Button)`
  padding: 0;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};
`;

export const PagePlatforms: React.FC = () => {
  const { queryParams, removeQueryParam } = useQueryParams<{ code?: string; invite?: string }>();
  const { addEventListener: addSessionEventListener, currentUser } = useSessionStore();
  const openHelpSidebar = useHelpSidebarStore((state) => state.openHelpSidebar);
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const addSuccesNotification = useNotificationStore((s) => s.addSuccessNotification);
  const navigate = useNavigate();
  const [pendingAcceptInvitePlatformId, setPendingAcceptInvitePlatformId] = useState<string | undefined>(undefined);

  const [inviteInModal, setInviteInModal] = useState<InviteModalParams>();
  const closeInviteModal = useCallback(() => {
    setInviteInModal(undefined);
    removeQueryParam('code');
    removeQueryParam('invite');
  }, [removeQueryParam]);

  const listPlatformRoles = useListPlatformRoles({ shouldNotLoad: currentUser === undefined });
  const listPlatformInvites = useListPlatformInvites({ shouldNotLoad: currentUser === undefined });
  const fetchPlatforms = useCallback(() => {
    if (currentUser) {
      listPlatformRoles.createRequest({ dashboardUserId: currentUser.id });
      listPlatformInvites.createRequest({ dashboardUserId: currentUser.id });
    }
  }, [currentUser]);
  useEffect(fetchPlatforms, [currentUser]);
  useEffect(() => {
    addSessionEventListener('accept_platform_invite', fetchPlatforms);
  }, [addSessionEventListener]);

  useEffect(() => {
    const inviteCode = queryParams?.code;
    const invitePlatformId = queryParams?.invite;
    if (!invitePlatformId || !listPlatformInvites.response) return;

    const invite = listPlatformInvites.response.invites.find((i) => i.platformId === invitePlatformId);
    if (!invite) return;

    setInviteInModal({ invite, inviteCode });
  }, [listPlatformInvites.response, queryParams]);

  const acceptPlatformInvite = useAcceptPlatformInvite({
    onError: (error) => {
      addDangerNotification({
        content: error.message ?? 'Something went wrong, please try again.',
      });
      setPendingAcceptInvitePlatformId(undefined);
    },
    onSuccess: () => {
      addSuccesNotification({
        content: 'Invite accepted',
      });
      setPendingAcceptInvitePlatformId(undefined);
      fetchPlatforms();
    },
  });

  const platformsColumns: TableColumn[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 'minmax(300px, auto)',
        Cell: (current) => <>{current.value}</>,
      },
      {
        Header: () => (
          <RoleHelpButton
            icon={<Icon.CircleQuestionmark />}
            onClick={() => openHelpSidebar('Roles')}
            variant="subtle"
            size="small"
          >
            Role
          </RoleHelpButton>
        ),
        width: 'minmax(min-content, 100px)',
        Cell: (current) => <Chip>{current.value}</Chip>,
        accessor: 'role',
      },
      {
        Header: 'Created',
        accessor: 'created',
        sortType: 'datetime',
        width: 'min-content',
        Cell: (current) => <RelativeTime timestamp={current.value} />,
      },
      {
        Header: '',
        width: 'min-content',
        accessor: 'action',
      },
    ],
    [openHelpSidebar]
  );

  const platformsTableData: PlatformList[] | undefined = useMemo(() => {
    const platformInvites = listPlatformInvites.response?.invites.map(
      ({ createdAt, platformId, platformName, platformRole }) => ({
        created: createdAt,
        id: platformId,
        isInvite: true,
        name: platformName,
        role: getRoleLabel(platformRole),
        action: (
          <AcceptInviteButton
            isDisabled={pendingAcceptInvitePlatformId !== undefined}
            isLoading={pendingAcceptInvitePlatformId === platformId}
            size="tiny"
            onClick={(e: React.MouseEvent) => {
              acceptPlatformInvite.createRequest({ platformId });
              setPendingAcceptInvitePlatformId(platformId);
              e.stopPropagation();
            }}
          >
            Accept
          </AcceptInviteButton>
        ),
      })
    );

    const platforms = listPlatformRoles.response?.platformRoles.map((entry) => ({
      id: entry.platformId,
      isInvite: false,
      name: entry.platformName,
      role: getRoleLabel(entry.name),
      hasPermission: entry.platformLevelPermissions.platformInfo !== 'none',
      created: entry.createdAt as any,
      action: entry.platformLevelPermissions.platformInfo !== 'none' && (
        <Dropdown
          isInside
          positionRight
          options={[
            {
              label: 'Edit',
              onClick: () => navigate(`${ROUTE.PLATFORMS}/edit/${entry.platformId}`),
            },
          ]}
          variant="dots"
        />
      ),
    }));

    return [...(platformInvites ?? []), ...(platforms ?? [])];
  }, [listPlatformRoles.response, listPlatformInvites.response, pendingAcceptInvitePlatformId]);

  return (
    <>
      <AcceptPlatformInviteModal
        invite={inviteInModal?.invite}
        inviteCode={inviteInModal?.inviteCode}
        open={!!inviteInModal}
        onClose={closeInviteModal}
        onSave={closeInviteModal}
      />
      <PageHeader text="Your Platforms" />
      <Fade show={listPlatformRoles.isInitialLoad || listPlatformInvites.isInitialLoad} base={StyledLoading} />
      <Fade show={!listPlatformRoles.isInitialLoad && !listPlatformInvites.isInitialLoad}>
        <Table
          columns={platformsColumns}
          data={platformsTableData}
          onRowClick={(row) => {
            const originalRow: PlatformList = row.original;

            if (originalRow.isInvite) {
              const invite = (listPlatformInvites.response ?? { invites: [] }).invites.find(
                (i) => i.platformId === originalRow.id
              );
              if (!invite) return;
              setInviteInModal({ invite });
              return;
            }

            if (!originalRow.hasPermission) {
              addDangerNotification({
                content: "You don't have permissions to view this page.",
                actionButton: {
                  label: 'Roles',
                  onClick: () => openHelpSidebar('Roles'),
                },
              });
              return;
            }

            navigate(`${ROUTE.PLATFORMS}/edit/${originalRow.id}`);
          }}
          isLoading={listPlatformRoles.isLoading || listPlatformInvites.isLoading}
        />
      </Fade>
    </>
  );
};
