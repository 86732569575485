import { CreateCustomRoleRequestObject, CustomRolePermissionLevel } from '~/typings/API';

export enum Access {
  Default = 'default',
  NoAccess = 'no-access',
  ViewOnly = 'view-only',
  FullAccess = 'full-access',
  Custom = 'custom',
}

export enum Mode {
  Live = 'live',
  Sandbox = 'sandbox',
}

export type Permissions = { [permission: string]: CustomRolePermissionLevel };

export type TransfersAndAccountsFields = Pick<
  CreateCustomRoleRequestObject,
  'bankAccountLevelOverrides' | 'entityLevelOverrides' | 'platformLevelPermissions'
>;

const OptionCreate = { label: 'Create', value: 'write' };
const OptionCreateAndEdit = { label: 'Create & Edit', value: 'write' };
const OptionDefault = { label: 'Default', value: 'default' };
const OptionEdit = { label: 'Edit Access', value: 'write' };
const OptionUpdate = { label: 'Edit Access', value: 'update' };
const OptionUpdateOnly = { label: 'Edit Access Only', value: 'update' };
const OptionOn = { label: 'On', value: 'on' };
const OptionOff = { label: 'Off', value: 'off' };
export const OptionNoAccess = { label: 'No Access', value: 'none' };
const OptionViewOnly = { label: 'View Only', value: 'read' };

export const OptionsNotCreatable = [OptionNoAccess, OptionViewOnly, OptionEdit];
export const OptionsThisEntity = [OptionDefault, ...OptionsNotCreatable];
export const OptionsNotEditable = [OptionNoAccess, OptionViewOnly, OptionCreate];
export const OptionsOnOff = [OptionOn, OptionOff];
export const OptionsOnOffWithDefault = [OptionDefault, ...OptionsOnOff];
export const OptionsFull = [OptionNoAccess, OptionViewOnly, OptionCreateAndEdit];
export const OptionsFullGranular = [OptionNoAccess, OptionViewOnly, OptionUpdateOnly, OptionCreateAndEdit];
export const OptionsFullGranularWithDefault = [OptionDefault, ...OptionsFullGranular];
export const OptionsBankAccounts = [OptionViewOnly, OptionUpdate, OptionCreateAndEdit];
export const OptionsEntities = [OptionViewOnly, OptionCreateAndEdit];
export const OptionsThisBankAccount = [OptionDefault, OptionViewOnly, OptionUpdate];
