import { PageTypeRestrict } from '~/app/Sitemap';
import { PlatformRolePermissionKey, PlatformRolePermissions } from '~/repositories';

export const checkIfRestricted = (
  permissions: PlatformRolePermissions | undefined,
  restrictIf: PageTypeRestrict | undefined
) => {
  if (!permissions || !restrictIf) {
    return false;
  }

  for (const objKey in restrictIf) {
    if (permissions.hasOwnProperty(objKey)) {
      const key = objKey as PlatformRolePermissionKey;
      const value = permissions[key];

      if (typeof value !== 'undefined' && !restrictIf[key]?.includes(value)) {
        return false;
      }
    } else {
      return false;
    }
  }
  return true;
};

export const checkIfRestrictedWrite = (
  permissions?: PlatformRolePermissions,
  permissionName?: PlatformRolePermissionKey[]
): boolean => {
  const restrictedIf = permissionName?.reduce((acc: Partial<PageTypeRestrict>, key: keyof PageTypeRestrict) => {
    acc[key] = ['none', 'read'];
    return acc;
  }, {}) as PageTypeRestrict;

  return !!permissions && checkIfRestricted(permissions, restrictedIf);
};
