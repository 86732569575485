import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon, IconCircleProps } from '@column/column-ui-kit';

import { ModalType, useModalStore } from '~/stores/Modal';
import { Headline, ModalIconCircle } from '~/styles';

import { ModalBase } from './Base';

type ModalData = {
  onReview?: () => void;
  onSaveReview?: () => void;
};

const Wrapper = styled(ModalBase)`
  --modal-width: 320px;
  padding: 24px;
`;

const Circle = styled(ModalIconCircle)<{ type: IconCircleProps['type'] }>`
  background-color: ${({ type, theme }) =>
    type === 'primary' ? theme.primary.blendToBackground(1000, 100) : theme.danger.blendToBackground(1000, 100)};

  svg {
    --icon-color: ${({ type, theme }) => (type === 'primary' ? theme.primary.background : theme.danger.background)};
  }
`;

const Buttons = styled.div<{ grow?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 0 -24px -24px -24px;
  padding: 24px;
  border-radius: 0 0 12px 12px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
`;

const Text = styled.p`
  margin: 0 auto 24px auto;
  width: 92%;
  font-size: 14px;
  color: ${({ theme }) => theme.secondary.background};
  text-align: center;
`;

export const ModalReviewTransferTemplate: FC = () => {
  const modalTypes: ModalType[] = ['ReviewTransferTemplate'];
  const [modalDataState, setModalDataState] = useState<ModalData>({});
  const closeModal = useModalStore((state) => state.closeModal);

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.getModalData(),
        (modalData) => {
          const data = modalData;

          if (!data) {
            return;
          }

          setModalDataState(data);
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  const handleReview = useCallback(() => {
    closeModal();

    modalDataState.onReview?.();
  }, [modalDataState]);

  const handleSaveReview = useCallback(() => {
    closeModal();

    modalDataState.onSaveReview?.();
  }, [modalDataState]);

  return (
    <Wrapper modalTypes={modalTypes}>
      <Circle type="primary">
        <Icon.CircleQuestionmark />
      </Circle>
      <StyledHeadline>Save changes?</StyledHeadline>
      <Text>
        Would you like to save the transfer template and proceed to the review page, or continue with your temporary
        changes?
      </Text>
      <Buttons grow>
        <StyledButton variant="secondary" onClick={handleSaveReview}>
          Save &amp; Continue
        </StyledButton>
        <StyledButton onClick={handleReview}>Continue</StyledButton>
      </Buttons>
    </Wrapper>
  );
};
