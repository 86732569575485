import React, { FC, PropsWithChildren, createContext, useCallback, useContext, useRef, useState } from 'react';

import { ModalAlert as Alert, ModalAlertProps } from './Global/Alert';

interface DialogContextType {
  openModal: () => void;
  closeModal: () => void;

  openAlert: (alertData: ModalAlertProps) => void;
  closeAlert: (id?: string) => void;
  getAlertData: (id: string) => Record<string, unknown> | undefined;
  setAlertData: (id: string, data: Record<string, unknown>) => void;
  removeAlertData: (id: string) => void;
}

interface AlertData {
  id: string;
  props: ModalAlertProps;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertData[]>([]);
  const alertDataRef = useRef<Map<string, Record<string, unknown>>>(new Map());

  const openAlert = useCallback((alertData: ModalAlertProps) => {
    const id = crypto.randomUUID();

    setAlerts((prev) => [...prev, { id, props: { ...alertData, isOpen: true } }]);

    if (alertData.data) {
      alertDataRef.current.set(id, alertData.data);
    }
  }, []);

  const getAlertData = useCallback((id: string) => {
    return alertDataRef.current.get(id);
  }, []);

  const setAlertData = useCallback((id: string, data: Record<string, unknown>) => {
    alertDataRef.current.set(id, data);
  }, []);

  const removeAlertData = useCallback((id: string) => {
    alertDataRef.current.delete(id);
  }, []);

  const closeAlert = useCallback((id?: string) => {
    setAlerts((prev) => {
      if (id === undefined) {
        const lastAlert = prev[prev.length - 1];

        if (lastAlert) {
          removeAlertData(lastAlert.id);
        }

        return prev.slice(0, -1);
      }

      removeAlertData(id);

      return prev.filter((alert) => alert.id !== id);
    });
  }, []);

  const openModal = useCallback(() => {
    // TODO
  }, []);

  const closeModal = useCallback(() => {
    // TODO
  }, []);

  return (
    <DialogContext.Provider
      value={{ openAlert, closeAlert, getAlertData, setAlertData, removeAlertData, openModal, closeModal }}
    >
      {children}

      {alerts.map((alert) => (
        <Alert key={alert.id} {...alert.props} />
      ))}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  return context;
};
