import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, Icon, Toggle } from '@column/column-ui-kit';

import { Details } from '~/app/pages/Transfers/_components/Details';
import { TransferReview } from '~/app/pages/Transfers/_components/TransferReview';
import { canUserReviewTransfer } from '~/app/pages/Transfers/_utils/transferReview';
import { buildWireEvents } from '~/app/pages/Transfers/_utils/wire';
import { ROUTE } from '~/app/routes';
import { CopyInput, Loading, RenderFields } from '~/components';
import { useAccountNumber, useBankAccount, useCounterparty, useEntity } from '~/hooks';
import { useReviewWireTransfer, useTransfer } from '~/hooks/useTransfers';
import { useNavigate } from '~/lib/navigation';
import { SimulateRepository, WireTransfer, convertTransferAmount } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { ReviewTransferRequest, WireWireTransferStatus } from '~/typings/enum';

import { InfoForm, TransferPageHeader } from './_components/Layout';
import { Timeline } from './_components/Timeline';

interface Params {
  id: string;
}

const formatData = (transfer: WireTransfer): Record<string, unknown> => {
  const formatted: Record<string, unknown> = {
    beneficiary: {
      accountNumber: transfer.beneficiaryAccountNumber,
      name: transfer.beneficiaryName,
      reference: (transfer as any).beneficiaryReference,
    },
    fiToFiInformation: {
      line1: transfer.fiToFiInformationLine1,
      line2: transfer.fiToFiInformationLine2,
      line3: transfer.fiToFiInformationLine3,
      line4: transfer.fiToFiInformationLine4,
      line5: transfer.fiToFiInformationLine5,
      line6: transfer.fiToFiInformationLine6,
    },
    originatorToBeneficiaryInformation: {
      line1: transfer.originatorToBeneficiaryInformationLine1,
      line2: transfer.originatorToBeneficiaryInformationLine2,
      line3: transfer.originatorToBeneficiaryInformationLine3,
      line4: transfer.originatorToBeneficiaryInformationLine4,
    },
    sender: {
      address: transfer.senderAddress,
      diName: transfer.senderDiName,
      diRoutingNumber: transfer.senderDiRoutingNumber,
      reference: transfer.senderReference,
    },
  };
  Object.entries(transfer).forEach(([key, value]) => {
    if (
      key.startsWith('beneficiary') ||
      key.startsWith('fiToFiInformation') ||
      key.startsWith('originatorToBeneficiaryInformation') ||
      key.startsWith('sender')
    ) {
      return;
    }
    formatted[key] = value;
  });
  return formatted;
};

export const PageTransfersWire: FC = () => {
  const { currentUser, isAuthorized, isSandbox, platformRole } = useSessionStore();
  const { id } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();
  const { addDangerNotification, addSuccessNotification } = useNotificationStore();

  const {
    response: bankAccount,
    isLoading: isBankAccountLoading,
    createRequest: fetchBankAccount,
  } = useBankAccount({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const {
    response: accountNumber,
    isLoading: isAccountNumberLoading,
    createRequest: fetchAccountNumber,
  } = useAccountNumber({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const {
    response: counterparty,
    isLoading: isCounterpartyLoading,
    createRequest: fetchCounterparty,
  } = useCounterparty({
    onError: (error) => addDangerNotification({ content: error.message }),
  });

  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const { response: entity, createRequest: fetchEntity } = useEntity({
    onSuccess: () => setIsLoadingEntity(false),
    onError: (error) => {
      setIsLoadingEntity(false);
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const {
    response: originalTransfer,
    isLoading: isTransferLoading,
    createRequest: fetchTransfer,
  } = useTransfer<WireTransfer>('wire')({
    initialParams: { id },
    onSuccess: async (response) => {
      fetchAccountNumber({ id: response.accountNumberId });

      try {
        if (response.bankAccountId) {
          const currentBankAccount = await fetchBankAccount({ id: response.bankAccountId });

          if (!currentBankAccount) {
            return;
          }

          if (isAuthorized({ permission: 'entities', permissionLevel: 'read' })) {
            fetchEntity({ id: currentBankAccount.owners?.[0] });
            setIsLoadingEntity(true);
          }
        }
      } catch (error) {
        addDangerNotification({ content: (error as Error).message });
      }

      if (response?.counterpartyId) {
        fetchCounterparty({ id: response.counterpartyId });
      }
    },

    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });
  const [isReviewLoading, setReviewLoading] = useState(false);
  const { response: reviewedTransfer, createRequest: createReviewReq } = useReviewWireTransfer({
    onError: () => setReviewLoading(false),
    onSuccess: () => setReviewLoading(false),
  });
  const reviewTransfer = useCallback(
    async (req: ReviewTransferRequest) => {
      setReviewLoading(true);
      try {
        await createReviewReq({ id, ...req });
      } catch (err) {
        addDangerNotification({
          content: (err as Error)?.message ?? 'An error occurred while reviewing the transfer.',
        });
      }
    },
    [id]
  );
  const transfer = reviewedTransfer || originalTransfer;
  const formattedTransfer = useMemo(() => (transfer ? formatData(transfer) : undefined), [transfer]);

  const formMethods = useForm<WireTransfer>();

  useEffect(() => {
    if (transfer) {
      formMethods.setValue('description', transfer.description || '-');
      formMethods.setValue('idempotencyKey', transfer.idempotencyKey || '-');
      formMethods.setValue('allowOverdraft', transfer.allowOverdraft || false);
    }
  }, [transfer]);

  const isDataLoading = useMemo(() => {
    return isAccountNumberLoading || isBankAccountLoading || isCounterpartyLoading || isTransferLoading;
  }, [isAccountNumberLoading, isBankAccountLoading, isCounterpartyLoading, isTransferLoading]);

  const handleSettle = useCallback(() => {
    if (!transfer) return;
    SimulateRepository.settleWire({ wireTransferId: transfer.id })
      .then(() => {
        fetchTransfer();
        addSuccessNotification({
          content: 'Wire transfer settled',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  }, [isSandbox, transfer]);

  return (
    <Loading isLoading={isDataLoading} fullHeight>
      {transfer && (
        <TransferPageHeader
          amount={transfer.amount!}
          createdAt={transfer.createdAt}
          isAdding={transfer ? convertTransferAmount('wire', transfer.isIncoming) > 0 : undefined}
          isIncoming={transfer.isIncoming || false}
          status={transfer.status!}
          transferId={transfer.id!}
          transferType={'Wire'}
        >
          {isSandbox &&
            !['COMPLETED', 'REJECTED', 'SETTLED', 'CANCELED', 'RETURNED', 'PRE_REVIEW'].includes(transfer.status) && (
              <Button onClick={handleSettle} variant="primary" size="small" icon={<Icon.Sandbox />}>
                Settle
              </Button>
            )}
        </TransferPageHeader>
      )}
      <TransferReview
        amount={transfer?.amount}
        bankAccountName={bankAccount?.displayName || bankAccount?.description || 'Unnamed Bank Account'}
        counterpartyName={counterparty?.name || counterparty?.description || 'Unnamed Counterparty'}
        createdByName={transfer?.createdBy?.name}
        description={transfer?.description}
        isDisabled={
          !isAuthorized({ permission: 'approveTransfers', permissionLevel: 'write', bankAccount }) ||
          !canUserReviewTransfer(currentUser, platformRole, transfer?.createdBy?.id)
        }
        isLoading={isReviewLoading}
        isOpen={!!transfer && transfer.status === WireWireTransferStatus.PRE_REVIEW}
        onSubmit={reviewTransfer}
      />
      {bankAccount && accountNumber && counterparty && transfer && !isLoadingEntity && (
        <Timeline
          entries={buildWireEvents({
            transfer,
            account: {
              accountNumber,
              bankAccount,
              entityType: entity ? (entity.type.toLowerCase() as 'person' | 'business') : undefined,
            },
            counterparty,
          })}
        />
      )}
      <FormProvider {...formMethods}>
        <InfoForm autoComplete="off">
          {RenderFields<WireTransfer>({
            sections: [
              {
                headline: 'Information',
                fields: [
                  {
                    id: 'description',
                    label: 'Description',
                    children: ({ value }) => {
                      return <CopyInput value={String(value)} />;
                    },
                  },
                  {
                    id: 'idempotencyKey',
                    label: 'Idempotency Key',
                    children: ({ value }) => {
                      return <CopyInput value={String(value) || ''} />;
                    },
                  },
                  {
                    id: 'allowOverdraft',
                    label: 'Allow Overdraft',
                    children: ({ value, onChange }) => {
                      const isChecked = typeof value === 'boolean' ? value : false;
                      return <Toggle isChecked={isChecked} onCheckedChange={onChange} isDisabled />;
                    },
                  },
                ],
              },
            ],
          })}
          <Details formattedData={formattedTransfer} rawData={transfer as Record<string, unknown> | undefined} />
        </InfoForm>
      </FormProvider>
    </Loading>
  );
};
