import { TimelineEntry, TimelineStatus } from '../_components/Timeline';
import { buildTransferParty, parseDate } from '~/app/pages/Transfers/_utils/timeline';
import { ROUTE } from '~/app/routes';
import { RealtimeTransfer } from '~/repositories';
import { AccountNumber, BankAccount } from '~/repositories/BankAccountRepository';
import { Counterparty } from '~/repositories/CounterpartyRepository';

const realtimeEvents = [
  {
    label: 'Initiated',
    field: 'initiatedAt',
  },
  {
    label: 'Pending',
    field: 'pendingAt',
  },
  {
    label: 'Blocked',
    field: 'blockedAt',
    status: TimelineStatus.Danger,
  },
  {
    label: 'Rejected',
    field: 'rejectedAt',
    status: TimelineStatus.Danger,
  },
  {
    label: 'Manual Review',
    field: 'manualReviewAt',
  },
  {
    label: 'Manual Review Approved',
    field: 'manualReviewApprovedAt',
  },
  {
    label: 'Manual Review Rejected',
    field: 'manualReviewRejectedAt',
    status: TimelineStatus.Danger,
  },
  {
    label: 'Accepted',
    field: 'acceptedAt',
  },
  {
    label: 'Completed',
    field: 'completedAt',
    status: TimelineStatus.Success,
  },
];

export const buildRealtimeEvents = ({
  transfer,
  account,
  counterparty,
}: {
  transfer: RealtimeTransfer;
  account: {
    accountNumber: AccountNumber;
    bankAccount: BankAccount;
    entityType?: 'person' | 'business';
  };
  counterparty: Counterparty;
}): TimelineEntry[] => {
  const completedEvents: TimelineEntry[] = realtimeEvents
    .filter((event) => event.field === 'completedAt' || !!transfer[event.field as keyof typeof transfer])
    .map((event) => {
      const date = parseDate(transfer[event.field as keyof typeof transfer] as string | undefined);

      const party =
        event.field === 'initiatedAt' || event.field === 'completedAt'
          ? buildTransferParty({
              account,
              counterparty,
              isIncoming: !!transfer.isIncoming,
              isOriginator: event.field === 'initiatedAt',
            })
          : undefined;

      const label = event.field === 'initiatedAt' && isReturningTransfer(transfer) ? 'Return Initiated' : event.label;
      const status = !!transfer[event.field as keyof typeof transfer] ? event.status : TimelineStatus.Pending;

      return {
        label,
        amount: transfer.amount ? { value: transfer.amount, isAdding: event.field === 'completedAt' } : undefined,
        date,
        partyData: party,
        status,
      };
    });

  if (isReturnedTransfer(transfer)) {
    completedEvents.push({
      label: 'Returned',
      status: TimelineStatus.Returned,
      link: {
        label: 'View transfer',
        path: `${ROUTE.TRANSFERS}/edit/realtime/${transfer.returnPairTransferId}`,
      },
    });
  }

  return completedEvents;
};

export const isReturnedTransfer = (transfer: RealtimeTransfer) =>
  !!transfer.returnPairTransferId && transfer.isIncoming;
export const isReturningTransfer = (transfer: RealtimeTransfer) =>
  !!transfer.returnPairTransferId && !transfer.isIncoming;
