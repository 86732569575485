import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AmountInput, Button, CurrenciesUSD } from '@column/column-ui-kit';

import { PopoverAction, PopoverBase, PopoverBaseProps } from '../Base';
import { FormElement, FormLabel } from '~/styles';

export interface PopoverFilterAmountData {
  amount?: number;
  amountGte?: number;
  amountLte?: number;
}

interface PopoverFilterAmountProps extends PopoverBaseProps {
  data: PopoverFilterAmountData;
  onSubmit?: (data: PopoverFilterAmountData) => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-padding: 0px;
  --popover-width: 248px;
  --popover-left: 0px;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 12px 12px 0 12px;
`;

export const PopoverFilterAmount: React.FC<PopoverFilterAmountProps> = ({ data, onSubmit, ...popoverProps }) => {
  const [currentData, setCurrentData] = useState<PopoverFilterAmountData>(data ?? {});

  const handleResetClick = useCallback(() => {
    setCurrentData({});

    if (onSubmit) {
      onSubmit({});
    }
  }, [onSubmit]);

  const handleApplyClick = useCallback(() => {
    if (onSubmit) {
      onSubmit(JSON.parse(JSON.stringify(currentData)));
    }
  }, [currentData, onSubmit]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>, key: keyof PopoverFilterAmountData) => {
      if (event.key === 'Enter') {
        setCurrentData((s) => ({ ...s, [key]: s[key] }));

        handleApplyClick();
      }
    },
    [currentData]
  );

  useEffect(() => {
    setCurrentData(data);
  }, [data, popoverProps.show]);

  return (
    <Wrapper {...popoverProps}>
      <Grid>
        <FormElement>
          <FormLabel>Amount</FormLabel>
          <AmountInput
            size="small"
            currencyList={CurrenciesUSD}
            fixedCurrencyCode="USD"
            value={Number(currentData.amount ?? 0)}
            onChange={(value) => {
              if (value) {
                if (currentData?.amountGte) {
                  setCurrentData((s) => ({ ...s, amountGte: undefined }));
                }
                if (currentData?.amountLte) {
                  setCurrentData((s) => ({ ...s, amountLte: undefined }));
                }
              }

              setCurrentData((s) => ({ ...s, amount: value === 0 ? undefined : Number(value) }));
            }}
            onKeyDown={(event) => handleKeyPress(event, 'amount')}
          />
        </FormElement>

        <FormElement>
          <FormLabel>Minimum</FormLabel>
          <AmountInput
            size="small"
            currencyList={CurrenciesUSD}
            fixedCurrencyCode="USD"
            value={Number(currentData.amountGte ?? 0)}
            onChange={(value) => {
              if (value) {
                if (currentData?.amount) {
                  setCurrentData((s) => ({ ...s, amount: undefined }));
                }
              }
              setCurrentData((s) => ({ ...s, amountGte: value === 0 ? undefined : Number(value) }));
            }}
            onKeyDown={(event) => handleKeyPress(event, 'amountGte')}
            isDisabled={!!currentData?.amount}
          />
        </FormElement>

        <FormElement>
          <FormLabel>Maximum</FormLabel>
          <AmountInput
            size="small"
            currencyList={CurrenciesUSD}
            fixedCurrencyCode="USD"
            value={Number(currentData.amountLte ?? 0)}
            onChange={(value) => {
              if (value) {
                if (currentData?.amount) {
                  setCurrentData((s) => ({ ...s, amount: undefined }));
                }
              }
              setCurrentData((s) => ({ ...s, amountLte: value === 0 ? undefined : Number(value) }));
            }}
            onKeyDown={(event) => handleKeyPress(event, 'amountLte')}
            isDisabled={!!currentData?.amountLte}
          />
        </FormElement>
      </Grid>

      <PopoverAction>
        <Button size="small" variant="secondary" onClick={handleResetClick}>
          Reset
        </Button>
        <Button size="small" onClick={handleApplyClick}>
          Apply
        </Button>
      </PopoverAction>
    </Wrapper>
  );
};
