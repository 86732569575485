import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface ClockIconProps {
  className?: string;
}

const Wrapper = styled.div``;

const Hours = styled.path`
  transform-origin: 7px 7px;
`;

const Minutes = styled.path`
  transform-origin: 7px 7px;
`;

export const ClockIcon: FC<ClockIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);
  const [rotation, setRotation] = useState<{ hours: string; minutes: string }>({
    hours: `${(360 / 12) * (new Date().getHours() % 12) + (360 / 12) * (new Date().getMinutes() / 60)}deg`,
    minutes: `${(360 / 60) * new Date().getMinutes() - 90}deg`,
  });

  const updateRotation = useCallback(() => {
    const date = new Date();

    setRotation({
      hours: `${(360 / 12) * (date.getHours() % 12) + (360 / 12) * (date.getMinutes() / 60)}deg`,
      minutes: `${(360 / 60) * date.getMinutes() - 90}deg`,
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(updateRotation, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase viewportSize={14}>
        <path
          d="M4.99153 2.15075C7.66961 1.04125 10.7397 2.3135 11.8492 4.99158C12.9587 7.66967 11.6864 10.7398 9.00836 11.8493C6.33028 12.9588 3.2602 11.6865 2.1507 9.00842C1.04178 6.33033 2.31345 3.26025 4.99153 2.15075"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Hours
          d="M7 4.5V7"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          style={{ rotate: rotation.hours }}
        />
        <Minutes
          d="M10 7H7"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          style={{ rotate: rotation.minutes }}
        />
      </IconBase>
    </Wrapper>
  );
};
