import React from 'react';
import styled from 'styled-components';

import { GOOGLE_MAPS_API_KEY } from '~/constants';
import { ApiAddress } from '~/typings/API';

interface MapProps {
  address: ApiAddress;
  height?: string;
  width?: string;
  margin?: string;
}

const MapContainer = styled.div<{ height: string; width: string; margin: string }>`
  background: ${({ theme }) => theme.secondary.blendToBackground(50)};
  background-size: cover;
  background-position: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.075) inset;
  overflow: hidden;
  margin: ${({ margin }) => margin};
`;

const formatAddressForGoogleMaps = (address: ApiAddress, width: number, height: number): string => {
  if (!address.line1 || !address.city || !address.state || !address.postalCode) return '';

  const addressStr = encodeURIComponent(
    [
      address.line1,
      address.line2,
      address.city,
      address.state,
      address.postalCode,
      address.countryCode === 'US' ? 'USA' : address.countryCode,
    ]
      .filter(Boolean)
      .join(' ')
  );

  return `https://maps.googleapis.com/maps/api/staticmap?center=${addressStr}&zoom=14&size=${width}x${height}&scale=2&markers=${addressStr}&key=${GOOGLE_MAPS_API_KEY}`;
};

export const Map = ({ address, height = '132px', width = '400px', margin = '0' }: MapProps) => {
  const parseSize = (size: string): number => {
    if (size.includes('%')) return 1200;
    return parseInt(size.replace(/[^0-9]/g, ''), 10);
  };
  const mapUrl = formatAddressForGoogleMaps(address, parseSize(width), parseSize(height));
  return <MapContainer height={height} width={width} margin={margin} style={{ backgroundImage: `url(${mapUrl})` }} />;
};
