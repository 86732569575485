import React, { FC } from 'react';
import { Path, useFormContext } from 'react-hook-form';

import { DropdownCell } from '../Dropdown';
import { useBankAccountDropdown, useDebounce } from '~/hooks';
import { TransferTemplateResponse } from '~/repositories/Transfer/TransferTemplateRepository';

interface BankAccountIdDropdownCellProps {
  id?: string;
  index: number;
  value?: string;
  array: 'achTransfers' | 'wireTransfers' | 'bookTransfers';
}

export const BankAccountIdDropdownCell: FC<BankAccountIdDropdownCellProps> = ({
  id = 'bankAccountId',
  index,
  value,
  array,
}) => {
  const { options, hiddenOptions, searchBankAccounts, isLoading } = useBankAccountDropdown(value);

  const { setValue } = useFormContext<TransferTemplateResponse>();

  const handleSearchBankAccounts = useDebounce(
    async (searchValue?: string) => {
      searchBankAccounts({ description: searchValue ?? '' });
    },
    500,
    [searchBankAccounts]
  );

  return (
    <DropdownCell
      id={id}
      array={array}
      index={index}
      active={value}
      options={options}
      hiddenOptions={hiddenOptions}
      fullWidth
      search
      searchLabel="Search for description"
      onSearchChange={handleSearchBankAccounts}
      isLoading={isLoading}
      onChange={(newValue: string) => setValue(`${array}.${index}.${id}` as Path<TransferTemplateResponse>, newValue)}
    />
  );
};
