import React, { FC, useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { Button, Chip, CurrenciesUSD, Fade, Icon, Tooltip, formatNumber } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { PageHeader } from '~/components';
import { LogoLoading } from '~/elements';
import { useFormErrorHandler } from '~/hooks/useFormErrorHandler';
import { useCreateACHTransfer, useCreateBookTransfer, useCreateWireTransfer } from '~/hooks/useTransfers';
import { useGetTransferTemplate, useUpdateTransferTemplate } from '~/hooks/useTransferTemplates';
import { useNavigate } from '~/lib/navigation';
import {
  AchTransferTemplateResponse,
  BookTransferTemplateResponse,
  TransferTemplateResponse,
  TransferTemplateType,
  WireTransferTemplateResponse,
} from '~/repositories/Transfer/TransferTemplateRepository';
import { useNotificationStore } from '~/stores/Notification';
import { ApiError } from '~/typings/API';
import { formatString, getDateTimeString } from '~/util';

import { AmountCell } from './_partial/TransferList/Cell/Amount';
import { DropdownCell } from './_partial/TransferList/Cell/Dropdown';
import { BankAccountIdDropdownCell } from './_partial/TransferList/Cell/DropdownCell/BankAccountId';
import { CounterpartyIdDropdownCell } from './_partial/TransferList/Cell/DropdownCell/CounterpartyId';
import { BankAccountIdTextCell } from './_partial/TransferList/Cell/Text/BankAccountId';
import { CounterpartyIdTextCell } from './_partial/TransferList/Cell/Text/CounterpartyId';
import { TextareaCell } from './_partial/TransferList/Cell/Textarea';
import { Table, TableColumn } from './_partial/TransferList/Table';

type TransferState = { type: 'idle' } | { type: 'loading' } | { type: 'success' } | { type: 'error'; message: string };

interface ReviewFormValues extends Omit<TransferTemplateResponse, 'achTransfers' | 'wireTransfers' | 'bookTransfers'> {
  achTransfers: Partial<AchTransferTemplateResponse>[];
  wireTransfers: Partial<WireTransferTemplateResponse>[];
  bookTransfers: Partial<BookTransferTemplateResponse>[];
}

interface Params {
  id: string;
}

interface LocationState {
  defaultFormValues?: ReviewFormValues;
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  padding: 24px;
  display: grid;
  gap: 24px;
`;

const Logo = styled(LogoLoading)`
  top: calc(50vh - 130px);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TextCell = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding: 8px 16px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const Amount = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.foreground};
`;

const Idle = styled(Icon.CirclePendingTwo)`
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const Loading = styled(Icon.Loading)`
  --icon-color: ${({ theme }) => theme.primary.background};
`;

const Success = styled(Icon.CircleCheck)`
  --icon-color: ${({ theme }) => theme.success.background};
`;

const ErrorTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Error = styled(Icon.CircleCross)`
  --icon-color: ${({ theme }) => theme.danger.background};
`;

const State = styled.div<{ $state: TransferState }>`
  --icon-size: 20px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  width: 100%;
  height: 40px;
  position: relative;
  box-sizing: border-box;
  padding-left: 40px;
  display: flex;
  align-items: center;
  font-feature-settings: 'tnum';

  ${Idle},
  ${Loading},
  ${Success},
  ${ErrorTooltip} {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    transition: opacity 0.3s ease;
  }

  ${Idle} {
    opacity: ${({ $state }) => ($state.type === 'idle' ? 1 : 0)};
    transition-delay: ${({ $state }) => ($state.type === 'idle' ? '0.2s' : '0s')};
    pointer-events: ${({ $state }) => ($state.type === 'idle' ? 'auto' : 'none')};
  }

  ${Loading} {
    opacity: ${({ $state }) => ($state.type === 'loading' ? 1 : 0)};
    transition-delay: ${({ $state }) => ($state.type === 'loading' ? '0.2s' : '0s')};
    pointer-events: ${({ $state }) => ($state.type === 'loading' ? 'auto' : 'none')};
  }

  ${Success} {
    opacity: ${({ $state }) => ($state.type === 'success' ? 1 : 0)};
    transition-delay: ${({ $state }) => ($state.type === 'success' ? '0.2s' : '0s')};
    pointer-events: ${({ $state }) => ($state.type === 'success' ? 'auto' : 'none')};
  }

  ${ErrorTooltip} {
    opacity: ${({ $state }) => ($state.type === 'error' ? 1 : 0)};
    transition-delay: ${({ $state }) => ($state.type === 'error' ? '0.2s' : '0s')};
    pointer-events: ${({ $state }) => ($state.type === 'error' ? 'auto' : 'none')};
  }
`;

const CounterCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
`;

const Counter = styled(Chip)`
  --chip-font-size: 11px;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: center;
  min-width: 24px;
`;

const achColumns: TableColumn<Partial<AchTransferTemplateResponse> & { id: string; state: TransferState }>[] = [
  {
    accessor: 'id',
    Header: '',
    Cell: ({ index }) => (
      <CounterCell>
        <Counter>{index + 1}</Counter>
      </CounterCell>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'state',
    Header: '',
    Cell: ({ value }) => (
      <State $state={value}>
        <Idle />
        <Loading />
        <Success />
        <ErrorTooltip content={value.type === 'error' ? value.message : 'Error'}>
          <Error />
        </ErrorTooltip>
      </State>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <AmountCell
          id="amount"
          array="achTransfers"
          index={index}
          currencyList={CurrenciesUSD}
          fixedCurrencyCode="USD"
        />
      ) : (
        <TextCell>
          <Amount>{formatNumber(value)}</Amount>
        </TextCell>
      );
    },
    customWidth: { fixed: 112 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'counterpartyId',
    Header: 'Counterparty',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <CounterpartyIdDropdownCell index={index} array="achTransfers" value={value} />
      ) : row.counterpartyDescription ? (
        <TextCell>{row.counterpartyDescription}</TextCell>
      ) : (
        <CounterpartyIdTextCell id={value} />
      );
    },
    customWidth: { fixed: 200 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'type',
    Header: 'Type',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <DropdownCell
          id="type"
          array="achTransfers"
          index={index}
          active={value}
          options={[
            { label: 'Debit', value: 'debit' },
            { label: 'Credit', value: 'credit' },
          ]}
        />
      ) : (
        <TextCell>
          <Chip>{formatString(value)}</Chip>
        </TextCell>
      );
    },
    customWidth: { fixed: 120 },
    isDefault: true,
  },
  {
    accessor: 'bankAccountId',
    Header: 'Bank Account',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <BankAccountIdDropdownCell index={index} array="achTransfers" value={value} />
      ) : row.bankAccountDescription ? (
        <TextCell>{row.bankAccountDescription}</TextCell>
      ) : (
        <BankAccountIdTextCell id={value} />
      );
    },
    customWidth: { fr: 2, min: 180 },
    isDefault: true,
  },
  {
    accessor: 'description',
    Header: 'Description',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="description" array="achTransfers" index={index} placeholder="Description" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: { fr: 2, min: 180 },
    isDefault: true,
  },
  {
    accessor: 'entryClassCode',
    Header: 'Entry Class Code',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <DropdownCell
          id="entryClassCode"
          array="achTransfers"
          index={index}
          placeholder="Please select"
          active={value}
          options={['CCD', 'CIE', 'CTX', 'PPD', 'TEL', 'WEB'].map((code: string) => ({
            label: code,
            value: code,
          }))}
        />
      ) : (
        <TextCell>
          <Chip>{value}</Chip>
        </TextCell>
      );
    },
    customWidth: {
      fr: 1,
      min: 180,
    },
    isDefault: true,
  },
  {
    accessor: 'allowOverdraft',
    Header: 'Allow Overdraft',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <DropdownCell
          id="allowOverdraft"
          array="achTransfers"
          index={index}
          placeholder="Please select"
          active={value}
          options={['Allow', 'Deny'].map((code: string) => ({
            label: code,
            value: code === 'Allow' ? true : false,
          }))}
        />
      ) : (
        <TextCell>
          <Chip>{value ? 'Allow' : 'Deny'}</Chip>
        </TextCell>
      );
    },
    customWidth: {
      fixed: 144,
    },
    isHidden: true,
  },
  {
    accessor: 'companyName',
    Header: 'Company Name',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="companyName" array="achTransfers" index={index} placeholder="Company Name" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 160,
    },
    isHidden: true,
  },
  {
    accessor: 'companyDiscretionaryData',
    Header: 'Company Discretionary',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell
          id="companyDiscretionaryData"
          array="achTransfers"
          index={index}
          placeholder="Company Discretionary"
        />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 200,
    },
    isHidden: true,
  },
  {
    accessor: 'companyEntryDescription',
    Header: 'Company Entry Info',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell
          id="companyEntryDescription"
          array="achTransfers"
          index={index}
          placeholder="Company Entry Info"
        />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 180,
    },
    isHidden: true,
  },
  {
    accessor: 'paymentRelatedInfo',
    Header: 'Payment Related Info',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="paymentRelatedInfo" array="achTransfers" index={index} placeholder="Payment Related Info" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 180,
    },
    isHidden: true,
  },
  {
    accessor: 'receiverName',
    Header: 'Receiver Name',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="receiverName" array="achTransfers" index={index} placeholder="Receiver Name" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 160,
    },
    isHidden: true,
  },
  {
    accessor: 'receiverId',
    Header: 'Receiver ID',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="receiverId" array="achTransfers" index={index} placeholder="Receiver ID" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: {
      min: 160,
    },
    isHidden: true,
  },
];

const wireColumns: TableColumn<Partial<WireTransferTemplateResponse> & { id: string; state: TransferState }>[] = [
  {
    accessor: 'id',
    Header: '',
    Cell: ({ index }) => (
      <CounterCell>
        <Counter>{index + 1}</Counter>
      </CounterCell>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'state',
    Header: '',
    Cell: ({ value }) => (
      <State $state={value}>
        <Idle />
        <Loading />
        <Success />
        <ErrorTooltip content={value.type === 'error' ? value.message : 'Error'}>
          <Error />
        </ErrorTooltip>
      </State>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <AmountCell
          id="amount"
          array="wireTransfers"
          index={index}
          currencyList={CurrenciesUSD}
          fixedCurrencyCode="USD"
        />
      ) : (
        <TextCell>
          <Amount>{formatNumber(value)}</Amount>
        </TextCell>
      );
    },
    customWidth: { fixed: 112 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'counterpartyId',
    Header: 'Counterparty',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <CounterpartyIdDropdownCell index={index} array="wireTransfers" value={value} />
      ) : row.counterpartyDescription ? (
        <TextCell>{row.counterpartyDescription}</TextCell>
      ) : (
        <CounterpartyIdTextCell id={value} />
      );
    },
    customWidth: { fixed: 240 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'bankAccountId',
    Header: 'Bank Account',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <BankAccountIdDropdownCell index={index} array="wireTransfers" value={value} />
      ) : row.bankAccountDescription ? (
        <TextCell>{row.bankAccountDescription}</TextCell>
      ) : (
        <BankAccountIdTextCell id={value} />
      );
    },
    customWidth: { min: 200 },
    isDefault: true,
  },
  {
    accessor: 'description',
    Header: 'Description',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="description" array="wireTransfers" index={index} placeholder="Description" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: { min: 180 },
    isDefault: true,
  },
  {
    accessor: 'allowOverdraft',
    Header: 'Allow Overdraft',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <DropdownCell
          id="allowOverdraft"
          array="wireTransfers"
          index={index}
          placeholder="Please select"
          active={value}
          options={['Allow', 'Deny'].map((code: string) => ({
            label: code,
            value: code === 'Allow' ? true : false,
          }))}
        />
      ) : (
        <TextCell>
          <Chip>{value ? 'Allow' : 'Deny'}</Chip>
        </TextCell>
      );
    },
    customWidth: {
      fixed: 144,
    },
    isHidden: true,
  },
];

const bookColumns: TableColumn<Partial<BookTransferTemplateResponse> & { id: string; state: TransferState }>[] = [
  {
    accessor: 'id',
    Header: '',
    Cell: ({ index }) => (
      <CounterCell>
        <Counter>{index + 1}</Counter>
      </CounterCell>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'state',
    Header: '',
    Cell: ({ value }) => (
      <State $state={value}>
        <Idle />
        <Loading />
        <Success />
        <ErrorTooltip content={value.type === 'error' ? value.message : 'Error'}>
          <Error />
        </ErrorTooltip>
      </State>
    ),
    customWidth: { fixed: 40 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <AmountCell
          id="amount"
          array="bookTransfers"
          index={index}
          currencyList={CurrenciesUSD}
          fixedCurrencyCode="USD"
        />
      ) : (
        <TextCell>
          <Amount>{formatNumber(value)}</Amount>
        </TextCell>
      );
    },
    customWidth: { fixed: 112 },
    isSticky: true,
    isDefault: true,
  },
  {
    accessor: 'senderBankAccountId',
    Header: 'Sender Bank Account',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <BankAccountIdDropdownCell id="senderBankAccountId" index={index} array="bookTransfers" value={value} />
      ) : row.senderBankAccountDescription ? (
        <TextCell>{row.senderBankAccountDescription}</TextCell>
      ) : (
        <BankAccountIdTextCell id={value} />
      );
    },
    customWidth: { min: 200 },
    isDefault: true,
  },
  {
    accessor: 'receiverBankAccountId',
    Header: 'Receiver Bank Account',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <BankAccountIdDropdownCell id="receiverBankAccountId" index={index} array="bookTransfers" value={value} />
      ) : row.receiverBankAccountDescription ? (
        <TextCell>{row.receiverBankAccountDescription}</TextCell>
      ) : (
        <BankAccountIdTextCell id={value} />
      );
    },
    customWidth: { min: 200 },
    isDefault: true,
  },
  {
    accessor: 'description',
    Header: 'Description',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <TextareaCell id="description" array="bookTransfers" index={index} placeholder="Description" />
      ) : (
        <TextCell>{value}</TextCell>
      );
    },
    customWidth: { min: 180 },
    isDefault: true,
  },
  {
    accessor: 'allowOverdraft',
    Header: 'Allow Overdraft',
    Cell: ({ value, row, index }) => {
      const isError = row.state.type === 'error';
      return isError ? (
        <DropdownCell
          id="allowOverdraft"
          array="bookTransfers"
          index={index}
          placeholder="Please select"
          active={value}
          options={['Allow', 'Deny'].map((code: string) => ({
            label: code,
            value: code === 'Allow' ? true : false,
          }))}
        />
      ) : (
        <TextCell>
          <Chip>{value ? 'Allow' : 'Deny'}</Chip>
        </TextCell>
      );
    },
    customWidth: {
      fixed: 144,
    },
    isHidden: true,
  },
];

export const PageTransferTemplatesReview: FC = () => {
  const { id } = useParams<keyof Params>() as Params;
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const location = useLocation();
  const locationState = location.state as LocationState;
  const { handleFormErrors } = useFormErrorHandler();

  const methods = useForm<ReviewFormValues>({
    defaultValues: locationState?.defaultFormValues ?? {
      achTransfers: [],
      wireTransfers: [],
      bookTransfers: [],
    },
  });

  const { handleSubmit, reset, control, watch } = methods;

  const { fields: achTransfers, update: updateAchTransfer } = useFieldArray({
    control,
    name: 'achTransfers',
  });

  const { fields: wireTransfers, update: updateWireTransfer } = useFieldArray({
    control,
    name: 'wireTransfers',
  });

  const { fields: bookTransfers, update: updateBookTransfer } = useFieldArray({
    control,
    name: 'bookTransfers',
  });

  const [achColumnEntries, setAchColumnEntries] = useState(achColumns);
  const [wireColumnEntries, setWireColumnEntries] = useState(wireColumns);
  const [bookColumnEntries, setBookColumnEntries] = useState(bookColumns);

  const [achTransferStates, setAchTransferStates] = useState<TransferState[]>([]);
  const [wireTransferStates, setWireTransferStates] = useState<TransferState[]>([]);
  const [bookTransferStates, setBookTransferStates] = useState<TransferState[]>([]);

  const templateType = watch('templateType');
  const visibleColumns = watch('visibleColumns') || [];

  useEffect(() => {
    const initializeStates = (length: number, currentStates: TransferState[]): TransferState[] =>
      currentStates.length === length ? currentStates : Array.from({ length }, () => ({ type: 'idle' }));

    setAchTransferStates((prev) => initializeStates(achTransfers.length, prev));
    setWireTransferStates((prev) => initializeStates(wireTransfers.length, prev));
    setBookTransferStates((prev) => initializeStates(bookTransfers.length, prev));
  }, [achTransfers.length, wireTransfers.length, bookTransfers.length]);

  const { isLoading, createRequest: getTransferTemplate } = useGetTransferTemplate({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(`${ROUTE.TRANSFERS}/templates`);
    },
    shouldNotLoad: !!locationState?.defaultFormValues,
  });

  const { createRequest: updateTransferTemplate } = useUpdateTransferTemplate();
  const { createRequest: createACHTransfer } = useCreateACHTransfer();
  const { createRequest: createWireTransfer } = useCreateWireTransfer();
  const { createRequest: createBookTransfer } = useCreateBookTransfer();

  const setTransferState = useCallback((type: 'ach' | 'wire' | 'book', index: number, state: TransferState) => {
    const setStateFn =
      type === 'ach' ? setAchTransferStates : type === 'wire' ? setWireTransferStates : setBookTransferStates;

    setStateFn((prev) => prev.map((s, i) => (i === index ? state : s)));
  }, []);

  const handleTransferRow = useCallback(
    async <T extends object>(params: {
      transferType: 'ach' | 'wire' | 'book';
      transfer: Partial<T>;
      index: number;
      currentState: TransferState;
      updatedTransfer?: T;
      createFn: (data: any) => Promise<any>;
      updateFn: (index: number, data: any) => void;
      watchPath: string;
    }) => {
      const { transferType, transfer, index, currentState, updatedTransfer, createFn, updateFn, watchPath } = params;

      if (currentState.type === 'success') return false;

      setTransferState(transferType, index, { type: 'loading' });
      try {
        const formValues = watch(watchPath as any);

        const requestData = { ...transfer, ...formValues, currencyCode: 'USD', idempotencyKey: uuid() } as Record<
          string,
          any
        >;

        if (transferType === 'ach' || transferType === 'wire') {
          delete requestData.transferTemplateRowId;
          delete requestData.bankAccountDescription;
          delete requestData.counterpartyDescription;
        } else if (transferType === 'book') {
          delete requestData.transferTemplateRowId;
          delete requestData.senderBankAccountDescription;
          delete requestData.receiverBankAccountDescription;
        }

        const result = await createFn(requestData);
        if (!result && transferType !== 'ach') return false;

        if (updatedTransfer) {
          if (transferType === 'ach' || transferType === 'wire') {
            updateFn(index, {
              ...formValues,
              bankAccountDescription: (updatedTransfer as any).bankAccountDescription,
              counterpartyDescription: (updatedTransfer as any).counterpartyDescription,
            });
          } else if (transferType === 'book') {
            updateFn(index, {
              ...formValues,
              senderBankAccountDescription: (updatedTransfer as any).senderBankAccountDescription,
              receiverBankAccountDescription: (updatedTransfer as any).receiverBankAccountDescription,
            });
          }
        }

        setTransferState(transferType, index, { type: 'success' });
        return true;
      } catch (e) {
        const error = e as ApiError & { details?: { reason?: string } };
        const errorMessage = error?.message
          ? `${error.message} ${error.details?.reason ?? ''}`
          : 'Unknown error occurred';

        setTransferState(transferType, index, { type: 'error', message: errorMessage });

        addDangerNotification({
          content: (
            <>
              <span className="underline">{index + 1}. Transfer</span> {errorMessage}
            </>
          ),
          timeout: 8000,
        });
        return false;
      }
    },
    [watch, setTransferState, addDangerNotification]
  );

  const handleAchTransferRow = useCallback(
    (
      achTransfer: Partial<AchTransferTemplateResponse>,
      index: number,
      currentState: TransferState,
      updatedTransfer?: AchTransferTemplateResponse
    ) =>
      handleTransferRow({
        transferType: 'ach',
        transfer: achTransfer,
        index,
        currentState,
        updatedTransfer,
        createFn: createACHTransfer,
        updateFn: updateAchTransfer,
        watchPath: `achTransfers.${index}`,
      }),
    [handleTransferRow, createACHTransfer, updateAchTransfer]
  );

  const handleWireTransferRow = useCallback(
    (
      wireTransfer: Partial<WireTransferTemplateResponse>,
      index: number,
      currentState: TransferState,
      updatedTransfer?: WireTransferTemplateResponse
    ) =>
      handleTransferRow({
        transferType: 'wire',
        transfer: wireTransfer,
        index,
        currentState,
        updatedTransfer,
        createFn: createWireTransfer,
        updateFn: updateWireTransfer,
        watchPath: `wireTransfers.${index}`,
      }),
    [handleTransferRow, createWireTransfer, updateWireTransfer]
  );

  const handleBookTransferRow = useCallback(
    (
      bookTransfer: Partial<BookTransferTemplateResponse>,
      index: number,
      currentState: TransferState,
      updatedTransfer?: BookTransferTemplateResponse
    ) =>
      handleTransferRow({
        transferType: 'book',
        transfer: bookTransfer,
        index,
        currentState,
        updatedTransfer,
        createFn: createBookTransfer,
        updateFn: updateBookTransfer,
        watchPath: `bookTransfers.${index}`,
      }),
    [handleTransferRow, createBookTransfer, updateBookTransfer]
  );

  const updateTransferTemplateWithErrorCorrections = useCallback(
    async (data: ReviewFormValues) => {
      if (templateType === TransferTemplateType.ACH) {
        const updateAchTransfersReq = data.achTransfers.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ counterpartyDescription, bankAccountDescription, ...t }) => ({ ...t })
        );
        const updatedTemplate = await updateTransferTemplate({
          transferTemplateId: id,
          achTransfers: updateAchTransfersReq,
        });

        if (!updatedTemplate) return [];

        const updatedAchTransfers = updatedTemplate.achTransfers || [];
        updatedAchTransfers.forEach((ut, i) => {
          updateAchTransfer(i, {
            ...data.achTransfers[i],
            bankAccountDescription: ut.bankAccountDescription,
            counterpartyDescription: ut.counterpartyDescription,
          });
        });

        return updatedAchTransfers;
      }

      if (templateType === TransferTemplateType.Wire) {
        const updateWireTransfersReq = data.wireTransfers.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ counterpartyDescription, bankAccountDescription, ...t }) => ({ ...t })
        );
        const updatedTemplate = await updateTransferTemplate({
          transferTemplateId: id,
          wireTransfers: updateWireTransfersReq,
        });

        if (!updatedTemplate) return [];

        const updatedWireTransfers = updatedTemplate.wireTransfers || [];
        updatedWireTransfers.forEach((ut, i) => {
          updateWireTransfer(i, {
            ...data.wireTransfers[i],
            bankAccountDescription: ut.bankAccountDescription,
            counterpartyDescription: ut.counterpartyDescription,
          });
        });

        return updatedWireTransfers;
      }

      if (templateType === TransferTemplateType.Book) {
        const updateBookTransfersReq = data.bookTransfers.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ receiverBankAccountDescription, senderBankAccountDescription, ...t }) => ({ ...t })
        );
        const updatedTemplate = await updateTransferTemplate({
          transferTemplateId: id,
          bookTransfers: updateBookTransfersReq,
        });

        if (!updatedTemplate) return [];

        const updatedBookTransfers = updatedTemplate.bookTransfers || [];
        updatedBookTransfers.forEach((ut, i) => {
          updateBookTransfer(i, {
            ...data.bookTransfers[i],
            senderBankAccountDescription: ut.senderBankAccountDescription,
            receiverBankAccountDescription: ut.receiverBankAccountDescription,
          });
        });

        return updatedBookTransfers;
      }

      return [];
    },
    [id, templateType, updateTransferTemplate, updateAchTransfer, updateWireTransfer, updateBookTransfer]
  );

  const handleSendTransfersClick = useCallback(
    async (data: ReviewFormValues) => {
      if (isSubmitLoading) return;

      setIsSubmitLoading(true);
      let successfulTransfers = 0;
      let updatedTransfers: any[] = [];

      const hasErrors = [...achTransferStates, ...wireTransferStates, ...bookTransferStates].some(
        (s) => s.type === 'error'
      );

      if (hasErrors) {
        updatedTransfers = await updateTransferTemplateWithErrorCorrections(data);
        if (!updatedTransfers.length && updatedTransfers.length !== 0) {
          setIsSubmitLoading(false);
          return;
        }
      }

      if (data.templateType === TransferTemplateType.ACH) {
        for (let i = 0; i < data.achTransfers.length; i++) {
          const currentState = achTransferStates[i] || { type: 'idle' };
          const success = await handleAchTransferRow(data.achTransfers[i], i, currentState, updatedTransfers[i]);
          if (success) successfulTransfers++;
        }
      } else if (data.templateType === TransferTemplateType.Wire) {
        for (let i = 0; i < data.wireTransfers.length; i++) {
          const currentState = wireTransferStates[i] || { type: 'idle' };
          const success = await handleWireTransferRow(data.wireTransfers[i], i, currentState, updatedTransfers[i]);
          if (success) successfulTransfers++;
        }
      } else if (data.templateType === TransferTemplateType.Book) {
        for (let i = 0; i < data.bookTransfers.length; i++) {
          const currentState = bookTransferStates[i] || { type: 'idle' };
          const success = await handleBookTransferRow(data.bookTransfers[i], i, currentState, updatedTransfers[i]);
          if (success) successfulTransfers++;
        }
      }

      updateTransferTemplate({
        transferTemplateId: id,
        usageCount: (watch('usageCount') ?? 0) + 1,
        lastUsedAt: getDateTimeString(new Date()),
      }).finally(() => {
        setIsSubmitLoading(false);
        setIsDone(true);

        if (successfulTransfers > 0) {
          addSuccessNotification({
            content: (
              <>
                <strong>{successfulTransfers}</strong> Transfers from <strong>"{data.description}"</strong> successfully
                sent
              </>
            ),
            timeout: 8000,
          });
        }
      });
    },
    [
      id,
      watch,
      isSubmitLoading,
      achTransferStates,
      wireTransferStates,
      bookTransferStates,
      handleAchTransferRow,
      handleWireTransferRow,
      handleBookTransferRow,
      updateTransferTemplateWithErrorCorrections,
      updateTransferTemplate,
      addSuccessNotification,
    ]
  );

  const navigation = {
    handleEditClick: useCallback(() => {
      navigate(`${ROUTE.TRANSFERS}/templates/edit/${id}`, { state: { defaultFormValues: watch() } });
    }, [id, navigate, watch]),

    handleCancelClick: useCallback(() => {
      navigate(`${ROUTE.TRANSFERS}/templates`, { state: { defaultFormValues: null } });
    }, [navigate]),

    handleViewTransfersClick: useCallback(() => {
      navigate(ROUTE.TRANSFERS, { state: { defaultFormValues: null } });
    }, [navigate]),
  };

  const onSuccess = useCallback(
    (data: ReviewFormValues) => {
      handleSendTransfersClick(data);
    },
    [handleSendTransfersClick]
  );

  const onError: SubmitHandler<FieldValues> = useCallback(
    (errors) => {
      handleFormErrors({ errors });
    },
    [handleFormErrors]
  );

  useEffect(() => {
    if (id && !locationState?.defaultFormValues) {
      getTransferTemplate({ transferTemplateId: id }).then((data) => {
        if (!data) return;

        const newTransferTemplate = { ...data } as Partial<ReviewFormValues>;
        delete newTransferTemplate.version;

        reset(newTransferTemplate);
      });
    } else if (locationState?.defaultFormValues) {
      reset(locationState.defaultFormValues);
    }
  }, [id, locationState?.defaultFormValues]);

  useEffect(() => {
    const updateColumnVisibility = (columns: any[], setter: React.Dispatch<React.SetStateAction<any[]>>) => {
      setter(
        columns.map((entry) => ({
          ...entry,
          isHidden: entry.isDefault ? false : !visibleColumns.includes(entry.accessor),
        }))
      );
    };

    if (templateType === TransferTemplateType.ACH) {
      updateColumnVisibility(achColumns, setAchColumnEntries);
    } else if (templateType === TransferTemplateType.Wire) {
      updateColumnVisibility(wireColumns, setWireColumnEntries);
    } else if (templateType === TransferTemplateType.Book) {
      updateColumnVisibility(bookColumns, setBookColumnEntries);
    }
  }, [watch('visibleColumns')]);

  const hasErrorStates =
    achTransferStates.some((s) => s.type === 'error') ||
    wireTransferStates.some((s) => s.type === 'error') ||
    bookTransferStates.some((s) => s.type === 'error');

  const renderActionButtons = () => (
    <ButtonGroup>
      <Button
        type="button"
        variant="secondary"
        size="small"
        onClick={navigation.handleEditClick}
        isDisabled={isSubmitLoading}
      >
        Edit
      </Button>
      {isDone ? (
        hasErrorStates ? (
          <Button type="submit" icon={<Icon.Send />} size="small" isLoading={isSubmitLoading}>
            Retry
          </Button>
        ) : (
          <Button type="button" size="small" onClick={navigation.handleViewTransfersClick}>
            View Transfers
          </Button>
        )
      ) : (
        <Button type="submit" icon={<Icon.Send />} size="small" isLoading={isSubmitLoading}>
          Send Transfers
        </Button>
      )}
    </ButtonGroup>
  );

  return (
    <FormProvider {...methods}>
      <Container onSubmit={handleSubmit(onSuccess, onError)}>
        <PageHeader text="Review Transfer Template">
          <Button
            type="button"
            variant="secondary"
            size="small"
            onClick={navigation.handleCancelClick}
            isDisabled={isSubmitLoading}
          >
            Cancel
          </Button>
          {renderActionButtons()}
        </PageHeader>

        <Fade show={!!isLoading && !locationState?.defaultFormValues}>
          <Logo />
        </Fade>

        <Fade show={!isLoading || !!locationState?.defaultFormValues}>
          <Wrapper>
            {templateType === TransferTemplateType.ACH && (
              <Table
                columns={achColumnEntries}
                data={achTransfers.map((transfer, i) => ({
                  ...transfer,
                  id: transfer.id ?? String(i),
                  state: achTransferStates[i] ?? { type: 'idle' },
                }))}
              />
            )}
            {templateType === TransferTemplateType.Wire && (
              <Table
                columns={wireColumnEntries}
                data={wireTransfers.map((transfer, i) => ({
                  ...transfer,
                  id: transfer.id ?? String(i),
                  state: wireTransferStates[i] ?? { type: 'idle' },
                }))}
              />
            )}
            {templateType === TransferTemplateType.Book && (
              <Table
                columns={bookColumnEntries}
                data={bookTransfers.map((transfer, i) => ({
                  ...transfer,
                  id: transfer.id ?? String(i),
                  state: bookTransferStates[i] ?? { type: 'idle' },
                }))}
              />
            )}

            <ButtonWrapper>
              <Button
                type="button"
                size="small"
                variant="secondary"
                onClick={navigation.handleCancelClick}
                isDisabled={isSubmitLoading}
              >
                Cancel
              </Button>
              {renderActionButtons()}
            </ButtonWrapper>
          </Wrapper>
        </Fade>
      </Container>
    </FormProvider>
  );
};
