import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  AmountInput,
  Button,
  Chip,
  CurrenciesUSD,
  Dropdown,
  DropdownOption,
  Input,
  NumberInput,
  Toggle,
} from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { Loading, PageHeader, RenderFields } from '~/components';
import { useGetPlatformIntlWireConfig } from '~/hooks';
import { useAccountNumber, useBankAccount, useBankAccountDropdown } from '~/hooks/useBankAccounts';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';
import { InternationalWireBillingConfig, PlatformRepository } from '~/repositories/PlatformRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FormText, Inner } from '~/styles';
import { formatString, getDateLongUTC } from '~/util';

interface Params {
  id?: string;
}

interface PlatformSettingsFields {
  // Platform Settings
  createdAt?: Date;
  name: string;
  platformLevel: string;
  reportingTimeZone?: string;

  // International Wire Config
  revenueAccountNumberId?: string;
  fixedFee?: number;
  fxRateMarginBps?: number;

  // Transfer Approval Config
  enabledForWire?: boolean;
}

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledAmountInput = styled(AmountInput)`
  > div:first-child {
    gap: 2px;
  }
`;

export const PagePlatformSettings: React.FC = () => {
  const { currentUser, currentPlatform, loadPlatform } = useSessionStore();
  const location = useLocation();
  const { id } = useParams<keyof Params>() as Params;
  const platformId = useMemo(() => {
    if (location.pathname === ROUTE.PLATFORM_SETTINGS && currentUser?.defaultPlatformId) {
      return currentUser.defaultPlatformId;
    }
    return id;
  }, [currentPlatform, currentUser, id]);

  const enableTransferApprovals = useFeatureFlag(FeatureFlag.EnableTransferApprovals);

  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const {
    searchBankAccounts,
    options: bankAccountList,
    hiddenOptions: bankAccountHiddenOptions,
  } = useBankAccountDropdown(undefined, {
    platformId,
  });
  const { createRequest: getBankAccountActive } = useBankAccount();
  const { createRequest: getAccountNumberActive } = useAccountNumber({
    onSuccess: (d) => getBankAccountActive({ id: d.bankAccountId, platformId }),
  });
  const [intlWireConfig, setIntlWireConfig] = useState<InternationalWireBillingConfig>();
  const { createRequest: getPlatformIntlWireConfig, isLoading: isIntlWireConfigLoading } = useGetPlatformIntlWireConfig(
    {
      onSuccess: (data) => {
        setIntlWireConfig(data);
        if (data.revenueAccountNumberId) {
          getAccountNumberActive({ id: data.revenueAccountNumberId, platformId });
        }
      },
      onError: (error) => {
        addDangerNotification({
          content: error.message ?? 'Something went wrong getting International Wire Billing Config, please try again.',
        });
      },
    }
  );

  const [timezones, setTimezones] = useState<DropdownOption[]>([]);

  const formMethods = useForm<PlatformSettingsFields>({
    defaultValues: {
      createdAt: currentPlatform?.createdAt,
      name: currentPlatform?.name,
      platformLevel: currentPlatform?.platformLevel,
      reportingTimeZone: currentPlatform?.reportingTimeZone,
      enabledForWire: currentPlatform?.transferApprovalConfig?.enabledForWire ?? false,
    },
  });
  const { handleSubmit, setValue } = formMethods;
  useEffect(() => {
    if (!currentPlatform) return;
    setValue('createdAt', currentPlatform.createdAt);
    setValue('name', currentPlatform.name ?? '');
    setValue('platformLevel', currentPlatform.platformLevel ?? '');
    setValue('reportingTimeZone', currentPlatform.reportingTimeZone ?? '');
    setValue('enabledForWire', currentPlatform.transferApprovalConfig?.enabledForWire ?? false);
  }, [currentPlatform]);
  useEffect(() => {
    if (!intlWireConfig) return;
    setValue('revenueAccountNumberId', intlWireConfig.revenueAccountNumberId);
    setValue('fixedFee', intlWireConfig.fixedFee);
    setValue('fxRateMarginBps', intlWireConfig.fxRateMarginBps);
  }, [intlWireConfig]);

  const handleSave = useCallback(
    (data: PlatformSettingsFields) => {
      if (!platformId || !currentUser) {
        return;
      }

      const updateFns: Promise<any>[] = [
        currentPlatform?.id === platformId
          ? currentPlatform.update({ name: data.name, reportingTimeZone: data.reportingTimeZone })
          : PlatformRepository.update({
              platformId,
              name: data.name,
              reportingTimeZone: data.reportingTimeZone,
            }),
      ];

      if (data.revenueAccountNumberId || data.fixedFee || data.fxRateMarginBps) {
        updateFns.push(
          PlatformRepository.updateInternationalWireConfig({
            revenueAccountNumberId: data.revenueAccountNumberId,
            fixedFee: data.fixedFee,
            fxRateMarginBps: data.fxRateMarginBps,
            platformId,
          })
        );
      }

      if (enableTransferApprovals) {
        updateFns.push(
          PlatformRepository.updateConfig({
            transferApprovalConfig: {
              enabledForWire: data.enabledForWire,
            },
            platformId,
          })
        );
      }

      Promise.all(updateFns)
        .then(() => {
          addSuccessNotification({
            content: 'Platform saved',
          });
          loadPlatform({ fetchMeta: true });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
          });
        });
    },
    [addSuccessNotification, addDangerNotification, currentUser, currentPlatform, enableTransferApprovals, platformId]
  );

  const fetchInit = () => {
    if (currentUser && platformId) {
      getPlatformIntlWireConfig({ platformId });
    }
  };
  useEffect(fetchInit, [platformId]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => fetchInit(),
        {
          fireImmediately: true,
        }
      ),
    []
  );

  useEffect(() => {
    import('~/data/timezones.json').then((json) => {
      const entries: DropdownOption[] = Object.keys(json.default).map((t) => ({
        label: t,
        small: json.default[t as keyof typeof json.default],
        value: t,
      }));

      entries.splice(1, 0, {
        isDivider: true,
      });

      setTimezones(entries);
    });
  }, []);

  return (
    <>
      <PageHeader text="Settings" />
      <Loading isLoading={(!intlWireConfig && isIntlWireConfigLoading) || !currentPlatform}>
        <FormProvider {...formMethods}>
          <form autoComplete="off" onSubmit={handleSubmit(handleSave)}>
            {RenderFields<PlatformSettingsFields>({
              sections: [
                {
                  headline: 'General',
                  fields: [
                    {
                      id: 'name',
                      label: 'Name',
                      rules: { required: true },
                      children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                        return (
                          <Input
                            value={String(value)}
                            onChange={onChange}
                            onBlur={onBlur}
                            ref={ref}
                            placeholder="Name"
                            hasError={isTouched && !!error}
                          />
                        );
                      },
                    },
                    {
                      id: 'reportingTimeZone',
                      label: 'Reporting Time Zone',
                      children: ({ value, onChange }) => {
                        return (
                          <Dropdown
                            variant="muted"
                            fullWidth
                            active={value}
                            onChange={onChange}
                            options={timezones}
                            search
                          />
                        );
                      },
                    },
                    {
                      id: 'createdAt',
                      label: 'Created',
                      children: ({ value }) => <FormText>{value ? getDateLongUTC(value as Date) : '-'}</FormText>,
                    },
                    {
                      id: 'platformLevel',
                      label: 'Platform Level',
                      children: ({ value }) => <Chip>{value ? formatString(value as string) : ''}</Chip>,
                    },
                  ],
                },
                {
                  headline: 'International Wire Billing Configuration',
                  fields: [
                    {
                      id: 'revenueAccountNumberId',
                      label: 'Revenue Account Number',
                      tooltip: 'Account number ID for international wire revenues of a platform',
                      children: ({ value, onChange }) => (
                        <Dropdown
                          options={bankAccountList}
                          hiddenOptions={bankAccountHiddenOptions}
                          fullWidth
                          maxWidth="640px"
                          active={value}
                          search
                          searchLabel="Search for description"
                          onSearchChange={(description: string) => searchBankAccounts({ description, platformId })}
                          onChange={onChange}
                        />
                      ),
                    },
                    {
                      id: 'fixedFee',
                      label: 'Fixed Fee',
                      children: ({ value, onChange }) => {
                        return (
                          <StyledAmountInput
                            value={value as number | undefined}
                            onChange={onChange}
                            placeholder="Fixed Fee"
                            currencyList={CurrenciesUSD}
                            fixedCurrencyCode="USD"
                            currencyCode="USD"
                          />
                        );
                      },
                    },
                    {
                      id: 'fxRateMarginBps',
                      label: 'FX Rate Margin BPS',
                      tooltip: 'Number of basis points (BPS) for FX margin. 1 bps = 0.01%',
                      children: ({ value, onChange }) => {
                        return (
                          <NumberInput
                            value={value ? String(value) : ''}
                            onChange={(newValue) => onChange(Number(newValue))}
                            placeholder="FX Rate Margin BPS"
                          />
                        );
                      },
                    },
                  ],
                },
                {
                  headline: 'Transfer Approval Configuration',
                  description:
                    'Configures transfer types which require approval. Transfers may be approved by users with the "Approve Transfers" permission on their role.',
                  hide: !enableTransferApprovals,
                  fields: [
                    {
                      id: 'enabledForWire',
                      label: 'Wire Transfers',
                      children: ({ value, onChange }) => (
                        <Inner p={0} pt={8}>
                          <Toggle isChecked={typeof value === 'boolean' ? value : false} onCheckedChange={onChange} />
                        </Inner>
                      ),
                    },
                  ],
                },
              ],
            })}

            <Inner>
              <Action>
                <Button type="submit" size="small">
                  Save changes
                </Button>
              </Action>
            </Inner>
          </form>
        </FormProvider>
      </Loading>
    </>
  );
};
