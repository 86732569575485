import React, { UIEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

import { PopoverBase, PopoverBaseProps, StyledPopover } from '../Base';
import { useNavigate } from '~/lib/navigation';
import {
  EventRepository,
  ParsedEvent,
  getEventACHType,
  getEventIcon,
  getEventRoute,
  getEventStatus,
  getEventSummary,
  getRawEventDirection,
  getRawEventType,
  parseEvent,
} from '~/repositories';
import { useSessionStore } from '~/stores/Session';
import { getTimePassed } from '~/util';

interface PopoverEventsProps extends PopoverBaseProps {
  active?: string;
  onActiveChange?: (active: string) => void;
}

const Wrapper = styled(PopoverBase)`
  --animation-enter-x: 0px;
  --animation-exit-x: 16px;

  --animation-enter-y: 0px;
  --animation-exit-y: 0px;

  --popover-width: 400px;
  --popover-right: 0;
  --popover-left: auto;
  --popover-top: 0px;
  --popover-bottom: 0;
  --popover-padding: 0;

  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 0 10px 10px 0;
  position: fixed;
  width: 420px;
  top: 56px;
  right: 8px;
  bottom: 8px;

  ${StyledPopover} {
    --animation-enter-x: 0px;
    --animation-exit-x: 24px;

    --animation-enter-y: 0px;
    --animation-exit-y: 0px;

    width: 400px;
    right: 0;
    left: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0 10px 10px 0;
    box-shadow:
      1px 0 0 ${({ theme }) => theme.style.dropdownBorderColor},
      -1px 0 0 ${({ theme }) => theme.style.dropdownBorderColor},
      0 4px 16px rgba(38 38 44 / 3%);
  }
`;

const Heading = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  gap: 24px;
  top: 0;
  padding: 16px 20px;
  border-radius: 0 10px 0 0;
  background: ${({ theme }) => theme.background};
  border-bottom: 1px solid ${({ theme }) => theme.gray.blendToBackground(200)};
  z-index: 2;
  line-height: 16px;

  div {
    max-height: 16px;
    display: flex;
    align-items: center;
  }
`;

const Title = styled.h4`
  font-size: 16px;
  color: ${({ theme }) => theme.foreground};
  font-weight: 500;
  margin: 0;
  flex: auto;
  line-height: 20px;
`;

const Scrollable = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const List = styled.ul`
  display: grid;
  list-style: none;
  padding: 8px;
  box-sizing: border-box;
  margin: 0;
`;

const Entry = styled.li`
  position: relative;
  padding: 0 12px;
  border-radius: 8px;
  margin-bottom: -1px;
  font-size: 14px;
  display: flex;
  background: transparent;
  transition: background 0.2s;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.gray.blendToBackground(50)};
    z-index: 1;
  }
`;

const EntryInner = styled.div`
  padding: 12px 0;
  width: 100%;
  display: flex;
  line-height: 16px;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.gray.blendToBackground(200)};
  transition: border-color 0.2s;

  ${Entry}:hover & {
    border-color: transparent;
  }

  ${Entry}:last-child & {
    border: none;
  }
`;

const EventMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  justify-content: center;
`;

const EventType = styled.h4`
  flex: auto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  flex-grow: 0;
  padding: 0;
  text-transform: capitalize;
`;

const EventDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

const EventIcon = styled.div`
  margin-left: -2px;
  display: flex;
  align-items: center;
`;

const EventStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  padding-top: 2px;

  span {
    white-space: nowrap;
    font-size: 12px;
    padding-right: 4px;
    color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

const Loading = styled.div`
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

const Chip = styled.div``;

export const PopoverEvents: React.FC<PopoverEventsProps> = (props) => {
  const [data, setData] = useState<{ events: ParsedEvent[]; isLoading: boolean }>({
    events: [],
    isLoading: false,
  });
  const navigate = useNavigate();

  const handleFetch = async (startingAfter?: string) => {
    EventRepository.getAll({ startingAfter, limit: 25 }).then((response) => {
      if (response.events.length) {
        const newEvents = response.events.map((event) => parseEvent(event));
        setData((curEvents) => ({
          events: typeof startingAfter === 'undefined' ? newEvents : curEvents.events.concat(newEvents),
          isLoading: response.hasMore,
        }));
      }
    });
  };

  const handleScrollEnd = useCallback(() => {
    if (data.isLoading) {
      handleFetch(data.events.at(-1)?.id);
    }
  }, [handleFetch, data]);

  const handleScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const element = event.target as HTMLDivElement;

      if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
        handleScrollEnd();
      }
    },
    [handleScrollEnd]
  );

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => [state.isSandbox, state.currentUser?.defaultPlatformId],

        (state) => {
          if (typeof state[1] === 'undefined') {
            return;
          }

          handleFetch();
        },
        {
          equalityFn: shallow,
          fireImmediately: true,
        }
      ),
    []
  );

  // Fetch events on popover open
  useEffect(() => {
    if (props.show) {
      handleFetch();
    }
  }, [props.show]);

  return (
    <Wrapper {...props}>
      <Scrollable onScroll={handleScroll}>
        <Heading>
          <Title>Events</Title>
        </Heading>
        <List>
          {data.events.map((event, index: number) => {
            return (
              <Entry
                key={`popover-${event.id}-${String(index)}`}
                onClick={() =>
                  navigate(
                    getRawEventType(event.eventType) === 'Identity'
                      ? `${getEventRoute(event.eventType)}/${(event.data.subType ?? 'undefined').toLowerCase()}/${event.data.id}`
                      : getRawEventType(event.eventType) === undefined
                        ? ''
                        : `${getEventRoute(event.eventType)}/${event.data.id}`
                  )
                }
              >
                <EntryInner>
                  <EventMeta>
                    <EventType>
                      {getRawEventDirection(event.eventType)} {getRawEventType(event.eventType)}{' '}
                      {getEventACHType(event.data)}
                    </EventType>

                    <EventDetail>
                      <EventIcon>{getEventIcon(event.eventType)}</EventIcon>

                      <span>{getEventSummary(event.data)}</span>
                    </EventDetail>
                  </EventMeta>

                  <EventStatus>
                    <Chip>{getEventStatus(event.eventType)}</Chip>
                    <span>{getTimePassed(new Date(event.createdAt))}</span>
                  </EventStatus>
                </EntryInner>
              </Entry>
            );
          })}
          {data.isLoading && <Loading>Loading...</Loading>}
        </List>
      </Scrollable>
    </Wrapper>
  );
};
