// All possible transfer statuses
export enum TransferStatus {
  ACCEPTED = 'accepted',
  ACKNOWLEDGED = 'acknowledged',
  BLOCKED = 'blocked',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  DEPOSITED = 'deposited',
  FAILED = 'failed',
  FIRST_RETURN = 'first_return',
  HOLD = 'hold',
  INITIATED = 'initiated',
  INVALID_STATUS = 'invalid_status',
  ISSUED = 'issued',
  MANUAL_REVIEW = 'manual_review',
  MANUAL_REVIEW_APPROVED = 'manual_review_approved',
  MANUAL_REVIEW_FAILED = 'manual_review_failed',
  MANUAL_REVIEW_REJECTED = 'manual_review_rejected',
  PENDING_DEPOSIT = 'pending_deposit',
  PENDING_FIRST_RETURN = 'pending_first_return',
  PENDING_RECLEAR = 'pending_reclear',
  PENDING_RETURN = 'pending_return',
  PENDING_SECOND_RETURN = 'pending_second_return',
  PENDING_STOP = 'pending_stop',
  PENDING = 'pending',
  PENDING_SUBMISSION = 'pending_submission',
  PENDING_USER_INITIATED_RETURN = 'pending_user_initiated_return',
  PRE_REVIEW = 'pre_review',
  RECLEARED = 'recleared',
  REJECTED = 'rejected',
  RETURN_CONTESTED = 'return_contested',
  RETURN_DISHONORED = 'return_dishonored',
  RETURNED = 'returned',
  RETURNED_AT = 'returned_at',
  SCHEDULED = 'scheduled',
  SECOND_RETURN = 'second_return',
  SETTLED = 'settled',
  STOPPED = 'stopped',
  SUBMITTED = 'submitted',
  USER_INITIATED_RETURN_DISHONORED = 'user_initiated_return_dishonored',
  USER_INITIATED_RETURN_SUBMITTED = 'user_initiated_return_submitted',
  USER_INITIATED_RETURNED = 'user_initiated_returned',

  UNKNOWN = 'unknown', // Special frontend-only status to catch unhandled statuses
}

export const parseTransferStatus = (status: string): TransferStatus => {
  const lowerStatus = status.toLowerCase();
  if (Object.values<string>(TransferStatus).includes(lowerStatus)) {
    return lowerStatus as TransferStatus;
  } else {
    console.error(`Unknown status: ${status}`);
    return TransferStatus.UNKNOWN;
  }
};

// Progress states for the transfer
export enum TransferProgress {
  PROGRESS_FAILED = -1,
  PROGRESS_0 = 0,
  PROGRESS_25 = 25,
  PROGRESS_50 = 50,
  PROGRESS_75 = 75,
  PROGRESS_100 = 100,

  RETURNED = 'returned',
  UNKNOWN = 'unknown', // Special frontend-only status to catch unhandled progress status
}
