import React, { FormEvent, useEffect, useState } from 'react';

import { Button, Radio } from '@column/column-ui-kit';

import { Form } from '../../Login';
import { useNavigate } from '~/lib/navigation';
import { ROUTE } from '~/public/routes';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline } from '~/styles';

import { Option, OptionList, RegisterAction, RegisterContent, RegisterContentGrid } from './FinancialProducts';

export const PageRegisterCompanyEmployees: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const [loading, setLoading] = useState<boolean>(false);
  const [companySize, setCompanySize] = useState<string>('');
  const navigate = useNavigate();

  const onSuccess = (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);

    currentPlatformState
      ?.updateMeta({
        companySize,
      })
      .then(() => {
        setLoading(false);
        navigate(ROUTE.REGISTER_CUSTOMERS);
      })
      .catch((error) => {
        setLoading(false);

        addDangerNotification({
          content: error.message,
        });
      });
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.currentPlatform,
        (currentPlatform) => {
          setCompanySize(currentPlatform?.companySize ?? '');
        },
        { fireImmediately: true }
      ),
    []
  );

  return (
    <RegisterContent>
      <RegisterContentGrid>
        <Headline>How many employees does your company&nbsp;have?</Headline>
      </RegisterContentGrid>

      <Form onSubmit={onSuccess}>
        <OptionList>
          {['1', '2 – 5', '6 – 20', '21 – 50', '51 – 100', '101 – 2,500', '2,500+'].map((optionKey: string) => {
            return (
              <Option
                isActive={companySize === optionKey}
                isSimple
                key={optionKey}
                onClick={() => setCompanySize(optionKey)}
              >
                <Radio
                  isChecked={companySize === optionKey}
                  onCheckedChange={() => setCompanySize(optionKey)}
                  label={optionKey}
                />
              </Option>
            );
          })}
        </OptionList>
        <RegisterAction>
          <Button variant="muted" onClick={() => navigate(ROUTE.REGISTER_FINANCIAL_PRODUCTS)} type="button">
            Back
          </Button>
          <Button isLoading={loading} isDisabled={!companySize}>
            Continue
          </Button>
        </RegisterAction>
      </Form>
    </RegisterContent>
  );
};
