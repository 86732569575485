import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Dropdown, Icon, Modal, ModalSize } from '@column/column-ui-kit';

import {
  OptionsFullGranularWithDefault,
  OptionsOnOffWithDefault,
  OptionsThisBankAccount,
  OptionsThisEntity,
  Permissions,
} from '../types';
import { Breadcrumb, InfoTooltip, RenderFields } from '~/components';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';
import { Divider, Inner, Text } from '~/styles';

import { PermissionToggles } from './PermissionToggles';

const ClearOverridesButton = styled(Button)`
  padding: 0;
`;

const CustomOverridesModalControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const CustomOverridesModalControlGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  * > div {
    margin-left: auto;
    max-width: 400px;
  }
`;

const Wrapper = styled.div`
  width: calc(100vw - 48px);
  max-width: 800px;
  text-align: left;
`;

interface CustomOverridesModalProps {
  breadcrumbs: string[];
  open: boolean;
  onClose: () => void;
  onSave: (permissions: Permissions) => void;
  currentPermissions: Permissions;
  parentPermissions: Permissions;
  permissionsToLabelsMap: { [permission: string]: string };
}
export const CustomOverridesModal: React.FC<CustomOverridesModalProps> = ({
  breadcrumbs,
  currentPermissions,
  open,
  onClose,
  onSave,
  parentPermissions,
  permissionsToLabelsMap,
}) => {
  const enableTransferApprovals = useFeatureFlag(FeatureFlag.EnableTransferApprovals);
  const permissionsTogglesToLabelsMap = useMemo(() => {
    const p = { ...permissionsToLabelsMap };
    delete p.approveTransfers;
    delete p.bankAccounts;
    delete p.thisEntity;
    delete p.thisBankAccount;
    return p;
  }, [permissionsToLabelsMap]);

  const parentName = useMemo(() => {
    if (breadcrumbs.length < 2) {
      return 'the platform';
    }
    return breadcrumbs[1];
  }, [breadcrumbs]);

  const form = useForm<Permissions>({
    values: currentPermissions,
  });

  const { control, getValues, setValue, reset } = form;

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const hasOverrides = useMemo(() => {
    return Object.values(form.watch()).some((value) => value !== 'default');
  }, [form.watch()]);

  const resetOverrides = useCallback(() => {
    Object.keys(permissionsToLabelsMap).forEach((permission) => {
      setValue(permission, 'default');
    });
  }, [setValue, parentPermissions]);

  return (
    <Modal isOpen={open} onClose={onClose} size={ModalSize.FitContent} withClose={false}>
      <Modal.Header title="Custom Overrides">
        <Breadcrumb entries={breadcrumbs.map((breadcrumb) => ({ label: breadcrumb }))} />
        <Modal.Close variant="subtle" size="small" onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <Wrapper>
          <CustomOverridesModalControls>
            <Text color="light" margin="6px 0" weight="medium">
              Transfers
            </Text>
            {hasOverrides && (
              <CustomOverridesModalControlGroup>
                <InfoTooltip
                  content={`By default, permissions are inherited from ${parentName}. Permissions highlighted in blue override permissions from ${parentName}.`}
                  zIndex={14}
                />
                <ClearOverridesButton
                  icon={<Icon.Reset />}
                  iconRight
                  size={'small'}
                  variant="subtle-primary"
                  onClick={() => resetOverrides()}
                >
                  Clear overrides
                </ClearOverridesButton>
              </CustomOverridesModalControlGroup>
            )}
          </CustomOverridesModalControls>
          <PermissionToggles
            control={control}
            parentPermissions={parentPermissions}
            permissionsToLabelsMap={permissionsTogglesToLabelsMap}
          />
          <Inner px={0}>
            <Divider fullWidth />
          </Inner>
          {enableTransferApprovals && (
            <>
              <Inner p={0} pb={12}>
                <Text color="light" weight="medium">
                  Controls
                </Text>
              </Inner>
              <FormProvider {...form}>
                {RenderFields<any>({
                  sections: [
                    {
                      variant: 'isCompact',
                      fields: [
                        {
                          id: 'approveTransfers',
                          label: 'Approve Transfers',
                          labelVariant: form.watch('approveTransfers') !== 'default' ? 'primary' : undefined,
                          description: 'Set the permissions for approving or rejecting transfers in review.',
                          compact: true,
                          children: ({ value, onChange }) => {
                            let onOffValue = 'off';
                            if (value === 'write' || value === 'read') {
                              onOffValue = 'on';
                            }
                            if (value === 'default') {
                              onOffValue = 'default';
                            }
                            const onOffOnChange = (newValue: 'default' | 'on' | 'off') => {
                              switch (newValue) {
                                case 'on':
                                  onChange('write');
                                  break;
                                case 'off':
                                  onChange('none');
                                  break;
                                case 'default':
                                  onChange('default');
                                  break;
                              }
                            };

                            return (
                              <DropdownWrapper>
                                <Dropdown
                                  active={onOffValue}
                                  options={OptionsOnOffWithDefault}
                                  onChange={onOffOnChange}
                                  fullWidth
                                />
                              </DropdownWrapper>
                            );
                          },
                        },
                      ],
                    },
                  ],
                })}
              </FormProvider>
              <Inner px={0}>
                <Divider fullWidth />
              </Inner>
            </>
          )}
          <Inner p={0} pb={12}>
            <Text color="light" weight="medium">
              Edit Access
            </Text>
          </Inner>
          <FormProvider {...form}>
            {RenderFields<any>({
              sections: [
                {
                  variant: 'isCompact',
                  fields: [
                    {
                      id: 'thisBankAccount',
                      hide: !currentPermissions.thisBankAccount,
                      label: 'This Bank Account',
                      labelVariant: form.watch('thisBankAccount') !== 'default' ? 'primary' : undefined,
                      description: 'Set edit permissions on this Account.',
                      compact: true,
                      children: ({ value, onChange }) => {
                        return (
                          <DropdownWrapper>
                            <Dropdown
                              active={value ?? 'default'}
                              options={OptionsThisBankAccount}
                              onChange={onChange}
                              fullWidth
                            />
                          </DropdownWrapper>
                        );
                      },
                    },
                    {
                      id: 'thisEntity',
                      hide: !currentPermissions.thisEntity,
                      label: 'This Entity',
                      labelVariant: form.watch('thisEntity') !== 'default' ? 'primary' : undefined,
                      description: 'Set edit permissions on this Entity.',
                      compact: true,
                      children: ({ value, onChange }) => {
                        return (
                          <DropdownWrapper>
                            <Dropdown
                              active={value ?? 'default'}
                              options={OptionsThisEntity}
                              onChange={onChange}
                              fullWidth
                            />
                          </DropdownWrapper>
                        );
                      },
                    },
                    {
                      id: 'bankAccounts',
                      hide: !currentPermissions.bankAccounts,
                      label: 'Bank Accounts on Entity',
                      labelVariant: form.watch('bankAccounts') !== 'default' ? 'primary' : undefined,
                      description: 'Set edit permissions on the underlying Accounts on this Entity.',
                      compact: true,
                      children: ({ value, onChange }) => {
                        return (
                          <DropdownWrapper>
                            <Dropdown
                              active={value ?? 'default'}
                              options={OptionsFullGranularWithDefault}
                              onChange={onChange}
                              fullWidth
                            />
                          </DropdownWrapper>
                        );
                      },
                    },
                  ],
                },
              ],
            })}
          </FormProvider>
        </Wrapper>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} size="small">
          Cancel
        </Button>
        <Button onClick={() => onSave(getValues())} size="small">
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
