import chroma from 'chroma-js';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button, Chip, Icon, formatNumber } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { DataTable } from '~/components/DataTable';
import { EmptyState } from '~/components/EmptyState';
import { PopoverFilterWrapper, PopoverRangepicker } from '~/components/Popover';
import { RestrictedBanner } from '~/components/RestrictedBanner';
import { TableColumn } from '~/components/Table';
import { RelativeTime } from '~/elements';
import { DatePreview } from '~/elements/DatePreview';
import { ActivityFilterType, useBankAccountActivity, useQueryParams } from '~/hooks';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';
import { useNavigate } from '~/lib/navigation';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Inner } from '~/styles';
import { convertDatesToRange, parseDateTime } from '~/util/dateFilterHelpers';

import { BankAccountRouteProps } from './Route';

export const TransferAmount = styled.div<{ $isAdding?: boolean }>`
  color: ${({ theme, $isAdding }) =>
    $isAdding === true
      ? chroma(theme.success.background)
          .darken(theme.id !== 'dark' ? 0.75 : 0)
          .hex()
      : theme.foreground};

  ${({ $isAdding }) =>
    $isAdding === false &&
    css`
      position: relative;

      &:before {
        content: '-';
        position: absolute;
        right: 100%;
        display: block;
        top: -1px;
        font: inherit;
        line-height: inherit;
        z-index: 10;
      }
    `}
`;

const Note = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const Description = styled.div`
  display: flex;
  align-items: baseline;
  grid-gap: 8px;
  padding: 4px 0;
  max-width: 100%;

  ${Note} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const ResetFilter = styled(Button)<{ $isHidden?: boolean }>`
  padding-left: 0;
  padding-right: 0;
  margin-left: 4px;
  margin-right: 8px;

  transition: opacity 0.2s;
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : 1)};
  pointer-events: ${({ $isHidden }) => ($isHidden ? 'none' : 'auto')};
`;

export const PageBankAccountsStatements: FC = () => {
  const { isAuthorized } = useSessionStore();
  const { addDangerNotification, addInfoNotification } = useNotificationStore();
  const { bankAccount } = useOutletContext<BankAccountRouteProps>();
  const navigate = useNavigate();
  const { queryParams } = useQueryParams<ActivityFilterType>();
  const { response, isLoading, isInitialLoad, setQueryParams, getFilteredQueryParams } = useBankAccountActivity(
    bankAccount.id
  )({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });

      navigate(`${ROUTE.BANK_ACCOUNTS}/edit/${bankAccount.id}`);
    },
  });
  const openModal = useModalStore((state) => state.openModal);
  const enableCustomCSVStatements = useFeatureFlag(FeatureFlag.EnableCustomCSVStatements);
  const [dateFilter, setDateFilter] = useState<string[]>([]);

  const hasReportingPermissions = isAuthorized({
    permission: 'reporting',
    permissionLevel: 'read',
    bankAccount: { id: bankAccount.id, owners: bankAccount.owners },
  });

  const data = useMemo(() => {
    return response?.activityLineItems.map((item) => ({
      ...item,
      id: item.activityLineItemId,
      description: [item.activityLineItemId, item.amount, item.description],
    }));
  }, [response?.activityLineItems]);

  const hasFilter = useMemo(
    () => Object.keys(getFilteredQueryParams(['page', 'limit', 'startingAfter', 'endingBefore'])).length > 0,
    [getFilteredQueryParams]
  );

  const columns: TableColumn[] = [
    {
      Header: 'Amount / Description',
      accessor: 'description',
      width: 'minmax(300px, 54%)',
      Cell: (current) => (
        <Description>
          <TransferAmount key={current.value[0]} $isAdding={Number(current.value[1] ?? 0) > 0}>
            {formatNumber(Number(current.value[1] ?? 0) < 0 ? (current.value[1] ?? 0) * -1 : (current.value[1] ?? 0))}
          </TransferAmount>
          {current.value[2] && <Note>{current.value[2]}</Note>}
        </Description>
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 'minmax(min-content, 18%)',
      Cell: (current) => <Chip>{current.value}</Chip>,
    },
    {
      Header: 'Settlement Date',
      accessor: 'settlementDate',
      width: 'minmax(min-content, 18%)',
      sortType: 'datetime',
      Cell: (current) => <RelativeTime timestamp={current.value} format="mm/dd/yyy hh:mm AM/PM" />,
    },
    {
      Header: 'Balance',
      accessor: 'runningBalance',
      width: 'minmax(min-content, 10%)',
      Cell: (current) => <Chip type="info">{formatNumber(Number(current.value ?? 0))}</Chip>,
    },
  ];

  const handleDateFilterSubmit = useCallback(
    (value: string | string[] | undefined) => {
      setDateFilter(Array.isArray(value) ? value : value ? [value] : []);

      if (typeof value === 'undefined' || !value || (value && value.length !== 2)) {
        setQueryParams(
          {
            limit: queryParams?.limit ?? 10,
            page: 1,
          },
          true
        );

        return;
      }

      const params = convertDatesToRange({
        startDateString: value[0],
        endDateString: value[1],
        field: 'effectiveAt',
      });

      setQueryParams(
        {
          limit: queryParams?.limit ?? 10,
          page: 1,
          ...params,
        },
        true
      );
    },
    [setDateFilter, setQueryParams, queryParams]
  );

  const handleFilterReset = useCallback(() => {
    setDateFilter([]);

    setQueryParams(
      {
        limit: queryParams?.limit ?? 10,
        page: 1,
      },
      true
    );
  }, [setDateFilter, setQueryParams, queryParams]);

  useEffect(() => {
    if (dateFilter.length === 2) {
      handleDateFilterSubmit(dateFilter);
    }
  }, []);

  useEffect(() => {
    if (queryParams?.effectiveAtGte && queryParams?.effectiveAtLt) {
      const startInfo = parseDateTime(queryParams.effectiveAtGte);
      const endInfo = parseDateTime(queryParams.effectiveAtLt, true);

      if (endInfo.date === dateFilter[0] && startInfo.date === dateFilter[1]) {
        return;
      }

      handleDateFilterSubmit([startInfo.date, endInfo.date]);
    }
  }, []);

  return (
    <>
      {!hasReportingPermissions && (
        <Inner pt={0}>
          <RestrictedBanner />
        </Inner>
      )}
      <DataTable
        filterElement={
          <>
            <PopoverFilterWrapper
              label={
                dateFilter.length === 2 ? (
                  <DatePreview isActive selectedDates={dateFilter} format="month-name" />
                ) : (
                  'Date'
                )
              }
              onSubmit={handleDateFilterSubmit}
              isActive={dateFilter.length > 0}
            >
              <PopoverRangepicker date={dateFilter ?? []} />
            </PopoverFilterWrapper>

            <ResetFilter
              size="small"
              variant="subtle-primary"
              icon={<Icon.Reset />}
              iconRight
              onClick={handleFilterReset}
              $isHidden={!hasFilter}
            >
              Reset filters
            </ResetFilter>
          </>
        }
        action={
          enableCustomCSVStatements && (
            <Button
              icon={<Icon.AnimationDownload />}
              size="small"
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                openModal('CreateStatement', { bankAccount, dates: dateFilter });
              }}
            >
              Export CSV
            </Button>
          )
        }
        tableId="bankAccountActivity"
        columns={columns}
        response={data ?? []}
        hasMore={response?.hasMore}
        isLoading={isLoading}
        isInitialLoad={isInitialLoad}
        hasFilter={hasFilter}
        setQueryParams={setQueryParams}
        empty={!hasFilter && <EmptyState headline="No activity found" text="No bank account activity available." />}
        onRowClick={(row) => {
          if (row.original.type === 'Realtime') {
            addInfoNotification({
              content: 'Realtime transfer view will be available soon',
            });

            return;
          }

          const type = String(row.original.type).toLowerCase().replace(' ', '-').trim();

          navigate(`${ROUTE.TRANSFERS}/edit/${type}/${row.original.transactionId}`);
        }}
      />
    </>
  );
};
