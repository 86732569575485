import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Chip, Modal, ModalSize } from '@column/column-ui-kit';

import { useAcceptPlatformInvite } from '~/hooks/useUsers';
import { Invite } from '~/repositories/InviteRepository';
import { getRoleLabel } from '~/repositories/RoleRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Paragraph } from '~/styles';

export interface Props {
  inviteCode?: string;
  invite?: Invite;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

const StyledBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  vertical-align: middle;

  & > * {
    margin-right: 6px;
  }
`;

export const AcceptPlatformInviteModal: React.FC<Props> = ({ invite, inviteCode, open, onClose, onSave }) => {
  const { triggerEvent: triggerSessionEvent } = useSessionStore();
  const [isAcceptingInvite, setAcceptingInvite] = useState<boolean>(false);
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const addSuccessNotification = useNotificationStore((s) => s.addSuccessNotification);

  const acceptPlatformInvite = useAcceptPlatformInvite({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'popup',
      });
      setAcceptingInvite(false);
    },
    onSuccess: () => {
      addSuccessNotification({
        content: 'Invite accepted',
      });
      setAcceptingInvite(false);
      triggerSessionEvent('accept_platform_invite');
      onSave();
    },
  });

  const handleAcceptClick = useCallback(() => {
    if (!invite || !inviteCode) return;
    acceptPlatformInvite.createRequest({ inviteCode, platformId: invite.platformId });
    setAcceptingInvite(true);
  }, [acceptPlatformInvite, inviteCode]);

  const roleName = useMemo(() => {
    if (!invite) return '';
    return getRoleLabel(invite.platformRole);
  }, [invite]);

  return (
    <Modal isOpen={open} onClose={onClose} size={ModalSize.Small}>
      <Modal.IconCircle type="primary" icon="AnimationMailbox" />
      <Modal.Content>
        <Modal.Headline>Join {invite?.platformName}?</Modal.Headline>
        <StyledBody>
          <Paragraph>Your role will be set as </Paragraph>
          <Chip>{roleName}</Chip>
        </StyledBody>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleAcceptClick} isLoading={isAcceptingInvite} isDisabled={!invite || isAcceptingInvite}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
