import { useEffect, useState } from 'react';

export const useNAICSCodes = () => {
  const [naicsCodes, setNaicsCodes] = useState<NAICSCode[]>([]);

  useEffect(() => {
    import('~/data/naics_codes.json').then((json) => {
      setNaicsCodes(json.default);
    });
  });
  return naicsCodes;
};
