import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { DropdownEntry, DropdownEntryText, Icon } from '@column/column-ui-kit';

import { PopoverBase, PopoverBaseProps } from '../Base';
import { ROUTE } from '~/app/routes';
import { useNavigate } from '~/lib/navigation';
import { ROUTE as PUBLIC_ROUTE } from '~/public/routes';
import { useSessionStore } from '~/stores/Session';
import { Caption } from '~/styles';

interface PopoverUserProps extends PopoverBaseProps {
  active?: string;
  onActiveChange?: (active: string) => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-right: 12px;
  --popover-left: auto;
  --popover-top: 44px;
  --popover-width: 220px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const User = styled(Caption)`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  margin: 0 -4px 4px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  h4 {
    font-weight: 500;
    color: ${({ theme }) => theme.secondary.background};
    margin: 0;
    font-size: 14px;
    line-height: 16px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.secondary.background};
    margin: 0;
  }
`;

export const PopoverUser: React.FC<PopoverUserProps> = (props) => {
  const { settings, changeTheme, currentUser } = useSessionStore();
  const navigate = useNavigate();
  const [userAnimate, setUserAnimate] = useState<boolean>(false);
  const [modeAnimate, setModeAnimate] = useState<boolean>(false);
  const [logoutAnimate, setLogoutAnimate] = useState<boolean>(false);

  const handleLogout = useCallback(() => {
    navigate(PUBLIC_ROUTE.LOGIN, {
      state: {
        action: 'signOut',
      },
    });
  }, []);

  return (
    <Wrapper
      className={props.className}
      onClose={props.onClose}
      show={props.show}
      withoutBackdrop={props.withoutBackdrop}
    >
      <User>
        <h4>
          {currentUser?.firstName} {currentUser?.lastName}
        </h4>
        <p>{currentUser?.email}</p>
      </User>
      <Options>
        <DropdownEntry
          onClick={() => navigate(ROUTE.PROFILE)}
          onMouseEnter={() => !userAnimate && setUserAnimate(true)}
        >
          <Icon.AnimationUser running={userAnimate} onDone={() => setUserAnimate(false)} />
          <DropdownEntryText>Profile</DropdownEntryText>
        </DropdownEntry>

        <DropdownEntry onClick={() => changeTheme()} onMouseEnter={() => !modeAnimate && setModeAnimate(true)}>
          <Icon.AnimationMode running={modeAnimate} onDone={() => setModeAnimate(false)} />
          <DropdownEntryText>{settings.themeType === 'default' ? 'Lights off' : 'Lights on'}</DropdownEntryText>
        </DropdownEntry>

        <DropdownEntry onClick={() => handleLogout()} onMouseEnter={() => !logoutAnimate && setLogoutAnimate(true)}>
          <Icon.AnimationLogout running={logoutAnimate} onDone={() => setLogoutAnimate(false)} />
          <DropdownEntryText>Logout</DropdownEntryText>
        </DropdownEntry>
      </Options>
    </Wrapper>
  );
};
