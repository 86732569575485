import isPropValid from '@emotion/is-prop-valid';
import React, { FC, useCallback } from 'react';
import { StyleSheetManager, WebTarget } from 'styled-components';

import { Layout } from '~/app/Layout';
import { DialogProvider } from '~/components/Dialog/Provider';
import { useCodeHighlighting } from '~/lib/json/useCodeHighlighting';

import { AuthProvider } from './AuthProvider';
import { LDClientIdentifier } from './LDClientIdentifier';
import Router from './Router';

export const App: FC = () => {
  useCodeHighlighting();

  // Add backwards compatibility from styled-components v5 -> v6:
  // https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
  const shouldForwardProp = useCallback((propName: string, target: WebTarget) => {
    if (typeof target === 'string') {
      return isPropValid(propName);
    }

    return true;
  }, []);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Layout>
        <AuthProvider>
          <LDClientIdentifier>
            <DialogProvider>
              <Router />
            </DialogProvider>
          </LDClientIdentifier>
        </AuthProvider>
      </Layout>
    </StyleSheetManager>
  );
};
