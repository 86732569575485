import React from 'react';

import { Icon } from '@column/column-ui-kit';

import { TransferProgress, TransferStatus, parseTransferStatus } from './types';

const COMPLETED_STATUSES = new Set([TransferStatus.COMPLETED, TransferStatus.SETTLED]);

const RETURNED_STATUSES = new Set([TransferStatus.RETURNED, TransferStatus.RETURNED_AT]);

const FAILED_STATUSES = new Set([
  TransferStatus.BLOCKED,
  TransferStatus.CANCELED,
  TransferStatus.FAILED,
  TransferStatus.FIRST_RETURN,
  TransferStatus.INVALID_STATUS,
  TransferStatus.MANUAL_REVIEW_FAILED,
  TransferStatus.MANUAL_REVIEW_REJECTED,
  TransferStatus.REJECTED,
  TransferStatus.RETURN_CONTESTED,
  TransferStatus.RETURN_DISHONORED,
  TransferStatus.RETURNED,
  TransferStatus.RETURNED_AT,
  TransferStatus.SECOND_RETURN,
  TransferStatus.STOPPED,
  TransferStatus.USER_INITIATED_RETURNED,
  TransferStatus.USER_INITIATED_RETURN_DISHONORED,
]);

const PROGRESS_0_STATUSES = new Set([TransferStatus.PRE_REVIEW]);

const PROGRESS_25_STATUSES = new Set([
  TransferStatus.INITIATED,
  TransferStatus.ISSUED,
  TransferStatus.MANUAL_REVIEW,
  TransferStatus.PENDING_DEPOSIT,
  TransferStatus.PENDING_SUBMISSION,
  TransferStatus.SCHEDULED,
  TransferStatus.USER_INITIATED_RETURN_SUBMITTED,
]);

const PROGRESS_50_STATUSES = new Set([
  TransferStatus.ACCEPTED,
  TransferStatus.ACKNOWLEDGED,
  TransferStatus.HOLD,
  TransferStatus.SUBMITTED,
  TransferStatus.PENDING,
]);

const PROGRESS_75_STATUSES = new Set([
  TransferStatus.DEPOSITED,
  TransferStatus.MANUAL_REVIEW_APPROVED,
  TransferStatus.PENDING_FIRST_RETURN,
  TransferStatus.PENDING_RECLEAR,
  TransferStatus.PENDING_RETURN,
  TransferStatus.PENDING_SECOND_RETURN,
  TransferStatus.PENDING_STOP,
  TransferStatus.PENDING_USER_INITIATED_RETURN,
  TransferStatus.RECLEARED,
]);

export const getProgressFromTransferStatus = (status: TransferStatus | string): TransferProgress => {
  const statusEnum = parseTransferStatus(status);

  if (COMPLETED_STATUSES.has(statusEnum)) {
    return TransferProgress.PROGRESS_100;
  }
  if (RETURNED_STATUSES.has(statusEnum)) {
    return TransferProgress.RETURNED;
  }
  if (PROGRESS_75_STATUSES.has(statusEnum)) {
    return TransferProgress.PROGRESS_75;
  }
  if (PROGRESS_50_STATUSES.has(statusEnum)) {
    return TransferProgress.PROGRESS_50;
  }
  if (PROGRESS_25_STATUSES.has(statusEnum)) {
    return TransferProgress.PROGRESS_25;
  }
  if (PROGRESS_0_STATUSES.has(statusEnum)) {
    return TransferProgress.PROGRESS_0;
  }
  if (FAILED_STATUSES.has(statusEnum)) {
    return TransferProgress.PROGRESS_FAILED;
  }
  console.error(`Unmapped status: ${status}`);
  return TransferProgress.UNKNOWN;
};

export const getTransferProgressIcon = (progress: TransferProgress) => {
  switch (progress) {
    case TransferProgress.PROGRESS_100:
      return <Icon.CircleCheck />;
    case TransferProgress.PROGRESS_75:
      return <Icon.CirclePendingThree />;
    case TransferProgress.PROGRESS_50:
      return <Icon.CirclePendingTwo />;
    case TransferProgress.PROGRESS_25:
      return <Icon.CirclePendingOne />;
    case TransferProgress.PROGRESS_0:
      return <Icon.CirclePendingApproval />;
    case TransferProgress.PROGRESS_FAILED:
      return <Icon.CircleCross />;
    case TransferProgress.RETURNED:
      return <Icon.CircleReturn />;
    default:
      return <Icon.CircleBlank />;
  }
};
