import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button } from '@column/column-ui-kit';

import { CheckReturnModal } from '~/app/pages/Transfers/_components/CheckReturnModal';
import { Details } from '~/app/pages/Transfers/_components/Details';
import { buildCheckEvents, buildCheckReturnEvents } from '~/app/pages/Transfers/_utils/check';
import { ROUTE } from '~/app/routes';
import { CopyInput, ImageLoading, Loading, RenderFields } from '~/components';
import { useAccountNumber, useBankAccount, useEntity } from '~/hooks';
import { useTransfer } from '~/hooks/useTransfers';
import { useNavigate } from '~/lib/navigation';
import {
  CheckRepository,
  CheckReturnsList,
  CheckTransfer,
  CheckTransferImages,
  convertTransferAmount,
} from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Divider, Inner } from '~/styles';

import { InfoForm, TransferPageHeader } from './_components/Layout';
import { Timeline } from './_components/Timeline';

interface Params {
  id: string;
}

const formatData = (transfer: CheckTransfer): Record<string, unknown> => {
  const formatted: Record<string, unknown> = {
    payee: {
      name: transfer.payeeName,
      address: transfer.payeeAddress,
    },
    check: {
      checkNum: transfer.checkNumber,
      micrLine: transfer.micrLine,
    },
    delivery: {
      status: transfer.deliveryStatus,
      message: transfer.message,
      memo: transfer.memo,
    },
  };

  Object.entries(transfer).forEach(([key, value]) => {
    if (key.startsWith('payee') || key.startsWith('check') || key.startsWith('delivery')) {
      return;
    }
    formatted[key] = value;
  });

  return formatted;
};

export const PageTransfersCheck: FC = () => {
  const { id } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();
  const { isAuthorized } = useSessionStore();
  const [isReturnModalOpen, setReturnModalOpen] = useState(false);
  const { addDangerNotification, addSuccessNotification } = useNotificationStore();

  const {
    response: bankAccount,
    isLoading: isBankAccountLoading,
    createRequest: fetchBankAccount,
  } = useBankAccount({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const {
    response: accountNumber,
    isLoading: isAccountNumberLoading,
    createRequest: fetchAccountNumber,
  } = useAccountNumber({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const { response: entity, createRequest: fetchEntity } = useEntity({
    onSuccess: () => setIsLoadingEntity(false),
    onError: (error) => {
      setIsLoadingEntity(false);
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const [transferImages, setTransferImages] = useState<CheckTransferImages>({});
  const [checkReturnsList, setCheckReturnsList] = useState<CheckReturnsList['returns'] | undefined>([]);
  const {
    response: transfer,
    isLoading: isTransferLoading,
    createRequest: fetchTransfer,
  } = useTransfer<CheckTransfer>('check')({
    initialParams: { id },
    onSuccess: async (response) => {
      fetchAccountNumber({ id: response.accountNumberId });

      try {
        if (response.bankAccountId) {
          const currentBankAccount = await fetchBankAccount({ id: response.bankAccountId });

          if (!currentBankAccount) {
            return;
          }

          if (isAuthorized({ permission: 'entities', permissionLevel: 'read' })) {
            fetchEntity({ id: currentBankAccount.owners?.[0] });
            setIsLoadingEntity(true);
          }
        }
      } catch (error) {
        addDangerNotification({ content: (error as Error).message });
      }

      CheckRepository.getImages(id)
        .then(async (images) => {
          setTransferImages({
            frontImageJpeg: `data:image/jpeg;base64,${images.frontImageJpeg}`,
            backImageJpeg: `data:image/jpeg;base64,${images.backImageJpeg}`,
          });
        })
        .catch(() => {
          setTransferImages({
            frontImageJpeg: undefined,
            backImageJpeg: undefined,
          });
        });

      CheckRepository.listReturns(id)
        .then((returns) => {
          if (returns.returns && returns.returns.length > 0) {
            setCheckReturnsList(returns.returns);
          }
        })
        .catch(() => {
          setCheckReturnsList(undefined);
        });
    },
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const formattedTransfer = useMemo(() => (transfer ? formatData(transfer) : undefined), [transfer]);

  const formValues = useMemo(
    () => ({ ...transfer, ...transferImages }) as CheckTransfer & CheckTransferImages,
    [transfer, transferImages]
  );
  const formMethods = useForm<CheckTransfer & CheckTransferImages>({ values: formValues });
  useEffect(() => {
    if (transfer || transferImages) {
      formMethods.reset();
    }
  }, [transfer, transferImages]);

  const isDataLoading = useMemo(() => {
    return isAccountNumberLoading || isBankAccountLoading || isTransferLoading || !transfer;
  }, [isAccountNumberLoading, isBankAccountLoading, isTransferLoading, transfer]);

  const handleStopPayment = useCallback(() => {
    if (!transfer) return;
    CheckRepository.stopPayment(transfer.id)
      .then(() => {
        fetchTransfer();
        addSuccessNotification({
          content: 'Check payment stopped',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  }, [transfer, fetchTransfer, addSuccessNotification, addDangerNotification]);

  const transferEntryType = transfer?.type.toLowerCase() === 'credit' ? 'check_credit' : 'check_debit';
  const isIncoming = transfer?.type.toLowerCase() === 'credit';

  return (
    <>
      {transfer && (
        <CheckReturnModal
          isOpen={isReturnModalOpen}
          onClose={() => setReturnModalOpen(false)}
          transferId={transfer.id}
        />
      )}
      <Loading isLoading={isDataLoading} fullHeight>
        {transfer && (
          <TransferPageHeader
            amount={transfer.depositedAmount || transfer.positivePayAmount || '0'}
            createdAt={transfer.createdAt}
            incomingText="Deposit"
            isAdding={transfer ? convertTransferAmount(transferEntryType, isIncoming) > 0 : undefined}
            isIncoming={transfer.type.toLowerCase() === 'credit'}
            outgoingText="Issue"
            status={transfer.status}
            transferId={transfer.id}
            transferType="Check"
          >
            {(transfer?.status === 'issued' || transfer?.status === 'manual_review') && (
              <Button variant="danger" size="small" onClick={handleStopPayment}>
                Stop Payment
              </Button>
            )}
            {transfer?.status === 'settled' && transfer?.type.toLowerCase() !== 'credit' && (
              <Button variant="secondary" onClick={() => setReturnModalOpen(true)} size="small">
                Return
              </Button>
            )}
          </TransferPageHeader>
        )}
        {bankAccount && accountNumber && transfer && !isLoadingEntity && (
          <Timeline
            entries={buildCheckEvents({
              transfer,
              account: {
                accountNumber,
                bankAccount,
                entityType: entity ? (entity.type.toLowerCase() as 'person' | 'business') : undefined,
              },
            })}
            returnEntries={buildCheckReturnEvents(checkReturnsList)}
          />
        )}
        <FormProvider {...formMethods}>
          <InfoForm autoComplete="off">
            {RenderFields<CheckTransfer & CheckTransferImages>({
              sections: [
                {
                  headline: 'Information',
                  fields: [
                    {
                      id: 'description',
                      label: 'Description',
                      children: ({ value }) => {
                        return <CopyInput value={value ? String(value) : '-'} />;
                      },
                    },
                    {
                      id: 'idempotencyKey',
                      label: 'Idempotency Key',
                      children: ({ value }) => {
                        return <CopyInput value={value ? String(value) : '-'} />;
                      },
                    },
                    {
                      id: 'checkNumber',
                      label: 'Check Number',
                      children: ({ value }) => {
                        return <CopyInput value={value ? String(value) : '-'} />;
                      },
                    },
                  ],
                },
                {
                  headline: 'MICR Line',
                  fields: [
                    {
                      id: 'micrLine.amountField',
                      label: 'Amount Field',
                      children: ({ value }) => {
                        return <CopyInput value={value ? String(value) : '-'} />;
                      },
                    },
                    {
                      id: 'micrLine.payorBankRoutingNumber',
                      label: 'Payor Bank Routing Number',
                      children: ({ value }) => {
                        return <CopyInput value={value ? String(value) : '-'} />;
                      },
                    },
                    {
                      id: 'micrLine.auxiliaryOnUs',
                      label: 'Auxiliary On Us',
                      children: ({ value }) => {
                        return <CopyInput value={value ? String(value) : '-'} />;
                      },
                    },
                    {
                      id: 'micrLine.onUs',
                      label: 'On Us',
                      children: ({ value }) => {
                        return <CopyInput value={value ? String(value) : '-'} />;
                      },
                    },
                    {
                      id: 'micrLine.externalProcessingCode',
                      label: 'External Processing Code',
                      children: ({ value }) => {
                        return <CopyInput value={value ? String(value) : '-'} />;
                      },
                    },
                  ],
                },
                {
                  headline: 'Images',
                  fields: [
                    {
                      id: 'frontImageJpeg',
                      label: 'Front',
                      children: ({ value }) => {
                        return (
                          <ImageLoading
                            base64String={value ? String(value) : undefined}
                            errorMessage="Front image could not be loaded"
                          />
                        );
                      },
                    },
                    {
                      id: 'backImageJpeg',
                      label: 'Back',
                      children: ({ value }) => {
                        return (
                          <ImageLoading
                            base64String={value ? String(value) : undefined}
                            errorMessage="Back image could not be loaded"
                          />
                        );
                      },
                    },
                  ],
                },
              ],
            })}
            <Divider />
            <Inner px={0} pt={24}>
              <Details formattedData={formattedTransfer} rawData={transfer as Record<string, unknown> | undefined} />
            </Inner>
          </InfoForm>
        </FormProvider>
      </Loading>
    </>
  );
};
