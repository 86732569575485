import React, { forwardRef } from 'react';
import { Path } from 'react-hook-form';
import styled from 'styled-components';

import { AmountInput, AmountInputProps } from '@column/column-ui-kit';

import { FormField } from '~/components';
import { TransferTemplate } from '~/repositories/Transfer/TransferTemplateRepository';

interface AmountCellProps extends AmountInputProps {
  id: string;
  array: 'achTransfers' | 'wireTransfers' | 'bookTransfers';
  index: number;
}

const Input = styled(AmountInput)`
  --input-background: transparent;
  --input-color: ${({ theme }) => theme.foreground};

  border-radius: var(--table-cell-border-radius);
  box-shadow: inset 0 0 0 var(--input-inline-outline, 0px) ${({ theme }) => theme.primary.background};
  position: relative;

  &:focus,
  &:focus-within {
    --input-inline-outline: 1px;

    z-index: 3;
  }
`;

export const AmountCell = forwardRef<HTMLLabelElement, AmountCellProps>(
  ({ id, array, index, value, ...props }, ref) => {
    return (
      <FormField<TransferTemplate>
        id={`${array}.${index}.${id}` as Path<TransferTemplate>}
        oneColumn
        noStyle
        defaultValue={value}
        rules={{ required: true }}
      >
        {({ value: valueFromForm, onChange, onBlur }, { isTouched, error }) => (
          <Input
            {...props}
            ref={ref}
            value={typeof valueFromForm !== 'undefined' ? Number(valueFromForm) : undefined}
            onChange={onChange}
            onBlur={onBlur}
            hasError={isTouched && !!error}
          />
        )}
      </FormField>
    );
  }
);
