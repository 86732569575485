import React, { FC, useCallback, useEffect } from 'react';

import { Button, Icon, Modal, ModalSize } from '@column/column-ui-kit';

import { useQueryParams } from '~/hooks/useQueryParams';
import { UserRepository } from '~/repositories/UserRepository';
import { Inner, Paragraph } from '~/styles';

const ACCEPT_CODE = 'c1e43af8-f71f-4c34-bb2f-f98561b5ec71';

export const AcceptLegalAgreementModal: FC = () => {
  const { queryParams, removeQueryParam } = useQueryParams<{ acceptAgreementsCode?: string }>();

  const closeModal = useCallback(() => {
    removeQueryParam('acceptAgreementsCode');
  }, []);

  useEffect(() => {
    if (queryParams?.acceptAgreementsCode === ACCEPT_CODE) {
      UserRepository.acceptLegalAgreement();
    }
  }, [queryParams]);

  return (
    <Modal isOpen={queryParams?.acceptAgreementsCode === ACCEPT_CODE} onClose={closeModal} size={ModalSize.Small}>
      <Modal.IconCircle type="primary">
        <Icon.AnimationDocumentEdit />
      </Modal.IconCircle>

      <Modal.Headline>Terms Accepted</Modal.Headline>

      <Inner pb={0} px={0} pt={12}>
        <Paragraph>No further action needed.</Paragraph>
      </Inner>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Continue to dashboard
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
