import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@column/column-ui-kit';

import { ModalType, useModalStore } from '~/stores/Modal';
import { Headline, PlatformInfoCheckbox, PlatformInfoCheckboxElement, PlatformInfoDescription } from '~/styles';

import { ModalBase } from './Base';

const Wrapper = styled(ModalBase)`
  --modal-width: 400px;
  padding: 24px;
`;

const CheckboxWrapper = styled(PlatformInfoCheckboxElement)`
  margin: 20px 0;
  cursor: pointer;
`;

export const ModalEnableProduction: React.FC = () => {
  const closeModal = useModalStore((state) => state.closeModal);
  const [checked, setChecked] = useState<boolean>(false);
  const modalTypes: ModalType[] = ['EnableProduction'];

  return (
    <Wrapper modalTypes={modalTypes}>
      <Headline withMargin>One last thing</Headline>
      <PlatformInfoDescription>
        You’ve completed all the necessary steps. Your account is now eligible to turn off sandbox mode and move real
        money.
      </PlatformInfoDescription>
      <CheckboxWrapper onClick={() => setChecked((value) => !value)}>
        <PlatformInfoCheckbox isChecked={checked} onCheckedChange={(value: boolean) => setChecked(value)} />
        <PlatformInfoDescription>
          I acknowledge that I am only doing this for testing purposes, not running my business
        </PlatformInfoDescription>
      </CheckboxWrapper>
      <Button isDisabled={!checked} onClick={() => closeModal()}>
        Submit
      </Button>
    </Wrapper>
  );
};
