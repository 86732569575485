import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

import { Dropdown, DropdownElement, DropdownOption, Icon, Input } from '@column/column-ui-kit';

import countryCodeJSON from '~/data/wires/country-codes.json';
import { useDebounce, useInstitutions } from '~/hooks';
import { FinancialInstitution } from '~/repositories';
import { ApiError } from '~/typings/API';
import { titleCase } from '~/util';

interface InstitutionsFieldProps {
  onChange: (institution: FinancialInstitution) => void;
  onError?: (error: ApiError) => void;
  className?: string;
}

export interface InstitutionsFieldRef {
  setValue: (value: string) => void;
}

interface CountryCodeEntry {
  countryCode: string;
  currencyCode: string;
  countryName: string;
}

const StyledDropdown = styled(Dropdown)`
  --dropdown-custom-padding: 0;
`;

const Field = styled.div`
  position: relative;
`;

const CountryCodeDropdown = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 5;
`;

const Label = styled.div<{ $isFilled: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px 8px 8px 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ $isFilled, theme }) =>
    !$isFilled ? theme.secondary.blendToBackground(700) : theme.secondary.background};
  transition:
    color 0.2s,
    background-color 0.2s;
  border-radius: 6px;

  &:hover {
    color: ${({ theme }) => theme.secondary.blendToBackground(900)};
    background-color: ${({ theme }) => theme.secondary.blendToBackground(130)};
  }
`;

const Up = styled(Icon.ChevronUpLarge)`
  --icon-size: 14px;
  --icon-color: currentColor;

  pointer-events: none;
`;

const Down = styled(Icon.ChevronDownLarge)`
  --icon-size: 14px;
  --icon-color: currentColor;

  pointer-events: none;
`;

const StyledInput = styled(Input)<{ $offset: string }>`
  padding-right: calc(12px + ${({ $offset }) => $offset});
`;

export const InstitutionsField = forwardRef<InstitutionsFieldRef, InstitutionsFieldProps>(
  ({ className, onChange, onError }, ref) => {
    const countryCodeDropdownRef = useRef<HTMLDivElement | null>(null);
    const countryCodeDropdownElemRef = useRef<DropdownElement | null>(null);
    const institutionDropdownRef = useRef<DropdownElement | null>(null);

    const [searchCountryCode, setSearchCountryCode] = useState<string>('');
    const [searchCountryCodeOpen, setSearchCountryCodeOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchOffset, setSearchOffset] = useState<string>('300px');

    const { createRequest: fetchInstitutions } = useInstitutions(false)({
      onError,
      onSuccess: (data) => {
        if (typeof data?.institutions !== 'undefined' && data.institutions?.length > 0) {
          setOptions(
            data.institutions.map((entry) => ({
              label: `${titleCase(String(entry.fullName))} (${entry.countryCode})`,
              small: `Routing # ${entry.routingNumber}, ${countryCodeJSON.find((e) => e.countryCode === entry.countryCode)?.countryName}`,
              onClick: () => {
                onChange(entry);

                setSearchValue(titleCase(String(entry.fullName)));
              },
            }))
          );
        } else {
          setOptions([]);
        }
      },
    });

    const [options, setOptions] = useState<DropdownOption[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      setValue: (value: string) => {
        setSearchValue(value);
      },
    }));

    const fetchInstitutionsDebounce = useDebounce((value: string) => {
      fetchInstitutions(
        searchCountryCode?.length > 1 ? { name: value, countryCode: searchCountryCode } : { name: value }
      );
    }, 100);

    useEffect(() => {
      if (searchValue.length === 0) {
        fetchInstitutions();
        return;
      }

      fetchInstitutionsDebounce(searchValue);
    }, [searchValue]);

    const handleSearchCountryCodeChange = useCallback(
      (value: string) => {
        setSearchCountryCode((prev) => {
          if (prev === value) {
            fetchInstitutions(searchValue?.length > 0 ? { name: searchValue } : {});

            return '';
          }

          fetchInstitutions(
            searchValue?.length > 0 ? { name: searchValue, countryCode: value } : { countryCode: value }
          );

          return value;
        });

        setSearchOffset(`${String(countryCodeDropdownRef.current?.offsetWidth)}px`);
      },
      [searchValue, countryCodeDropdownRef]
    );

    useEffect(() => {
      countryCodeDropdownElemRef.current?.close();
      countryCodeDropdownElemRef.current?.clearSearch();
    }, [searchCountryCode, countryCodeDropdownElemRef]);

    return (
      <Field>
        <StyledDropdown
          className={className}
          isOpen={isOpen}
          options={options}
          fullWidth
          ref={institutionDropdownRef}
          customLabel={
            <StyledInput
              $offset={searchOffset}
              value={searchValue}
              onChange={setSearchValue}
              placeholder={
                countryCodeJSON.find((entry) => entry.countryCode === searchCountryCode)
                  ? `Search institution name in ${countryCodeJSON.find((entry) => entry.countryCode === searchCountryCode)?.countryName}`
                  : 'Search institution name'
              }
              icon={<Icon.Search />}
              onFocus={() => setIsOpen(true)}
              onBlur={() => setIsOpen(false)}
            />
          }
        />

        <CountryCodeDropdown
          ref={(refObj) => {
            countryCodeDropdownRef.current = refObj;

            setSearchOffset(`${String(refObj?.offsetWidth)}px`);
          }}
        >
          <Dropdown
            ref={countryCodeDropdownElemRef}
            options={countryCodeJSON
              .map((c: CountryCodeEntry) => ({
                label: `${c.countryName} (${c.countryCode})`,
                value: c.countryCode,
              }))
              .sort((a, b) => (a.value === searchCountryCode ? -1 : b.value === searchCountryCode ? 1 : 0))
              .flatMap((item, index, array) =>
                index === 1 && array[0].value === searchCountryCode ? [{ isDivider: true }, item] : [item]
              )}
            active={searchCountryCode}
            onChange={handleSearchCountryCodeChange}
            onOpenChange={setSearchCountryCodeOpen}
            maxWidth="220px"
            search
            positionRight
            offsetX={4}
            offsetY={4}
            customLabel={
              <Label $isFilled={searchCountryCode?.length > 1}>
                {searchCountryCode || 'Country'}
                {searchCountryCodeOpen ? <Up /> : <Down />}
              </Label>
            }
          />
        </CountryCodeDropdown>
      </Field>
    );
  }
);
