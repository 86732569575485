import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Dropdown, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { Datepicker, PageHeader, RestrictedBanner } from '~/components';
import { useNavigate } from '~/lib/navigation';
import { ReportType, ReportingRepository } from '~/repositories';
import { NotificationPropsType, useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { EditToolbar, FormElement, FormLabel, Grid, Inner, Paragraph } from '~/styles';
import { getDateFormat } from '~/util';

const StyledParagraph = styled(Paragraph)`
  max-width: 64ch;
  margin: 0 0 16px 0;
`;

const DateList = styled.div`
  padding: 0px 0 24px 8px;
  border-left: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const DatePreview = styled.span`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  svg {
    display: inline-block;
    vertical-align: top;

    --icon-size: 14px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

const StyledFormElement = styled(FormElement)`
  gap: 12px;
`;

export const PageReportingSchedule: React.FC = () => {
  const currentPermission = useSessionStore((s) => s.currentPermission);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();

  const [type, setType] = useState<ReportType | undefined>(undefined);
  const [dates, setDates] = useState<string[]>([]);
  const [hoverDate, setHoverDate] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSchedule = () => {
    if (!dates || !Array.isArray(dates) || dates.length !== 2) {
      addDangerNotification({
        content: 'Please select a date range',
      });
      return;
    }
    if (!type) {
      addDangerNotification({
        content: 'Please select a type',
      });
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    ReportingRepository.schedule({
      fromDate: dates[0] ?? '',
      toDate: dates[1] ?? '',
      type,
    })
      .then(() => {
        addSuccessNotification({
          content: 'Report scheduled',
        });
        navigate(ROUTE.REPORTING);
      })
      .catch((error) => {
        const object: NotificationPropsType = {
          content: error.message,
        };

        if (error.code === 'invalid_field_value') {
          object.timeout = 8000;
          object.actionButton = {
            label: 'Platform Settings',
            onClick: () => navigate(ROUTE.PLATFORM_SETTINGS),
          };
        }

        addDangerNotification(object);
      })
      .finally(() => setIsLoading(false));
  };

  const handleChange = (range?: 'today' | 'lastDays' | 'lastWeek' | 'lastMonth') => {
    const from = new Date();

    if (range === 'lastDays') {
      from.setDate(from.getDate() - 3);
    }

    if (range === 'lastWeek') {
      from.setDate(from.getDate() - 7);
    }

    if (range === 'lastMonth') {
      from.setMonth(from.getMonth() - 1);
    }

    setDates([getDateFormat(from), getDateFormat(new Date())]);
  };

  return (
    <>
      <PageHeader text="Schedule Report">
        <Button onClick={() => navigate(ROUTE.REPORTING)} variant="secondary" size="small">
          Cancel
        </Button>
        <Button
          onClick={handleSchedule}
          isLoading={isLoading}
          isDisabled={currentPermission?.reporting !== 'write'}
          size="small"
          icon={<Icon.Plus />}
        >
          Schedule Report
        </Button>
      </PageHeader>

      {currentPermission?.reporting !== 'write' && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}

      <Inner data-disabled={currentPermission?.reporting !== 'write'}>
        <StyledParagraph>
          Create a report for a date range or single day. Reports that include the current business day will be
          completed at the end of the day.
        </StyledParagraph>

        <Grid vertical>
          <FormElement>
            <FormLabel>Type</FormLabel>
            <Dropdown
              options={[
                {
                  label: 'Bank account summary',
                  value: 'bank_account_summary',
                },
                {
                  label: 'Bank account transaction',
                  value: 'bank_account_transaction',
                },
              ]}
              active={type}
              fullWidth
              variant="muted"
              onChange={(value: ReportType) => setType(value)}
            />
          </FormElement>

          <Grid>
            <StyledFormElement>
              <FormLabel>
                Date Range{' '}
                {dates.length >= 1 && (
                  <DatePreview>
                    ({dates[0]}
                    <Icon.ArrowRight />
                    {(dates.length === 2 ? dates[1] : hoverDate) ?? '?'})
                  </DatePreview>
                )}
              </FormLabel>
              <Datepicker
                date={dates}
                onDateSubmit={(value) => {
                  setDates(value as string[]);
                }}
                showMonths={2}
                disableBefore={new Date('2022-01-01')}
                disableAfter={new Date()}
                onHoverDate={(value) => setHoverDate(value)}
              />
            </StyledFormElement>

            <DateList>
              <Button variant="subtle" onClick={() => handleChange('today')}>
                Today
              </Button>
              <Button variant="subtle" onClick={() => handleChange('lastDays')}>
                Last 3 Days
              </Button>
              <Button variant="subtle" onClick={() => handleChange('lastWeek')}>
                Last Week
              </Button>
              <Button variant="subtle" onClick={() => handleChange('lastMonth')}>
                Last Month
              </Button>
            </DateList>
          </Grid>
        </Grid>
      </Inner>

      <Inner>
        <EditToolbar>
          <Button onClick={() => navigate(ROUTE.REPORTING)} variant="secondary" size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSchedule}
            isLoading={isLoading}
            isDisabled={currentPermission?.reporting !== 'write'}
            size="small"
            icon={<Icon.Plus />}
          >
            Schedule Report
          </Button>
        </EditToolbar>
      </Inner>
    </>
  );
};
