import React, { useEffect, useMemo, useState } from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button, Dropdown, DropdownOption, Icon, Input, Toggle, ToggleHeight } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { CopyInput, Loading, Notification, PageHeader, RestrictedBanner, SectionHeader } from '~/components';
import { useBankAccountDropdown, useEntities } from '~/hooks';
import { useNavigate } from '~/lib/navigation';
import {
  AccountNumber,
  BankAccount,
  BankAccountRepository,
  CreateBankAccount,
  UpdateBankAccount,
} from '~/repositories/BankAccountRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Box, EditToolbar, FormElement, FormLabel, FormParagraphLight, FormText, Grid, Inner } from '~/styles';
import { getDateLongUTC } from '~/util';

import { BankAccountRouteProps } from './Route';

interface Params {
  id: string;
}

const AddEntity = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.primary.background};
`;

const Up = styled(Icon.ChevronUpLarge)`
  cursor: pointer;
  margin-left: auto;
`;

const Down = styled(Icon.ChevronDownLarge)`
  cursor: pointer;
  margin-left: auto;
`;

const StyledToggleHeight = styled(ToggleHeight)`
  --toggle-height-padding: 16px 0 24px;
`;

const Plus = styled(Icon.Plus)<{ isOpen: boolean }>`
  transition: transform 0.2s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(45deg);
    `}
`;

const SandboxWarning = styled.div`
  display: grid;
  grid-column: span 2;

  > div {
    align-self: end;
    padding: 0;
  }
`;

export const Balance = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;
  grid-column: 1 / 3;
`;

export const FormTextAmount = styled(FormText)<{ isNegative?: boolean; isAvailable?: boolean }>`
  font-size: 24px;
  font-weight: 600;
  padding: 2px 0;
  color: ${({ theme }) => theme.foreground};

  ${({ isNegative }) =>
    isNegative &&
    css`
      color: ${({ theme }) => theme.danger.background};
    `}

  ${({ isAvailable, isNegative }) =>
    isAvailable &&
    !isNegative &&
    css`
      color: ${({ theme }) => theme.primary.background};
    `}
`;

export const FormLabelAmount = styled(FormLabel)`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const CreateAltAccountNumber = styled.div`
  background: ${({ theme }) => theme.background};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  padding: 4px;
  margin-top: 16px;
`;

const Trigger = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
  border-radius: 6px;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.body};
  }
`;

const FormAction = styled.div`
  align-self: end;
`;

export const PageBankAccountsEdit: React.FC = () => {
  const { currentUser, currentPlatform, isAuthorized, isSandbox } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [accountNumbers, setAccountNumbers] = useState<DeepPartial<AccountNumber[]>>([{}]);
  const [accountNumbersOpen, setAccountNumbersOpen] = useState<number>(0);
  const [accountNumbersAdd, setAccountNumbersAdd] = useState<DeepPartial<AccountNumber>>({});
  const [accountNumbersAddOpen, setAccountNumbersAddOpen] = useState<boolean>(false);

  const outletContext = useOutletContext<BankAccountRouteProps>();
  const bankAccount = outletContext?.bankAccount;
  const [bankAccountState, setBankAccountState] = useState<Partial<BankAccount & { entityId: string }>>(
    bankAccount ?? {}
  );

  const {
    options: bankAccountList,
    hiddenOptions: bankAccountHiddenOptions,
    isLoading: isBankAccountsLoading,
  } = useBankAccountDropdown(bankAccountState.overdraftReserveAccountId, {
    type: 'OVERDRAFT_RESERVE',
  });

  const { response: entityList, setQueryParams: searchEntities } = useEntities();
  const entitiesOptions = useMemo<DropdownOption[]>(() => {
    return (
      entityList?.entities?.map((entity) => ({
        label: entity.name,
        value: entity.id,
      })) ?? []
    );
  }, [entityList]);

  const handleSubmit = () => {
    if (!currentUser) {
      return;
    }

    if (id) {
      const request: Partial<UpdateBankAccount> = {
        description: String(bankAccountState.description),
        displayName: bankAccountState.displayName,
        isOverdraftable: bankAccountState.isOverdraftable,
        overdraftReserveAccountId: bankAccountState.overdraftReserveAccountId,
      };

      if (bankAccountState.isOverdraftable === false) {
        delete request.overdraftReserveAccountId;
      }

      BankAccountRepository.update(id, request)
        .then((response) => {
          addSuccessNotification({
            content:
              response.displayName || response.description
                ? `${response.displayName || response.description} updated`
                : 'Updated',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
          });
        });
      return;
    }
    const createRequest: Partial<CreateBankAccount> = {
      description: String(bankAccountState.description),
      displayName: bankAccountState.displayName,
      isOverdraftable: bankAccountState.isOverdraftable,
      overdraftReserveAccountId: bankAccountState.overdraftReserveAccountId,
      entityId: bankAccountState.entityId,
    };

    BankAccountRepository.create(createRequest as CreateBankAccount)
      .then((response) => {
        navigate(ROUTE.BANK_ACCOUNTS);
        addSuccessNotification({
          content:
            response.displayName || response.description
              ? `${response.displayName || response.description} created`
              : 'Created',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const handleNewAccountNumber = () => {
    if (!id) {
      return;
    }
    BankAccountRepository.createAccountNumber(id, accountNumbersAdd as AccountNumber)
      .then(() => {
        BankAccountRepository.getAllAccountNumber(id)
          .then((accountNumberResponse) => {
            setAccountNumbers(accountNumberResponse.accountNumbers);
            setAccountNumbersOpen(accountNumberResponse.accountNumbers.length - 1);
            setAccountNumbersAdd({});
            setAccountNumbersAddOpen(false);
          })
          .catch((e) => console.error('BankAccountRepository.getAllAccountNumber', e));
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  useEffect(() => {
    if (!bankAccount?.id) {
      return;
    }

    BankAccountRepository.getAllAccountNumber(bankAccount?.id).then((accountNumberResponse) => {
      setAccountNumbers(accountNumberResponse.accountNumbers);
    });
  }, [bankAccount]);

  const hasUpdatePermissions = isAuthorized({
    permission: 'bankAccounts',
    permissionLevel: 'update',
    bankAccount: { id, owners: bankAccountState.owners },
  });

  return (
    <>
      {!id && (
        <PageHeader text={id ? bankAccount?.displayName || bankAccount?.description || 'Account' : 'Create Account'}>
          <Button onClick={() => navigate(-1)} variant="secondary" size="small">
            {id ? 'Back' : 'Cancel'}
          </Button>
          <Button onClick={handleSubmit} isDisabled={!hasUpdatePermissions} size="small" icon={!id && <Icon.Plus />}>
            {id ? 'Save' : 'Create Account'}
          </Button>
        </PageHeader>
      )}

      <Loading isLoading={isBankAccountsLoading} fullHeight>
        {!hasUpdatePermissions && (
          <Inner py={0}>
            <RestrictedBanner />
          </Inner>
        )}

        <SectionHeader text="Information" />

        <Inner data-disabled={!hasUpdatePermissions} pt={16}>
          <Grid>
            {id && (
              <FormElement>
                <FormLabel>ID</FormLabel>
                <CopyInput value={id} />
              </FormElement>
            )}
            {id && (
              <FormElement>
                <FormLabel>Created</FormLabel>
                <FormText>{bankAccount?.createdAt && getDateLongUTC(bankAccount.createdAt as any)}</FormText>
              </FormElement>
            )}
            <FormElement>
              <FormLabel>Description</FormLabel>
              <Input
                onChange={(value: string) => setBankAccountState({ ...bankAccountState, description: value })}
                value={bankAccountState.description ?? ''}
                placeholder="Description"
              />
            </FormElement>
            <FormElement>
              <FormLabel>
                Display Name <small>(Optional)</small>
              </FormLabel>
              <Input
                onChange={(value: string) => setBankAccountState({ ...bankAccountState, displayName: value })}
                value={bankAccountState.displayName ?? ''}
                placeholder="Display Name"
              />
            </FormElement>

            <FormElement>
              <FormLabel>Entity</FormLabel>
              {id ? (
                <FormText>
                  {entitiesOptions.filter((entity) => bankAccountState.owners?.includes(entity.value))[0]
                    ? entitiesOptions.filter((entity) => bankAccountState.owners?.includes(entity.value))[0].label
                    : ''}
                </FormText>
              ) : (
                entitiesOptions.length > 0 && (
                  <Dropdown
                    variant="muted"
                    fullWidth
                    maxWidth="640px"
                    active={bankAccountState.entityId}
                    onChange={(value: string | number | undefined) =>
                      setBankAccountState({ ...bankAccountState, entityId: value?.toString() })
                    }
                    search
                    searchLabel="Business or person name..."
                    onSearchChange={(value: string) => searchEntities({ name: value })}
                    options={entitiesOptions}
                  />
                )
              )}
              {entitiesOptions.length < 1 && (
                <FormParagraphLight>
                  No entities found - <AddEntity to={`${ROUTE.ENTITIES}/edit/business`}>add one first</AddEntity>.
                </FormParagraphLight>
              )}
            </FormElement>
            {entitiesOptions.length > 0 && (
              <FormElement>
                <FormLabel>Entity ID</FormLabel>
                <CopyInput
                  value={
                    id && bankAccountState?.owners ? bankAccountState?.owners[0] : (bankAccountState.entityId ?? '')
                  }
                  placeholder="Select an entity first"
                />
              </FormElement>
            )}
          </Grid>
        </Inner>

        <SectionHeader text="Overdraft" border />
        <Inner data-disabled={!hasUpdatePermissions} pt={16}>
          <Grid>
            {bankAccountList?.length < 1 && (
              <FormElement>
                <Notification
                  color="danger"
                  withClose={false}
                  variant="light"
                  actionButton={{
                    label: 'Create',
                    onClick: () => {
                      if (currentPlatform?.isLiveEnabled) {
                        navigate(ROUTE.PLATFORM);
                        return;
                      }
                      navigate(ROUTE.PLATFORM_ROOT_ENTITY);
                    },
                  }}
                >
                  You will need to create a root entity first to select the overdraft reserve account.
                </Notification>
              </FormElement>
            )}
            {bankAccountState.type === 'OVERDRAFT_RESERVE' && (
              <FormElement>
                <Notification color="danger" withClose={false} variant="light">
                  Overdraft reserve accounts cannot be overdrafted.
                </Notification>
              </FormElement>
            )}
            <FormElement>
              <FormLabel>Overdraftable</FormLabel>
              <FormText>
                <Toggle
                  onCheckedChange={(value: boolean) =>
                    setBankAccountState({ ...bankAccountState, isOverdraftable: value })
                  }
                  isChecked={bankAccountState.isOverdraftable ?? false}
                  isDisabled={bankAccountList?.length < 1 || bankAccountState.type === 'OVERDRAFT_RESERVE'}
                />
              </FormText>
            </FormElement>
            <FormElement>
              <FormLabel>Reserve Account ID</FormLabel>
              <Dropdown
                options={bankAccountList?.filter((b) => b.value !== bankAccountState.id)}
                hiddenOptions={bankAccountHiddenOptions?.filter((b) => b.value !== bankAccountState.id)}
                active={bankAccountState.overdraftReserveAccountId}
                fullWidth
                maxWidth="640px"
                search
                variant="muted"
                onChange={(value: string) =>
                  setBankAccountState({ ...bankAccountState, overdraftReserveAccountId: value })
                }
                isDisabled={!bankAccountState.isOverdraftable}
              />
            </FormElement>
          </Grid>
        </Inner>

        {id && (
          <>
            <SectionHeader text="Account Numbers" border />

            <Inner py={0}>
              {id &&
                accountNumbers.map((entry: DeepPartial<AccountNumber>, index: number) => (
                  <Box variant="secondary" key={index}>
                    <SectionHeader
                      counter={index + 1}
                      text={`Account Number - ${entry.description}`}
                      onClick={() => setAccountNumbersOpen(index)}
                    >
                      {accountNumbersOpen === index ? <Up /> : <Down />}
                    </SectionHeader>
                    <StyledToggleHeight isClose={accountNumbersOpen !== index}>
                      <Inner py={0}>
                        <Grid>
                          <FormElement>
                            <FormLabel>Description</FormLabel>
                            <FormText>{entry.description || '-'}</FormText>
                          </FormElement>
                          <FormElement newRow>
                            <FormLabel>Account Number</FormLabel>
                            <CopyInput value={entry.accountNumber} />
                          </FormElement>
                          <FormElement>
                            <FormLabel>Routing Number</FormLabel>
                            <CopyInput value={entry.routingNumber} />
                          </FormElement>

                          {isSandbox && (
                            <SandboxWarning>
                              <Notification color="warning" withClose={false} variant="light">
                                Account numbers created in sandbox mode cannot be used to receive funds.
                              </Notification>
                            </SandboxWarning>
                          )}

                          <FormElement>
                            <FormLabel>ID</FormLabel>
                            <CopyInput value={entry.id} />
                          </FormElement>
                          <FormElement>
                            <FormLabel>Created</FormLabel>
                            <FormText>{entry?.createdAt && getDateLongUTC(entry.createdAt as any)}</FormText>
                          </FormElement>
                        </Grid>
                      </Inner>
                    </StyledToggleHeight>
                  </Box>
                ))}

              <CreateAltAccountNumber>
                <Trigger onClick={() => setAccountNumbersAddOpen(!accountNumbersAddOpen)}>
                  <Plus isOpen={accountNumbersAddOpen} />
                  Create New Account Number
                </Trigger>
                <StyledToggleHeight isClose={!accountNumbersAddOpen}>
                  <Inner py={0} px={20}>
                    <Grid>
                      <FormElement>
                        <FormLabel>Description</FormLabel>
                        <Input
                          value={accountNumbersAdd.description ?? ''}
                          onChange={(value: string) =>
                            setAccountNumbersAdd({ ...accountNumbersAdd, description: value })
                          }
                          placeholder="Description"
                        />
                      </FormElement>
                      <FormAction>
                        <Button onClick={handleNewAccountNumber}>Create Account Number</Button>
                      </FormAction>
                    </Grid>
                  </Inner>
                </StyledToggleHeight>
              </CreateAltAccountNumber>
            </Inner>
          </>
        )}

        <Inner>
          <EditToolbar>
            <Button onClick={() => navigate(-1)} variant="secondary" size="small">
              {id ? 'Back' : 'Cancel'}
            </Button>
            <Button onClick={handleSubmit} isDisabled={!hasUpdatePermissions} size="small" icon={!id && <Icon.Plus />}>
              {id ? 'Save' : 'Create Account'}
            </Button>
          </EditToolbar>
        </Inner>
      </Loading>
    </>
  );
};
