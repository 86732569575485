import { client } from '../client';
import {
  CreateRealtimeTransferRequest,
  RealtimeTransfer,
  RealtimeTransferList,
  RealtimeTransferServiceListRealtimeTransfersParams,
} from '~/typings/API';
import { ReturnRealtimeTransfer } from '~/typings/enum';
import { convertValues } from '~/util';

export type CreateRealtimeTransfer = CreateRealtimeTransferRequest;
export type { RealtimeTransfer };

export class RealtimeRepository {
  static async create(realtimeTransfer: CreateRealtimeTransfer, idempotency?: string) {
    return client
      .post<CreateRealtimeTransfer, RealtimeTransfer>('/transfers/realtime', realtimeTransfer, { idempotency })
      .then((response) => convertValues<RealtimeTransfer>(response));
  }

  static async get(request: GetRequestType) {
    return client
      .get<unknown, RealtimeTransfer>(`/transfers/realtime/${request.id}`)
      .then((response) => convertValues<RealtimeTransfer>(response));
  }

  static async getAll(query?: Partial<RealtimeTransferServiceListRealtimeTransfersParams>) {
    return client
      .get<RealtimeTransferServiceListRealtimeTransfersParams, RealtimeTransferList>('/transfers/realtime', { query })
      .then((response) => ({
        transfers: Array.isArray(response?.transfers)
          ? response.transfers.map((e) => convertValues<RealtimeTransfer>(e))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async createReturn(realtimeTransferId: string, request: ReturnRealtimeTransfer) {
    return client
      .post<ReturnRealtimeTransfer, RealtimeTransfer>(`/transfers/realtime/${realtimeTransferId}/return`, request)
      .then((response) => convertValues<RealtimeTransfer>(response));
  }
}
