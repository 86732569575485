import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, Chip, Icon, Toggle } from '@column/column-ui-kit';

import { Details } from '~/app/pages/Transfers/_components/Details';
import { buildAchEvents, buildAchReturnEvents } from '~/app/pages/Transfers/_utils/ach';
import { ROUTE } from '~/app/routes';
import { CopyInput, Loading, RenderFields } from '~/components';
import { useAccountNumber, useBankAccount, useCounterparty, useEntity } from '~/hooks';
import { useTransfer } from '~/hooks/useTransfers';
import { useNavigate } from '~/lib/navigation';
import { AchRepository, AchReturn, AchTransfer, SimulateRepository, TransferEntryType } from '~/repositories';
import { convertTransferAmount } from '~/repositories/Transfer';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Divider, Inner } from '~/styles';
import { getDateLongUTC } from '~/util';

import { AchReturnModal } from './_components/AchReturnModal';
import { InfoForm, TransferPageHeader } from './_components/Layout';
import { Timeline } from './_components/Timeline';
import { EntryClassCodeDefinitions, IdempotencyKeyDefinitions } from './TypeContent/ach';

interface Params {
  id: string;
}

const formatData = (transfer: AchTransfer): Record<string, unknown> => {
  const formatted: Record<string, any> = {
    company: {
      discretionaryData: transfer.companyDiscretionaryData,
      entryDescription: transfer.companyEntryDescription,
      id: transfer.companyId,
      name: transfer.companyName,
    },
    receiver: {
      id: transfer.receiverId,
      name: transfer.receiverName,
    },
    return: {
      contestedAt: transfer.returnContestedAt,
      dishonoredAt: transfer.returnDishonoredAt,
      returnedAt: transfer.returnedAt,
    },
  };
  if (transfer.returnDetails) {
    (formatted.return as Record<string, any>).details = {};
    transfer.returnDetails.forEach((details, idx) => {
      const id = `Detail ${idx + 1} - `;
      Object.entries(details).forEach(([key, value]) => {
        formatted.return.details = {
          ...formatted.return.details,
          [`${id} ${key}`]: value,
        };
      });
    });
  }
  Object.entries(transfer).forEach(([key, value]) => {
    if (key.startsWith('company') || key.startsWith('receiver') || key.startsWith('return')) {
      return;
    }
    formatted[key] = value;
  });
  return formatted;
};

export const PageTransfersAch: FC = () => {
  const { id } = useParams<keyof Params>() as Params;
  const { isAuthorized, isSandbox } = useSessionStore();
  const navigate = useNavigate();
  const { addDangerNotification, addSuccessNotification } = useNotificationStore();
  const [isOpen, setIsOpen] = useState(false);
  const [achReturn, setAchReturn] = useState<DeepPartial<AchReturn>>();

  const {
    response: bankAccount,
    isLoading: isBankAccountLoading,
    createRequest: fetchBankAccount,
  } = useBankAccount({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const {
    response: accountNumber,
    isLoading: isAccountNumberLoading,
    createRequest: fetchAccountNumber,
  } = useAccountNumber({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const {
    response: transfer,
    isLoading: isTransferLoading,
    createRequest: fetchTransfer,
  } = useTransfer<AchTransfer>('ach')({
    initialParams: { id },
    onSuccess: async (response) => {
      if (!response.accountNumberId) return;
      fetchAccountNumber({ id: response.accountNumberId });

      try {
        if (response.bankAccountId) {
          const currentBankAccount = await fetchBankAccount({ id: response.bankAccountId });

          if (!currentBankAccount) {
            return;
          }

          if (isAuthorized({ permission: 'entities', permissionLevel: 'read' })) {
            fetchEntity({ id: currentBankAccount.owners?.[0] });
            setIsLoadingEntity(true);
          }
        }
      } catch (error) {
        addDangerNotification({ content: (error as Error).message });
      }

      if (response?.counterpartyId) {
        fetchCounterparty({ id: response.counterpartyId });
      }
    },

    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const {
    response: counterparty,
    isLoading: isCounterpartyLoading,
    createRequest: fetchCounterparty,
  } = useCounterparty({
    initialParams: transfer?.counterpartyId ? { id: transfer.counterpartyId } : undefined,
    onError: (error) => addDangerNotification({ content: error.message }),
  });

  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const { response: entity, createRequest: fetchEntity } = useEntity({
    onSuccess: () => setIsLoadingEntity(false),
    onError: (error) => {
      setIsLoadingEntity(false);
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const formattedTransfer = useMemo(() => (transfer ? formatData(transfer) : undefined), [transfer]);

  const formMethods = useForm<AchTransfer>();

  useEffect(() => {
    if (transfer) {
      formMethods.setValue('description', transfer.description || '-');
      formMethods.setValue('idempotencyKey', transfer.idempotencyKey || '-');
      formMethods.setValue('allowOverdraft', transfer.allowOverdraft || false);
      formMethods.setValue('type', transfer.type || undefined);
      formMethods.setValue('effectiveOn', transfer.effectiveOn || '-');
      formMethods.setValue('entryClassCode', transfer.entryClassCode || '-');
      formMethods.setValue('paymentRelatedInfo', transfer.paymentRelatedInfo || '-');
      formMethods.setValue('companyId', transfer.companyId || '-');
      formMethods.setValue('companyName', transfer.companyName || '-');
      formMethods.setValue('companyEntryDescription', transfer.companyEntryDescription || '-');
      formMethods.setValue('companyDiscretionaryData', transfer.companyDiscretionaryData || '-');
      formMethods.setValue('receiverName', transfer.receiverName || '-');
      formMethods.setValue('receiverId', transfer.receiverId || '-');
    }
  }, [transfer]);

  useEffect(() => {
    if (
      transfer?.status &&
      ['RETURNED', 'RETURN_CONTESTED', 'RETURN_DISHONORED', 'PENDING_RETURN'].includes(transfer.status)
    ) {
      AchRepository.getReturn(id)
        .then(setAchReturn)
        .catch(() => null);
    }
  }, [transfer?.status, id]);

  const isDataLoading = useMemo(() => {
    return (
      (transfer?.accountNumberId && isAccountNumberLoading) ||
      isBankAccountLoading ||
      (transfer?.counterpartyId && isCounterpartyLoading) ||
      !transfer ||
      isTransferLoading
    );
  }, [isAccountNumberLoading, isBankAccountLoading, isCounterpartyLoading, isTransferLoading, transfer]);

  const handleSettle = useCallback(() => {
    if (!transfer) return;
    SimulateRepository.settleACH({ achTransferId: transfer.id })
      .then(() => {
        fetchTransfer();
        addSuccessNotification({
          content: 'ACH transfer settled',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  }, [isSandbox, transfer]);

  const handleCancel = () => {
    AchRepository.cancel(id)
      .then(() => {
        fetchTransfer();
        addSuccessNotification({
          content: 'ACH transfer cancelled',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const onReturn = useCallback(() => {
    setIsOpen(true);
  }, []);

  const timelineEntries = useMemo(
    () =>
      transfer && accountNumber && bankAccount
        ? buildAchEvents({
            transfer,
            account: {
              accountNumber,
              bankAccount,
              entityType: entity ? (entity.type.toLowerCase() as 'person' | 'business') : undefined,
            },
            counterparty: counterparty || undefined,
            company: {
              companyName: transfer.companyName,
              companyId: transfer.companyId,
            },
          })
        : [],
    [transfer, accountNumber, bankAccount, entity, counterparty, transfer?.companyName, transfer?.companyId]
  );

  const returnEntries = useMemo(() => buildAchReturnEvents({ achReturn }), [achReturn]);

  const transferEntryType: TransferEntryType = transfer?.type === 'CREDIT' ? 'ach_credit' : 'ach_debit';

  return (
    <>
      <AchReturnModal transferId={transfer?.id ?? ''} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Loading isLoading={isDataLoading} fullHeight>
        {transfer && (
          <TransferPageHeader
            amount={transfer.amount}
            createdAt={transfer.createdAt}
            isAdding={transfer?.type ? convertTransferAmount(transferEntryType, transfer.isIncoming) > 0 : undefined}
            isIncoming={transfer.isIncoming || false}
            status={transfer.status!}
            transferId={transfer.id!}
            transferType={`ACH ${transfer.type?.charAt(0).toUpperCase()}${transfer.type?.slice(1).toLowerCase() ?? ''}`}
          >
            {isSandbox && !['COMPLETED', 'REJECTED', 'SETTLED', 'CANCELED', 'RETURNED'].includes(transfer.status) && (
              <Button onClick={handleSettle} variant="primary" size="small" icon={<Icon.Sandbox />}>
                Settle
              </Button>
            )}

            {['INITIATED', 'PENDING_SUBMISSION'].includes(transfer.status) && (
              <Button variant="danger" size="small" onClick={handleCancel} icon={<Icon.Cross />}>
                Cancel
              </Button>
            )}

            {!transfer.returnedAt && transfer.isIncoming && (
              <Button variant="danger" size="small" icon={<Icon.Return />} onClick={onReturn}>
                Return
              </Button>
            )}
          </TransferPageHeader>
        )}
        {bankAccount && accountNumber && transfer && !isLoadingEntity && (
          <Timeline entries={timelineEntries} returnEntries={returnEntries} />
        )}

        <FormProvider {...formMethods}>
          <InfoForm autoComplete="off">
            {RenderFields<AchTransfer>({
              sections: [
                {
                  headline: 'Information',
                  fields: [
                    {
                      id: 'description',
                      label: 'Description',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || '-'} />;
                      },
                    },
                    {
                      id: 'idempotencyKey',
                      label: 'Idempotency Key',
                      tooltip: {
                        headline: 'Idempotency Keys',
                        content: IdempotencyKeyDefinitions,
                      },
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || ''} />;
                      },
                    },
                    {
                      id: 'allowOverdraft',
                      label: 'Allow Overdraft',
                      children: ({ value, onChange }) => {
                        const isChecked = typeof value === 'boolean' ? value : false;
                        return <Toggle isChecked={isChecked} onCheckedChange={onChange} isDisabled />;
                      },
                    },
                  ],
                },
                {
                  headline: 'ACH',
                  fields: [
                    {
                      id: 'type',
                      label: 'Type',
                      children: ({ value }) => {
                        return (
                          <Chip size="large">
                            {(String(value) || '-').charAt(0).toUpperCase() +
                              (String(value) || '-').slice(1).toLowerCase()}
                          </Chip>
                        );
                      },
                    },
                    {
                      id: 'effectiveOn',
                      label: 'Effective Date',
                      children: ({ value }) => {
                        return <CopyInput value={getDateLongUTC(new Date(value)) || '-'} />;
                      },
                    },
                    {
                      id: 'entryClassCode',
                      label: 'Entry Class Code',
                      tooltip: {
                        headline: 'Entry Class Code Definitions',
                        content: EntryClassCodeDefinitions,
                      },
                      children: ({ value }) => {
                        return <Chip size="large">{String(value) || '-'}</Chip>;
                      },
                    },
                    {
                      id: 'paymentRelatedInfo',
                      label: 'Payment Related Info',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || '-'} />;
                      },
                    },
                  ],
                },
                {
                  headline: 'Company',
                  fields: [
                    {
                      id: 'companyId',
                      label: 'Company ID',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || '-'} />;
                      },
                    },
                    {
                      id: 'companyName',
                      label: 'Company Name',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || '-'} />;
                      },
                    },
                    {
                      id: 'companyEntryDescription',
                      label: 'Company Entry Description',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || '-'} />;
                      },
                    },
                    {
                      id: 'companyDiscretionaryData',
                      label: 'Company Discretionary Data',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || '-'} />;
                      },
                    },
                  ],
                },
                {
                  headline: 'Receiver',
                  fields: [
                    {
                      id: 'receiverName',
                      label: 'Receiver Name',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || '-'} />;
                      },
                    },
                    {
                      id: 'receiverId',
                      label: 'Receiver ID',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || '-'} />;
                      },
                    },
                  ],
                },
              ],
            })}
            <Divider />
            <Inner px={0} pt={24}>
              <Details formattedData={formattedTransfer} rawData={transfer as Record<string, unknown> | undefined} />
            </Inner>
          </InfoForm>
        </FormProvider>
      </Loading>
    </>
  );
};
