import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface ScannerIconProps {
  className?: string;
  side?: 'front' | 'back';
}

const Wrapper = styled.div``;

export const ScannerIcon: FC<ScannerIconProps> = ({ className, side }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSizeX={52} viewportSizeY={37}>
        {side === 'front' ? (
          <>
            <path d="M51 7V4C51 2.34 49.66 1 48 1H45" stroke="currentColor" strokeWidth="2" />
            <path d="M45 35H48C49.66 35 51 33.66 51 32V29" stroke="currentColor" strokeWidth="2" />
            <path d="M1 29V32C1 33.66 2.34 35 4 35H7" stroke="currentColor" strokeWidth="2" />
            <path d="M7 1H4C2.34 1 1 2.34 1 4V7" stroke="currentColor" strokeWidth="2" />
            <path
              d="M44 25V11C44 9.34315 42.6569 8 41 8L11 8C9.34315 8 8 9.34315 8 11V25C8 26.6569 9.34315 28 11 28L41 28C42.6569 28 44 26.6569 44 25Z"
              stroke="currentColor"
              strokeWidth="2"
            />
            <path
              d="M39 14H33C32.4477 14 32 14.4477 32 15V16C32 16.5523 32.4477 17 33 17H39C39.5523 17 40 16.5523 40 16V15C40 14.4477 39.5523 14 39 14Z"
              stroke="currentColor"
              strokeWidth="2"
            />
            <path d="M12 24H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 24H32" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </>
        ) : (
          <>
            <path d="M51 7.5V4.5C51 2.84 49.66 1.5 48 1.5H45" stroke="currentColor" strokeWidth="2" />
            <path d="M45 35.5H48C49.66 35.5 51 34.16 51 32.5V29.5" stroke="currentColor" strokeWidth="2" />
            <path d="M1 29.5V32.5C1 34.16 2.34 35.5 4 35.5H7" stroke="currentColor" strokeWidth="2" />
            <path d="M7 1.5H4C2.34 1.5 1 2.84 1 4.5V7.5" stroke="currentColor" strokeWidth="2" />
            <path
              d="M44 25.5V11.5C44 9.84315 42.6569 8.5 41 8.5L11 8.5C9.34315 8.5 8 9.84315 8 11.5V25.5C8 27.1569 9.34315 28.5 11 28.5L41 28.5C42.6569 28.5 44 27.1569 44 25.5Z"
              stroke="currentColor"
              strokeWidth="2"
            />
            <path d="M32 8.5V28.5" stroke="currentColor" strokeWidth="2" />
            <path
              d="M40.9728 15.0299C41.0328 15.0799 41.2628 15.1199 41.3128 15.0299C41.3628 14.9399 40.5627 13.7699 40.0527 13.1999C39.5427 12.6299 39.0028 12.0399 38.3328 11.6199C38.1528 11.5099 37.9627 11.4099 37.7627 11.3599C37.0927 11.1799 37.2427 12.2299 37.3227 12.5699C37.5427 13.5299 38.1027 14.4599 38.6027 15.2899C38.7727 15.5599 38.9927 15.9699 38.9227 16.2999C38.8327 16.6999 38.5028 16.5099 38.2228 16.5099C37.7528 16.5099 37.6527 17.1799 37.6727 17.5299C37.7027 18.3499 38.1627 19.0399 38.3727 19.8099C38.7427 21.1599 39.0128 22.4999 38.9728 23.8599"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        )}
      </IconBase>
    </Wrapper>
  );
};
