import React, { useRef } from 'react';
import styled from 'styled-components';

import { Button, Dropdown, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import {
  EmptyState,
  PageHeader,
  PaginationWrapper,
  PaginationWrapperRefProps,
  PopoverFilterEntry,
  TableColumn,
} from '~/components';
import { useDialog } from '~/components/Dialog/Provider';
import { RelativeTime } from '~/elements';
import { useNavigate } from '~/lib/navigation';
import { CounterpartyRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Truncate } from '~/styles';

const StyledDropdown = styled(Dropdown)`
  margin: -12px 0;
`;

export const PageCounterparties: React.FC = () => {
  const { openAlert } = useDialog();
  const currentUser = useSessionStore((state) => state.currentUser);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  const handleDelete = (entry: any) => {
    openAlert({
      headline: 'Delete counterparty',
      content: 'This action cannot be undone.',
      confirmButton: {
        label: 'Delete',
        variant: 'danger',
      },
      onSuccess: () => {
        if (!currentUser) {
          return;
        }
        CounterpartyRepository.delete({ id: entry.id })
          .then(() => {
            addSuccessNotification({
              content: 'Counterparty deleted',
            });
            if (paginationRef.current) {
              paginationRef.current.fetch();
            }
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
            });
          });
      },
    });
  };

  const handleFetch = async (params: any) => {
    return CounterpartyRepository.getAll(params).then((response) => {
      const entries: any = [];
      if (response.counterparties.length) {
        response.counterparties.map((entry: any) => {
          entries.push({
            id: entry.id,
            description: entry?.description ?? entry?.wire?.beneficiaryName ?? '-',
            accountNumber: entry.accountNumber,
            routingNumber: entry.routingNumber,
            created: entry.createdAt,
            action: (
              <StyledDropdown
                isInside
                positionRight
                options={[
                  {
                    label: 'View',
                    onClick: () => navigate(`${ROUTE.COUNTERPARTIES}/edit/${entry.id}`),
                  },
                  {
                    label: 'Delete',
                    isDanger: true,
                    onClick: () => handleDelete(entry),
                  },
                ]}
                variant="dots"
              />
            ),
          });
        });
      }
      return { entries, hasMore: Boolean(response?.hasMore) };
    });
  };

  const columns: TableColumn[] = [
    {
      Header: 'Description / Name',
      accessor: 'description',
      width: 'minmax(300px, auto)',
    },
    {
      Header: 'Routing Number',
      width: 'minmax(150px, min-content)',
      Cell: (current) => <Truncate>{current.value}</Truncate>,
      accessor: 'routingNumber',
    },
    {
      Header: 'Account Number',
      width: 'minmax(150px, min-content)',
      Cell: (current) => <Truncate>{current.value}</Truncate>,
      accessor: 'accountNumber',
    },
    {
      Header: 'Created',
      accessor: 'created',
      sortType: 'datetime',
      width: 'min-content',
      Cell: (current) => <RelativeTime timestamp={current.value} />,
    },
    {
      Header: '',
      width: 'min-content',
      accessor: 'action',
    },
  ];

  const filter: PopoverFilterEntry[] = [
    {
      id: 'accountNumber',
      label: 'Account Number',
      type: 'input',
    },
    {
      id: 'routingNumber',
      label: 'Routing Number',
      type: 'input',
    },
    {
      id: 'created',
      label: 'Created',
      type: 'date',
    },
  ];

  return (
    <>
      <PageHeader text="Counterparties" border={false}>
        <Button onClick={() => navigate(ROUTE.BANK_ACCOUNTS)} icon={<Icon.Bank />} size="small" variant="secondary">
          Accounts
        </Button>
        <Button onClick={() => navigate(`${ROUTE.COUNTERPARTIES}/edit`)} icon={<Icon.Plus />} size="small">
          Create Counterparty
        </Button>
      </PageHeader>

      <PaginationWrapper
        tableId="counterparties"
        ref={paginationRef}
        fetch={handleFetch}
        columns={columns}
        filter={filter}
        empty={
          <EmptyState headline="No counterparties found" text="Add your first counterparty to get started.">
            <Button onClick={() => navigate(`${ROUTE.COUNTERPARTIES}/edit`)} size="small" icon={<Icon.Plus />}>
              Create Counterparty
            </Button>
          </EmptyState>
        }
        rowClick={(row) => {
          navigate(`${ROUTE.COUNTERPARTIES}/edit/${row.original.id}`);
        }}
      />
    </>
  );
};
