import React, { useContext, useMemo } from 'react';
import { UNSAFE_RouteContext as RouteContext, RouteProps, Routes } from 'react-router';

export const RootRoutes = (props: RouteProps) => {
  const ctx = useContext(RouteContext);

  const value = useMemo(
    () => ({
      ...ctx,
      matches: [],
    }),
    [ctx]
  );

  return (
    <RouteContext.Provider value={value}>
      <Routes {...props} />
    </RouteContext.Provider>
  );
};
