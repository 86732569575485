import isEqual from 'lodash.isequal';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Dropdown, Fade, Icon, Input, OptionGroup, OptionProps } from '@column/column-ui-kit';

import { EntryClassCodeDefinitions } from '../TypeContent/ach';
import { ROUTE } from '~/app/routes';
import { PageHeader, RenderFields } from '~/components';
import { LogoLoading } from '~/elements';
import { useBankAccountDropdown } from '~/hooks';
import { useFormErrorHandler } from '~/hooks/useFormErrorHandler';
import {
  useCreateTransferTemplate,
  useGetTransferTemplate,
  useUpdateTransferTemplate,
} from '~/hooks/useTransferTemplates';
import { useNavigate } from '~/lib/navigation';
import {
  CreateTransferTemplateRequest,
  TransferTemplateResponse,
  TransferTemplateType,
  UpdateTransferTemplateRequest,
} from '~/repositories/Transfer/TransferTemplateRepository';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { log, removeEmptyString } from '~/util';

import { TransferList } from './_partial/TransferList';

interface Params {
  id: string;
}

interface LocationState {
  defaultFormValues?: TransferTemplateResponse | null;
}

const Wrapper = styled.div`
  padding: 0 0 24px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const Logo = styled(LogoLoading)`
  top: calc(50vh - 130px);
`;

const StyledOption = styled(OptionGroup.Option)<OptionProps>`
  opacity: 1;

  ${OptionGroup.Option.Label} {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }
`;

const OptionCheck = styled(Icon.CircleCheck)`
  --icon-size: 20px;
  --icon-color: ${({ theme }) => theme.primary.background};
  margin: -2px 0 -2px -2px;
`;

export const PageTransferTemplatesEdit: FC = () => {
  const { id } = useParams<keyof Params>() as Params;
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const openModal = useModalStore((state) => state.openModal);
  const { handleFormErrors } = useFormErrorHandler();

  const location = useLocation();
  const locationState = location.state as LocationState;

  const initialValues = useRef<Partial<TransferTemplateResponse> | null>(null);

  const { createRequest: getTransferTemplate, isLoading } = useGetTransferTemplate({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });
      navigate(`${ROUTE.TRANSFERS}/templates`);
    },
  });

  const { createRequest: createTransferTemplate } = useCreateTransferTemplate({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });
    },
    onSuccess: () => {
      addSuccessNotification({
        content: 'Transfer template created successfully',
      });
    },
  });

  const { createRequest: updateTransferTemplate } = useUpdateTransferTemplate({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });
    },
    onSuccess: () => {
      addSuccessNotification({
        content: 'Transfer template updated successfully',
      });
    },
  });

  const defaultFormValues: Partial<TransferTemplateResponse> = locationState?.defaultFormValues ?? {
    templateType: TransferTemplateType.ACH,
    achDefaultParameters: {},
    achTransfers: [],
    wireDefaultParameters: {},
    wireTransfers: [],
    bookDefaultParameters: {},
    bookTransfers: [],
  };

  const methods = useForm<TransferTemplateResponse>({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const { handleSubmit, reset, watch, setValue, getValues } = methods;
  const templateType = watch('templateType');
  const achTransfers = watch('achTransfers');
  const wireTransfers = watch('wireTransfers');
  const bookTransfers = watch('bookTransfers');

  const {
    options: achDefaultParametersBankAccountOptions,
    hiddenOptions: achDefaultParametersBankAccountHiddenOptions,
    searchBankAccounts: searchAchDefaultParametersBankAccount,
  } = useBankAccountDropdown(watch('achDefaultParameters.bankAccountId'));

  const {
    options: wireDefaultParametersBankAccountOptions,
    hiddenOptions: wireDefaultParametersBankAccountHiddenOptions,
    searchBankAccounts: searchWireDefaultParametersBankAccount,
  } = useBankAccountDropdown(watch('wireDefaultParameters.bankAccountId'));

  const {
    options: senderBookDefaultParametersBankAccountOptions,
    hiddenOptions: senderBookDefaultParametersBankAccountHiddenOptions,
    searchBankAccounts: searchSenderBookDefaultParametersBankAccount,
  } = useBankAccountDropdown(watch('bookDefaultParameters.senderBankAccountId'));

  const {
    options: receiverBookDefaultParametersBankAccountOptions,
    hiddenOptions: receiverBookDefaultParametersBankAccountHiddenOptions,
    searchBankAccounts: searchReceiverBookDefaultParametersBankAccount,
  } = useBankAccountDropdown(watch('bookDefaultParameters.receiverBankAccountId'));

  useEffect(() => {
    if (id && !locationState?.defaultFormValues) {
      getTransferTemplate({ transferTemplateId: id }).then((data) => {
        if (!data) {
          return;
        }

        const newTransferTemplate = { ...data } as Partial<TransferTemplateResponse>;
        delete newTransferTemplate?.version;

        reset(newTransferTemplate);
        initialValues.current = newTransferTemplate;
      });
    }

    if (locationState?.defaultFormValues) {
      reset(locationState.defaultFormValues);
      initialValues.current = locationState.defaultFormValues;
    }
  }, [id, locationState?.defaultFormValues]);

  const validateAndCleanTransferTemplate = (
    transferTemplate: TransferTemplateResponse
  ): Partial<TransferTemplateResponse> | null => {
    const newTransferTemplate = removeEmptyString<Partial<TransferTemplateResponse>>(transferTemplate);
    const currentTemplateType = watch('templateType');

    const validateTransfers = (
      type: TransferTemplateType,
      transfers: unknown[] | undefined,
      typeName: string
    ): boolean => {
      if (currentTemplateType === type) {
        if (!transfers || transfers.length <= 0) {
          addDangerNotification({
            content: `Please add at least one ${typeName} transfer`,
          });
          return false;
        }

        const hasInvalidTransfers = transfers.some((transfer: any, index: number) => {
          const missingFields = [];

          if (
            transfer.amount === undefined ||
            transfer.amount === null ||
            transfer.amount === '' ||
            Number(transfer.amount) <= 0
          ) {
            missingFields.push('Amount');
          }

          if (type === TransferTemplateType.ACH || type === TransferTemplateType.Wire) {
            if (!transfer.counterpartyId) {
              missingFields.push('Counterparty');
            }

            if (!transfer.bankAccountId) {
              missingFields.push('Bank Account');
            }

            if (type === TransferTemplateType.ACH && !transfer.type) {
              missingFields.push('Type');
            }

            if (type === TransferTemplateType.ACH && !transfer.entryClassCode) {
              missingFields.push('Entry Class Code');
            }
          }

          if (type === TransferTemplateType.Book) {
            if (!transfer.senderBankAccountId) {
              missingFields.push('Sender Bank Account');
            }

            if (!transfer.receiverBankAccountId) {
              missingFields.push('Receiver Bank Account');
            }
          }

          if (missingFields.length > 0) {
            addDangerNotification({
              content: `Transfer #${index + 1} is missing required fields: ${missingFields.join(', ')}`,
            });
            return true;
          }

          return false;
        });

        if (hasInvalidTransfers) {
          return false;
        }
      }
      return true;
    };

    if (!validateTransfers(TransferTemplateType.ACH, watch('achTransfers'), 'ACH')) {
      return null;
    }
    if (!validateTransfers(TransferTemplateType.Wire, watch('wireTransfers'), 'Wire')) {
      return null;
    }
    if (!validateTransfers(TransferTemplateType.Book, watch('bookTransfers'), 'Book')) {
      return null;
    }

    if (currentTemplateType === TransferTemplateType.ACH) {
      delete newTransferTemplate.wireTransfers;
      delete newTransferTemplate.wireDefaultParameters;
      delete newTransferTemplate.bookTransfers;
      delete newTransferTemplate.bookDefaultParameters;
    } else if (currentTemplateType === TransferTemplateType.Wire) {
      delete newTransferTemplate.achTransfers;
      delete newTransferTemplate.achDefaultParameters;
      delete newTransferTemplate.bookTransfers;
      delete newTransferTemplate.bookDefaultParameters;
    } else if (currentTemplateType === TransferTemplateType.Book) {
      delete newTransferTemplate.achTransfers;
      delete newTransferTemplate.achDefaultParameters;
      delete newTransferTemplate.wireTransfers;
      delete newTransferTemplate.wireDefaultParameters;
    }

    const cleanTransfers = <T extends { bankAccountDescription?: string; counterpartyDescription?: string }>(
      transfers: T[] | undefined
    ): void => {
      transfers?.forEach((transfer) => {
        delete transfer.bankAccountDescription;
        delete transfer.counterpartyDescription;
      });
    };

    cleanTransfers(newTransferTemplate.achTransfers);
    cleanTransfers(newTransferTemplate.wireTransfers);

    newTransferTemplate.bookTransfers?.forEach((transfer) => {
      delete transfer?.senderBankAccountDescription;
      delete transfer?.receiverBankAccountDescription;
    });

    return newTransferTemplate;
  };

  const onSuccess = useCallback(
    async (transferTemplate: TransferTemplateResponse) => {
      const newTransferTemplate = validateAndCleanTransferTemplate(transferTemplate);
      if (!newTransferTemplate) return;

      if (id) {
        const updateTransferTemplateRequest = {
          ...newTransferTemplate,
        } as Partial<TransferTemplateResponse>;

        delete updateTransferTemplateRequest.templateType;
        delete updateTransferTemplateRequest.lastUsedAt;
        delete updateTransferTemplateRequest.usageCount;
        delete updateTransferTemplateRequest.version;
        delete updateTransferTemplateRequest.achDefaultParameters?.bankAccountDescription;
        delete updateTransferTemplateRequest.achDefaultParameters?.counterpartyDescription;
        delete updateTransferTemplateRequest.wireDefaultParameters?.bankAccountDescription;
        delete updateTransferTemplateRequest.wireDefaultParameters?.counterpartyDescription;
        delete updateTransferTemplateRequest.bookDefaultParameters?.senderBankAccountDescription;
        delete updateTransferTemplateRequest.bookDefaultParameters?.receiverBankAccountDescription;

        const newData = await updateTransferTemplate({
          ...updateTransferTemplateRequest,
          transferTemplateId: id,
        } as UpdateTransferTemplateRequest);

        if (newData) {
          reset(newData);
        }

        return;
      }

      try {
        const newData = await createTransferTemplate(newTransferTemplate as CreateTransferTemplateRequest);

        navigate(`${ROUTE.TRANSFERS}/templates/edit/${newData?.transferTemplateId}`, { replace: true });
      } catch (error) {
        addDangerNotification({
          content: error instanceof Error ? error.message : 'An unknown error occurred',
        });
      }
    },
    [id, templateType, achTransfers, wireTransfers, bookTransfers]
  );

  const onError: SubmitHandler<FieldValues> = useCallback((errors) => {
    handleFormErrors({ errors });
  }, []);

  const handleCancelClick = useCallback(() => {
    reset();
    navigate(`${ROUTE.TRANSFERS}/templates`, { state: { defaultFormValues: null } });
  }, []);

  const handleReviewClick = () => {
    const normalizeTransfers = (obj: Record<string, unknown>) => {
      const normalized = JSON.parse(JSON.stringify(obj));

      const processTransfers = (
        key: string,
        transformFn: (transfer: Record<string, unknown>) => Record<string, unknown>
      ) => {
        if (normalized[key]) {
          normalized[key] = normalized[key].map(transformFn);
        }
      };

      processTransfers('wireTransfers', (transfer) => ({
        ...transfer,
        amount: String(transfer.amount),
        bankAccountDescription: undefined,
        counterpartyDescription: undefined,
      }));

      processTransfers('achTransfers', (transfer) => ({
        ...transfer,
        amount: String(transfer.amount),
        bankAccountDescription: undefined,
        counterpartyDescription: undefined,
      }));

      processTransfers('bookTransfers', (transfer) => ({
        ...transfer,
        amount: String(transfer.amount),
        senderBankAccountDescription: undefined,
        receiverBankAccountDescription: undefined,
      }));

      return normalized;
    };

    const initial = removeEmptyString(initialValues.current ?? {});
    const current = removeEmptyString(watch());

    const cleanInitial = normalizeTransfers(initial as Record<string, unknown>);
    const cleanCurrent = normalizeTransfers(current as Record<string, unknown>);

    const hasChanges = !isEqual(cleanInitial, cleanCurrent);

    log({ type: 'info', name: 'ReviewTransferTemplate', context: { hasChanges, cleanInitial, cleanCurrent } });

    if (!hasChanges) {
      navigate(`${ROUTE.TRANSFERS}/templates/review/${id}`, {
        state: { defaultFormValues: watch() },
      });
      return;
    }

    openModal('ReviewTransferTemplate', {
      onReview: () => {
        navigate(`${ROUTE.TRANSFERS}/templates/review/${id}`, {
          state: { defaultFormValues: watch() },
        });
      },
      onSaveReview: () => {
        onSuccess(getValues()).then(() => {
          navigate(`${ROUTE.TRANSFERS}/templates/review/${id}`, { state: { defaultFormValues: null } });
        });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSuccess, onError)}>
        <PageHeader text={id ? 'Edit Transfer Template' : 'Create Transfer Template'}>
          <Button type="button" variant="secondary" size="small" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button type="submit" size="small">
            {id ? 'Save Template' : 'Create Template'}
          </Button>
          {id && (
            <Button type="button" size="small" onClick={handleReviewClick}>
              Review
            </Button>
          )}
        </PageHeader>

        <Fade show={!!isLoading && !!id && !locationState?.defaultFormValues}>
          <Logo />
        </Fade>
        <Fade show={!isLoading || !id || !!locationState?.defaultFormValues}>
          <Wrapper>
            {RenderFields<TransferTemplateResponse>({
              sections: [
                {
                  fields: [
                    {
                      id: 'description',
                      label: 'Template Name',
                      defaultValue: '',
                      rules: {
                        required: true,
                      },
                      children: ({ value, onChange, onBlur }, { isTouched, error }) => (
                        <Input
                          placeholder="Template Name"
                          hasError={isTouched && !!error}
                          value={String(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      ),
                    },
                    {
                      id: 'templateType',
                      label: 'Template Type',
                      defaultValue: TransferTemplateType.ACH,
                      children: ({ value, onChange }) => (
                        <OptionGroup orientation={OptionGroup.Orientation.Horizontal}>
                          {((value === TransferTemplateType.ACH && id) || !id) && (
                            <StyledOption
                              isActive={value === TransferTemplateType.ACH}
                              onClick={() => !id && onChange(TransferTemplateType.ACH)}
                              isDisabled={!!id}
                            >
                              {id ? <OptionCheck /> : <OptionGroup.Option.Radio />}
                              <OptionGroup.Option.Label>ACH</OptionGroup.Option.Label>
                            </StyledOption>
                          )}

                          {((value === TransferTemplateType.Wire && id) || !id) && (
                            <StyledOption
                              isActive={value === TransferTemplateType.Wire}
                              onClick={() => !id && onChange(TransferTemplateType.Wire)}
                              isDisabled={!!id}
                            >
                              {id ? <OptionCheck /> : <OptionGroup.Option.Radio />}
                              <OptionGroup.Option.Label>Wire</OptionGroup.Option.Label>
                            </StyledOption>
                          )}

                          {((value === TransferTemplateType.Book && id) || !id) && (
                            <StyledOption
                              isActive={value === TransferTemplateType.Book}
                              onClick={() => !id && onChange(TransferTemplateType.Book)}
                              isDisabled={!!id}
                            >
                              {id ? <OptionCheck /> : <OptionGroup.Option.Radio />}
                              <OptionGroup.Option.Label>Book</OptionGroup.Option.Label>
                            </StyledOption>
                          )}
                        </OptionGroup>
                      ),
                    },
                  ],
                },
                {
                  headline: (
                    <>
                      Default Parameters <small>(Optional)</small>
                    </>
                  ),
                  description: 'These parameters apply to any new transfers added to the transfers list below',
                  fields: [
                    {
                      hide: watch('templateType') !== TransferTemplateType.ACH,
                      id: 'achDefaultParameters.bankAccountId',
                      defaultValue: '',
                      label: 'Bank Account',
                      description: 'Select a bank account to originate the ACH transfer.',
                      children: ({ value, onBlur }, { error }) => (
                        <Dropdown
                          options={achDefaultParametersBankAccountOptions ?? []}
                          hiddenOptions={achDefaultParametersBankAccountHiddenOptions ?? []}
                          active={value}
                          fullWidth
                          maxWidth="640px"
                          search
                          searchLabel="Search for description"
                          onSearchChange={(description: string) =>
                            searchAchDefaultParametersBankAccount({ description })
                          }
                          onChange={(baccId) => setValue('achDefaultParameters.bankAccountId', baccId)}
                          onOpenChange={(open) => !open && onBlur()}
                          hasError={!!error}
                        />
                      ),
                    },
                    {
                      hide: watch('templateType') !== TransferTemplateType.Wire,
                      id: 'wireDefaultParameters.bankAccountId',
                      defaultValue: '',
                      label: 'Bank Account',
                      description: 'Select a bank account to originate the Wire transfer.',
                      children: ({ value, onBlur }, { error }) => (
                        <Dropdown
                          options={wireDefaultParametersBankAccountOptions ?? []}
                          hiddenOptions={wireDefaultParametersBankAccountHiddenOptions ?? []}
                          active={value}
                          fullWidth
                          maxWidth="640px"
                          search
                          searchLabel="Search for description"
                          onSearchChange={(description: string) =>
                            searchWireDefaultParametersBankAccount({ description })
                          }
                          onChange={(baccId) => setValue('wireDefaultParameters.bankAccountId', baccId)}
                          onOpenChange={(open) => !open && onBlur()}
                          hasError={!!error}
                        />
                      ),
                    },
                    {
                      hide: watch('templateType') !== TransferTemplateType.Book,
                      id: 'bookDefaultParameters.senderBankAccountId',
                      defaultValue: '',
                      label: 'Sender Bank Account',
                      description: 'Select a bank account to send the book transfer from.',
                      children: ({ value, onBlur }, { error }) => (
                        <Dropdown
                          options={senderBookDefaultParametersBankAccountOptions ?? []}
                          hiddenOptions={senderBookDefaultParametersBankAccountHiddenOptions ?? []}
                          active={value}
                          fullWidth
                          maxWidth="640px"
                          search
                          searchLabel="Search for description"
                          onSearchChange={(description: string) =>
                            searchSenderBookDefaultParametersBankAccount({ description })
                          }
                          onChange={(baccId) => setValue('bookDefaultParameters.senderBankAccountId', baccId)}
                          onOpenChange={(open) => !open && onBlur()}
                          hasError={!!error}
                        />
                      ),
                    },
                    {
                      hide: watch('templateType') !== TransferTemplateType.Book,
                      id: 'bookDefaultParameters.receiverBankAccountId',
                      defaultValue: '',
                      label: 'Receiver Bank Account',
                      description: 'Select a bank account to send the book transfer to.',
                      children: ({ value, onBlur }, { error }) => (
                        <Dropdown
                          options={receiverBookDefaultParametersBankAccountOptions ?? []}
                          hiddenOptions={receiverBookDefaultParametersBankAccountHiddenOptions ?? []}
                          active={value}
                          fullWidth
                          maxWidth="640px"
                          search
                          searchLabel="Search for description"
                          onSearchChange={(description: string) =>
                            searchReceiverBookDefaultParametersBankAccount({ description })
                          }
                          onChange={(baccId) => setValue('bookDefaultParameters.receiverBankAccountId', baccId)}
                          onOpenChange={(open) => !open && onBlur()}
                          hasError={!!error}
                        />
                      ),
                    },
                    {
                      id: 'achDefaultParameters.type',
                      hide: watch('templateType') !== TransferTemplateType.ACH,
                      label: 'Type',
                      description: 'Select between ACH Debit or ACH Credit.',
                      defaultValue: 'DEBIT',
                      children: ({ value, onChange }) => (
                        <OptionGroup orientation={OptionGroup.Orientation.Horizontal}>
                          <OptionGroup.Option isActive={value === 'DEBIT'} onClick={() => onChange('DEBIT')}>
                            <OptionGroup.Option.Radio />
                            <OptionGroup.Option.Label>Debit</OptionGroup.Option.Label>
                            <OptionGroup.Option.Description>
                              Pull money from a counterparty to your bank account.
                            </OptionGroup.Option.Description>
                          </OptionGroup.Option>

                          <OptionGroup.Option isActive={value === 'CREDIT'} onClick={() => onChange('CREDIT')}>
                            <OptionGroup.Option.Radio />
                            <OptionGroup.Option.Label>Credit</OptionGroup.Option.Label>
                            <OptionGroup.Option.Description>
                              Push money from your bank account to a counterparty.
                            </OptionGroup.Option.Description>
                          </OptionGroup.Option>
                        </OptionGroup>
                      ),
                    },
                    {
                      id: 'achDefaultParameters.entryClassCode',
                      hide: watch('templateType') !== TransferTemplateType.ACH,
                      label: 'Entry Class Code',
                      defaultValue: 'CCD',
                      tooltip: {
                        headline: 'Entry Class Code Definitions',
                        content: EntryClassCodeDefinitions,
                      },
                      children: ({ value, onChange, onBlur }, { error }) => (
                        <Dropdown
                          options={['CCD', 'CIE', 'CTX', 'PPD', 'TEL', 'WEB'].map((code: string) => ({
                            label: code,
                            value: code,
                          }))}
                          active={value}
                          fullWidth
                          onChange={onChange}
                          onOpenChange={(open) => !open && onBlur()}
                          hasError={!!error}
                        />
                      ),
                    },
                    {
                      id: 'wireDefaultParameters.description',
                      hide: watch('templateType') !== TransferTemplateType.Wire,
                      label: 'Description',
                      defaultValue: '',
                      children: ({ value, onChange, onBlur }) => (
                        <Input placeholder="Description" value={String(value)} onChange={onChange} onBlur={onBlur} />
                      ),
                    },
                    {
                      id: 'bookDefaultParameters.description',
                      hide: watch('templateType') !== TransferTemplateType.Book,
                      label: 'Description',
                      defaultValue: '',
                      children: ({ value, onChange, onBlur }) => (
                        <Input placeholder="Description" value={String(value)} onChange={onChange} onBlur={onBlur} />
                      ),
                    },
                  ],
                },
                {
                  headline: 'Transfers',
                  description:
                    'Create a list of default transfers. Parameters can be added to or edited when the template is in use.',
                  content: <TransferList type={watch('templateType')} />,
                },
              ],
            })}
            <ButtonWrapper>
              <Button type="button" size="small" variant="secondary" onClick={handleCancelClick}>
                Cancel
              </Button>
              <ButtonGroup>
                <Button type="submit" size="small">
                  {id ? 'Save Template' : 'Create Template'}
                </Button>
                {id && (
                  <Button type="button" size="small" onClick={handleReviewClick}>
                    Review
                  </Button>
                )}
              </ButtonGroup>
            </ButtonWrapper>
          </Wrapper>
        </Fade>
      </form>
    </FormProvider>
  );
};
