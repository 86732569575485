import gsap from 'gsap';
import React, { FC, MouseEvent, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonProps, Icon } from '@column/column-ui-kit';

interface ButtonPinProps extends Omit<ButtonProps, 'variant'> {
  isPinned?: boolean;
  variant?: 'secondary' | 'subtle';
}

const ButtonContainer = styled(Button)<{ $isActive?: boolean; $hasChildren?: boolean }>`
  --pin-icon-fill-scale: 0;
  --pin-icon-y: 0px;
  --pin-icon-scale-x: 1;
  --pin-icon-scale-y: 1;
  --pin-icon-rotate: 0deg;
  --pin-icon-circle-opacity: 0;
  --pin-icon-circle-scale: 0;

  position: relative;

  ${({ $hasChildren }) =>
    !$hasChildren &&
    css`
      padding: 4px;
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      --pin-icon-fill-scale: 1;
    `}
`;

export const ButtonPin: FC<PropsWithChildren<ButtonPinProps>> = ({
  isPinned,
  variant = 'subtle',
  children,
  ...buttonProps
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isActive, setIsActive] = useState<boolean>(isPinned ?? false);

  const onMouseEnter = useCallback(
    (event: MouseEvent<SVGSVGElement>) => {
      if (buttonRef.current?.hasAttribute('animating')) {
        return;
      }

      gsap.to(buttonRef.current, {
        keyframes: [
          {
            '--pin-icon-rotate': event.nativeEvent.offsetX > 21 ? '-4deg' : '4deg',
            duration: 0.1,
          },
          {
            '--pin-icon-rotate': event.nativeEvent.offsetX > 21 ? '4deg' : '-4deg',
            duration: 0.1,
          },
          {
            '--pin-icon-rotate': '0deg',
            duration: 0.1,
          },
        ],
      });
    },
    [buttonRef.current]
  );

  const onButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      buttonProps.onClick?.(event);

      event.stopPropagation();

      if (buttonRef.current?.hasAttribute('animating')) {
        return;
      }

      buttonRef.current?.setAttribute('animating', 'true');

      if (isActive) {
        gsap.to(buttonRef.current, {
          keyframes: [
            {
              '--pin-icon-scale-y': 0.9,
              '--pin-icon-fill-scale': 0,
              duration: 0.15,
            },
            {
              '--pin-icon-scale-y': 1,
              duration: 0.25,
            },
          ],
          onComplete: () => {
            buttonRef.current?.removeAttribute('animating');

            setIsActive(false);
          },
        });

        return;
      }

      gsap.to(buttonRef.current, {
        '--pin-icon-circle-scale': 1,
        duration: 0.45,
        delay: 0.15,
      });

      gsap.to(buttonRef.current, {
        keyframes: [
          {
            '--pin-icon-circle-opacity': 0.35,
            duration: 0.2,
            delay: 0.15,
          },
          {
            '--pin-icon-circle-opacity': 0,
            duration: 0.3,
          },
        ],
      });

      gsap.to(buttonRef.current, {
        keyframes: [
          {
            '--pin-icon-y': '-4px',
            '--pin-icon-scale-x': 0.925,
            duration: 0.1,
          },
          {
            '--pin-icon-y': '1px',
            '--pin-icon-scale-x': 1,
            '--pin-icon-scale-y': 0.8,
            duration: 0.1,
            delay: 0.05,
          },
          {
            '--pin-icon-y': '0px',
            '--pin-icon-scale-y': 1,
            '--pin-icon-fill-scale': 1,
            duration: 0.2,
          },
        ],
        onComplete: () => {
          buttonRef.current?.removeAttribute('animating');

          setIsActive(true);
        },
      });
    },
    [buttonProps.onClick, isActive, isPinned, buttonRef.current]
  );

  useEffect(() => {
    if (isPinned !== isActive) {
      setIsActive(isPinned ?? false);
    }
  }, [isPinned, isActive]);

  return (
    <ButtonContainer
      ref={buttonRef}
      variant={variant}
      $isActive={isActive}
      {...buttonProps}
      onClick={onButtonClick}
      icon={<Icon.AnimationPin onMouseEnter={onMouseEnter} />}
      $hasChildren={!!children}
      type="button"
      size="small"
    >
      {children}
    </ButtonContainer>
  );
};
