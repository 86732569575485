import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Icon, Input, OptionGroup } from '@column/column-ui-kit';

import { Notification, RenderFields } from '~/components';
import { Card, Inner, Paragraph, Text, TinyHeadline } from '~/styles';
import { ReviewTransferRequest, TransferReviewDecision } from '~/typings/enum';

interface Props {
  amount?: string | number;
  bankAccountName: string;
  description?: string;
  counterpartyName: string;
  createdByName?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  onSubmit: (req: ReviewTransferRequest) => Promise<void>;
}

const Header = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 25% auto 25%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: right;
  margin-left: 25%;
  margin-right: 25%;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
`;

const DetailsCard = styled(Card)`
  background: ${({ theme }) => theme.body};
  overflow: hidden;
  container-type: inline-size;
  padding: 10px 0;
`;

const DetailsGrid = styled.div`
  display: grid;
  gap: 0 1px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(200)};
  grid-auto-flow: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr;

  @container (min-width: 400px) {
    grid-auto-flow: column;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-columns: 1fr;
  }
`;

const DetailWrapper = styled.div`
  background: ${({ theme }) => theme.body};
  break-inside: avoid;
  padding: 6px 18px;
  box-sizing: border-box;
  flex: 1 1 0;
  min-width: 180px;

  @container (min-width: 400px) {
    &:nth-child(odd) {
      padding-top: 2px;
    }

    &:nth-child(even) {
      padding-bottom: 2px;
    }
  }
`;

const Detail: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <DetailWrapper>
    <Paragraph size="small">{label}</Paragraph>
    <TinyHeadline>{value}</TinyHeadline>
  </DetailWrapper>
);

const TransferReviewWrapper = styled.div<{ isOpen?: boolean }>`
  background: ${({ theme }) => theme.body};
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: hidden;
  transition:
    max-height 300ms ease-out,
    opacity 300ms ease-out;
`;

const ButtonText = styled.span<{ isActive: boolean }>`
  position: absolute;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  text-align: center;
  transition: opacity 0.2s;
`;

const SubmitButton = styled(Button)`
  width: 100px;
  position: relative;
`;

export const TransferReview: React.FC<Props> = ({
  bankAccountName,
  counterpartyName,
  createdByName,
  description,
  isDisabled,
  isLoading,
  isOpen,
  onSubmit,
}) => {
  const formMethods = useForm<ReviewTransferRequest>({
    defaultValues: {},
  });
  const decision = formMethods.watch('decision');

  return (
    <TransferReviewWrapper isOpen={isOpen}>
      <Inner>
        <Header>
          <Text weight="medium" size={16}>
            Review
          </Text>
          <div>
            {isDisabled && (
              <Inner p={0} pb={18}>
                <Notification color="info" variant="light" withClose={false}>
                  You don't have permission to review this transfer.
                </Notification>
              </Inner>
            )}
            <Inner p={0} pb={isDisabled ? 0 : 16}>
              <DetailsCard variant="secondary">
                <DetailsGrid>
                  <Detail label="Account" value={bankAccountName} />
                  <Detail label="Counterparty" value={counterpartyName} />
                  <Detail label="Created By" value={createdByName ?? '-'} />
                  <Detail label="Description" value={description ?? '-'} />
                </DetailsGrid>
              </DetailsCard>
            </Inner>
          </div>
        </Header>
        {!isDisabled && (
          <FormProvider {...formMethods}>
            <form autoComplete="off" onSubmit={formMethods.handleSubmit(onSubmit)}>
              {RenderFields<ReviewTransferRequest>({
                sections: [
                  {
                    variant: 'isCompact',
                    fields: [
                      {
                        id: 'decision',
                        label: 'Decision',
                        rules: isDisabled ? undefined : { required: true },
                        children: ({ value, onChange }) => (
                          <Card variant="secondary">
                            <OptionGroup orientation={OptionGroup.Orientation.Horizontal} noBorder>
                              <OptionGroup.Option
                                isActive={value === TransferReviewDecision.Approved}
                                isDisabled={isDisabled || isLoading}
                                onClick={() => onChange(TransferReviewDecision.Approved)}
                              >
                                <OptionGroup.Option.Radio />
                                <OptionGroup.Option.Label>Approve</OptionGroup.Option.Label>
                                <OptionGroup.Option.Tooltip content="The transfer will be initiated.">
                                  <Icon.CircleInfo />
                                </OptionGroup.Option.Tooltip>
                              </OptionGroup.Option>

                              <OptionGroup.Option
                                isActive={value === TransferReviewDecision.Rejected}
                                isDisabled={isDisabled || isLoading}
                                onClick={() => onChange(TransferReviewDecision.Rejected)}
                              >
                                <OptionGroup.Option.Radio />
                                <OptionGroup.Option.Label>Reject</OptionGroup.Option.Label>
                                <OptionGroup.Option.Tooltip content="The transfer will be marked as rejected, no funds will be withdrawn.">
                                  <Icon.CircleInfo />
                                </OptionGroup.Option.Tooltip>
                              </OptionGroup.Option>
                            </OptionGroup>
                          </Card>
                        ),
                      },
                      {
                        id: 'comment',
                        label: 'Comment',
                        children: ({ onChange, value }, { error }) => {
                          return (
                            <Input
                              isDisabled={isDisabled}
                              onChange={onChange}
                              placeholder={'Comment'}
                              value={value as string | undefined}
                              hasError={!!error}
                            />
                          );
                        },
                      },
                    ],
                  },
                ],
              })}
              <Footer>
                <SubmitButton
                  isDisabled={!decision || isDisabled || isLoading}
                  isLoading={isLoading}
                  type={'submit'}
                  variant={decision === 'rejected' ? 'danger' : 'primary'}
                  size={'small'}
                >
                  <ButtonText isActive={!decision || decision === TransferReviewDecision.Approved}>Approve</ButtonText>
                  <ButtonText isActive={decision === TransferReviewDecision.Rejected}>Reject</ButtonText>
                </SubmitButton>
              </Footer>
            </form>
          </FormProvider>
        )}
      </Inner>
    </TransferReviewWrapper>
  );
};
