import { TimelineEntry, TimelinePartyType, TimelineStatus } from '~/app/pages/Transfers/_components/Timeline';
import { TransferCheckPayee } from '~/app/pages/Transfers/_components/Timeline/types';
import { buildTransferParty, parseDate } from '~/app/pages/Transfers/_utils/timeline';
import { CheckReturnReason, CheckReturnsList, CheckTransfer } from '~/repositories';
import { AccountNumber, BankAccount } from '~/repositories/BankAccountRepository';
import { formatString } from '~/util';

const checkEvents = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Deposited',
    field: 'depositedAt',
    status: TimelineStatus.Success,
  },
  {
    label: 'Pending deposit',
    field: 'PendingDepositAt',
  },
  {
    label: 'Pending stop',
    field: 'pendingStopAt',
    status: TimelineStatus.InProgress,
  },
  {
    label: 'Stopped',
    field: 'stoppedAt',
    status: TimelineStatus.Danger,
  },
  {
    label: 'Settled',
    field: 'settledAt',
  },
  {
    label: 'Pending first return',
    field: 'pendingFirstReturnAt',
    status: TimelineStatus.InProgress,
  },
  {
    label: 'First return',
    field: 'firstReturnAt',
  },
  {
    label: 'Reclear',
    field: 'reclearAt',
  },
  {
    label: 'Second return',
    field: 'secondReturnAt',
  },
  {
    label: 'Pending user initiated return',
    field: 'pendingUserInitiatedReturnAt',
    status: TimelineStatus.InProgress,
  },
  {
    label: 'User initiated returned',
    field: 'userInitiatedReturnedAt',
  },
  {
    label: 'Returned',
    field: 'returnedAt',
  },
];

export const buildCheckEvents = ({
  transfer,
  account,
}: {
  transfer: CheckTransfer;
  account: {
    accountNumber: AccountNumber;
    bankAccount: BankAccount;
    entityType?: 'person' | 'business';
  };
}): TimelineEntry[] => {
  const isRejected = transfer.status === 'rejected';
  const isStopped = transfer.status === 'stopped';
  const isReturned = transfer.status.includes('return');

  return checkEvents
    .filter(
      (event) =>
        event.field === 'createdAt' || event.field === 'settledAt' || !!transfer[event.field as keyof typeof transfer]
    )
    .map((event) => {
      const date = parseDate(
        transfer[event.field as keyof typeof transfer] as string | undefined,
        isRejected ? 'Rejected' : isStopped ? 'Stopped' : isReturned ? 'Returned' : 'Pending'
      );

      const isIncoming = transfer.type.toLowerCase() === 'credit';

      let partyData;
      if (!isIncoming) {
        if (event.field === 'createdAt') {
          partyData = buildTransferParty({
            account,
            isIncoming: false,
            isOriginator: true,
          });
        } else if (event.field === 'settledAt') {
          partyData = {
            data: {
              payeeName: transfer.payeeName,
              payeeAddress: transfer.payeeAddress,
              deliveredByColumn: transfer.deliveredByColumn,
            } as TransferCheckPayee,
            metadata: transfer.deliveredByColumn ? 'Mailed' : undefined,
            partyType: TimelinePartyType.Receiver,
          };
        }
      } else {
        if (event.field === 'createdAt') {
          partyData = {
            data: {
              payeeName: transfer.payeeName || transfer.description,
            } as TransferCheckPayee,
            partyType: TimelinePartyType.Receiver,
          };
        } else if (event.field === 'settledAt') {
          partyData = buildTransferParty({
            account,
            isIncoming: true,
            isOriginator: false,
          });
        }
      }

      const defaultStatus = isRejected || isStopped || isReturned ? TimelineStatus.Danger : TimelineStatus.Pending;
      let status = !!transfer[event.field as keyof typeof transfer] ? event.status : defaultStatus;
      if (event.field === 'pendingStopAt' && !!transfer.stoppedAt) {
        status = TimelineStatus.Success;
      }
      if (event.field === 'pendingFirstReturnAt' && !!transfer.firstReturnAt) {
        status = TimelineStatus.Success;
      }
      if (event.field === 'pendingUserInitiatedReturnAt' && !!transfer.userInitiatedReturnedAt) {
        status = TimelineStatus.Success;
      }

      const amount = isIncoming ? transfer.depositedAmount : transfer.positivePayAmount;

      return {
        label: event.label,
        amount: amount
          ? {
              value: parseFloat(amount),
              isAdding: event.field === 'settledAt' || event.field === 'depositedAt',
            }
          : undefined,
        date,
        partyData,
        status,
      };
    });
};

export const buildCheckReturnEvents = (returns?: CheckReturnsList['returns']): TimelineEntry[] => {
  if (!returns || returns.length < 1) return [];

  return returns.map((checkReturn) => {
    let description;
    switch (checkReturn.returnReason) {
      case 'Q':
        description = 'Not authorized';
        break;
      case 'N':
        description = 'Altered/fictitious item - suspected counterfeit';
        break;
    }

    return {
      label: `Return ${formatString(checkReturn.status)}`,
      date: new Date(checkReturn.createdAt),
      status: checkReturn.status === 'processed' ? TimelineStatus.Returned : TimelineStatus.Success,
      returnData: {
        reason: checkReturn.returnReason as CheckReturnReason,
        description,
      },
    } as TimelineEntry;
  });
};
