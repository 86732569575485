import { TimelineEntry, TimelineStatus, TransferCompany, TransferParty } from '../_components/Timeline/types';
import { TimelinePartyType } from '~/app/pages/Transfers/_components/Timeline';
import { Counterparty } from '~/repositories';
import { AccountNumber, BankAccount } from '~/repositories/BankAccountRepository';
import { TransferReview } from '~/typings/API';

export const buildTransferParty = ({
  account,
  counterparty,
  company,
  isIncoming,
  isOriginator,
}: {
  account: {
    accountNumber: AccountNumber;
    bankAccount: BankAccount;
    entityType?: 'person' | 'business';
    transferType?: string;
  };
  counterparty?: Counterparty;
  company?: TransferCompany;
  isIncoming: boolean;
  isOriginator: boolean;
}): TransferParty => {
  if (isOriginator) {
    if (isIncoming && counterparty) {
      return {
        data: {
          counterparty,
        },
        partyType: TimelinePartyType.Sender,
      };
    }
    if (isIncoming && company) {
      return {
        data: {
          companyId: company.companyId,
          companyName: company.companyName,
        },
        partyType: TimelinePartyType.Sender,
      };
    }
    return {
      data: {
        accountNumber: account.accountNumber,
        bankAccount: account.bankAccount,
        entityType: account.entityType,
      },
      partyType: TimelinePartyType.Sender,
      metadata: account.transferType
        ? `${account.transferType.charAt(0).toUpperCase()}${account.transferType.slice(1).toLowerCase()}`
        : undefined,
    };
  }

  if (!isIncoming && counterparty) {
    return {
      data: {
        counterparty,
      },
      partyType: TimelinePartyType.Receiver,
    };
  }
  if (!isIncoming && company) {
    return {
      data: {
        companyId: company.companyId,
        companyName: company.companyName,
      },
      partyType: TimelinePartyType.Receiver,
    };
  }
  return {
    data: {
      accountNumber: account.accountNumber,
      bankAccount: account.bankAccount,
      entityType: account.entityType,
    },
    partyType: TimelinePartyType.Receiver,
    metadata: account.transferType
      ? `${account.transferType.charAt(0).toUpperCase()}${account.transferType.slice(1).toLowerCase()}`
      : undefined,
  };
};

export const buildTimelineEntryForTransferReviews = (
  preReviewAt: string | undefined,
  reviews?: TransferReview[]
): TimelineEntry => {
  if (reviews) {
    const lastReview = reviews.reduce((prev, curr) => (prev.updatedAt > curr.updatedAt ? prev : curr));
    return {
      label: 'Reviewed',
      date: parseDate(lastReview.updatedAt),
      reviewData: reviews,
    };
  }

  return {
    label: 'Pending Approval',
    status: TimelineStatus.InProgress,
    date: parseDate(preReviewAt),
  };
};

export const parseDate = (date: string | undefined, placeholder: string = 'Pending') => {
  if (!date) {
    return placeholder;
  }
  return date ? new Date(date) : placeholder;
};
