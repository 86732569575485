import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface AnimationConnectIconProps {
  className?: string;
  isConnected?: boolean;
}

const Wrapper = styled.div``;

const Path = styled.path<{ isConnected?: boolean }>`
  stroke-dasharray: 82, 100;
  stroke-dashoffset: 154;
  transition:
    stroke-dashoffset 0.2s,
    transform 0.2s;

  ${({ isConnected }) =>
    isConnected &&
    css`
      stroke-dashoffset: 182;
      transform: translate(7%, -3%);
    `}
`;

export const AnimationConnectIcon: FC<AnimationConnectIconProps> = ({ className, isConnected }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={14}>
        <g stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9078 10.7125V10.7125C8.85738 12.7629 5.53354 12.7629 3.48313 10.7125V10.7125C1.43271 8.66206 1.43271 5.33823 3.48313 3.28781V3.28781C5.53354 1.2374 8.85738 1.2374 10.9078 3.28781V3.28781C12.9582 5.33823 12.9582 8.66206 10.9078 10.7125Z"
          />
          <Path isConnected={isConnected} d="M4 7.74779L5.25 8.95L8.75 5.44995" pathLength="100" />
        </g>
      </IconBase>
    </Wrapper>
  );
};
