import React, { useRef } from 'react';

import { Button, Chip, Dropdown, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import {
  EmptyState,
  PageHeader,
  PaginationWrapper,
  PaginationWrapperRefProps,
  PopoverFilterEntry,
  TableColumn,
} from '~/components';
import { useNavigate } from '~/lib/navigation';
import { AchRepository } from '~/repositories';
import { formatString, isValidDate } from '~/util';

export const PageReturns: React.FC = () => {
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  const handleFetch = async (params: any) => {
    return AchRepository.getAllReturns(params).then((response) => {
      const entries: any = [];
      if (response.achReturns.length) {
        response.achReturns.map((entry: any) => {
          entries.push({
            achTransferId: entry.achTransferId,
            status: formatString(entry.status),
            isIncoming: entry.isIncoming ? 'True' : 'False',
            created: entry.createdAt,
            action: (
              <Dropdown
                isInside
                positionRight
                options={[
                  {
                    label: 'View',
                    onClick: () => navigate(`${ROUTE.RETURNS}/view/${entry.achTransferId}`),
                  },
                ]}
                variant="dots"
              />
            ),
          });
        });
      }
      return { entries, hasMore: Boolean(response?.hasMore) };
    });
  };

  const columns: TableColumn[] = [
    {
      Header: 'ACH Transfer ID',
      accessor: 'achTransferId',
    },
    {
      Header: 'Incoming',
      accessor: 'isIncoming',
      Cell: (current) => <Chip>{current.value}</Chip>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (current) => <Chip>{current.value}</Chip>,
    },
    {
      Header: 'Created',
      Cell: (current) => (isValidDate(current.value) ? current.value.toLocaleDateString('en-US') : '-'),
      accessor: 'created',
      sortType: 'datetime',
    },
    {
      Header: '',
      accessor: 'action',
    },
  ];

  const filter: PopoverFilterEntry[] = [
    {
      id: 'created',
      label: 'Created',
      type: 'date',
    },
  ];

  return (
    <>
      <PageHeader text="Returns" border={false}>
        <Button onClick={() => navigate(ROUTE.TRANSFERS)} icon={<Icon.Money />} size="small" variant="secondary">
          Transfers
        </Button>
      </PageHeader>

      <PaginationWrapper
        tableId="returns"
        ref={paginationRef}
        fetch={handleFetch}
        fetchId="achTransferId"
        columns={columns}
        filter={filter}
        empty={
          <EmptyState headline="No returns found" text="Add your first return or wait for returns to come in.">
            <Button onClick={() => navigate(ROUTE.TRANSFERS)} size="small" icon={<Icon.ArrowRight />}>
              View Transfers
            </Button>
          </EmptyState>
        }
        rowClick={(row) => {
          navigate(`${ROUTE.RETURNS}/view/${row.original.achTransferId}`);
        }}
      />
    </>
  );
};
