import React, { CSSProperties, FC, MouseEvent, useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled, { createGlobalStyle, css } from 'styled-components';

import { Button, Currencies, Currency, Icon, formatNumber } from '@column/column-ui-kit';

import { ModalBase } from '../../Base';
import { Flag } from '~/components/Flag';
import { NotificationList } from '~/components/NotificationList';
import countryCodeJSON from '~/data/wires/country-codes.json';
import { useCreateCounterparty, useDeleteCounterparty, useInstitution } from '~/hooks';
import { useFormErrorHandler } from '~/hooks/useFormErrorHandler';
import { useTransfersFetch } from '~/hooks/useTransfers';
import { Counterparty, CreateCounterparty, FinancialInstitution, ValidateIBANObject } from '~/repositories';
import { ModalType, useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { FormElement, FormLabel, FormLabelIcon, Headline, ModalIconCircle, ModalText } from '~/styles';
import { titleCase } from '~/util';

import { ContentAccount } from './partial/Account';
import { ContentBank } from './partial/Bank';
import { ContentOwner } from './partial/Owner';

type StepID = 'bank' | 'account' | 'owner';
type StepState = 'active' | 'completed' | 'disabled';

export type StepType = {
  id: StepID;
  label: string;
  state: StepState;
};

export interface CountryCodeEntry {
  countryCode: string;
  currencyCode: string;
  countryName: string;
}

type TemporaryData = {
  institution: FinancialInstitution | null;
  institutionSidebar: boolean;
  iban: ValidateIBANObject | null;
  steps: StepType[];
  flagImageData: string | null;
  countryCodeEntry: CountryCodeEntry | null;
  currency: Currency | null;
  oldCounterpartyId: string | null;
};

export type FormData = CreateCounterparty & { temporaryData: TemporaryData };

const GlobalStyles = createGlobalStyle`
  @property --mask-size {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 0%;
  }

  @property --mask-size-inner {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 0%;
  }
`;

const CounterpartyWrapper = styled(ModalBase)<{ $showSidebar: boolean }>`
  --modal-width: 500px;
  --modal-offset-x: 0px;

  ${({ $showSidebar }) =>
    $showSidebar &&
    css`
      --modal-offset-x: 132px;
    `}

  place-self: start;
  margin-top: 12dvh;
  transition: transform 0.3s;
  transform: translateX(calc(var(--modal-offset-x) * -1));
`;

const Content = styled.div`
  position: relative;
  padding: 24px;
  z-index: 2;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.background};
  box-shadow:
    rgba(43, 49, 67, 0.075) 0px 0px 0px 1px,
    rgba(43, 49, 67, 0.075) 0px 1px 3px,
    rgba(43, 49, 67, 0.075) 0px 8px 20px,
    rgba(43, 49, 67, 0.024) 0px 4px 12px;
`;

const Tabs = styled.div`
  position: relative;
  overflow: clip;
  padding: 8px;
  margin: -8px;
`;

const Tab = styled.div<{ $isActive: boolean }>`
  transition: opacity 0.2s;

  ${({ $isActive }) =>
    !$isActive &&
    css`
      pointer-events: none;
      opacity: 0;
      position: absolute;
    `}
`;

const Sidebar = styled.div`
  width: 276px;
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  padding: 24px 24px 24px 36px;
  border-radius: 0 12px 12px 0;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  transition: transform 0.3s;
  transform: translateX(calc(var(--modal-offset-x, 0px) * 2));
`;

const SidebarInner = styled.div<{ $showSidebar: boolean }>`
  --mask-size: 0%;
  --mask-size-inner: 0%;

  opacity: 0;
  transition:
    --mask-size 0.4s ease,
    --mask-size-inner 0.7s ease,
    opacity 0.4s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  mask-image: radial-gradient(circle at 0% 50%, black var(--mask-size-inner), transparent var(--mask-size));

  ${({ $showSidebar }) =>
    $showSidebar &&
    css`
      --mask-size: 100%;
      --mask-size-inner: 100%;
      opacity: 1;
    `}
`;

const Blur = styled.div`
  position: absolute;
  inset: 1px;
  border-radius: 10px;
  display: none;
  overflow: hidden;
  filter: saturate(1.5) brightness(1.15);

  &:after {
    content: '';
    position: absolute;
    inset: -20px -5% 60% -5%;
    background-image: var(--background-blur);
    background-size: 170% 180%;
    filter: blur(35px);
    opacity: 0.4;
    background-position: center center;
  }
`;

export const Form = styled.form``;

const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -24px 24px -24px;
  padding: 0 24px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
`;

const Step = styled.div<{ $state: StepState; $isFlag?: boolean }>`
  position: relative;
  display: flex;
  gap: 8px;
  padding: 12px 0;
  font-size: 14px;
  line-height: 18px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  transition:
    background-color 0.2s,
    box-shadow 0.2s;

  ${({ $state }) =>
    $state === 'active' &&
    css`
      box-shadow: inset 0 -2px 0 ${({ theme }) => theme.primary.blendToBackground(900)};
      color: ${({ theme }) => theme.foreground};
    `}
`;

const StepLabel = styled.div`
  font-weight: 500;
`;

const StepNumber = styled.div<{ $state: StepState; $isFlag?: boolean }>`
  font-weight: 600;
  font-size: 11px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
  color: ${({ theme }) => theme.secondary.blendToBackground(900)};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(250)};

  ${({ $state }) =>
    $state === 'active' &&
    css`
      background-color: ${({ theme }) => theme.primary.blendToBackground(900)};
      color: ${({ theme }) => theme.secondary.blendToBackground(50)};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primary.blendToBackground(1000)};
    `}
`;

export const Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 24px;
  align-items: start;
`;

export const Row = styled.div`
  grid-column: 1 / 3;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 0 -24px -24px -24px;
  padding: 24px;
  border-radius: 0 0 12px 12px;
  margin-top: 24px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}
`;
const StyledModalText = styled(ModalText)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledModalIconCircle = styled(ModalIconCircle)`
  width: 48px;
  height: 48px;
  margin: 0;

  svg {
    --icon-size: 24px;
  }
`;

const AddressIcon = styled(ModalIconCircle)`
  background-color: ${({ theme }) => theme.background};
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.1),
    0 4px 10px rgba(0, 0, 0, 0.05),
    0 4px 16px rgba(0, 0, 0, 0.05);
`;

const AddressCountry = styled(FormLabelIcon)`
  margin-top: 8px;
  margin-bottom: 24px;
`;

const Address = styled.div`
  font-size: 14px;
  line-height: 20px;
  display: grid;
  grid-gap: 4px;

  p {
    margin: 0;
  }
`;

const DefaultCurrency = styled.div`
  margin-top: 24px;
  display: grid;
  grid-gap: 8px;
  width: 100%;
`;

const DefaultCurrencyList = styled.div`
  display: grid;
  grid-gap: 4px;
`;

const DefaultCurrencyEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 20px;
  font-size: 14px;
  width: 100%;
`;

const DefaultCurrencyLabel = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const DefaultCurrencyValue = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.secondary.background};

  small {
    font: inherit;
    color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  }
`;

export const ModalCounterpartyInternational: FC = () => {
  const tabsRef = useRef<Record<StepID, HTMLDivElement | null>>({
    bank: null,
    account: null,
    owner: null,
  });
  const { closeModal, getModalData } = useModalStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const modalTypes: ModalType[] = ['CounterpartyInternational'];
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { createRequest: fetchInstitution } = useInstitution();
  const { createRequest: createCounterparty } = useCreateCounterparty();
  const { createRequest: fetchTransfers } = useTransfersFetch();
  const { createRequest: deleteCounterparty } = useDeleteCounterparty();

  const { handleSubmitErrors } = useFormErrorHandler({ defaultDisplayType: 'popup' });

  const useFormMethods = useForm<FormData>({
    defaultValues: {
      temporaryData: {
        steps: [
          { id: 'bank', label: 'Beneficial Bank', state: 'active' },
          { id: 'account', label: 'Bank Account', state: 'disabled' },
          { id: 'owner', label: 'Account Owner', state: 'disabled' },
        ],
        institution: null,
        institutionSidebar: false,
        iban: null,
        flagImageData: null,
        countryCodeEntry: null,
        currency: null,
        oldCounterpartyId: null,
      },
    },
    mode: 'onChange',
    shouldFocusError: false,
  });
  const { handleSubmit, setValue, reset, watch, getValues, trigger, formState } = useFormMethods;

  useEffect(() => {
    const flagBackground = () => {
      if (!canvasRef.current) {
        return;
      }

      const canvas = canvasRef.current;
      const canvasBlur = document.createElement('canvas');
      canvasBlur.width = canvas.width;
      canvasBlur.height = canvas.height;
      const ctxBlur = canvasBlur.getContext('2d');

      if (ctxBlur) {
        ctxBlur.drawImage(canvas, 0, 0);

        ctxBlur.drawImage(canvasBlur, 0, 0);

        setValue('temporaryData.flagImageData', canvasBlur.toDataURL());
      }
    };

    const animationFrame = requestAnimationFrame(flagBackground);

    return () => cancelAnimationFrame(animationFrame);
  }, []);

  const handleSubmitClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>, isSubmit: boolean) => {
      if (!isSubmit) {
        event.preventDefault();
      }

      const steps = getValues('temporaryData.steps');

      const currentIndex = steps.findIndex((step) => step.state === 'active');

      if (currentIndex === 0) {
        // const isValid = await trigger('routingNumber');

        // if (!isValid) {
        //   handleFormErrors({ errors: formState.errors, display: 'popup' });

        //   return;
        // }

        if (!watch('temporaryData.institution')) {
          addDangerNotification({
            content: 'Please add a valid Routing Number / BIC',
            display: 'popup',
          });

          return;
        }
      }

      if (currentIndex === -1) {
        return;
      }

      if (currentIndex === steps.length - 1) {
        return;
      }

      const updatedSteps = steps.map((step: StepType, index) => {
        if (index === currentIndex) {
          return { ...step, state: 'completed' };
        }
        if (index === currentIndex + 1) {
          return { ...step, state: 'active' };
        }
        return step;
      });

      setValue('temporaryData.steps', updatedSteps as StepType[]);
    },
    [formState, trigger]
  );

  const onSuccess = (data: FormData) => {
    const { temporaryData, ...newCounterparty } = data;

    createCounterparty(newCounterparty)
      .then((response) => {
        if (!response) {
          return;
        }

        getModalData()?.callback(response, temporaryData?.currency);

        const oldCounterpartyId = watch('temporaryData.oldCounterpartyId');

        if (oldCounterpartyId) {
          addSuccessNotification({
            content: 'Counterparty updated successfully',
          });

          fetchTransfers({ counterpartyId: oldCounterpartyId }).then((transferResponse) => {
            if ((transferResponse?.transfers ?? []).length === 0) {
              deleteCounterparty({ id: oldCounterpartyId });
            }
          });
        } else {
          addSuccessNotification({
            content: 'Counterparty created and selected successfully',
          });
        }

        closeModal();
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  const handleCancelClick = useCallback(() => {
    const steps = getValues('temporaryData.steps');

    const currentIndex = steps.findIndex((step) => step.state === 'active');

    if (currentIndex === -1) {
      return;
    }

    if (currentIndex === 0) {
      closeModal();

      return;
    }

    const updatedSteps = steps.map((step, index) => {
      if (index === currentIndex) {
        return { ...step, state: 'disabled' };
      }
      if (index === currentIndex - 1) {
        return { ...step, state: 'active' };
      }
      return step;
    });

    setValue('temporaryData.steps', updatedSteps as StepType[]);
  }, []);

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.getModalData(),
        (data) => {
          if (data?.values) {
            const {
              routingNumber,
              routingNumberType,
              accountNumber,
              address,
              legalId,
              legalType,
              localBankCode,
              localAccountNumber,
              localBankCountryCode,
              localBankName,
              name,
              phone,
              email,
              description,
              id: oldCounterpartyId,
            }: Counterparty = data.values;

            reset();

            setTimeout(() => {
              setValue('routingNumber', routingNumber ?? '');
              setValue('routingNumberType', routingNumberType ?? 'aba');
              setValue('accountNumber', accountNumber ?? '');
              setValue('address', address ?? {});
              setValue('legalId', legalId ?? '');
              setValue('legalType', legalType ?? '');
              setValue('localBankCode', localBankCode ?? '');
              setValue('localAccountNumber', localAccountNumber ?? '');
              setValue('localBankCountryCode', localBankCountryCode ?? '');
              setValue('localBankName', localBankName ?? '');
              setValue('name', name ?? '');
              setValue('phone', phone ?? '');
              setValue('email', email ?? '');
              setValue('description', description ?? '');

              setValue('temporaryData.oldCounterpartyId', oldCounterpartyId ?? null);

              if (routingNumber) {
                fetchInstitution({ routingNumber })
                  .then((response) => {
                    if (response) {
                      setValue('temporaryData.institution', response);
                      setValue('temporaryData.institutionSidebar', response !== null);

                      const countryCodeEntry =
                        countryCodeJSON.find((e) => e.countryCode === response?.countryCode) ?? null;

                      setValue('temporaryData.countryCodeEntry', countryCodeEntry);
                      setValue(
                        'temporaryData.currency',
                        Currencies.find(({ code }) => code === countryCodeEntry?.currencyCode) ?? null
                      );

                      setValue('routingNumberType', response?.routingNumberType ?? 'aba');

                      setValue('localBankCountryCode', response?.countryCode ?? '');
                      setValue('address.countryCode', response?.countryCode ?? '');
                    }
                  })
                  .catch((error) => {
                    addDangerNotification({
                      content: error.message,
                      display: 'popup',
                    });
                  });
              }
            }, 0);

            return;
          }

          reset();
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  return (
    <CounterpartyWrapper modalTypes={modalTypes} $showSidebar={watch('temporaryData.institutionSidebar')}>
      <GlobalStyles />

      <Sidebar>
        <SidebarInner $showSidebar={watch('temporaryData.institutionSidebar')}>
          <AddressIcon>
            <Flag
              countryCode={watch('temporaryData.institution.countryCode')?.toLowerCase() ?? 'us'}
              ref={canvasRef}
              size={40}
            />
          </AddressIcon>

          <ModalText>
            <Headline size="small" fullWidth>
              {titleCase(String(watch('temporaryData.institution')?.fullName))}
            </Headline>
          </ModalText>

          <AddressCountry>
            <Icon.Globe />
            <FormLabel>
              {watch('temporaryData.countryCodeEntry.countryName')} ({watch('temporaryData.institution')?.countryCode})
            </FormLabel>
          </AddressCountry>

          <FormElement>
            <FormLabelIcon>
              <Icon.Marker />
              <FormLabel>Address</FormLabel>
            </FormLabelIcon>
            <Address>
              {watch('temporaryData.institution')?.streetAddress && (
                <p>{titleCase(String(watch('temporaryData.institution')?.streetAddress))}</p>
              )}
              <p>
                {titleCase(String(watch('temporaryData.institution')?.city))}{' '}
                {watch('temporaryData.institution')?.state} {watch('temporaryData.institution')?.zipCode}
              </p>
            </Address>
          </FormElement>

          {watch('temporaryData.currency') && (
            <DefaultCurrency>
              <FormLabelIcon>
                <Icon.Money />
                <FormLabel>Currency (Default)</FormLabel>
              </FormLabelIcon>
              <DefaultCurrencyList>
                <DefaultCurrencyEntry>
                  <DefaultCurrencyLabel>Name</DefaultCurrencyLabel>
                  <DefaultCurrencyValue>{watch('temporaryData.currency.nameSingle')}</DefaultCurrencyValue>
                </DefaultCurrencyEntry>
                <DefaultCurrencyEntry>
                  <DefaultCurrencyLabel>Code & Symbol</DefaultCurrencyLabel>
                  <DefaultCurrencyValue>
                    {watch('temporaryData.currency.code')} / {watch('temporaryData.currency.symbolNative')}
                  </DefaultCurrencyValue>
                </DefaultCurrencyEntry>
                <DefaultCurrencyEntry>
                  <DefaultCurrencyLabel>Decimal Digits</DefaultCurrencyLabel>
                  <DefaultCurrencyValue>
                    {watch('temporaryData.currency.decimalDigits')}{' '}
                    <small>({formatNumber(0, watch('temporaryData.currency.code'))})</small>
                  </DefaultCurrencyValue>
                </DefaultCurrencyEntry>
              </DefaultCurrencyList>
            </DefaultCurrency>
          )}
        </SidebarInner>
      </Sidebar>
      <Content>
        <Blur style={{ '--background-blur': `url(${watch('temporaryData.flagImageData')})` } as CSSProperties} />
        <StyledModalText>
          <StyledModalIconCircle>
            <Icon.AnimationUserArrow />
          </StyledModalIconCircle>
          <Headline size="middle" fullWidth>
            {watch('temporaryData.oldCounterpartyId') ? 'Edit Counterparty' : 'New Counterparty'}
          </Headline>
        </StyledModalText>
        <Steps>
          {watch('temporaryData.steps').map(({ id, label, state }, index) => (
            <Step $state={state} key={id}>
              <StepNumber $state={state}>{index + 1}</StepNumber>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Steps>

        <div>
          <NotificationList display="popup" />

          <FormProvider {...useFormMethods}>
            <Form onSubmit={handleSubmit(onSuccess, (errors) => handleSubmitErrors<FormData>(errors))}>
              <Tabs>
                <Tab
                  $isActive={watch('temporaryData.steps').at(0)?.state === 'active'}
                  ref={(el) => {
                    tabsRef.current.bank = el;
                  }}
                >
                  <ContentBank />
                </Tab>
                <Tab
                  $isActive={watch('temporaryData.steps').at(1)?.state === 'active'}
                  ref={(el) => {
                    tabsRef.current.account = el;
                  }}
                >
                  <ContentAccount />
                </Tab>
                <Tab
                  $isActive={watch('temporaryData.steps').at(2)?.state === 'active'}
                  ref={(el) => {
                    tabsRef.current.owner = el;
                  }}
                >
                  <ContentOwner />
                </Tab>
              </Tabs>
              <Buttons>
                <Button
                  type="button"
                  variant="secondary"
                  icon={watch('temporaryData.steps').at(0)?.state !== 'active' ? <Icon.ChevronLeft /> : undefined}
                  onClick={handleCancelClick}
                >
                  {watch('temporaryData.steps').at(0)?.state === 'active' ? 'Cancel' : 'Back'}
                </Button>

                <Button
                  type={watch('temporaryData.steps').at(2)?.state === 'active' ? 'submit' : 'button'}
                  icon={
                    watch('temporaryData.steps').at(2)?.state === 'active' ? (
                      <Icon.CircleCheck />
                    ) : (
                      <Icon.ChevronRight />
                    )
                  }
                  iconRight={watch('temporaryData.steps').at(2)?.state !== 'active'}
                  onClick={(event) => handleSubmitClick(event, watch('temporaryData.steps').at(2)?.state === 'active')}
                >
                  {watch('temporaryData.steps').at(2)?.state === 'active' ? 'Submit' : 'Continue'}
                </Button>
              </Buttons>
            </Form>
          </FormProvider>
        </div>
      </Content>
    </CounterpartyWrapper>
  );
};
