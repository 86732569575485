import React, { FC } from 'react';

import { Button, Icon } from '@column/column-ui-kit';

import { EmptyState, PageHeader, SectionHeader } from '~/components';
import { useNavigate } from '~/lib/navigation';
import { ROUTE } from '~/public/routes';
import { useSessionStore } from '~/stores/Session';
import { Inner } from '~/styles';

import { BusinessForm, EntityStatus, PersonForm } from './Partial';

export const PagePlatformInfo: FC = () => {
  const rootEntity = useSessionStore((state) => state.rootEntity);
  const navigate = useNavigate();

  return (
    <>
      <PageHeader text="Info" />
      <Inner py={0}>
        <EntityStatus status={rootEntity?.verificationStatus} />

        {!rootEntity && (
          <EmptyState headline="No root entity" text="Create your Root Entity by opening a Commercial Account.">
            <Button onClick={() => navigate(`${ROUTE.REGISTER_OPEN_BANK_ACCOUNT}`)} size="small" icon={<Icon.Plus />}>
              Create Commercial Account
            </Button>
          </EmptyState>
        )}
      </Inner>

      {rootEntity?.personDetails && (
        <>
          <SectionHeader text="Root Entity" />
          <PersonForm personEntity={rootEntity?.personDetails} id={rootEntity?.id} disableForm />
        </>
      )}
      {rootEntity?.businessDetails && (
        <>
          <SectionHeader text="Root Entity" />
          <BusinessForm businessEntity={rootEntity?.businessDetails} id={rootEntity?.id} disableForm />
        </>
      )}
    </>
  );
};
