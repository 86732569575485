import React, { ComponentType, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Button, DropdownCheckmark, DropdownEntry, DropdownEntryText, Icon } from '@column/column-ui-kit';

import { PopoverBase, PopoverBaseProps, StyledPopover } from '../Base';
import { useSessionStore } from '~/stores/Session';
import { Caption, Line } from '~/styles';

export interface PopoverPlatformType {
  id: string;
  label: string;
  role: string;
  isInvite?: boolean;
}

interface PopoverPlatformProps extends PopoverBaseProps {
  platforms: PopoverPlatformType[];
  active?: string;
  onActiveChange?: (active: string) => void;
  onAcceptClick?: (id: string) => void;
  onManageClick?: () => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-min-width: 320px;
  --popover-width: auto;
  --popover-left: -24px;

  ${StyledPopover} {
    max-height: calc(100vh - 106px);
    overflow: auto;
  }
`;

const StyledLine = styled(Line)`
  margin: 4px -4px;
  width: auto;
`;

const StyledCaption = styled(Caption)`
  margin: 8px 0 8px 12px;
  font-size: 12px;
  text-transform: uppercase;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const DropdownEntrySettings = styled(DropdownEntry)`
  &:last-child {
    margin-bottom: 0;
  }
`;

const Role = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const DropdownEntrySimple = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  gap: 8px;
  position: relative;
  z-index: 1;
  padding: 8px 8px;
  border-radius: 8px;
  color: ${({ theme }) => theme.secondary.background};
  background-color: var(--entry-background, transparent);
  transition: background-color 0.2s;

  &:first-child {
    margin-top: 4px;
  }
  &:last-child {
    margin-bottom: 4px;
  }

  svg {
    --icon-size: 16px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }

  &:hover {
    --entry-background: ${({ theme }) => theme.secondary.blendToBackground(50)};
  }
`;

const Accept = styled(Button)`
  margin-left: auto;
`;

const StyledDropdownEntrySimple = styled(DropdownEntrySimple)`
  border: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
  padding: 8px 12px;
`;

export const PopoverPlatform: React.FC<PopoverPlatformProps> = (props) => {
  const currentPlatform = useSessionStore((state) => state.currentPlatform);

  const handleClick = (id: string) => {
    if (currentPlatform?.id === id) {
      return;
    }

    if (props.onActiveChange) {
      props.onActiveChange(id);
    }
  };

  const handleAccept = (id: string) => {
    if (props.onAcceptClick) {
      props.onAcceptClick(id);
    }
  };

  const handleManageClick = () => {
    if (props.onManageClick) {
      props.onManageClick();
    }
  };

  return (
    <Wrapper {...props}>
      <StyledCaption>Platforms</StyledCaption>
      <Options>
        {props.platforms.map((entry: PopoverPlatformType) => {
          const Element = (entry.isInvite ? StyledDropdownEntrySimple : DropdownEntry) as ComponentType<
            PropsWithChildren<{
              isActive: boolean;
              onClick: () => void;
            }>
          >;
          return (
            <Element
              key={entry.id}
              isActive={props.active === entry.id}
              onClick={() => !entry.isInvite && handleClick(entry.id)}
            >
              <DropdownEntryText>
                {entry.label}
                {entry.role && <Role>{entry.role}</Role>}
              </DropdownEntryText>
              {entry.id === props.active && !entry.isInvite && <DropdownCheckmark />}
              {entry.isInvite && (
                <Accept size="tiny" onClick={() => handleAccept(entry.id)}>
                  Accept
                </Accept>
              )}
            </Element>
          );
        })}
      </Options>
      <StyledLine />
      <DropdownEntrySettings onClick={handleManageClick}>
        <Icon.Settings />
        <DropdownEntryText>Manage platforms</DropdownEntryText>
      </DropdownEntrySettings>
    </Wrapper>
  );
};
