import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Chip, Dropdown, DropdownOption, Fade, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { PageHeader, SectionHeader, Table, TableColumn } from '~/components';
import { useDialog } from '~/components/Dialog/Provider';
import { Loading, LogoLoading, RelativeTime } from '~/elements';
import { useListInvites } from '~/hooks/useInvites';
import { useListRoles } from '~/hooks/useRoles';
import { useListUsers } from '~/hooks/useUsers';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';
import { useNavigate } from '~/lib/navigation';
import { DashboardUserRedacted, FactorTypeResponse, Invite, InviteRepository, UserRepository } from '~/repositories';
import { OwnerRoleId, getRoleLabel, precannedRolesByID, precannedRolesByName } from '~/repositories/RoleRepository';
import { Factor } from '~/repositories/UserRepository';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Inner, Line, Truncate } from '~/styles';

import { InviteUsersModal } from './InviteUsersModal';

interface UserListInfo {
  id: string;
  name: string;
  isOwner: boolean;
}

interface UserListRole {
  id: string;
  name: string;
}

interface UserList {
  id?: string;
  info?: UserListInfo;
  email?: string;
  factors?: Factor[];
  role?: UserListRole;
  action?: ReactNode;
}

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

const StyledLoading = styled(Loading)`
  top: 40px;
  transform: translateX(-50%);
`;

const Relative = styled.div`
  position: relative;
`;

const RoleHelpButton = styled(Button)`
  padding: 0;
  font-weight: 500;
`;

const UserInfo = styled.div`
  display: inline-flex;
  gap: 4px;
  vertical-align: top;
  margin-left: 8px;
`;

const StyledTruncate = styled(Truncate)`
  display: flex;
  align-items: center;
`;

const StyledTable = styled(Table)`
  tr {
    align-items: center;
  }
`;

export const PageTeam: React.FC = () => {
  const { currentUser, currentPlatform, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const { openAlert } = useDialog();
  const openHelpSidebar = useHelpSidebarStore((state) => state.openHelpSidebar);

  const navigate = useNavigate();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
  const openInviteModal = useCallback(() => setIsInviteModalOpen(true), []);
  const closeInviteModal = useCallback(() => setIsInviteModalOpen(false), []);

  const [userList, setUserList] = useState<UserList[]>([]);
  const [invites, setInvites] = useState<Invite[]>([]);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(true);
  const [isInvitesLoading, setIsInvitesLoading] = useState<boolean>(true);
  const isLoading = isUsersLoading || isInvitesLoading;

  const { createRequest: createListUsersRequest, response: usersResponse } = useListUsers({
    shouldNotLoad: currentPlatform === undefined,
    onSuccess: () => setIsUsersLoading(false),
  });
  useEffect(() => {
    if (!currentUser || !usersResponse) return;
    const newUserList = usersResponse.dashboardUsers.map((user) => {
      const smsFactor = user.factors.find(
        (factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.SMS
      );
      const totpFactor = user.factors.find(
        (factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.TOTP
      );

      return {
        id: user.id,
        info: {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          isOwner: user.platformRole.name === 'owner',
        },
        email: user.email,
        factors: user.factors,
        role: {
          id: user.platformRole.id,
          name: user.platformRole.name,
        },
        action: (
          <Dropdown
            isInside
            positionRight
            options={[
              {
                label: 'Reset Authenticator 2FA',
                isDisabled: !smsFactor || !totpFactor,
                tooltip: !totpFactor
                  ? { content: 'User does not have Authenticator 2FA configured', placement: 'left' }
                  : !smsFactor
                    ? { content: 'Authenticator 2FA may not be reset without SMS 2FA enabled', placement: 'left' }
                    : undefined,
                onClick: totpFactor ? () => handleUserDisableTotpMfa(user, totpFactor) : undefined,
              },
              {
                label: 'Remove',
                isDanger: true,
                isDisabled: currentUser.id === user.id || user.platformRole.name === 'owner',
                onClick: () => handleUserRemoveConfirm(user, user.platformRole.platformId),
              },
            ]}
            variant="dots"
          />
        ),
      };
    });
    setUserList(newUserList);
  }, [currentUser, usersResponse]);

  const { createRequest: createListPlatformInvitesRequest } = useListInvites({
    shouldNotLoad: currentPlatform === undefined,
    onSuccess: (resp) => {
      setIsInvitesLoading(false);
      setInvites(resp.invites);
    },
  });

  const isCustomRolesEnabled = useFeatureFlag(FeatureFlag.DashboardCustomRoles);
  const { response: rolesResponse } = useListRoles();
  const rolesList: DropdownOption[] = useMemo(() => {
    const roles = (rolesResponse?.roles || []).map((role) => ({
      label: getRoleLabel(role.name),
      value: role.id,
    }));
    if (!isCustomRolesEnabled) return roles;
    return [
      ...roles,
      { isDivider: true },
      {
        label: 'Manage roles',
        icon: <Icon.Settings />,
        onClick: () => navigate(ROUTE.PLATFORM_ROLES),
        variant: 'secondary',
      },
    ];
  }, [isCustomRolesEnabled, rolesResponse]);

  const fetchData = useCallback(() => {
    if (!currentPlatform) return;
    createListUsersRequest({ platformId: currentPlatform.id });
    createListPlatformInvitesRequest({ platformId: currentPlatform.id });
  }, [currentPlatform]);

  useEffect(fetchData, [fetchData]);

  const deleteInvite = useCallback(
    (email: string) => {
      if (!currentPlatform) return;
      InviteRepository.delete(currentPlatform.id, email)
        .then(() => {
          setInvites((oldInvites) => oldInvites.filter((invite) => invite.email !== email));
          fetchData();
          addSuccessNotification({
            content: 'Invite deleted',
          });
        })
        .catch((error: any) => {
          addDangerNotification({
            content: error.message,
          });
        });
    },
    [currentPlatform, fetchData]
  );

  const handleRoleChange = useCallback(
    (userId: string, email: string, roleId: string) => {
      if (!currentPlatform || !rolesResponse) return;

      const role = (rolesResponse.roles ?? []).find((r) => r.id === roleId);
      if (!role) return;

      setUserList((list) => {
        const current = list.findIndex((u) => u.id === userId);

        if (current !== -1) {
          list[current].role = {
            id: roleId,
            name: role.name,
          };
          return [...list];
        }

        return list;
      });

      UserRepository.updatePlatformRole(currentPlatform.id, {
        userId,
        customRoleId: precannedRolesByID[roleId] ? undefined : roleId,
        precannedRoleName: precannedRolesByID[roleId] ? precannedRolesByID[roleId].name : undefined,
      })
        .then(() => {
          fetchData();

          addSuccessNotification({
            content: `The role from "${email}" is updated to "${getRoleLabel(role.name)}" successfully.`,
          });
        })
        .catch((error: any) => {
          addDangerNotification({
            content: error.message,
          });
        });
    },
    [currentPlatform, rolesResponse]
  );

  const handleRemoveUser = useCallback((user: DashboardUserRedacted, platformId: string) => {
    UserRepository.removeUserFromPlatform({
      dashboardUserId: user.id,
      platformId,
    })
      .then(() => {
        fetchData();

        addSuccessNotification({
          content: `"${user.firstName} ${user.lastName}" successfully removed from this platform.`,
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  }, []);

  const handleUserRemoveConfirm = useCallback(
    (user: DashboardUserRedacted, platformId: string) => {
      openAlert({
        headline: `Remove ${user.firstName}?`,
        content: `Are you sure you want to remove ${user.firstName} ${user.lastName} from this platform?`,
        confirmButton: {
          label: 'Remove',
          variant: 'danger',
        },
        onSuccess: () => handleRemoveUser(user, platformId),
      });
    },
    [handleRemoveUser]
  );

  const handleUserDisableTotpMfa = useCallback(
    (user: DashboardUserRedacted, totpFactor: Factor) => {
      openAlert({
        headline: `Disable Authenticator App for ${user.firstName}`,
        content: `This action cannot be undone. If authenticator app two-factor authentication is disabled, it can
          only be re-enabled by ${user.firstName}.`,
        confirmButton: {
          label: 'Disable',
          variant: 'danger',
        },
        onSuccess: () => {
          UserRepository.deleteFactor({ factorId: totpFactor?.factorId })
            .then(() => {
              addSuccessNotification({
                content: 'Authenticator app disabled',
              });
              setUserList((list) =>
                list.map((u) =>
                  u.id === user.id
                    ? { ...u, factors: (u.factors ?? []).filter((f) => f.factorId !== totpFactor.factorId) }
                    : u
                )
              );
            })
            .catch((error) => {
              addDangerNotification({
                content: error.message,
              });
            });
        },
      });
    },
    [currentUser]
  );

  const resendInvite = useCallback(
    (email: string) => {
      if (!currentPlatform) return;

      InviteRepository.resend(email, currentPlatform.id)
        .then(() => {
          addSuccessNotification({
            content: 'Invite resent',
          });
        })
        .catch((error: any) => {
          addDangerNotification({
            content: error.message,
          });
        });
    },
    [currentPlatform]
  );

  const userColumns: TableColumn[] = useMemo(
    () =>
      currentPlatform
        ? [
            {
              Header: 'Name',
              accessor: 'info',
              width: 'minmax(300px, auto)',
              Cell: (current: { value: UserListInfo }) => (
                <StyledTruncate>
                  {current.value.name}
                  {(current.value.id === currentUser?.id || current.value.isOwner) && (
                    <UserInfo>
                      {current.value.id === currentUser?.id && <Chip type="info">You</Chip>}
                      {current.value.isOwner && <Chip>Owner</Chip>}
                    </UserInfo>
                  )}
                </StyledTruncate>
              ),
            },
            {
              Header: 'Email',
              accessor: 'email',
              width: 'min-content',
              Cell: (current: { value: string }) => <Truncate>{current.value}</Truncate>,
            },
            {
              Header: '2FA',
              accessor: 'factors',
              width: 'min-content',
              Cell: (current: { value: Factor[] }) => {
                const factors = current.value ?? [];
                if (factors.length === 0) {
                  return <Chip>Not enabled</Chip>;
                }
                if (factors.some((factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.TOTP)) {
                  return <Chip type={'success'}>Authenticator</Chip>;
                }
                if (factors.some((factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.SMS)) {
                  return <Chip type={'success'}>SMS</Chip>;
                }
                return null;
              },
            },
            {
              Header: () => (
                <RoleHelpButton
                  icon={<Icon.CircleQuestionmark />}
                  onClick={() => openHelpSidebar('Roles')}
                  variant="subtle"
                  size="small"
                >
                  Role
                </RoleHelpButton>
              ),
              accessor: 'role',
              width: 'minmax(200px, min-content)',
              Cell: (current: any) => {
                return (
                  <Dropdown
                    size="small"
                    active={current.value.id === OwnerRoleId ? precannedRolesByName.admin.id : current.value.id}
                    onChange={(value: string) => {
                      handleRoleChange(current.row.original.id, current.row.original.email, value);
                    }}
                    fullWidth
                    isDisabled={
                      (currentPermission?.platformInfo !== 'write' &&
                        currentPlatform.id === currentUser?.defaultPlatformId) ||
                      currentUser?.id === current.value.id ||
                      (current.value.id === OwnerRoleId && currentPlatform.id === currentUser?.defaultPlatformId)
                    }
                    options={rolesList}
                  />
                );
              },
            },
            {
              Header: '',
              width: 'min-content',
              accessor: 'action',
            },
          ]
        : [],
    [currentPlatform, currentUser, currentPermission, rolesList]
  );

  const inviteColumns: TableColumn[] = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        width: 'minmax(300px, auto)',
        Cell: (current: { value: string }) => <Truncate>{current.value}</Truncate>,
      },
      {
        Header: () => (
          <RoleHelpButton
            icon={<Icon.CircleQuestionmark />}
            onClick={() => openHelpSidebar('Roles')}
            variant="subtle"
            size="small"
          >
            Role
          </RoleHelpButton>
        ),
        accessor: 'platformRole',
        width: 'minmax(116px, max-content)',
        Cell: (current: { value: string }) => <Chip>{getRoleLabel(current.value)}</Chip>,
      },
      {
        Header: 'Created',
        accessor: 'created',
        width: 'min-content',
        Cell: (current: { value: string }) => <RelativeTime timestamp={current.value} />,
      },
      {
        Header: '',
        width: 'min-content',
        accessor: 'action',
      },
    ],
    []
  );

  const inviteList = useMemo(
    () =>
      (invites ?? []).map((invite) => ({
        id: invite.platformId,
        email: invite.email,
        platformRole: invite.platformRole,
        created: invite.createdAt ? new Date(invite.createdAt) : undefined,
        action: (
          <Dropdown
            isInside
            positionRight
            options={[
              {
                label: 'Resend',
                onClick: () => resendInvite(invite.email),
              },
              {
                label: 'Delete',
                onClick: () => deleteInvite(invite.email),
                isDanger: true,
              },
            ]}
            variant="dots"
          />
        ),
      })),
    [deleteInvite, invites, resendInvite]
  );

  return (
    <>
      <InviteUsersModal
        onClose={closeInviteModal}
        onSave={(newInvites) => {
          closeInviteModal();
          setInvites((oldInvites) => [...oldInvites, ...newInvites.toReversed()]);
        }}
        open={isInviteModalOpen}
        roles={rolesList}
      />
      <PageHeader text="Team">
        <Button onClick={openInviteModal} icon={<Icon.Mail />} size="small">
          Invite User
        </Button>
      </PageHeader>
      <StyledFade show={isLoading}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!isLoading}>
        <Relative>
          <Fade show={isLoading} base={StyledLoading} />
          <Fade show={!isLoading}>
            <SectionHeader text="Users" />
            <Inner pt={0} px={0}>
              <StyledTable columns={userColumns} data={userList} />
            </Inner>
            <Line />
            <SectionHeader text="Pending Invites" />
            <StyledTable columns={inviteColumns} data={inviteList} />
          </Fade>
        </Relative>
      </Fade>
    </>
  );
};
