import React, { FormEvent, useEffect, useState } from 'react';

import { Button, Radio } from '@column/column-ui-kit';

import { Form } from '../../Login';
import { useNavigate } from '~/lib/navigation';
import { ROUTE } from '~/public/routes';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline, Paragraph } from '~/styles';

import { Option, OptionList, RegisterAction, RegisterContent, RegisterContentGrid } from './FinancialProducts';

export const PageRegisterCompliance: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const [loading, setLoading] = useState<boolean>(false);
  const [complianceCapabilities, setComplianceCapabilities] = useState<string>('');
  const navigate = useNavigate();

  const onSuccess = (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);

    currentPlatformState
      ?.updateMeta({
        complianceCapabilities,
      })
      .then(() => {
        setLoading(false);
        navigate(ROUTE.REGISTER_OPEN_BANK_ACCOUNT);
      })
      .catch((error) => {
        setLoading(false);

        addDangerNotification({
          content: error.message,
        });
      });
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.currentPlatform,
        (currentPlatform) => {
          setComplianceCapabilities(currentPlatform?.complianceCapabilities ?? '');
        },
        { fireImmediately: true }
      ),
    []
  );

  return (
    <RegisterContent>
      <RegisterContentGrid>
        <Headline>Do you have a compliance function?</Headline>
        <Paragraph size="small">Your response helps us better understand your compliance needs.</Paragraph>
      </RegisterContentGrid>

      <Form onSubmit={onSuccess}>
        <OptionList>
          {['Yes', 'No', "I'm not sure"].map((optionKey: string) => {
            return (
              <Option
                isActive={complianceCapabilities === optionKey}
                isSimple
                key={optionKey}
                onClick={() => setComplianceCapabilities(optionKey)}
              >
                <Radio
                  isChecked={complianceCapabilities === optionKey}
                  onCheckedChange={() => setComplianceCapabilities(optionKey)}
                  label={optionKey}
                />
              </Option>
            );
          })}
        </OptionList>
        <RegisterAction>
          <Button variant="muted" onClick={() => navigate(ROUTE.REGISTER_CUSTOMERS)} type="button">
            Back
          </Button>
          <Button isLoading={loading} isDisabled={!complianceCapabilities}>
            Continue
          </Button>
        </RegisterAction>
      </Form>
    </RegisterContent>
  );
};
