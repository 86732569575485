export const MODE = import.meta.env.MODE;
export const IS_PRODUCTION = MODE === 'production';
export const API_URL = import.meta.env.VITE_API_URL;
export const APP_VERSION = import.meta.env.VITE_APP_VERSION || 'development';
export const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN;
export const DATATDOG_BROWSER_LOGS_KEY = 'pub8d4801a9177aaabc76f12049aff19e16';
export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
export const GOOGLE_RECAPTCHA_SITE_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY;
export const LAUNCH_DARKLY_CLIENT_ID = import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID;
export const HOST = import.meta.env.VITE_HOST;
