import React, { forwardRef, useRef, useState } from 'react';
import { Path } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { Dropdown, DropdownElement, DropdownOption, DropdownProps, Icon, mergeRefs } from '@column/column-ui-kit';

import { FormField } from '~/components';
import { TransferTemplate } from '~/repositories/Transfer/TransferTemplateRepository';
import { removeEmptyString } from '~/util';

export interface DropdownCellProps extends DropdownProps {
  id: string;
  array: 'achTransfers' | 'wireTransfers' | 'bookTransfers';
  index: number;
  onClose?: () => void;
}

const Label = styled.div<{ $isFilled: boolean; $isOpen?: boolean; $isLoading?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ $isFilled, theme }) =>
    !$isFilled ? theme.secondary.blendToBackground(700) : theme.secondary.background};
  transform-origin: 0% 50%;
  transition:
    color 0.2s,
    box-shadow 0.2s,
    transform 0.2s,
    opacity 0.2s;

  &:hover {
    color: ${({ theme }) => theme.secondary.blendToBackground(900)};
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primary.background};
    `}

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0;
      transform: translateX(-4px) scale(0.9);
    `}
`;

const LabelContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: table-cell;
  max-width: 100%;
`;

const Up = styled(Icon.ChevronUpLarge)`
  --icon-size: 14px;
  --icon-color: currentColor;

  pointer-events: none;
`;

const Down = styled(Icon.ChevronDownLarge)`
  --icon-size: 14px;
  --icon-color: currentColor;

  pointer-events: none;
`;

const Loading = styled(Icon.Loading)<{ $isLoading?: boolean }>`
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition:
    transform 0.2s,
    opacity 0.2s;

  ${({ $isLoading }) =>
    !$isLoading &&
    css`
      opacity: 0;
      transform: translateY(-12px) scale(0.75);
    `}
`;

export const DropdownCell = forwardRef<DropdownElement, DropdownCellProps>(
  ({ id, array, index, active, onClose, options, hiddenOptions, isLoading, ...props }, ref) => {
    const dropdownRef = useRef<DropdownElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
      <FormField<TransferTemplate>
        id={`${array}.${index}.${id}` as Path<TransferTemplate>}
        noStyle
        oneColumn
        defaultValue={active}
      >
        {({ value, onChange }, { isTouched, error }) => (
          <Dropdown
            {...props}
            ref={mergeRefs([ref, dropdownRef])}
            active={value ?? active}
            onChange={onChange}
            hasError={isTouched && !!error}
            customLabel={
              <>
                <Label
                  $isLoading={isLoading}
                  $isFilled={!![...options, ...(hiddenOptions ?? [])].find((option) => option.value === value)}
                  $isOpen={isOpen}
                >
                  <LabelContent>
                    {[...options, ...(hiddenOptions ?? [])].find((option) => option.value === value)?.label ||
                      props.placeholder ||
                      'Please select'}
                  </LabelContent>
                  {isOpen ? <Up /> : <Down />}
                </Label>
                <Loading $isLoading={isLoading} />
              </>
            }
            options={removeEmptyString<DropdownOption[]>(options)}
            hiddenOptions={removeEmptyString<DropdownOption[]>(hiddenOptions ?? [])}
            onOpenChange={(open) => {
              setIsOpen(open);

              if (!open) {
                dropdownRef.current?.clearSearch();
                onClose?.();
              }
            }}
            fullWidth
          />
        )}
      </FormField>
    );
  }
);
