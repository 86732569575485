import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

import { ROUTE as APP_ROUTE } from '~/app/routes';
import { useNavigate } from '~/lib/navigation';
import { ROUTE } from '~/public/routes';
import { Headline, Paragraph, TinyHeadline } from '~/styles';

import { RegisterContent, RegisterContentGrid } from './Questions/FinancialProducts';

const Contact = styled(RegisterContentGrid)`
  padding: 24px;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(250)};
`;

export const BulletList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 32px;
  counter-reset: section;
`;

const Scale = keyframes`
  to {
    transform: scaleY(1);
  }
`;

const StrokeOffset = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const Show = keyframes`
  to {
    opacity: 1;
  }
`;

const BulletListEntry = styled.li`
  padding: 0 0 0 44px;
  position: relative;
  display: grid;
  grid-gap: 4px;
  animation-name: ${Show};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.25s;
  opacity: 0.6;

  svg {
    display: block;
    left: 11px;
    bottom: 10px;
    position: absolute;
    z-index: 1;

    & > path {
      fill: ${({ theme }) => theme.secondary.blendToBackground(50)};
    }

    g {
      stroke: ${({ theme }) => theme.secondary.blendToBackground(300)};
      strokewidth: 2;
      strokelinecap: round;
      strokelinejoin: round;
      fill: none;

      path {
        &:first-child {
          stroke-dasharray: 29px;
          stroke-dashoffset: 29px;
          animation: ${StrokeOffset} 0.5s ease 0.8s forwards;
        }
        &:last-child {
          stroke-dasharray: 10px;
          stroke-dashoffset: 10px;
          animation: ${StrokeOffset} 0.4s ease 1.3s forwards;
        }
      }
    }
  }

  &:after {
    content: '';
    top: 34px;
    bottom: -26px;
    left: 11px;
    width: 2px;
    border-radius: 1px;
    background-color: ${({ theme }) => theme.secondary.blendToBackground(300)};
    position: absolute;
    transform-origin: 1px 1px;
    transform: scaleY(0);
    animation: inherit;
    animation-name: ${Scale};
  }

  &:first-child {
    opacity: 1;
    animation-delay: 0.25s;

    &:after {
      background: linear-gradient(
        to bottom,
        ${({ theme }) => theme.primary.blendToBackground(800)},
        ${({ theme }) => theme.secondary.blendToBackground(300)} 60%
      );
    }
  }

  &:last-child {
    opacity: 0.4;
    animation-delay: 2s;

    &:after {
      background: linear-gradient(to bottom, ${({ theme }) => theme.secondary.blendToBackground(300)}, transparent 65%);
    }
  }

  &:before {
    content: counter(section);
    counter-increment: section;
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 2px;
    color: ${({ theme }) => theme.primary.foreground};
    background-color: ${({ theme }) => theme.primary.background};
    box-shadow:
      inset 0 -1px 1px ${({ theme }) => theme.primary.blendToBackground(1050)},
      inset 0 1px 1px ${({ theme }) => theme.primary.blendToBackground(900)};
  }
`;

const Sandbox = styled.div`
  --sandbox-arrow-x: 0;

  display: grid;
  grid-gap: 4px;
  position: relative;
  padding: 20px 48px 20px 24px;
  margin: -8px 0 -20px 0;
  cursor: pointer;
  border-radius: 12px;
  /* background-color: ${({ theme }) => theme.secondary.blendToBackground(50)}; */
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
  transition:
    background-color 0.2s,
    box-shadow 0.2s;

  &:hover {
    --sandbox-arrow-x: 4px;

    background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(250)};
  }

  p {
    max-width: 320px;
  }
`;

const SandboxHeadline = styled(TinyHeadline)`
  font-size: 16px;
`;

const SandboxArrow = styled(Icon.ChevronRight)`
  --icon-size: 20px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  position: absolute;
  right: 24px;
  top: 50%;
  transform: translate(var(--sandbox-arrow-x), -50%);
  transition: transform 0.2s;
`;

export const PageRegisterOpenBankAccount: React.FC = () => {
  const navigate = useNavigate();

  return (
    <RegisterContent>
      <Contact>
        <BulletList>
          <BulletListEntry>
            <svg width="20" height="29" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 1H0V29C0 28.4477 0.447715 28 1 28C1.55228 28 2 28.4477 2 29H20V1Z" />
              <g>
                <path d="M1 1V3.5C1 11.7843 7.71573 18.5 16 18.5H19" />
                <path d="M16 15.5L19 18.5L16 21.5" />
              </g>
            </svg>

            <Headline size="small">Start with a commercial account</Headline>
            <Paragraph>
              Fill out information about your business and beneficial owners, and open your commercial bank account.
              Please contact us to start your onboarding process.
            </Paragraph>
            {/* <StartButton fullWidth onClick={() => navigate(ROUTE.REGISTER_BUSINESS_INFORMATION)}> */}
            {/* Start KYC Process */}
            {/* </StartButton> */}
          </BulletListEntry>
          <BulletListEntry>
            <Headline size="small">Request additional products</Headline>
            <Paragraph>Add card and loan programs, remove transfer limits, and much more.</Paragraph>
            <div />
          </BulletListEntry>
          <BulletListEntry>
            <Headline size="small">Go live</Headline>
            <Paragraph>Launch your product and onboard real customers.</Paragraph>
            <div />
          </BulletListEntry>
        </BulletList>
      </Contact>

      <Sandbox
        onClick={() =>
          navigate(APP_ROUTE.ROOT, {
            state: {
              from: 'register',
            },
          })
        }
      >
        <SandboxHeadline>Skip to sandbox</SandboxHeadline>
        <Paragraph size="small">
          Start testing now with fake money and no limits. You can start the process of going to production later.
        </Paragraph>
        <SandboxArrow />
      </Sandbox>

      <Button variant="subtle" fullWidth onClick={() => navigate(ROUTE.REGISTER_COMPLIANCE)}>
        Back
      </Button>
    </RegisterContent>
  );
};
