import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface PowerIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const PowerIcon: FC<PowerIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={32}>
        <path d="M16 3V13" stroke="currentColor" strokeWidth="2" />
        <path
          d="M19.89 6.78003C23.48 8.30003 26 11.85 26 16C26 21.53 21.53 26 16 26C10.47 26 6 21.53 6 16C6 11.85 8.52 8.30003 12.11 6.78003"
          stroke="currentColor"
          strokeWidth="2"
        />
      </IconBase>
    </Wrapper>
  );
};
