import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Chip, ChipStyleProps, Icon, formatNumber } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import {
  EventAccount,
  EventBookTransfer,
  EventCheck,
  EventIdentity,
  EventIncoming,
  EventLoan,
  EventOutgoing,
  EventReport,
} from '~/elements';
import { EventEvent } from '~/typings/API';
import { formatString } from '~/util';

const EventTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

const EventStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

const EventType = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.foreground};
  margin: 0;
  text-transform: capitalize;
`;

const EventTypeMap = new Map<string, string>([
  ['account', 'Account'],
  ['ach', 'ACH'],
  ['book', 'Book Transfer'],
  ['check', 'Check'],
  ['loan', 'Loan'],
  ['disbursement', 'Disbursement'],
  ['identity', 'Identity'],
  ['payment', 'Payment'],
  ['reporting', 'Report'],
  ['swift', 'International Wire Transfer'],
  ['verification', 'Verification'],
  ['wire', 'Wire Transfer'],
  ['realtime', 'Realtime Payment'],
  ['bank_account', 'Bank Account'],
]);

const EventRouteMap = new Map<string, string>([
  ['account', `${ROUTE.BANK_ACCOUNTS}/edit`],
  ['ach', `${ROUTE.TRANSFERS}/edit/ach`],
  ['book', `${ROUTE.TRANSFERS}/edit/book`],
  ['check', `${ROUTE.TRANSFERS}/edit/check`],
  ['swift', `${ROUTE.TRANSFERS}/edit/intl-wire`],
  ['wire', `${ROUTE.TRANSFERS}/edit/wire`],
  ['realtime', `${ROUTE.TRANSFERS}/edit/realtime`],
  ['loan', `${ROUTE.LOANS}/edit`],
  ['payment', `${ROUTE.LOANS}/edit/payment`],
  ['disbursement', `${ROUTE.LOANS}/edit/disbursement`],
  ['identity', `${ROUTE.ENTITIES}/edit`],
  ['verification', `${ROUTE.ENTITIES}/edit`],
  ['reporting', `${ROUTE.REPORTING}`],
  ['bank_account', 'Bank Account'],
]);

const EventIconMap = new Map<string, ReactNode>([
  ['account', <EventAccount key="account" />],
  ['check', <EventCheck key="check" />],
  ['identity', <EventIdentity key="identity" />],
  ['incoming_transfer', <EventIncoming key="incoming" />],
  ['loan', <EventLoan key="loan" />],
  ['outgoing_transfer', <EventOutgoing key="outgoing" />],
  ['reporting', <EventReport key="reporting" />],
  ['transfer', <EventBookTransfer key="book" />],
  ['bank_account', <EventAccount key="account" />],
]);

const EventTransferDirectionMap = new Map<string, string>([
  ['bank_account_summary', 'Bank Account Summary'],
  ['bank_account_transaction', 'Bank Account Transaction'],
  ['incoming_drawdown_request', 'Incoming Drawdown request'],
  ['incoming_transfer', 'Incoming'],
  ['incoming_debit', 'Incoming Debit'],
  ['outgoing_debit', 'Outgoing Debit'],
  ['outgoing_transfer', 'Outgoing'],
]);

const EventStatusMap = new Map<string, string>([
  ['noc', 'Notification of Change'],
  ['nsf', 'Insufficient Funds'],
  ['pending_deposit', 'Pending'],
]);

const EventStatusTypeMap = new Map<string, ChipStyleProps['type']>([
  // Success
  ['completed', 'success'],
  ['created', 'success'],
  ['paid_off', 'success'],
  ['settled', 'success'],
  ['verified', 'success'],

  // Danger
  ['cancelled', 'danger'],
  ['denied ', 'danger'],
  ['delinquent', 'danger'],
  ['failed', 'danger'],
  ['overdrafted', 'danger'],
  ['rejected', 'danger'],
  ['rejected', 'danger'],
  ['return_contested', 'danger'],
  ['returned', 'danger'],
  ['returned_to_sender', 'danger'],
]);

export const getEventType = (value: string) => {
  // Parse event string into array
  const eventString = value.split('.');

  const eventType = EventTypeMap.get(eventString[0]);

  const eventTransferDirection =
    eventString[0] === 'check' && eventString[1] === 'incoming_debit'
      ? 'Incoming'
      : eventString[0] === 'check' && eventString[1] === 'outgoing_debit'
        ? 'Outgoing'
        : eventString[0] === 'book' ||
            (eventString[0] === 'identity' && eventString[1] === 'verification') ||
            eventString[0] === 'account' ||
            (eventString[0] === 'identity' && !eventString[2])
          ? null
          : (EventTransferDirectionMap.get(eventString[1]) ?? formatString(eventString[1]));

  return (
    <EventTypeWrapper>
      <EventStatusWrapper>
        <EventType>
          {eventTransferDirection} {eventType}
        </EventType>
      </EventStatusWrapper>
    </EventTypeWrapper>
  );
};

export const getEventStatus = (value: string) => {
  const eventString = value.split('.');

  const eventStatus =
    // If it's a delivered check use the 4th segment of the event string
    eventString[0] === 'check' && eventString[2] === 'delivery'
      ? (EventStatusMap.get(eventString[3]) ?? formatString(eventString[3]))
      : // If it's a loan, identity, or account event with no 3rd segment use the 2nd segment of the event string
        (eventString[0] === 'loan' && !eventString[2]) ||
          (eventString[0] === 'identity' && !eventString[2]) ||
          (eventString[0] === 'account' && !eventString[2])
        ? (EventStatusMap.get(eventString[1]) ?? formatString(eventString[1]))
        : (EventStatusMap.get(eventString[2]) ?? formatString(eventString[2]));

  const statusType = EventStatusTypeMap.get(eventString[2]) ?? 'default';

  return <Chip type={statusType}>{eventStatus}</Chip>;
};

export const getEventIcon = (value: string) => {
  const eventString = value.split('.');

  const eventIcon = ['incoming_transfer', 'outgoing_transfer', 'transfer'].includes(eventString[1])
    ? EventIconMap.get(eventString[1])
    : (EventIconMap.get(eventString[0]) ?? <Icon.CalendarLayer />);

  return eventIcon;
};

// Raw return

export const getRawEventType = (value: string) => {
  // Parse event string into array
  const eventString = value.split('.');

  const eventType = EventTypeMap.get(eventString[0]);

  return eventType;
};

export const getRawEventDirection = (value: string) => {
  // Parse event string into array
  const eventString = value.split('.');

  const eventTransferDirection =
    eventString[0] === 'check' && eventString[1] === 'incoming_debit'
      ? 'Issued'
      : eventString[0] === 'check' && eventString[1] === 'outgoing_debit'
        ? 'Deposited'
        : eventString[0] === 'book' ||
            (eventString[0] === 'identity' && eventString[1] === 'verification') ||
            eventString[0] === 'account' ||
            (eventString[0] === 'identity' && !eventString[2])
          ? null
          : (EventTransferDirectionMap.get(eventString[1]) ?? formatString(eventString[1]));

  return eventTransferDirection;
};

export interface ParsedEvent {
  id: string;
  createdAt: string;
  eventType: string;
  data: {
    amount?: number;
    businessDetails?: { businessName: string };
    depositedAmount?: number;
    description?: string;
    eventType?: string;
    fromDate?: string;
    id?: string;
    personDetails?: { firstName: string; lastName: string };
    positivePayAmount?: number;
    subType?: string;
    toDate?: string;
    type?: string;
  };
}

export const parseEvent = (event: EventEvent): ParsedEvent => {
  const data = event.data as any;

  return {
    id: event.id as string,
    createdAt: event.createdAt as string,
    eventType: event.type as string,
    data: {
      amount: data?.amount,
      businessDetails: data?.businessDetails ? { businessName: data.businessDetails.businessName } : undefined,
      depositedAmount: data?.depositedAmount,
      description: data?.description,
      eventType: event.type,
      fromDate: data?.fromDate,
      id: data?.id,
      personDetails: data?.personDetails
        ? { firstName: data.personDetails.firstName, lastName: data.personDetails.lastName }
        : undefined,
      positivePayAmount: data?.positivePayAmount,
      subType: data?.type,
      toDate: data?.toDate,
    },
  };
};

export const getEventSummary = (eventData: ParsedEvent['data']): string | undefined => {
  if (eventData.amount) {
    return formatNumber(eventData.amount);
  }

  switch (eventData.eventType?.split('.')[0]) {
    case 'account':
      return eventData.description;
    case 'loan':
      return eventData.description;
    case 'check':
      if (eventData.subType === 'debit') {
        return formatNumber(eventData.positivePayAmount);
      }
      return formatNumber(eventData.depositedAmount);
    case 'identity':
      if (eventData.personDetails) {
        return `${eventData.personDetails.firstName} ${eventData.personDetails.lastName}`;
      }
      return eventData.businessDetails?.businessName;
    case 'reporting':
      return `${eventData.fromDate} — ${eventData.toDate}`;
  }
};

export const getEventACHType = (eventData: ParsedEvent['data']): string | undefined => {
  if (eventData.subType === 'CREDIT') {
    return 'Credit';
  }
  if (eventData.subType === 'DEBIT') {
    return 'Debit';
  }
  return undefined;
};

export const getEventRoute = (value: string): string => {
  const eventString = value.split('.');
  const eventRoute =
    eventString[1] === 'bank_account_monthly_statement' || eventString[1] === 'bank_account_daily_statement'
      ? ROUTE.STATEMENTS
      : EventRouteMap.get(eventString[0]);

  return eventRoute ?? '';
};

export * from './EventRepository';
