import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface BoltIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const BoltIcon: FC<BoltIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={32}>
        <path d="M14 27V18H7L19 5V14H26L14 27Z" stroke="currentColor" strokeWidth="2" />
      </IconBase>
    </Wrapper>
  );
};
