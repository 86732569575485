import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { Button, Dropdown, Input, Modal, ModalSize, Textarea } from '@column/column-ui-kit';

import { AchRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { FormElement, FormLabel } from '~/styles';
import { CreateAchReturnRequestReturnCode } from '~/typings/API';

interface AchReturnModalProps {
  transferId: string;
  isOpen: boolean;
  onClose: () => void;
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledFormElement = styled(FormElement)`
  text-align: left;
`;

const returnReasons: { label: string; value: CreateAchReturnRequestReturnCode }[] = [
  { label: 'Invalid', value: 'INVALID' },
  { label: 'R01', value: 'R01' },
  { label: 'R02', value: 'R02' },
  { label: 'R03', value: 'R03' },
  { label: 'R04', value: 'R04' },
  { label: 'R05', value: 'R05' },
  { label: 'R06', value: 'R06' },
  { label: 'R07', value: 'R07' },
  { label: 'R08', value: 'R08' },
  { label: 'R09', value: 'R09' },
  { label: 'R10', value: 'R10' },
  { label: 'R11', value: 'R11' },
  { label: 'R12', value: 'R12' },
  { label: 'R14', value: 'R14' },
  { label: 'R15', value: 'R15' },
  { label: 'R16', value: 'R16' },
  { label: 'R17', value: 'R17' },
  { label: 'R20', value: 'R20' },
  { label: 'R21', value: 'R21' },
  { label: 'R22', value: 'R22' },
  { label: 'R23', value: 'R23' },
  { label: 'R24', value: 'R24' },
  { label: 'R29', value: 'R29' },
  { label: 'R31', value: 'R31' },
  { label: 'R33', value: 'R33' },
  { label: 'R37', value: 'R37' },
  { label: 'R38', value: 'R38' },
  { label: 'R39', value: 'R39' },
  { label: 'R51', value: 'R51' },
  { label: 'R52', value: 'R52' },
  { label: 'R53', value: 'R53' },
];

export const AchReturnModal: FC<AchReturnModalProps> = ({ isOpen, onClose, transferId }) => {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [returnCode, setReturnCode] = useState<CreateAchReturnRequestReturnCode>();
  const [description, setDescription] = useState<string>('');
  const [addenda, setAddenda] = useState<string>('');

  const { addSuccessNotification, addDangerNotification } = useNotificationStore();

  const handleSubmit = () => {
    if (!returnCode) return;

    setIsButtonLoading(true);
    AchRepository.createReturn(transferId, {
      returnCode,
      description,
      addenda,
      ignoreDeadline: false,
    })
      .then(() => {
        onClose();
        addSuccessNotification({ content: 'Return initiated' });
      })
      .catch((error: Error) => {
        addDangerNotification({ content: error.message, display: 'popup' });
      })
      .finally(() => setIsButtonLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={ModalSize.Small} withClose={false}>
      <Modal.Header title="Return Transfer">
        <Modal.Close variant="subtle" size="small" onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <FormWrapper>
          <StyledFormElement>
            <FormLabel>Return Code</FormLabel>
            <Dropdown
              onChange={(value) => setReturnCode(value as CreateAchReturnRequestReturnCode)}
              active={returnCode}
              options={returnReasons}
              fullWidth
            />
          </StyledFormElement>
          <StyledFormElement>
            <FormLabel>Description</FormLabel>
            <Textarea onChange={setDescription} value={description} placeholder="Description" />
          </StyledFormElement>
          <StyledFormElement>
            <FormLabel>Addenda</FormLabel>
            <Input onChange={setAddenda} value={addenda} placeholder="Addenda" />
          </StyledFormElement>
        </FormWrapper>
      </Modal.Content>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} isLoading={isButtonLoading} variant="danger" isDisabled={!returnCode}>
          Return
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
