import {
  ApiAddress,
  EntityBusinessDetails,
  EntityCreateBusinessEntityRequest,
  EntityCreatePersonEntityRequest,
  EntityEntity,
  EntityEntityWithDetails,
  EntityList,
  EntityPersonDetails,
  EntityServiceGetEntitiesParams,
  EntityUpdateBusinessEntityBody,
  EntityUpdatePersonEntityBody,
  EntityVerificationStatus,
} from '~/typings/API';
import { convertValues } from '~/util/convertValues';

import { client } from './client/Client';

type AddressType = ApiAddress;
type PersonDetails = EntityPersonDetails;
type EntityWithDetails = EntityEntityWithDetails;
export type EntityListParams = EntityServiceGetEntitiesParams;
export type CreatePersonEntity = EntityCreatePersonEntityRequest;
export type CreateBusinessEntity = EntityCreateBusinessEntityRequest;
export type UpdatePersonEntity = EntityUpdatePersonEntityBody;
export type UpdateBusinessEntity = EntityUpdateBusinessEntityBody;
export type VerificationStatus = EntityVerificationStatus;
export type { AddressType, PersonDetails, EntityWithDetails, EntityList, EntityBusinessDetails };

export class EntityRepository {
  static async createPerson(request: CreatePersonEntity) {
    return client
      .post<CreatePersonEntity, EntityWithDetails>('/entities/person', request)
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async updatePerson(id: string, request: UpdatePersonEntity) {
    return client
      .patch<UpdatePersonEntity, EntityWithDetails>(`/entities/person/${id}`, request)
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async createBusiness(request: CreateBusinessEntity) {
    return client
      .post<CreateBusinessEntity, EntityWithDetails>('/entities/business', request)
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async updateBusiness(id: string, request: UpdateBusinessEntity) {
    return client
      .patch<UpdateBusinessEntity, EntityWithDetails>(`/entities/business/${id}`, request, {
        allowNumbersInRequest: true,
      })
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async get(request: GetRequestType) {
    return client.get<unknown, EntityWithDetails>(`/entities/${request.id}`).then((response) => {
      const parsed = convertValues<EntityWithDetails>(response);
      if (parsed.businessDetails?.ein) {
        parsed.businessDetails.ein = parsed.businessDetails.ein.toString();
      }
      if (parsed.businessDetails?.registrationId?.number) {
        // eslint-disable-next-line id-blacklist
        parsed.businessDetails.registrationId.number = parsed.businessDetails.registrationId.number.toString();
      }
      if (parsed.businessDetails?.address?.postalCode) {
        parsed.businessDetails.address.postalCode = parsed.businessDetails.address.postalCode.toString();
      }
      if (parsed.businessDetails?.beneficialOwners) {
        parsed.businessDetails.beneficialOwners = parsed.businessDetails.beneficialOwners.map((e) => ({
          ...e,
          address: e.address?.postalCode ? { ...e.address, postalCode: e.address.postalCode.toString() } : undefined,
          ssn: e.ssn?.toString(),
          driversLicense: e.driversLicense?.number
            ? // eslint-disable-next-line id-blacklist
              { ...e.driversLicense, number: e.driversLicense.number.toString() }
            : undefined,
          // eslint-disable-next-line id-blacklist
          nationalId: e.nationalId?.number ? { ...e.nationalId, number: e.nationalId.number.toString() } : undefined,
          // eslint-disable-next-line id-blacklist
          passport: e.passport?.number ? { ...e.passport, number: e.passport.number.toString() } : undefined,
        }));
      }
      if (parsed.personDetails?.address?.postalCode) {
        parsed.personDetails.address.postalCode = parsed.personDetails.address.postalCode.toString();
      }
      return parsed;
    });
  }

  static async getWithMode(id: string, mode?: 'live' | 'sandbox') {
    return client
      .get<unknown, EntityWithDetails>(`/entities/${id}`, { sandbox: mode ? mode === 'sandbox' : undefined })
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async getAll(request?: Partial<EntityListParams> & { mode?: 'live' | 'sandbox' }) {
    const { mode, ...query } = request ?? {};
    return client
      .get<EntityListParams, EntityList>('/entities', { query, sandbox: mode ? mode === 'sandbox' : undefined })
      .then((response) => ({
        entities: Array.isArray(response?.entities) ? response.entities.map((e) => convertValues<EntityEntity>(e)) : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async delete(id: string) {
    return client.delete<object, object>(`/entities/${id}`);
  }

  static async createRootPerson(platformId: string, request: CreatePersonEntity, mode: 'live' | 'sandbox') {
    return client
      .post<CreatePersonEntity, EntityWithDetails>(`/dashboard/platforms/${platformId}/root-entities/person`, request, {
        sandbox: mode === 'sandbox',
        platformId,
      })
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async createRootBusiness(platformId: string, request: CreateBusinessEntity, mode: 'live' | 'sandbox') {
    return client
      .post<
        CreateBusinessEntity,
        EntityWithDetails
      >(`/dashboard/platforms/${platformId}/root-entities/business`, request, { sandbox: mode === 'sandbox', platformId })
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async getRoot(platformId: string, mode: 'live' | 'sandbox') {
    return client
      .get<unknown, EntityWithDetails>(`/dashboard/platforms/${platformId}/root-entities`, {
        sandbox: mode === 'sandbox',
        platformId,
      })
      .then((response) => convertValues<EntityWithDetails>(response));
  }
}
