import { UserModel } from '~/models';
import { PlatformRole } from '~/repositories';

export const canUserReviewTransfer = (
  user: UserModel | undefined,
  platformRole: PlatformRole | undefined,
  transferCreatedById: string | undefined
): boolean => {
  if (platformRole?.name === 'admin' || platformRole?.name === 'owner') {
    return true;
  }

  return user?.id !== transferCreatedById;
};
