import React, { FC, ReactNode, useCallback } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SegmentedControl, SegmentedControlOptionProps, SegmentedControlVariant } from '@column/column-ui-kit';

import { useNavigate } from '~/lib/navigation';

interface Entry extends Partial<SegmentedControlOptionProps> {
  path: string;
  hide?: boolean;
  activePath?: string;
}

interface PageTabNavigationProps {
  entries?: Entry[];
  className?: string;
}

interface PageProps {
  headline?: string;
  entries?: Entry[];
  Component?: ReactNode;
}

const PageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageTabNavigation: FC<PageTabNavigationProps> = ({ className, entries }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const handleNavChange = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const replaceParams = useCallback(
    (path: string) => {
      let newPath = path;
      Object.keys(params).forEach((key) => {
        const value = params[key];
        if (typeof value === 'string') {
          newPath = newPath.replace(`:${key}`, value);
        }
      });
      return newPath;
    },
    [params]
  );

  if (!entries) {
    return null;
  }

  return (
    <SegmentedControl
      className={className}
      active={location.pathname}
      activeWildcard
      options={
        entries.map(
          (entry: Entry) =>
            !entry.hide && {
              label: entry.label === 'Edit' ? 'Account Details' : entry.label,
              value: replaceParams(entry.path),
              removeWildcard: entry.removeWildcard,
            }
        ) as SegmentedControlOptionProps[]
      }
      onOptionChange={handleNavChange}
      variant={SegmentedControlVariant.Secondary}
    />
  );
};

export const Page: React.FC<PageProps> = () => {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  );
};
