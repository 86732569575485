import React, { ReactNode, useCallback } from 'react';

import { Button, ButtonProps, IconCircleProps, Modal, ModalSize } from '@column/column-ui-kit';

import { useDialog } from '../Provider';

export type ModalAlertProps = {
  headline: ReactNode | string;
  content: ReactNode | string;
  icon?: IconCircleProps['icon'];
  type?: IconCircleProps['type'];
  cancelButton?: Omit<ButtonProps, 'onClick' | 'children'> & { label?: string };
  confirmButton?: Omit<ButtonProps, 'onClick' | 'children'> & { label?: string };
  onSuccess?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  data?: Record<string, any>;
};

export const ModalAlert = ({
  headline,
  content,
  type,
  icon,
  cancelButton,
  confirmButton,
  onClose,
  onSuccess,
  isOpen,
}: ModalAlertProps) => {
  const { closeAlert } = useDialog();

  const handleClose = useCallback(() => {
    onClose?.();
    closeAlert();
  }, [onClose]);

  const handleSuccess = useCallback(() => {
    onSuccess?.();
    handleClose();
  }, [onSuccess, handleClose]);

  return (
    <Modal size={ModalSize.Small} isOpen={isOpen} onClose={() => closeAlert()}>
      <Modal.IconCircle type={type ?? 'danger'} icon={icon ?? 'CircleInfo'} />

      <Modal.Content>
        <Modal.Headline fullWidth>{headline}</Modal.Headline>
        <Modal.Paragraph fullWidth>{content}</Modal.Paragraph>
      </Modal.Content>

      <Modal.Footer>
        <Button variant="secondary" {...cancelButton} onClick={handleClose} size={confirmButton?.size ?? 'small'}>
          {cancelButton?.label ?? 'Cancel'}
        </Button>

        <Button variant="danger" {...confirmButton} onClick={handleSuccess} size={confirmButton?.size ?? 'small'}>
          {confirmButton?.label ?? 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
