import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface WireIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const WireIcon: FC<WireIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase viewportSize={20}>
        <path
          d="M5 8.75C6.51878 8.75 7.75 7.51878 7.75 6C7.75 4.48122 6.51878 3.25 5 3.25C3.48122 3.25 2.25 4.48122 2.25 6C2.25 7.51878 3.48122 8.75 5 8.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path d="M18 11H12V17H18V11Z" stroke="currentColor" strokeWidth="1.5" />
        <path
          d="M5 8.74999V14.36C5 15.99 7.24 16.45 7.88 14.95L12.12 5.04999C12.76 3.54999 15 4.00999 15 5.63999V11"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </IconBase>
    </Wrapper>
  );
};
