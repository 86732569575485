import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dropdown, Input } from '@column/column-ui-kit';

import { CountryCodeEntry, Fields, FormData, Row } from '../index';
import { FormField } from '~/components/FormField';
import countryCodeJSON from '~/data/wires/country-codes.json';
import { useValidateIBAN } from '~/hooks';
import { useCounterpartyValidation } from '~/hooks/validate/useCounterpartyValidate';
import { InputSuccessIcon } from '~/styles';
import { isValidIBANFormat } from '~/util';

export const ContentAccount: FC = () => {
  const { setValue, watch } = useFormContext<FormData>();
  const { validateField } = useCounterpartyValidation(watch('temporaryData.institution.countryCode') ?? 'US');

  const { createRequest: fetchIBANalidate } = useValidateIBAN({
    onSuccess: (response) => {
      if (!watch('localBankCode')) {
        setValue('localBankCode', response.bankId, {
          shouldValidate: true,
          shouldTouch: true,
        });
      }
    },
  });

  const handleIBANValidate = useCallback(
    async (value: string) => {
      if (validateField('accountNumber', value)) {
        if (isValidIBANFormat(value)) {
          try {
            const iban = await fetchIBANalidate({ id: value });

            setValue('temporaryData.iban', iban ?? null);

            return !!iban;
          } catch (error) {
            setValue('temporaryData.iban', null);

            return false;
          }
        } else {
          setValue('temporaryData.iban', null);

          return true;
        }
      } else {
        setValue('temporaryData.iban', null);

        return false;
      }
    },
    [fetchIBANalidate, setValue, validateField]
  );

  const { fields } = useCounterpartyValidation(watch('localBankCountryCode') ?? 'US');

  return (
    <Fields>
      <Row>
        <FormField<FormData>
          id="accountNumber"
          label="Account Number"
          rules={{
            required: true,
            minLength: {
              value: 8,
              message: 'Account number must be at least 8 characters',
            },
            validate: (value) =>
              watch('routingNumberType') === 'bic' ? handleIBANValidate(String(value)) || 'Invalid IBAN format' : true,
          }}
          oneColumn
        >
          {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
            <Input
              value={String(value ?? '')}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              placeholder="Account Number"
              hasError={isTouched && !!error}
            >
              <InputSuccessIcon $isSuccess={!error && (watch('accountNumber') ?? '').length > 0} />
            </Input>
          )}
        </FormField>
      </Row>

      <FormField<FormData>
        id="accountType"
        label="Account Type"
        rules={{ required: fields.includes('accountType') }}
        oneColumn
      >
        {({ value, onChange }) => (
          <Dropdown
            fullWidth
            active={String(value ?? '')}
            onChange={onChange}
            placeholder="Select Type"
            options={[
              {
                label: 'Checking',
                value: 'checking',
              },
              {
                label: 'Savings',
                value: 'savings',
              },
            ]}
          />
        )}
      </FormField>

      <FormField<FormData>
        id="description"
        label="Description"
        tooltip="Enter a brief description of the counterparty."
        rules={{ required: true }}
        oneColumn
      >
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="Description"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <FormField<FormData>
        id="localBankName"
        label="Local Bank Name"
        tooltip="Provide the official name of the recipient's local bank."
        rules={{ required: fields.includes('localBankName') }}
        oneColumn
      >
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="Local Bank Name"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <FormField<FormData>
        id="localBankCode"
        label="Local Bank Code"
        tooltip="Enter the local bank code of the beneficiary's bank (e.g., India IFSC, Australia BSB, China CNAPS). This field is recommended for international wire transfers and required in some countries."
        rules={{ required: fields.includes('localBankCode') }}
        oneColumn
      >
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="Local Bank Code"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <Row>
        <FormField<FormData>
          id="localAccountNumber"
          label="Local Account Number"
          tooltip="Input the recipient's bank account number."
          rules={{
            required: fields.includes('localAccountNumber'),
            minLength: {
              value: 8,
              message: 'Account number must be at least 8 characters',
            },
          }}
          oneColumn
        >
          {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
            <Input
              value={String(value ?? '')}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              placeholder="Local Account Number"
              hasError={isTouched && !!error}
            >
              <InputSuccessIcon $isSuccess={isTouched && !error && (watch('localAccountNumber') ?? '').length > 0} />
            </Input>
          )}
        </FormField>
      </Row>

      <FormField<FormData>
        id="localBankCountryCode"
        label="Local Bank Country Code"
        tooltip="Select the country code of the recipient's bank."
        rules={{ required: true }}
        oneColumn
      >
        {({ value, onChange }) => (
          <Dropdown
            options={countryCodeJSON
              .map((c: CountryCodeEntry) => ({
                label: `${c.countryName} (${c.countryCode})`,
                value: c.countryCode,
              }))
              .sort((a, b) => (a.value === value ? -1 : b.value === value ? 1 : 0))
              .flatMap((item, index, array) =>
                index === 1 && array[0].value === value ? [{ isDivider: true }, item] : [item]
              )}
            fullWidth
            search
            active={value}
            onChange={onChange}
            placeholder="Select Country Code"
          />
        )}
      </FormField>
    </Fields>
  );
};
