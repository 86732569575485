import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from '@column/column-ui-kit';

import { CodeInput, NotificationList } from '~/components';
import { FactorTypeResponse, UserRepository } from '~/repositories';
import { ModalType, useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline, Hint, ModalIconCircle, ModalText, Paragraph } from '~/styles';
import { log } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

import { ModalBase } from './Base';

const Wrapper = styled(ModalBase)`
  --modal-width: 244px;
  padding: 24px;
`;

const StyledHint = styled(Hint)`
  text-align: center;
  margin-top: 16px;
`;

const Resend = styled.span`
  display: inline-block;
  cursor: pointer;
  color: ${({ theme }) => theme.primary.background};
`;

const MODAL_TYPE = 'Authenticate';

export const ModalAuthenticate: React.FC = () => {
  const { currentUser, authRequired, phoneNumber, refetchCurrentUser, setPhoneNumber, signIn } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const { closeModal, openModals } = useModalStore();
  const modalTypes: ModalType[] = [MODAL_TYPE];

  const pendingSmsFactor = currentUser?.factors.find(
    (factor) => !factor.factorVerified && factor.factorType === FactorTypeResponse.SMS
  );
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (openModals.includes(MODAL_TYPE)) {
      setCode('');
    }
  }, [openModals]);

  const { handleReCaptcha } = reCaptchaCheck();

  const handleResend = (event: React.MouseEvent) => {
    event.preventDefault();

    const { factorId } = pendingSmsFactor || {};
    if (!factorId) {
      return;
    }

    UserRepository.resendFactorVerification({ factorId })
      .then(() => {
        log({
          name: 'User MFA resent',
          context: {
            phoneNumber,
          },
        });

        addSuccessNotification({
          content: 'Code resent',
          display: 'popup',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  const handleSubmit = () => {
    if (!currentUser) {
      return;
    }

    setLoading(true);

    handleReCaptcha(
      (reCaptchaToken) =>
        currentUser
          .verifyMfa(reCaptchaToken, { factorId: pendingSmsFactor?.factorId, code })
          .then(() => {
            if (authRequired()) {
              setPhoneNumber(undefined);
              signIn()
                .then(() => {
                  closeModal();
                  setLoading(false);
                })
                .catch((error) => {
                  addDangerNotification({
                    content: error.message,
                    display: 'popup',
                  });
                });
              return;
            }
            log({
              name: 'User enabled MFA',
              context: currentUser,
            });
            addSuccessNotification({
              content: 'Two-factor authentication enabled',
            });
            refetchCurrentUser();
            closeModal();
            setCode('');
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            addDangerNotification({
              content: error.message,
              display: 'popup',
            });
          }),
      (err) => {
        addDangerNotification({
          content: err.message,
          display: 'popup',
        });
      }
    );
  };

  useEffect(() => {
    if (code && code.length === 6) {
      handleSubmit();
    }
  }, [code]);

  return (
    <Wrapper modalTypes={modalTypes}>
      <ModalIconCircle>
        <Icon.Phone />
      </ModalIconCircle>
      <ModalText>
        <Headline size="small" fullWidth>
          Authenticate
        </Headline>
        <Paragraph>Please enter the 6-digit code we have sent to your phone.</Paragraph>
      </ModalText>
      <NotificationList display="popup" />
      <CodeInput
        autoFocus
        placeholder="######"
        type="number"
        value={code}
        onChange={(value: string) => setCode(value)}
        isLoading={loading}
      />
      <StyledHint>
        Haven’t received the code? <Resend onClick={handleResend}>Resend</Resend>
      </StyledHint>
    </Wrapper>
  );
};
