import { TooltipProps } from '@column/column-ui-kit';

import { TransferStatus } from '~/lib/transfers/types';
import { formatString } from '~/util';

export const formatTransferStatus = (status: TransferStatus): string => {
  if (status.toLowerCase() === 'pre_review') {
    return 'Pending Approval';
  }
  return formatString(status);
};

export const getTransferStatusTooltip = (status: TransferStatus): TooltipProps | undefined => {
  if (transferStatusTooltips.has(status)) {
    return {
      content: transferStatusTooltips.get(status),
      delay: 200,
      triggerClick: false,
    };
  }
  return undefined;
};

const transferStatusTooltips = new Map<TransferStatus, string>([
  [TransferStatus.CANCELED, 'The transaction was canceled, and no funds were moved.'],
  [
    TransferStatus.COMPLETED,
    'The transaction has been fully processed and the funds have successfully reached the intended destination.',
  ],
  [TransferStatus.DEPOSITED, 'Funds have been deposited into the account as scheduled.'],
  [TransferStatus.FAILED, 'The transaction could not be processed due to an error or rejection.'],
  [
    TransferStatus.FIRST_RETURN,
    'The first attempt to process the transaction was returned, possibly due to issues like NSF (Non-Sufficient Funds).',
  ],
  [TransferStatus.HOLD, 'Funds or the transaction are currently on hold, pending further verification or action.'],
  [TransferStatus.INITIATED, 'The transaction has been initiated and is pending completion of processing steps.'],
  [TransferStatus.ISSUED, 'A check or other payment form has been issued but not yet cashed or deposited.'],
  [
    TransferStatus.MANUAL_REVIEW,
    'The transaction is undergoing manual review to verify its details or source before proceeding.',
  ],
  [TransferStatus.MANUAL_REVIEW_APPROVED, 'The transaction has passed manual review and is approved for processing.'],
  [TransferStatus.PENDING_DEPOSIT, 'The deposit has been initiated but is not yet complete.'],
  [
    TransferStatus.PENDING_FIRST_RETURN,
    'A return has been initiated on the first attempt of processing the transaction, awaiting finalization.',
  ],
  [TransferStatus.PENDING_RECLEAR, 'The transaction is undergoing an attempt to clear after an initial failure.'],
  [TransferStatus.PENDING_RETURN, 'A return on the transaction has been initiated and is currently being processed.'],
  [TransferStatus.PENDING_SECOND_RETURN, 'The second attempt to return the transaction is underway.'],
  [TransferStatus.PENDING_STOP, 'A stop payment request has been submitted and is pending action.'],
  [TransferStatus.PENDING_SUBMISSION, 'The transaction is prepared and pending submission to the clearing system.'],
  [TransferStatus.PENDING_USER_INITIATED_RETURN, 'A return initiated by the user is currently being processed.'],
  [TransferStatus.PRE_REVIEW, 'The transaction is pending approval and has not yet been initiated.'],
  [TransferStatus.RECLEARED, 'The transaction has been cleared again following a previous clearance issue.'],
  [
    TransferStatus.REJECTED,
    'The transaction was rejected by the receiving party or due to an issue with the transaction details.',
  ],
  [TransferStatus.RETURN_CONTESTED, 'The dishonored return of the transaction has been rejected by the receiving bank'],
  [TransferStatus.RETURN_DISHONORED, 'the return was not accepted or honored by the originating bank.'],
  [TransferStatus.RETURNED, 'The transaction was returned by the receiving party or due to a failure in processing.'],
  [TransferStatus.SCHEDULED, 'The transaction is scheduled for a future date and has not yet been processed.'],
  [TransferStatus.SECOND_RETURN, 'The transaction has been returned for a second time, indicating persistent issues.'],
  [TransferStatus.SETTLED, 'Funds movement is complete.'],
  [TransferStatus.STOPPED, 'The transaction has been stopped, typically upon the request of the sender.'],
  [
    TransferStatus.SUBMITTED,
    'The transaction has been submitted to the processing network and is currently being processed.',
  ],
  [TransferStatus.USER_INITIATED_RETURNED, 'A return initiated by the user has been processed and completed.'],
  [
    TransferStatus.USER_INITIATED_RETURN_SUBMITTED,
    'A return initiated by the user has been submitted and is pending processing.',
  ],
  [
    TransferStatus.USER_INITIATED_RETURN_DISHONORED,
    'A user-initiated return was not honored, typically due to procedural errors or disputes.',
  ],
]);
