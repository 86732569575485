import { Counterparty } from '~/repositories';
import { AccountNumber, BankAccount } from '~/repositories/BankAccountRepository';
import { ApiAddress, TransferReview } from '~/typings/API';

export enum TimelineStatus {
  Success = 'success',
  Danger = 'danger',
  InProgress = 'in-progress',
  Pending = 'pending',
  Warning = 'warning',
  Returned = 'returned',
}

export enum TimelinePartyType {
  Sender = 'sender',
  Receiver = 'receiver',
}

export interface TimelineEntry {
  label: string;
  amount?: TransferAmount;
  isAdding?: boolean;
  status?: TimelineStatus;
  link?: {
    label: string;
    path: string;
  };
  date?: Date | string;
  partyData?: TransferParty;
  returnData?: TransferReturn;
  reviewData?: TransferReview[];
}

export interface TransferAmount {
  value: number;
  isAdding?: boolean;
}

export interface TransferParty {
  data: TransferBankAccount | TransferCounterparty | TransferCompany | TransferCheckPayee;
  partyType: TimelinePartyType;
  metadata?: string;
}

export interface TransferBankAccount {
  bankAccount: BankAccount;
  accountNumber: AccountNumber;
  entityType?: 'person' | 'business';
}

export interface TransferCounterparty {
  counterparty: Counterparty;
}

export interface TransferCompany {
  companyId: string;
  companyName: string;
}

export interface TransferCheckPayee {
  payeeName: string;
  deliveredByColumn: boolean;
  payeeAddress: ApiAddress;
}

export type TransferReturn = {
  addenda?: string;
  code: string;
  description: string;
} & {
  reason: string;
  description?: string;
};
