import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { Button, Dropdown, Modal, ModalSize } from '@column/column-ui-kit';

import { RealtimeRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { FormElement, FormLabel } from '~/styles';
import type { ReturnRealtimeTransfer } from '~/typings/enum';

interface RealtimeReturnModalProps {
  transferId: string;
  isOpen: boolean;
  onClose: () => void;
}

const StyledFormElement = styled(FormElement)`
  text-align: left;
`;

const returnReasons: { label: string; value: ReturnRealtimeTransfer['reason'] }[] = [
  { label: 'Incorrect Beneficiary Account', value: 'incorrect_beneficiary_account' },
  { label: 'Beneficiary Account Blocked', value: 'beneficiary_account_blocked' },
  { label: 'Incorrect Amount', value: 'incorrect_amount' },
  { label: 'Beneficiary Mismatch', value: 'beneficiary_mismatch' },
  { label: 'Refused by Beneficiary', value: 'refused_by_beneficiary' },
  { label: 'Requested by Originator', value: 'requested_by_originator' },
  { label: 'Fraud', value: 'fraud' },
  { label: 'Compliance Rejected', value: 'compliance_rejected' },
];

export const RealtimeReturnModal: FC<RealtimeReturnModalProps> = ({ isOpen, onClose, transferId }) => {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<ReturnRealtimeTransfer['reason']>();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();

  const handleSubmit = () => {
    if (!reason) return;

    setIsButtonLoading(true);
    RealtimeRepository.createReturn(transferId, { reason: reason })
      .then(() => {
        onClose();
        addSuccessNotification({ content: 'Return initiated' });
      })
      .catch((error: Error) => {
        addDangerNotification({ content: error.message, display: 'popup' });
      })
      .finally(() => setIsButtonLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={ModalSize.Small} withClose={false}>
      <Modal.Header title="Return Transfer">
        <Modal.Close variant="subtle" size="small" onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <StyledFormElement>
          <FormLabel>Reason for return</FormLabel>
          <Dropdown
            options={returnReasons}
            active={reason}
            onChange={(value) => setReason(value as ReturnRealtimeTransfer['reason'])}
            fullWidth
            variant="secondary"
          />
        </StyledFormElement>
      </Modal.Content>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} isLoading={isButtonLoading} variant="danger" isDisabled={!reason}>
          Return
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
