import { EntityList, EntityListParams, EntityRepository, EntityWithDetails } from '~/repositories/EntityRepository';

import { createApiHook } from './utils/createApiHook';

export const useEntities = createApiHook<EntityList, EntityListParams>(EntityRepository.getAll, {
  triggerAutomatically: true,
  triggerOnSessionStoreSubscribe: true,
  includeQueryParams: true,
});

export const useEntity = createApiHook<EntityWithDetails, GetRequestType>(EntityRepository.get);
