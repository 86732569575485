import { TimelineEntry, TimelineStatus } from '~/app/pages/Transfers/_components/Timeline';
import { TransferCompany } from '~/app/pages/Transfers/_components/Timeline/types';
import { buildTransferParty, parseDate } from '~/app/pages/Transfers/_utils/timeline';
import { TransferStatus } from '~/lib/transfers';
import { AchReturn, AchTransfer, Counterparty } from '~/repositories';
import { AccountNumber, BankAccount } from '~/repositories/BankAccountRepository';
import { formatString } from '~/util';

const achEvents = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Submitted',
    field: 'submittedAt',
  },
  {
    label: 'Acknowledged',
    field: 'acknowledgedAt',
  },
  {
    label: 'Processed',
    field: 'processedAt',
  },
  {
    label: 'Settled',
    field: 'settledAt',
    status: TimelineStatus.Success,
  },
  {
    label: 'Rejected',
    field: 'rejectedAt',
    status: TimelineStatus.Danger,
  },
  {
    label: 'Cancelled',
    field: 'cancelledAt',
    status: TimelineStatus.Danger,
  },
  {
    label: 'Returned',
    field: 'returnedAt',
    status: TimelineStatus.Returned,
  },
  {
    label: 'Completed',
    field: 'completedAt',
    status: TimelineStatus.Success,
  },
];

export const buildAchEvents = ({
  transfer,
  account,
  counterparty,
  company,
}: {
  transfer: AchTransfer;
  account: {
    accountNumber: AccountNumber;
    bankAccount: BankAccount;
    entityType?: 'person' | 'business';
  };
  counterparty?: Counterparty;
  company?: TransferCompany;
}): TimelineEntry[] => {
  const rejected = (transfer.status.toLowerCase() as TransferStatus) === TransferStatus.REJECTED;
  return achEvents
    .filter(
      (event) =>
        (event.field === 'createdAt' ||
          (!transfer.returnedAt && event.field === 'settledAt') ||
          !!transfer[event.field as keyof typeof transfer]) &&
        event.field !== 'returnedAt'
    )
    .map((event) => {
      const date = parseDate(
        transfer[event.field as keyof typeof transfer] as string | undefined,
        rejected ? 'Cancelled' : 'Pending'
      );

      let amount;
      if (transfer.amount) {
        amount = {
          value: transfer.amount,
          isAdding: event.field === 'completedAt' || event.field === 'settledAt',
        };
      }

      const party =
        (event.field === 'createdAt' ||
          (event.field === 'completedAt' && transfer.settledAt) ||
          (event.field === 'settledAt' && !transfer.completedAt)) &&
        (counterparty || company || account)
          ? buildTransferParty({
              account: {
                ...account,
                transferType: transfer.type,
              },
              counterparty,
              company,
              isIncoming: transfer.type === 'CREDIT' ? transfer.isIncoming : !transfer.isIncoming,
              isOriginator: event.field === 'createdAt',
            })
          : undefined;

      const defaultStatus = rejected ? TimelineStatus.Danger : TimelineStatus.Pending;
      const status = !!transfer[event.field as keyof typeof transfer] ? event.status : defaultStatus;

      return {
        label: event.label,
        amount,
        date,
        partyData: party,
        status,
      };
    });
};

export const buildAchReturnEvents = ({ achReturn }: { achReturn?: DeepPartial<AchReturn> }): TimelineEntry[] => {
  if (!achReturn?.details) return [];

  return achReturn.details.map(
    (detail) =>
      ({
        label: `Return ${formatString(detail.status)}`,
        date: new Date(detail.createdAt),
        status: detail.status === 'INITIATED' ? TimelineStatus.Returned : TimelineStatus.Success,
        returnData: {
          addenda: detail.addenda,
          code: detail.returnCode,
          description: detail.description,
        },
      }) as TimelineEntry
  );
};
