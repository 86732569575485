import { createApiHook } from '~/hooks/utils/createApiHook';
import {
  CreateTransferTemplateRequest,
  CreateTransferTemplateResponse,
  DeleteTransferTemplateRequest,
  DeleteTransferTemplateResponse,
  GetTransferTemplateRequest,
  GetTransferTemplateResponse,
  ListTransferTemplateRequest,
  ListTransferTemplateResponse,
  TransferTemplateRepository,
  UpdateTransferTemplateRequest,
  UpdateTransferTemplateResponse,
} from '~/repositories/Transfer/TransferTemplateRepository';

type TransferTemplateParams = ListTransferTemplateRequest & {
  page?: number;
};

export const useCreateTransferTemplate = createApiHook<CreateTransferTemplateResponse, CreateTransferTemplateRequest>(
  TransferTemplateRepository.create,
  {
    triggerAutomatically: false,
  }
);

export const useDeleteTransferTemplate = createApiHook<DeleteTransferTemplateResponse, DeleteTransferTemplateRequest>(
  TransferTemplateRepository.delete,
  {
    triggerAutomatically: false,
  }
);

export const useGetTransferTemplate = createApiHook<GetTransferTemplateResponse, GetTransferTemplateRequest>(
  TransferTemplateRepository.get,
  {
    triggerAutomatically: false,
  }
);

export const useListTransferTemplates = createApiHook<ListTransferTemplateResponse, TransferTemplateParams>(
  TransferTemplateRepository.list,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
    includeQueryParams: true,
    addQueryParamsToUrl: true,
    excludeQueryParams: ['page'],
  }
);

export const useUpdateTransferTemplate = createApiHook<UpdateTransferTemplateResponse, UpdateTransferTemplateRequest>(
  TransferTemplateRepository.update,
  {
    triggerAutomatically: false,
  }
);
