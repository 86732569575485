import * as React from 'react';
import { useMemo } from 'react';

import { Chip } from '@column/column-ui-kit';

import { getProgressFromTransferStatus, getTransferProgressIcon } from '../progress';
import { formatTransferStatus, getTransferStatusTooltip } from '../status';
import { TransferProgress, TransferStatus, parseTransferStatus } from '../types';

interface TransferStatusChipProps {
  status: string | TransferStatus;
  isReturn?: boolean;
  isIncoming?: boolean;
  returnPairTransferId?: string;
}

export const TransferStatusChip: React.FC<TransferStatusChipProps> = ({ status, isReturn }) => {
  const transferStatus = useMemo(() => parseTransferStatus(status), [status]);
  const transferProgress = useMemo(() => getProgressFromTransferStatus(transferStatus), [transferStatus]);
  const chipType = useMemo(() => getChipType(transferProgress, transferStatus), [transferProgress, transferStatus]);

  return (
    <Chip
      icon={getTransferProgressIcon(transferProgress)}
      tooltip={getTransferStatusTooltip(transferStatus)}
      type={chipType}
    >
      {isReturn ? `Return ${formatTransferStatus(transferStatus)}` : formatTransferStatus(transferStatus)}
    </Chip>
  );
};

const getChipType = (progress: TransferProgress, status: TransferStatus) => {
  switch (progress) {
    case TransferProgress.PROGRESS_FAILED:
      return 'danger';
    case TransferProgress.PROGRESS_100:
      return 'success';
  }

  switch (status) {
    case TransferStatus.BLOCKED:
    case TransferStatus.REJECTED:
    case TransferStatus.RETURNED:
    case TransferStatus.STOPPED:
      return 'danger';
    case TransferStatus.DEPOSITED:
    case TransferStatus.COMPLETED:
      return 'success';
    case TransferStatus.HOLD:
      return 'info';
  }

  return 'default';
};
