import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Fade, Icon } from '@column/column-ui-kit';

import { ModalType, useModalStore } from '~/stores/Modal';

const Wrapper = styled(Fade)`
  --animation-enter-y: 0;
  --animation-exit-y: 0;

  --modal-width: fit-content;
  --modal-height: fit-content;
  z-index: 14;
  position: fixed;
  inset: 0;
  overflow: hidden;
`;

const Scroll = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
  display: grid;
  place-items: center;
`;

const Backdrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0 0 0 / 60%);
  background-color: rgba(0 0 0 / 40%);
`;

const Modal = styled.div`
  background-color: ${({ theme }) => theme.background};
  box-shadow:
    0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(1100, 150)},
    ${({ theme }) => theme.style.floatingShadowXL};
  border-radius: 12px;
  width: var(--modal-width);
  height: var(--modal-height);
  position: relative;
  z-index: 2;
  margin: 24px auto;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      box-shadow:
        0 0 0 1px ${theme.secondary.blendToBackground(150)},
        ${({}) => theme.style.floatingShadowXL};
    `}
`;

const Close = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 8px;
  top: 8px;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  z-index: 1;
  transition: background-color 0.2s;

  svg {
    --icon-size: 20px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(750)};

    path {
      transition: stroke 0.2s;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(1000, 100)};
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(1000)};
    }
  }
`;

export interface ModalProps {
  modalTypes: ModalType[];
  className?: string;
  withClose?: boolean;
}

export const ModalBase: React.FC<PropsWithChildren<ModalProps>> = (props) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const { closeModal, getModalIsOpen, getModal } = useModalStore();

  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };
    window.addEventListener('keydown', keyDownListener);
    return () => window.removeEventListener('keydown', keyDownListener);
  }, []);

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => ({
          getModalState: state.getModal(),
          getModalIsOpenState: state.getModalIsOpen(),
        }),
        ({ getModalState, getModalIsOpenState }) => {
          setIsHidden(!getModalIsOpenState || !props.modalTypes.includes(getModalState!));
        }
      ),
    []
  );

  useEffect(() => {
    setIsHidden(!getModalIsOpen() || !props.modalTypes.includes(getModal()!));
  }, [props.modalTypes]);

  return (
    <Wrapper show={!isHidden} timeoutEnter={0}>
      <Backdrop onClick={() => (props.withClose ?? true) && closeModal()} />
      <Scroll>
        <Modal onClick={(e: React.MouseEvent) => e.stopPropagation()} className={props.className}>
          {(props.withClose ?? true) && (
            <Close onClick={() => closeModal()}>
              <Icon.Close />
            </Close>
          )}
          {props.children}
        </Modal>
      </Scroll>
    </Wrapper>
  );
};
