import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface CheckJamIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const CheckJam: FC<CheckJamIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={48}>
        <g strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor">
          <path d="M28.2617 14V22.16" />
          <path d="M19.74 25.8399L11.23 27.8199C10.6 27.9699 10 27.4899 10 26.8499V16.7199C10 16.0199 10.48 15.4199 11.16 15.2599L24 12.2799C26.35 11.7299 28.26 12.5099 28.26 14.0099C28.26 15.5099 25.85 16.6199 24 17.7199C22.15 18.8199 19.74 19.9299 19.74 21.4299C19.74 22.9299 21.65 23.7099 24 23.1599L36.77 20.1899C37.4 20.0399 38 20.5199 38 21.1599V31.2899C38 31.9899 37.52 32.5899 36.84 32.7499L24 35.7299C21.65 36.2799 19.74 35.4999 19.74 33.9999" />
          <path d="M19.7383 21.4199V33.9999" />
          <path d="M32.25 11.8799L34.61 8.41992" />
          <path d="M13.3984 39.5801L15.7584 36.1201" />
          <path d="M35.8984 15.4305L39.2984 12.9805" />
          <path d="M8.71094 35.0203L12.1109 32.5703" />
        </g>
      </IconBase>
    </Wrapper>
  );
};
