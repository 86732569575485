import { gsap } from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { NotificationList } from '~/components';
import { useNavigate } from '~/lib/navigation';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline, Paragraph } from '~/styles';
import { log } from '~/util';

import { AdditionalLogo, HiddenLogo, LogoWrapper, StyledPanel, animateLogo } from './Login';

const Text = styled.div`
  display: grid;
  grid-gap: 8px;
`;

interface Params {
  code: string;
}

export const PageVerify: React.FC = () => {
  const addDangerNotification = useNotificationStore((state) => state.addDangerNotification);
  const logoWrapperRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<SVGSVGElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { code } = useParams<keyof Params>() as Params;

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.currentUser,
        (currentUser) => {
          if (!currentUser) {
            return;
          }

          currentUser
            .verifyEmail(code)
            .then(() => {
              log({
                name: 'User email verified',
                context: {
                  email: currentUser.email,
                },
              });
            })
            .catch((error) => {
              log({
                name: 'User email verification failed',
                context: {
                  error,
                },
              });

              addDangerNotification({
                content: 'Verification, failed. Please try again.',
                display: 'page-verify',
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        {
          fireImmediately: true,
        }
      ),
    [code]
  );

  const handleRedirect = () => {
    if (!logoRef.current || !logoWrapperRef.current || !panelRef.current) {
      return;
    }

    animateLogo(logoRef.current, logoWrapperRef.current, panelRef.current, gsap, MotionPathPlugin, () =>
      navigate(ROUTE.ROOT, {
        state: { from: 'auth' },
      })
    );
  };

  return (
    <>
      <LogoWrapper ref={logoWrapperRef}>
        <AdditionalLogo size="24px" variant="full" ref={logoRef} />
      </LogoWrapper>
      <StyledPanel ref={panelRef}>
        <HiddenLogo size="24px" />
        <Text>
          <div>
            <NotificationList display="page-verify" />
            <Headline fullWidth size="small">
              Verified!
            </Headline>
          </div>
          <Paragraph>Email address successfully verified.</Paragraph>
        </Text>
        <Button onClick={handleRedirect} isLoading={isLoading}>
          Back to Dashboard
        </Button>
      </StyledPanel>
    </>
  );
};
