import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Icon, Toggle } from '@column/column-ui-kit';

import { Details } from '~/app/pages/Transfers/_components/Details';
import { TransferPageHeader } from '~/app/pages/Transfers/_components/Layout';
import { ROUTE } from '~/app/routes';
import { CopyInput, Loading, RenderFields } from '~/components';
import { useBankAccount, useCounterparty, useEntity } from '~/hooks';
import { useTransfer } from '~/hooks/useTransfers';
import { useNavigate } from '~/lib/navigation';
import { TransferStatus } from '~/lib/transfers';
import { RealtimeTransfer, convertTransferAmount } from '~/repositories';
import { BankAccountRepository } from '~/repositories/BankAccountRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';

import { RealtimeReturnModal } from './_components/RealtimeReturnModal';
import { Timeline } from './_components/Timeline';
import { buildRealtimeEvents, isReturnedTransfer, isReturningTransfer } from './_utils/realtime';

interface Params {
  id: string;
}

const InfoForm = styled.form`
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

export const PageTransfersRealtime: FC = () => {
  const { id } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();
  const { isAuthorized } = useSessionStore();
  const { addDangerNotification } = useNotificationStore();
  const [accountNumber, setAccountNumber] = useState<any>({});
  const [isAccountNumberLoading, setIsAccountNumberLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);

  const { response: bankAccount, createRequest: fetchBankAccount } = useBankAccount({
    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const { response: entity, createRequest: fetchEntity } = useEntity({
    onSuccess: () => setIsLoadingEntity(false),
    onError: (error) => {
      setIsLoadingEntity(false);
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });

  const {
    response: t,
    isLoading,
    createRequest: fetchTransfer,
  } = useTransfer<RealtimeTransfer>('realtime')({
    initialParams: { id },
    onSuccess: async (response) => {
      fetchAccountNumber(response.accountNumberId);

      try {
        if (response.bankAccountId) {
          const currentBankAccount = await fetchBankAccount({ id: response.bankAccountId });

          if (!currentBankAccount) {
            return;
          }

          if (isAuthorized({ permission: 'entities', permissionLevel: 'read' })) {
            fetchEntity({ id: currentBankAccount.owners?.[0] });
            setIsLoadingEntity(true);
          }
        }
      } catch (error) {
        addDangerNotification({ content: (error as Error).message });
      }

      if (response?.counterpartyId) {
        fetchCounterparty({ id: response.counterpartyId });
      }
    },

    onError: (error) => {
      addDangerNotification({ content: error.message });
      navigate(ROUTE.TRANSFERS);
    },
  });
  useEffect(() => {
    fetchTransfer({ id });
  }, [id]);
  const transfer = useMemo(() => {
    if (!t) return;
    const initiatedAt = t.initiatedAt ?? t.completedAt ?? undefined;
    return { ...t, initiatedAt };
  }, [t]);

  const { response: counterparty, createRequest: fetchCounterparty } = useCounterparty({
    onError: (error) => addDangerNotification({ content: error.message }),
  });

  const fetchAccountNumber = (accountNumberId?: string) => {
    if (!accountNumberId) {
      setIsAccountNumberLoading(false);
      return;
    }

    setIsAccountNumberLoading(true);
    BankAccountRepository.getAccountNumber({ id: accountNumberId })
      .then(setAccountNumber)
      .catch((error) => {
        addDangerNotification({ content: error.message });
      })
      .finally(() => setIsAccountNumberLoading(false));
  };

  const formMethods = useForm<RealtimeTransfer>();

  useEffect(() => {
    if (transfer) {
      formMethods.setValue('description', transfer.description || '-');
      formMethods.setValue('idempotencyKey', transfer.idempotencyKey || '-');
      formMethods.setValue('allowOverdraft', transfer.allowOverdraft || false);
    }
  }, [transfer]);

  const onReturn = useCallback(() => {
    setIsOpen(true);
  }, []);

  const isDataLoading = useMemo(() => {
    return isLoading || isAccountNumberLoading;
  }, [isLoading, isAccountNumberLoading]);

  const status = transfer?.returnPairTransferId && transfer?.isIncoming ? TransferStatus.RETURNED : transfer?.status;

  return (
    <>
      <RealtimeReturnModal transferId={transfer?.id ?? ''} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Loading isLoading={isDataLoading} fullHeight>
        {transfer && (
          <TransferPageHeader
            amount={transfer.amount!}
            createdAt={transfer.initiatedAt ?? undefined}
            isAdding={transfer ? convertTransferAmount('realtime', transfer.isIncoming ?? false) > 0 : undefined}
            isIncoming={transfer.isIncoming || false}
            isReturn={transfer && isReturningTransfer(transfer)}
            status={status}
            transferId={transfer.id!}
            transferType={'Realtime'}
          >
            {transfer && isReturnedTransfer(transfer) ? (
              <Button
                variant="muted"
                size="small"
                icon={<Icon.Return />}
                onClick={() => navigate(`${ROUTE.TRANSFERS}/edit/realtime/${transfer.returnPairTransferId}`)}
              >
                Returned
              </Button>
            ) : transfer && isReturningTransfer(transfer) ? (
              <Button
                variant="muted"
                size="small"
                icon={<Icon.Return />}
                onClick={() => navigate(`${ROUTE.TRANSFERS}/edit/realtime/${transfer.returnPairTransferId}`)}
              >
                Original Transfer
              </Button>
            ) : (
              transfer?.completedAt &&
              transfer?.isIncoming && (
                <Button variant="danger" size="small" onClick={onReturn}>
                  Return
                </Button>
              )
            )}
          </TransferPageHeader>
        )}
        {bankAccount && accountNumber && counterparty && transfer && !isLoadingEntity && (
          <Timeline
            entries={buildRealtimeEvents({
              transfer,
              account: {
                accountNumber,
                bankAccount,
                entityType: entity ? (entity.type.toLowerCase() as 'person' | 'business') : undefined,
              },
              counterparty,
            })}
          />
        )}
        <FormProvider {...formMethods}>
          <InfoForm autoComplete="off">
            {RenderFields<RealtimeTransfer>({
              sections: [
                {
                  headline: 'Information',
                  fields: [
                    {
                      id: 'description',
                      label: 'Description',
                      children: ({ value }) => {
                        return <CopyInput value={String(value)} />;
                      },
                    },
                    {
                      id: 'idempotencyKey',
                      label: 'Indempotency Key',
                      children: ({ value }) => {
                        return <CopyInput value={String(value) || ''} />;
                      },
                    },
                    {
                      id: 'allowOverdraft',
                      label: 'Allow Overdraft',
                      children: ({ value, onChange }) => {
                        const isChecked = typeof value === 'boolean' ? value : false;
                        return <Toggle isChecked={isChecked} onCheckedChange={onChange} isDisabled />;
                      },
                    },
                  ],
                },
              ],
            })}
            <Details
              formattedData={transfer as Record<string, unknown>}
              rawData={transfer as Record<string, unknown>}
            />
          </InfoForm>
        </FormProvider>
      </Loading>
    </>
  );
};
