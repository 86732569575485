import React, { useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Chip, Icon, formatNumber } from '@column/column-ui-kit';

import { CopyRow } from '~/app/pages/Transfers/_components/CopyRow';
import { ROUTE } from '~/app/routes';
import { Map } from '~/elements/Map';
import { useNavigate } from '~/lib/navigation';
import { returnCodesTooltipMap } from '~/repositories';
import { useSessionStore } from '~/stores/Session';
import { Card, Flex, Inner, Text } from '~/styles';
import { TransferReview } from '~/typings/API';
import { TransferReviewDecision } from '~/typings/enum';
import { formatString } from '~/util';

import { TimelineEventDetails } from './TimelineEventDetails';
import {
  TimelineStatus,
  TransferAmount,
  TransferBankAccount,
  TransferCheckPayee,
  TransferCompany,
  TransferCounterparty,
  TransferParty,
  TransferReturn,
} from './types';

interface TimelineEventProps {
  amount?: TransferAmount;
  date?: Date | string;
  link?: { label: string; path: string };
  label: string;
  status?: TimelineStatus;
  partyData?: TransferParty;
  returnData?: TransferReturn;
  reviewData?: TransferReview[];
}

const Avatar = styled.div<{ size?: 'small' | 'large'; status?: TimelineStatus }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${({ theme }) => theme.secondary.blendToBackground(50)};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};

  ${({ status }) =>
    status === TimelineStatus.Danger &&
    css`
      color: ${({ theme }) => theme.danger.background};
      background: ${({ theme }) => theme.danger.blendToBackground(1000, 100)};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.danger.blendToBackground(400)};
    `}

  ${({ status }) =>
    status === TimelineStatus.Success &&
    css`
      color: ${({ theme }) => theme.success.background};
      background: ${({ theme }) => theme.success.blendToBackground(1000, 150)};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.success.blendToBackground(400)};
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      width: 20px;
      height: 20px;

      border-radius: 6px;
      box-shadow: none;

      svg {
        --icon-size: 18px;
      }
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      width: 40px;
      height: 40px;
    `}
`;

const Event = styled.div<{ $isLast?: boolean }>`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 25% auto 25%;
  padding-bottom: 24px;
  align-items: start;
`;

const Status = styled.div<{ status?: TimelineStatus }>`
  min-width: 248px;
  position: relative;
  padding-top: 14px; // 16px - 2px to align with card text
  align-self: stretch;
  &:after {
    content: '';
    width: 2px;
    border-radius: 1px;
    background: ${({ theme }) => theme.secondary.blendToBackground(300)};
    position: absolute;
    top: 42px;
    bottom: -36px;
    left: 9px;
  }

  ${Event}:last-child &:after {
    display: none;
  }

  h4 {
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${({ theme, status }) => theme.secondary.blendToBackground(status === TimelineStatus.Pending ? 600 : 1000)};
  }

  p {
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme, status }) => theme.secondary.blendToBackground(status === TimelineStatus.Pending ? 400 : 800)};
    margin: 0 0 24px 28px;

    a {
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        --icon-color: ${({ theme }) => theme.secondary.background};
        opacity: 0;
        transform: translateX(-4px);
        transition:
          color 0.2s,
          transform 0.2s,
          opacity 0.2s;
      }

      &:hover {
        color: ${({ theme }) => theme.secondary.background};

        svg {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  svg {
    color: ${({ theme }) => theme.secondary.blendToBackground(600)};

    ${({ status }) =>
      status === TimelineStatus.Success &&
      css`
        color: ${({ theme }) => theme.success.background};
      `}

    ${({ status }) =>
      (status === TimelineStatus.Danger || status === TimelineStatus.Returned) &&
      css`
        color: ${({ theme }) => theme.danger.background};
      `}
  }
`;

export const TimelineEvent: React.FC<TimelineEventProps> = ({
  amount,
  date,
  label,
  link,
  status,
  partyData,
  returnData,
  reviewData,
}) => {
  const { isSandbox } = useSessionStore();
  const navigate = useNavigate();

  const eventDetails = useMemo(() => {
    if (!partyData) return;
    return buildTransferEventDetailsFromParty(partyData.data, !!isSandbox, navigate, partyData.metadata);
  }, [navigate, partyData]);

  const icon =
    status === TimelineStatus.Pending ? (
      <Icon.CircleBlank />
    ) : status === TimelineStatus.Danger ? (
      <Icon.CircleCross />
    ) : status === TimelineStatus.Warning ? (
      <Icon.CircleCheck />
    ) : status === TimelineStatus.Returned ? (
      <Icon.CircleReturn />
    ) : status === TimelineStatus.InProgress ? (
      <Icon.CirclePendingApproval />
    ) : (
      <Icon.CircleCheck />
    );

  return (
    <Event>
      <Status status={status || TimelineStatus.Success}>
        <Text as="h4" size={14} weight="medium" lineHeight="24px">
          {icon}
          {label}
        </Text>

        {date && (
          <Text as="p" size={12} color="lighter">
            {typeof date === 'string' ? (
              date
            ) : (
              <>
                {date.toLocaleString('en-US', { weekday: 'long' })}, {date.toLocaleString('en-US', { month: 'long' })}{' '}
                {date.getDate()}, {date.getFullYear()} <br />
                {date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
              </>
            )}
          </Text>
        )}

        {link && (
          <Text as="p" size={12} color="lighter">
            <a onClick={() => navigate(link.path)}>
              {link.label}
              <Icon.ChevronRight />
            </a>
          </Text>
        )}
      </Status>

      {returnData && (
        <Card variant="secondary">
          {returnData.code && (
            <CopyRow
              label="Return Code"
              value={
                <Inner p={16}>
                  <Flex flexDirection="column" gap={8}>
                    <Chip size="large" type="danger">
                      {returnData.code}
                    </Chip>
                    <Text size={12}>{returnCodesTooltipMap.get(returnData.code)}</Text>
                  </Flex>
                </Inner>
              }
            />
          )}

          {returnData.reason && (
            <CopyRow
              label="Return Reason"
              value={
                <Inner p={16}>
                  <Flex flexDirection="column" gap={8}>
                    <Chip size="large" type="danger">
                      {returnData.reason}
                    </Chip>
                    <Text size={12}>{returnCodesTooltipMap.get(returnData.reason)}</Text>
                  </Flex>
                </Inner>
              }
            />
          )}

          {returnData.description && <CopyRow label="Return Description" value={returnData.description || ''} />}

          {returnData.addenda && <CopyRow label="Return Addenda" value={returnData.addenda || ''} />}
        </Card>
      )}
      {amount && partyData && (
        <TimelineEventDetails
          details={eventDetails?.details || []}
          icon={eventDetails?.icon}
          status={status}
          onClick={eventDetails?.onClick || undefined}
          subtitle={`${amount.isAdding === false ? '-' : ''}${formatNumber(amount.value)}`}
          title={eventDetails?.name || 'Unnamed'}
          metadata={eventDetails?.metadata}
        />
      )}
      {reviewData &&
        reviewData.map((transferReview, idx) => (
          <TimelineEventDetails
            key={`transfer-review-${idx}`}
            details={transferReview.comment ? [{ label: 'Comment', value: transferReview.comment }] : undefined}
            icon={
              <Avatar
                size="small"
                status={
                  transferReview.decision === TransferReviewDecision.Approved
                    ? TimelineStatus.Success
                    : TimelineStatus.Danger
                }
              >
                {transferReview.decision === TransferReviewDecision.Approved ? <Icon.Checkmark /> : <Icon.Cross />}
              </Avatar>
            }
            title={formatString(transferReview.decision)}
            secondaryTitle={`by ${transferReview.reviewedBy.name}`}
            variant="secondary"
          />
        ))}
    </Event>
  );
};

const buildTransferEventDetailsFromParty = (
  party: TransferBankAccount | TransferCheckPayee | TransferCounterparty | TransferCompany,
  isSandbox: boolean,
  navigate: NavigateFunction,
  metadata?: string
):
  | {
      name: string;
      icon: React.ReactNode;
      details?: { label: string; value: string | React.ReactNode; warning?: string }[];
      onClick?: () => void;
      metadata?: string;
    }
  | undefined => {
  if ((party as TransferBankAccount).bankAccount) {
    return buildTransferEventDetailsFromBankAccount(party as TransferBankAccount, isSandbox, navigate, metadata);
  }
  if ((party as TransferCounterparty).counterparty) {
    return buildTransferEventDetailsFromCounterparty(party as TransferCounterparty, navigate);
  }
  if ((party as TransferCompany).companyName) {
    return buildTransferEventDetailsFromCompany(party as TransferCompany);
  }
  if ((party as TransferCheckPayee).payeeName) {
    return buildTransferEventDetailsFromCheckPayee(party as TransferCheckPayee);
  }
  return undefined;
};

const buildTransferEventDetailsFromBankAccount = (
  { accountNumber, bankAccount, entityType }: TransferBankAccount,
  isSandbox: boolean,
  navigate: NavigateFunction,
  metadata?: string
) => {
  let icon: React.ReactNode;
  if (entityType) {
    icon = entityType === 'person' ? <Icon.User /> : <Icon.Business />;
  } else {
    icon = <Icon.Bank />;
  }

  return {
    name: bankAccount.displayName || bankAccount.description || 'Unnamed Bank Account',
    onClick: () => navigate(`${ROUTE.BANK_ACCOUNTS}/edit/${bankAccount?.id}`),
    icon: <Avatar size="large">{icon}</Avatar>,
    details: [
      { label: 'Bank Account ID', value: bankAccount.id },
      { label: 'Routing Number', value: accountNumber.routingNumber || '' },
      {
        label: 'Account Number',
        value: accountNumber.accountNumber || '',
        warning: isSandbox ? 'Account numbers created in sandbox mode cannot be used to receive funds.' : undefined,
      },
    ],
    metadata,
  };
};

const buildTransferEventDetailsFromCheckPayee = ({
  payeeName,
  deliveredByColumn,
  payeeAddress,
}: TransferCheckPayee) => ({
  name: payeeName || 'Unnamed Payee',
  icon: (
    <Avatar size="large">
      <Icon.User />
    </Avatar>
  ),
  details: deliveredByColumn
    ? [
        {
          label: 'Mail To Address',
          value: (
            <Inner p={16}>
              <Text as="address" size={14} lineHeight="body" color="light">
                {payeeAddress.line1}
                <br />
                {payeeAddress.line2 && (
                  <>
                    {payeeAddress.line2}
                    <br />
                  </>
                )}
                {payeeAddress.city}, {payeeAddress.state} {payeeAddress.postalCode}{' '}
                {payeeAddress.countryCode && payeeAddress.countryCode === 'US' && 'United States'}
              </Text>
              <Map address={payeeAddress} width="100%" margin="16px 0 0" />
            </Inner>
          ),
        },
      ]
    : [],
  metadata: deliveredByColumn ? 'Mailed' : undefined,
});

const buildTransferEventDetailsFromCounterparty = (
  { counterparty }: TransferCounterparty,
  navigate: NavigateFunction
) => ({
  name: counterparty.name || counterparty.description || 'Unnamed Counterparty',
  onClick: () => navigate(`${ROUTE.COUNTERPARTIES}/edit/${counterparty?.id}`),
  icon: <Avatar size="large">{counterparty.legalType === 'individual' ? <Icon.User /> : <Icon.Business />}</Avatar>,
  details: [
    { label: 'Counterparty ID', value: counterparty.id || '' },
    { label: 'Routing Number', value: String(counterparty.routingNumber) },
    {
      label: 'Account Number',
      value: String(counterparty.accountNumber),
    },
  ],
  metadata: undefined,
});

const buildTransferEventDetailsFromCompany = ({ companyId, companyName }: TransferCompany) => ({
  name: companyName || 'Unnamed Company',
  icon: (
    <Avatar size="large">
      <Icon.Business />
    </Avatar>
  ),
  details: [{ label: 'Company ID', value: String(companyId) || '' }],
  metadata: undefined,
});
