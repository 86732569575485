import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon, Modal, ModalSize, formatNumber } from '@column/column-ui-kit';

import { usePinBankAccounts } from '../../BankAccounts/_hooks/usePinBankAccounts';
import { BankAccountWithDetails } from '~/typings/API';

const Item = styled.div<{ $isDragging: boolean; $showDivider: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 0 -8px;
  gap: 8px;
  user-select: none;
  transition:
    background-color 0.2s,
    box-shadow 0.2s,
    border-radius 0.1s;

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      z-index: 2;
      background-color: ${({ theme }) => theme.background};
      border-radius: ${({ theme }) => theme.style.borderRadiusM};
      box-shadow:
        inset 0 -0.5px 1.5px ${({ theme }) => theme.secondary.blendToBackground(150)},
        inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)},
        0 1px 2px ${({ theme }) => theme.secondary.blendToBackground(150)},
        0 3px 10px -2px ${({ theme }) => theme.secondary.blendToBackground(125)};
    `}

  ${({ $isDragging, $showDivider }) =>
    !$isDragging &&
    css`
      &:not(:last-child) {
        border-bottom: 1px solid
          ${({ theme }) =>
            $showDivider ? theme.primary.blendToBackground(600) : theme.secondary.blendToBackground(150)};
      }
    `}
`;

const DragHandle = styled.button<{ $isDragging: boolean }>`
  all: unset;
  cursor: grab;
  padding: 6px;
  flex-basis: 24px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  border-radius: ${({ theme }) => theme.style.borderRadiusS};
  transition:
    background-color 0.2s,
    color 0.2s;

  svg {
    --icon-size: 16px;
  }

  &:hover {
    background: ${({ theme }) => theme.secondary.blendToBackground(100)};
    color: ${({ theme }) => theme.foreground};
  }

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      background: ${({ theme }) => theme.primary.blendToBackground(100)};
      color: ${({ theme }) => theme.primary.background};
    `}
`;

const DragHandleIcon = styled(Icon.Drag)`
  --icon-size: 20px;
  --icon-color: currentColor;

  transition: color 0.2s;
`;

const AccountInfo = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AccountAmout = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.foreground};
`;

const AccountName = styled.div`
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
`;

const RemoveButton = styled.button`
  --pin-icon-fill-scale: 1;

  all: unset;
  cursor: pointer;
  display: table;
  position: relative;
  padding: 6px;
  border-radius: ${({ theme }) => theme.style.borderRadiusS};
  transition: background-color 0.2s;

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 1px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${({ theme }) => theme.primary.background};
    box-shadow: 0 1px 0 0 ${({ theme }) => theme.background};
    transition:
      box-shadow 0.2s,
      background-color 0.2s;
  }

  svg {
    --icon-size: 16px;
  }

  &:hover {
    background: ${({ theme }) => theme.secondary.blendToBackground(100)};

    &:before {
      box-shadow: 0 1px 0 0 ${({ theme }) => theme.secondary.blendToBackground(100)};
    }
  }
`;

interface SortableItemProps {
  account: BankAccountWithDetails;
  onRemove: (id: string) => void;
  showDivider: boolean;
}

export const SortableItem: FC<SortableItemProps> = ({ account, onRemove, showDivider }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: account.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Item ref={setNodeRef} style={style} $isDragging={isDragging} $showDivider={showDivider}>
      <DragHandle {...attributes} {...listeners} $isDragging={isDragging}>
        <DragHandleIcon />
      </DragHandle>

      <AccountName>{account.displayName || account.description}</AccountName>

      <AccountInfo>
        <AccountAmout>{formatNumber(account.balances.availableAmount)}</AccountAmout>

        <RemoveButton onClick={() => onRemove(account.id)}>
          <Icon.AnimationPin isActive />
        </RemoveButton>
      </AccountInfo>
    </Item>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -8px;
`;

const Empty = styled.div`
  padding: 12px 16px;
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

interface PinnedBankAccountsModalProps {
  isOpen: boolean;
  onClose: () => void;
  cardsPerView: number;
}

export const PinnedBankAccountsModal: FC<PinnedBankAccountsModalProps> = ({ isOpen, onClose, cardsPerView }) => {
  const [localPinnedAccounts, setLocalPinnedAccounts] = useState<BankAccountWithDetails[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const { pinnedAccounts, reorderAccounts } = usePinBankAccounts();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (pinnedAccounts.length === localPinnedAccounts.length) {
      return;
    }

    setLocalPinnedAccounts(pinnedAccounts);
  }, [isOpen]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!over || active.id === over.id) {
        return;
      }

      const oldIndex = localPinnedAccounts.findIndex((item) => item.id === active.id);
      const newIndex = localPinnedAccounts.findIndex((item) => item.id === over.id);

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const newPinnedAccounts = [...localPinnedAccounts];
      const [movedItem] = newPinnedAccounts.splice(oldIndex, 1);
      newPinnedAccounts.splice(newIndex, 0, movedItem);

      const updatedPinnedAccounts = newPinnedAccounts.map((account) => ({
        ...account,
        order: newIndex,
      }));

      setLocalPinnedAccounts(updatedPinnedAccounts);
    },
    [localPinnedAccounts]
  );

  const handleRemove = useCallback((id: string) => {
    setLocalPinnedAccounts((prev) => {
      const filtered = prev.filter((account) => account.id !== id);

      return filtered.map((account, index) => ({
        ...account,
        order: index,
      }));
    });

    setLocalPinnedAccounts((prev) => prev.filter((account) => account.id !== id));
  }, []);

  const onSave = useCallback(async () => {
    if (isButtonLoading) {
      return;
    }

    setIsButtonLoading(true);

    await reorderAccounts(
      localPinnedAccounts.map((account, index) => ({
        bankAccountId: account.id,
        order: index,
      }))
    );

    setIsButtonLoading(false);

    onClose();
  }, [localPinnedAccounts, onClose, isButtonLoading]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={ModalSize.Medium} withClose={false}>
      <Modal.Header title="Pinned Accounts">
        <Modal.Close variant="subtle" size="small" onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <List>
            <SortableContext
              items={localPinnedAccounts.map((account) => account.id)}
              strategy={verticalListSortingStrategy}
            >
              {localPinnedAccounts.map((account, index) => (
                <React.Fragment key={account.id}>
                  <SortableItem
                    account={account}
                    onRemove={handleRemove}
                    showDivider={(index + 1) % cardsPerView === 0 && index !== localPinnedAccounts.length - 1}
                  />
                </React.Fragment>
              ))}
              {localPinnedAccounts.length === 0 && <Empty>No Bank Accounts are pinned</Empty>}
            </SortableContext>
          </List>
        </DndContext>
      </Modal.Content>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} size="small">
          Cancel
        </Button>
        <Button onClick={onSave} isLoading={isButtonLoading} size="small">
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
