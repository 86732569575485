import { PlatformRepository } from '~/repositories/PlatformRepository';
import {
  Factor,
  PlatformSettings,
  SetupMFARequest,
  UserRepository,
  VerifyMFARequest,
} from '~/repositories/UserRepository';
import { useSessionStore } from '~/stores/Session';

import { BaseModel } from './BaseModel';

export type UserType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  emailState: string;
  factors: Factor[];
  dashboardDisplayMode: string;
  defaultPlatformId: string;
  platformSettings: PlatformSettings;
};

export type UpdateUser = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  defaultPlatformId?: string;
};

export class UserModel extends BaseModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  factors: Factor[];
  phoneNumber: string;
  emailState: string;
  dashboardDisplayMode: string;
  defaultPlatformId: string;
  platformSettings: PlatformSettings;

  constructor(attrs: UserType) {
    super();
    this.id = attrs.id;
    this.firstName = attrs.firstName;
    this.lastName = attrs.lastName;
    this.email = attrs.email;
    this.phoneNumber = attrs.phoneNumber;
    this.emailState = attrs.emailState;
    this.factors = attrs.factors;
    this.dashboardDisplayMode = attrs.dashboardDisplayMode;
    this.defaultPlatformId = attrs.defaultPlatformId;
    this.platformSettings = attrs.platformSettings;
  }

  get isEmailVerified() {
    return this.emailState === 'VERIFIED';
  }

  get isEmailPending() {
    return this.emailState === 'VERIFY_IN_PROGRESS';
  }

  get isMfaVerified() {
    const verifiedFactors = this.factors.filter((factor) => factor.factorVerified);
    return verifiedFactors.length > 0;
  }

  get getPlatformSettings() {
    return this.platformSettings;
  }

  async createVerifyEmail() {
    await UserRepository.createVerifyEmail();
    this.emailState = 'VERIFY_IN_PROGRESS';

    const sessionStore = useSessionStore.getState();
    sessionStore.setCurrentUser({ ...this });

    return this;
  }

  async verifyEmail(code: string) {
    return UserRepository.verifyEmail(code).then(() => {
      this.emailState = 'VERIFIED';
    });
  }

  async setupMfa(reCaptchaToken: string, request?: SetupMFARequest) {
    return UserRepository.setupMfa(reCaptchaToken, request);
  }

  async verifyMfa(reCaptchaToken: string, request: VerifyMFARequest) {
    return UserRepository.verifyMfa(reCaptchaToken, request);
  }

  async updatePlatformSettings(request: Partial<PlatformSettings>) {
    const pinnedBankAccounts = [
      ...new Map((request.pinnedBankAccounts ?? []).map((item) => [item.bankAccountId, item])).values(),
    ];

    this.platformSettings = { ...request, pinnedBankAccounts };

    return UserRepository.updatePlatformSettings(request);
  }

  async update(attrs: UpdateUser, reCaptchaToken: string) {
    if (attrs.email && this.email !== attrs.email) {
      setTimeout(() => {
        this.emailState = 'NOT_VERIFIED';
      }, 0);
    }
    if (attrs.firstName) {
      this.firstName = attrs.firstName;
    }
    if (attrs.lastName) {
      this.lastName = attrs.lastName;
    }
    if (attrs.email) {
      this.email = attrs.email;
    }
    if (attrs.phoneNumber) {
      this.phoneNumber = attrs.phoneNumber;
    }
    if (attrs.defaultPlatformId) {
      this.defaultPlatformId = attrs.defaultPlatformId;

      const platformRolesResponse = await UserRepository.platformRoles({ dashboardUserId: this.id });

      const platformRole = platformRolesResponse.platformRoles.find(
        (role) => this.id === role.dashboardUserId && this.defaultPlatformId === role.platformId
      );

      const platform = await PlatformRepository.get(this.defaultPlatformId);

      setTimeout(() => {
        useSessionStore.getState().setPlatform(platform, platformRole);
      }, 0);
    }
    return UserRepository.update({ dashboardUserId: this.id, ...attrs }, reCaptchaToken);
  }
}
