import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldPath, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import {
  Button,
  Chip,
  Dropdown,
  Icon,
  Input,
  List,
  ListRow,
  ListRowVariant,
  SegmentedControl,
  SegmentedControlSize,
} from '@column/column-ui-kit';

import {
  Access,
  Mode,
  OptionNoAccess,
  OptionsBankAccounts,
  OptionsEntities,
  OptionsOnOff,
  Permissions,
  TransfersAndAccountsFields,
} from '../types';
import { EntitiesAndAccounts } from '../useEntitiesAndAccounts';
import {
  BankAccountDefaultPermissions,
  BankAccountLevelPermissionsToLabelsMap,
  EntityLevelDefaultPermissions,
  EntityLevelPermissionsToLabelsMap,
  PlatformLevelPermissionsToLabelsMap,
  buildBankAccountOverridesFromPermissionLevel,
  buildBankAccountOverridesFromPermissions,
  buildEntityOverridesFromPermissionLevel,
  buildEntityOverridesFromPermissions,
  getAccessLevel,
  getBankAccountPermissions,
  getEntityPermissions,
  setBankAccountPermissionsFromPermissionLevel,
  setBankAccountPermissionsFromPermissions,
  setEntityPermissionsFromPermissionLevel,
  setEntityPermissionsFromPermissions,
} from '../utils';
import { AccessDropdown } from '~/app/pages/Platform/Roles/_components/AccessDropdown';
import { CustomOverridesModal } from '~/app/pages/Platform/Roles/_components/CustomOverridesModal';
import { PermissionToggles } from '~/app/pages/Platform/Roles/_components/PermissionToggles';
import { FormField, Loading } from '~/components';
import { useSessionStore } from '~/stores/Session';
import { Box, Divider, Hint, Inner, Line, ParagraphLight, SmallHeadline, TinyHeadlineLight } from '~/styles';
import {
  BankAccountWithDetails,
  CustomRoleBankAccountLevelOverrides,
  CustomRoleEntityLevelOverrides,
  CustomRolePermissionLevel,
  CustomRolePlatformLevelPermissions,
  EntityEntity,
} from '~/typings/API';

interface Props {
  liveEntitiesAndAccounts: EntitiesAndAccounts;
  sandboxEntitiesAndAccounts: EntitiesAndAccounts;
}

const DefaultPermissionsWrapper = styled.div<{ height?: number }>`
  overflow: hidden;
  transition: height 0.25s ease-in-out;
  height: ${({ height }) => height}px;
`;

const ClearOverridesButton = styled(Button)`
  padding: 0;
`;

const LoadMoreButton = styled(Button)`
  padding: 0;
`;

const BankAccountAndEntitiyFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  column-gap: 48px;
  row-gap: 24px;

  & > div {
    margin-right: auto;
    width: calc(50% - 24px);
    min-width: 500px;

    & > div {
      max-width: 300px;
    }
  }
`;

const HeaderActionsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const RowAction = styled.div`
  max-width: 200px;
`;

const RowDescription = styled(ParagraphLight)`
  padding: 1px 6px 0 6px;
`;

const RowTitle = styled.div`
  flex-grow: 1;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledHint = styled(Hint)`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  font-weight: 400;
`;

export const TransfersAndAccounts: React.FC<Props> = ({ liveEntitiesAndAccounts, sandboxEntitiesAndAccounts }) => {
  const { currentPlatform } = useSessionStore();
  const { control, setValue } = useFormContext();
  const [bankAccountSearch, setBankAccountSearch] = useState<string>('');
  const [mode, setMode] = useState<Mode>(currentPlatform?.isLiveEnabled ? Mode.Live : Mode.Sandbox);
  const enableTransferApprovals = false;

  useEffect(() => {
    if (!bankAccountSearch) return;
    if (mode === Mode.Sandbox) {
      sandboxEntitiesAndAccounts.bankAccounts.search(bankAccountSearch);
    } else {
      liveEntitiesAndAccounts.bankAccounts.search(bankAccountSearch);
    }
  }, [bankAccountSearch, mode]);
  const showLoadMore = bankAccountSearch === '';
  const isSearchLoading =
    mode === Mode.Sandbox
      ? sandboxEntitiesAndAccounts.bankAccounts.isSearchLoading
      : liveEntitiesAndAccounts.bankAccounts.isSearchLoading;

  const platformLevelPermissions = useWatch<TransfersAndAccountsFields>({
    name: 'platformLevelPermissions',
  }) as CustomRolePlatformLevelPermissions;
  const [customAccessSelected, setCustomAccessSelected] = useState<boolean>(false);
  const defaultAccess = useMemo(() => {
    if (customAccessSelected) {
      return Access.Custom;
    }
    if (
      Object.keys(PlatformLevelPermissionsToLabelsMap).every(
        (value) =>
          platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'default' ||
          platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'none'
      )
    ) {
      return Access.NoAccess;
    }
    if (
      Object.keys(PlatformLevelPermissionsToLabelsMap).every(
        (value) => platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'read'
      )
    ) {
      return Access.ViewOnly;
    }
    if (
      Object.keys(PlatformLevelPermissionsToLabelsMap).every(
        (value) => platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'write'
      )
    ) {
      return Access.FullAccess;
    }
    return Access.Custom;
  }, [customAccessSelected, platformLevelPermissions]);
  const showCustomPermissions = defaultAccess === Access.Custom;

  const setDefaultAccess = useCallback(
    (access: Access) => {
      let value: CustomRolePermissionLevel;
      switch (access) {
        case Access.Custom:
          setCustomAccessSelected(true);
          if (defaultAccess === Access.NoAccess) {
            value = 'read';
            break;
          }
          return;
        case Access.FullAccess:
          setCustomAccessSelected(false);
          value = 'write';
          break;
        case Access.ViewOnly:
          setCustomAccessSelected(false);
          value = 'read';
          break;
        case Access.NoAccess:
          setCustomAccessSelected(false);
          value = 'none';
          break;
        default:
          value = 'default';
          return;
      }
      Object.keys(PlatformLevelPermissionsToLabelsMap).forEach((permission) => {
        setValue(`platformLevelPermissions.${permission}` as FieldPath<TransfersAndAccountsFields>, value);
      });
      setValue('platformLevelPermissions.bankAccounts', value);
      setValue('platformLevelPermissions.entities', value);
    },
    [defaultAccess, platformLevelPermissions, setValue]
  );

  const [defaultAccessBoxHeight, setDefaultAccessBoxHeight] = useState<number>();
  const defaultAccountPermissionsWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!defaultAccountPermissionsWrapperRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (!defaultAccountPermissionsWrapperRef.current) return;
      setDefaultAccessBoxHeight(defaultAccountPermissionsWrapperRef.current.scrollHeight);
    });
    resizeObserver.observe(defaultAccountPermissionsWrapperRef.current);
    return () => resizeObserver.disconnect();
  }, [showCustomPermissions]);

  return (
    <>
      <Inner>
        <Box variant={'secondary'}>
          <DefaultPermissionsWrapper height={defaultAccessBoxHeight}>
            <Inner ref={defaultAccountPermissionsWrapperRef}>
              <Inner p={0} pb={showCustomPermissions ? 24 : 0}>
                <RowWrapper>
                  <RowTitle>
                    <SmallHeadline>Default Account Permissions</SmallHeadline>
                    <StyledHint>Set the default permissions for new and existing accounts</StyledHint>
                  </RowTitle>
                  <RowAction>
                    <AccessDropdown onChange={setDefaultAccess} value={defaultAccess} />
                  </RowAction>
                </RowWrapper>
              </Inner>
              {showCustomPermissions && (
                <>
                  <Line />
                  <Inner px={0}>
                    <TinyHeadlineLight>Transfers</TinyHeadlineLight>
                  </Inner>
                  <Inner p={0} py={4}>
                    <PermissionToggles
                      control={control}
                      permissionsToLabelsMap={Object.fromEntries(
                        Object.entries(PlatformLevelPermissionsToLabelsMap).filter(
                          ([permission]) =>
                            permission !== 'bankAccounts' &&
                            permission !== 'entities' &&
                            permission !== 'approveTransfers'
                        )
                      )}
                      fieldPathPrefix="platformLevelPermissions"
                    />
                  </Inner>
                  <Inner px={0}>
                    <Divider fullWidth />
                  </Inner>
                  {enableTransferApprovals && (
                    <>
                      <Inner p={0} pb={12}>
                        <TinyHeadlineLight>Controls</TinyHeadlineLight>
                      </Inner>
                      <BankAccountAndEntitiyFieldWrapper>
                        <FormField<TransfersAndAccountsFields>
                          id="platformLevelPermissions.approveTransfers"
                          description="Set the default permissions for approving or rejecting transfers in review."
                          label="Approve Transfers"
                          compact
                        >
                          {({ value, onChange }) => {
                            const onOffValue = value === 'write' || value === 'read' ? 'on' : 'off';
                            const onOffOnChange = (newValue: 'on' | 'off') =>
                              newValue === 'on' ? onChange('write') : onChange('none');
                            return (
                              <Dropdown active={onOffValue} options={OptionsOnOff} onChange={onOffOnChange} fullWidth />
                            );
                          }}
                        </FormField>
                      </BankAccountAndEntitiyFieldWrapper>
                      <Inner px={0}>
                        <Divider fullWidth />
                      </Inner>
                    </>
                  )}
                  <Inner p={0} pb={12}>
                    <TinyHeadlineLight>Edit Access</TinyHeadlineLight>
                  </Inner>
                  <BankAccountAndEntitiyFieldWrapper>
                    <FormField<TransfersAndAccountsFields>
                      id="platformLevelPermissions.entities"
                      label="Entities"
                      description="Set the default permissions for new and existing entities."
                      compact
                    >
                      {({ value, onChange }) => {
                        const effectiveValue = value === 'default' ? 'none' : value;
                        return (
                          <Dropdown
                            active={effectiveValue}
                            options={effectiveValue === 'none' ? [OptionNoAccess, ...OptionsEntities] : OptionsEntities}
                            onChange={onChange}
                            fullWidth
                          />
                        );
                      }}
                    </FormField>
                    <FormField<TransfersAndAccountsFields>
                      id="platformLevelPermissions.bankAccounts"
                      label="Bank Accounts"
                      description="Set the default permissions for new and existing bank accounts."
                      compact
                    >
                      {({ value, onChange }) => {
                        const effectiveValue = value === 'default' ? 'none' : value;
                        return (
                          <Dropdown
                            active={effectiveValue}
                            options={
                              effectiveValue === 'none' ? [OptionNoAccess, ...OptionsBankAccounts] : OptionsBankAccounts
                            }
                            onChange={onChange}
                            fullWidth
                          />
                        );
                      }}
                    </FormField>
                  </BankAccountAndEntitiyFieldWrapper>
                </>
              )}
            </Inner>
          </DefaultPermissionsWrapper>
        </Box>
      </Inner>
      <Inner pt={0}>
        <RowWrapper>
          <RowTitle>
            <SmallHeadline>Account Overrides</SmallHeadline>
            <StyledHint>Override permissions on individual Accounts and Entities.</StyledHint>
          </RowTitle>
          <HeaderActionsWrapper>
            <Input
              icon={<Icon.Search />}
              onChange={setBankAccountSearch}
              placeholder="Search bank accounts"
              size={'small'}
              value={bankAccountSearch}
            />
            {currentPlatform?.isLiveEnabled && (
              <SegmentedControl
                active={mode}
                options={[
                  { label: 'Live', value: Mode.Live },
                  { label: 'Sandbox', value: Mode.Sandbox },
                ]}
                size={SegmentedControlSize.Small}
                onOptionChange={setMode}
              />
            )}
          </HeaderActionsWrapper>
        </RowWrapper>
      </Inner>
      <AccountOverridesList
        bankAccountSearch={bankAccountSearch}
        entitiesAndAccounts={mode === Mode.Sandbox ? sandboxEntitiesAndAccounts : liveEntitiesAndAccounts}
        showLoadMore={showLoadMore}
      />
      {<Loading isLoading={isSearchLoading} />}
    </>
  );
};

const AccountActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 200px;
  justify-content: space-between;
`;

const StyledFolderIcon = styled(Icon.Folder)`
  --icon-color: ${({ theme }) => theme.gray.blendToBackground(600)};
`;

const AccountOverridesList: React.FC<{
  bankAccountSearch: string;
  entitiesAndAccounts: EntitiesAndAccounts;
  showLoadMore: boolean;
}> = ({ bankAccountSearch, entitiesAndAccounts, showLoadMore }) => {
  const { getValues, watch } = useFormContext<TransfersAndAccountsFields>();
  const { replace: replaceBankAccountOverrides } = useFieldArray<TransfersAndAccountsFields>({
    name: 'bankAccountLevelOverrides',
  });
  const { replace: replaceEntityOverrides } = useFieldArray<TransfersAndAccountsFields>({
    name: 'entityLevelOverrides',
  });
  const bankAccountLevelOverrides = watch('bankAccountLevelOverrides', []);
  const entityLevelOverrides = watch('entityLevelOverrides', []);
  const hasOverrides = useMemo(() => {
    // Only check for overrides on the visible subset of entities and accounts (sandbox or live)
    let nonDefaultEntityOverridesInView = entityLevelOverrides.filter(
      (override: CustomRoleEntityLevelOverrides) =>
        entitiesAndAccounts.entities.data.findIndex((entity) => entity.id === override.entityId) !== -1
    );
    nonDefaultEntityOverridesInView = nonDefaultEntityOverridesInView
      .map((entity) => {
        return Object.fromEntries(Object.entries(entity).filter(([k, v]) => k === 'entityId' || v !== 'default'));
      })
      .filter((entity) => Object.keys(entity).length > 1);
    const nonDefaultBankAccountOverridesInView = bankAccountLevelOverrides
      .filter(
        (override: CustomRoleBankAccountLevelOverrides) =>
          entitiesAndAccounts.bankAccounts.data.findIndex(
            (bankAccount) => bankAccount.id === override.bankAccountId
          ) !== -1
      )
      .map((bankAccount) => {
        return Object.fromEntries(
          Object.entries(bankAccount).filter(([k, v]) => k === 'bankAccountId' || v !== 'default')
        );
      })
      .filter((bankAccount) => Object.keys(bankAccount).length > 1);
    return nonDefaultEntityOverridesInView.length + nonDefaultBankAccountOverridesInView.length > 0;
  }, [bankAccountLevelOverrides, entitiesAndAccounts, entityLevelOverrides]);
  const clearOverrides = useCallback(() => {
    // Only clear overrides for the visible subset of entities and accounts (sandbox or live)
    replaceBankAccountOverrides(
      getValues('bankAccountLevelOverrides').filter(
        (override: CustomRoleBankAccountLevelOverrides) =>
          entitiesAndAccounts.bankAccounts.data.findIndex(
            (bankAccount) => bankAccount.id === override.bankAccountId
          ) === -1
      )
    );
    replaceEntityOverrides(
      getValues('entityLevelOverrides').filter(
        (override: CustomRoleEntityLevelOverrides) =>
          entitiesAndAccounts.entities.data.findIndex((entity) => entity.id === override.entityId) === -1
      )
    );
  }, [entitiesAndAccounts, getValues, replaceBankAccountOverrides, replaceEntityOverrides]);
  const bankAccounts = entitiesAndAccounts.bankAccounts.data.filter((bankAccount) => {
    if (!bankAccountSearch) return true;
    return (
      (bankAccount.description && bankAccount.description.toLowerCase().includes(bankAccountSearch.toLowerCase())) ||
      (bankAccount.displayName && bankAccount.displayName.toLowerCase().includes(bankAccountSearch.toLowerCase()))
    );
  });
  const entities = entitiesAndAccounts.entities.data.filter((entity) => {
    if (!bankAccountSearch) return true;
    return bankAccounts.some((bankAccount) => bankAccount.owners.includes(entity.id as string));
  });
  const rootEntities = entities.filter((entity) => entity.isRoot);
  const nonRootEntities = entities.filter((entity) => !entity.isRoot);
  const entityIdToBankAccountsMap = useMemo(() => {
    const map = new Map<string, BankAccountWithDetails[]>();
    bankAccounts.forEach((bankAccount) => {
      bankAccount.owners.forEach((entityId) => {
        if (!map.has(entityId)) {
          map.set(entityId, []);
        }
        map.get(entityId)?.push(bankAccount);
      });
    });
    return map;
  }, [bankAccounts, entities]);

  return (
    <List>
      <ListRow
        content="Entities and accounts"
        rightDecoration={
          <>
            <AccountActionsWrapper>
              <ParagraphLight>Access</ParagraphLight>
              {hasOverrides && (
                <ClearOverridesButton
                  icon={<Icon.Reset />}
                  iconRight
                  size={'small'}
                  variant="subtle-primary"
                  onClick={() => clearOverrides()}
                >
                  Clear overrides
                </ClearOverridesButton>
              )}
            </AccountActionsWrapper>
          </>
        }
        variant={ListRowVariant.Header}
      />
      {rootEntities.map((entity) => {
        return (
          <EntityListSection
            key={entity.id}
            entity={entity}
            bankAccounts={entityIdToBankAccountsMap.get(entity.id as string)}
            hasMoreBankAccounts={entitiesAndAccounts.bankAccounts.paginationState[entity.id as string]?.hasMore}
            loadMoreBankAccounts={() => entitiesAndAccounts.bankAccounts.loadMore(entity.id as string)}
            showLoadMore={showLoadMore}
            rootEntity
          />
        );
      })}
      {nonRootEntities.map((entity) => {
        return (
          <EntityListSection
            key={entity.id}
            entity={entity}
            bankAccounts={entityIdToBankAccountsMap.get(entity.id as string)}
            hasMoreBankAccounts={entitiesAndAccounts.bankAccounts.paginationState[entity.id as string]?.hasMore}
            loadMoreBankAccounts={() => entitiesAndAccounts.bankAccounts.loadMore(entity.id as string)}
            showLoadMore={showLoadMore}
          />
        );
      })}
      {entitiesAndAccounts.entities.paginationState.hasMore && (
        <ListRow
          content={
            <LoadMoreButton onClick={() => entitiesAndAccounts.entities.loadMore()} size={'tiny'} variant={'subtle'}>
              Load More Entities
            </LoadMoreButton>
          }
        />
      )}
    </List>
  );
};

interface EntityListSectionProps {
  bankAccounts?: BankAccountWithDetails[];
  entity: EntityEntity;
  hasMoreBankAccounts?: boolean;
  loadMoreBankAccounts: () => void;
  rootEntity?: boolean;
  showLoadMore?: boolean;
}
const EntityListSection: React.FC<EntityListSectionProps> = ({
  bankAccounts,
  entity,
  hasMoreBankAccounts,
  loadMoreBankAccounts,
  rootEntity,
  showLoadMore,
}) => {
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState(false);
  const openOverridesModal = useCallback(() => setTimeout(() => setIsOverridesModalOpen(true), 150), []);
  const closeOverridesModal = useCallback(() => setIsOverridesModalOpen(false), []);
  const { setValue } = useFormContext<TransfersAndAccountsFields>();

  const platformLevelPermissions = useWatch<TransfersAndAccountsFields>({
    name: 'platformLevelPermissions',
  }) as CustomRolePlatformLevelPermissions;
  const { replace: replaceBankAccounts } = useFieldArray<TransfersAndAccountsFields>({
    name: 'bankAccountLevelOverrides',
  });
  const bankAccountLevelOverrides = useWatch<TransfersAndAccountsFields>({
    defaultValue: [],
    name: 'bankAccountLevelOverrides',
  }) as CustomRoleBankAccountLevelOverrides[];
  const { append: appendEntity, remove: removeEntity } = useFieldArray<TransfersAndAccountsFields>({
    name: 'entityLevelOverrides',
  });
  const entityLevelOverrides = useWatch<TransfersAndAccountsFields>({
    defaultValue: [],
    name: 'entityLevelOverrides',
  }) as CustomRoleEntityLevelOverrides[];

  const {
    entityIndex,
    actualPermissions: entityPermissions,
    effectivePermissions: entityEffectivePermissions,
    numOverrides,
  } = useMemo(
    () =>
      getEntityPermissions({
        entityId: entity.id as string,
        entityLevelOverrides,
        platformPermissions: platformLevelPermissions,
      }),
    [entity.id, entityLevelOverrides, platformLevelPermissions]
  );

  const setOverridesFromPermissionLevel = useCallback(
    (permissionLevel: CustomRolePermissionLevel) => {
      if (entityIndex === -1) {
        appendEntity(buildEntityOverridesFromPermissionLevel(entity.id as string, permissionLevel));
      } else {
        setEntityPermissionsFromPermissionLevel({
          permissionLevel,
          keyPrefix: `entityLevelOverrides.${entityIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [entity.id, entityIndex, appendEntity, setValue]
  );

  const setOverridesFromPermissions = useCallback(
    (permissions: Permissions) => {
      if (entityIndex === -1) {
        appendEntity(buildEntityOverridesFromPermissions(entity.id as string, permissions));
      } else {
        setEntityPermissionsFromPermissions({
          permissions,
          keyPrefix: `entityLevelOverrides.${entityIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [entity.id, entityIndex, appendEntity, setValue]
  );

  // The current (persisted in the form) access level for this entity
  const entityAccessLevel = useMemo(() => {
    if (entityIndex === -1) {
      return Access.Default;
    }
    return getAccessLevel(entityPermissions);
  }, [entityIndex, entityPermissions]);
  // The access level to show in the UI
  const visibleAccessLevel = isOverridesModalOpen ? Access.Custom : entityAccessLevel;

  const setEntityAccessLevel = useCallback(
    (access: Access) => {
      switch (access) {
        case Access.Custom:
          openOverridesModal();
          return;
        case Access.Default:
          if (entityIndex !== -1) {
            removeEntity(entityIndex);
          }
          return;
        case Access.FullAccess:
          setOverridesFromPermissionLevel('write');
          return;
        case Access.ViewOnly:
          setOverridesFromPermissionLevel('read');
          return;
        case Access.NoAccess:
          setOverridesFromPermissionLevel('none');
          // Remove all bank account overrides for this entity
          replaceBankAccounts(
            bankAccountLevelOverrides.filter(
              (override) => !bankAccounts?.some((bankAccount) => bankAccount.id === override.bankAccountId)
            )
          );
          return;
      }
    },
    [bankAccountLevelOverrides, replaceBankAccounts, setOverridesFromPermissionLevel]
  );

  return (
    <>
      <CustomOverridesModal
        breadcrumbs={[entity.name as string]}
        currentPermissions={entityAccessLevel === Access.Custom ? entityPermissions : EntityLevelDefaultPermissions}
        onClose={closeOverridesModal}
        onSave={(permissions) => {
          setOverridesFromPermissions(permissions);
          closeOverridesModal();
        }}
        open={isOverridesModalOpen}
        parentPermissions={platformLevelPermissions as Permissions}
        permissionsToLabelsMap={EntityLevelPermissionsToLabelsMap}
      />
      <ListRow
        icon={<StyledFolderIcon filled={!!rootEntity} flat />}
        rightDecoration={
          <>
            {entityAccessLevel === Access.Custom && numOverrides > 0 && <Chip>{numOverrides} Overrides</Chip>}
            <AccountActionsWrapper>
              <AccessDropdown
                onChange={setEntityAccessLevel}
                value={visibleAccessLevel}
                variant={'muted'}
                showDefault
              />
              {visibleAccessLevel === Access.Custom && (
                <Button onClick={openOverridesModal} variant={'secondary'} size={'small'}>
                  Edit
                </Button>
              )}
            </AccountActionsWrapper>
          </>
        }
        content={entity.name as string}
        variant={ListRowVariant.SubHeader}
      />
      {bankAccounts?.map((bankAccount) => (
        <BankAccountListRow
          key={bankAccount.id}
          bankAccount={bankAccount}
          parentName={entity.name as string}
          parentOverrides={entityLevelOverrides}
          parentPermissions={entityEffectivePermissions}
        />
      ))}
      {hasMoreBankAccounts && showLoadMore && (
        <ListRow
          depth={1}
          content={
            <LoadMoreButton onClick={() => loadMoreBankAccounts()} size={'tiny'} variant={'subtle'}>
              Load More Accounts
            </LoadMoreButton>
          }
        />
      )}
    </>
  );
};

interface BankAccountListRowProps {
  bankAccount: BankAccountWithDetails;
  parentName: string;
  parentOverrides: CustomRoleEntityLevelOverrides[];
  parentPermissions: Permissions;
}
const BankAccountListRow: React.FC<BankAccountListRowProps> = ({
  bankAccount,
  parentName,
  parentOverrides,
  parentPermissions,
}) => {
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState<boolean>(false);
  const openOverridesModal = useCallback(() => setTimeout(() => setIsOverridesModalOpen(true), 150), []);
  const closeOverridesModal = useCallback(() => setIsOverridesModalOpen(false), []);
  const { setValue } = useFormContext<TransfersAndAccountsFields>();

  const bankAccountLevelOverrides = useWatch<TransfersAndAccountsFields>({
    defaultValue: [],
    name: 'bankAccountLevelOverrides',
  }) as CustomRoleBankAccountLevelOverrides[];
  const { append: appendBankAccount, remove: removeBankAccount } = useFieldArray<TransfersAndAccountsFields>({
    name: 'bankAccountLevelOverrides',
  });

  const {
    bankAccountIndex,
    actualPermissions: bankAccountPermissions,
    numOverrides,
  } = useMemo(
    () =>
      getBankAccountPermissions({
        bankAccountId: bankAccount.id,
        bankAccountLevelOverrides,
      }),
    [bankAccount.id, bankAccountLevelOverrides]
  );

  const setOverridesFromPermissionLevel = useCallback(
    (permissionLevel: CustomRolePermissionLevel) => {
      if (bankAccountIndex === -1) {
        appendBankAccount(buildBankAccountOverridesFromPermissionLevel(bankAccount.id, permissionLevel));
      } else {
        setBankAccountPermissionsFromPermissionLevel({
          permissionLevel,
          keyPrefix: `bankAccountLevelOverrides.${bankAccountIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [bankAccount.id, bankAccountIndex, appendBankAccount, setValue]
  );

  const setOverridesFromPermissions = useCallback(
    (permissions: Permissions) => {
      if (bankAccountIndex === -1) {
        appendBankAccount(buildBankAccountOverridesFromPermissions(bankAccount.id, permissions));
      } else {
        setBankAccountPermissionsFromPermissions({
          permissions,
          keyPrefix: `bankAccountLevelOverrides.${bankAccountIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [bankAccount.id, bankAccountIndex, appendBankAccount, setValue]
  );

  // The current (persisted in the form) access level for this bank account
  const bankAccountAccessLevel = useMemo(() => {
    if (bankAccountIndex === -1) {
      return Access.Default;
    }
    return getAccessLevel(bankAccountPermissions);
  }, [bankAccountIndex, bankAccountPermissions]);
  // The access level to show in the UI
  const visibleAccessLevel = isOverridesModalOpen ? Access.Custom : bankAccountAccessLevel;
  const parentAccessLevel = useMemo(() => getAccessLevel(parentPermissions), [parentPermissions]);

  const setBankAccountAccessLevel = useCallback(
    (access: Access) => {
      switch (access) {
        case Access.Custom:
          openOverridesModal();
          return;
        case Access.Default:
          if (bankAccountIndex !== -1) {
            removeBankAccount(bankAccountIndex);
          }
          return;
        case Access.FullAccess:
          setOverridesFromPermissionLevel('write');
          return;
        case Access.ViewOnly:
          setOverridesFromPermissionLevel('read');
          return;
        case Access.NoAccess:
          setOverridesFromPermissionLevel('none');
          return;
      }
    },
    [setOverridesFromPermissionLevel, removeBankAccount]
  );

  const showAccessDropdown = parentOverrides.length === 0 || parentAccessLevel !== Access.NoAccess;

  return (
    <>
      <CustomOverridesModal
        breadcrumbs={[parentName, bankAccount.displayName ?? bankAccount.description ?? 'Unnamed']}
        currentPermissions={
          bankAccountAccessLevel === Access.Custom ? bankAccountPermissions : BankAccountDefaultPermissions
        }
        onClose={closeOverridesModal}
        onSave={(permissions) => {
          setOverridesFromPermissions(permissions);
          closeOverridesModal();
        }}
        open={isOverridesModalOpen}
        parentPermissions={parentPermissions}
        permissionsToLabelsMap={BankAccountLevelPermissionsToLabelsMap}
      />
      <ListRow
        key={bankAccount.id}
        depth={1}
        rightDecoration={
          showAccessDropdown && (
            <>
              {bankAccountAccessLevel === Access.Custom && numOverrides > 0 && <Chip>{numOverrides} Overrides</Chip>}
              <AccountActionsWrapper>
                <AccessDropdown
                  onChange={setBankAccountAccessLevel}
                  value={visibleAccessLevel}
                  variant={'muted'}
                  showDefault
                />
                {visibleAccessLevel === Access.Custom && (
                  <Button onClick={openOverridesModal} variant={'muted'} size={'small'}>
                    Edit
                  </Button>
                )}
              </AccountActionsWrapper>
            </>
          )
        }
        content={
          <>
            {bankAccount.displayName ?? bankAccount.description ?? 'Unnamed'}
            {bankAccount.displayName && bankAccount.description && (
              <RowDescription size={'small'}>({bankAccount.description})</RowDescription>
            )}
          </>
        }
        variant={ListRowVariant.Default}
      />
    </>
  );
};
