import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface IssueCheckIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const IssueCheckIcon: FC<IssueCheckIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase viewportSize={20}>
        <path d="M8.63 9.75H2.75V17.25H17.25V9.75H11.92" stroke="currentColor" strokeWidth="1.5" />
        <path d="M9.5 14.5H5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M15 13H12" stroke="currentColor" strokeWidth="1.5" />
        <path
          d="M8.53891 9.84L15.2089 3.15C15.4089 2.95 15.7189 2.95 15.9189 3.15L16.8589 4.09C17.0589 4.29 17.0589 4.6 16.8589 4.8L10.1889 11.49L8.19891 12.16C7.99891 12.23 7.81891 12.04 7.87891 11.84L8.54891 9.84H8.53891Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path d="M15.5619 6.09L13.9219 4.44" stroke="currentColor" strokeWidth="1.5" />
      </IconBase>
    </Wrapper>
  );
};
