import React from 'react';
import styled from 'styled-components';

import { DropdownEntry, DropdownEntryText, Icon } from '@column/column-ui-kit';

import { PopoverBase, PopoverBaseProps } from '../Base';
import { useSessionStore } from '~/stores/Session';
import { Caption, Line } from '~/styles';
import { log } from '~/util';

interface PopoverPlatformStatusProps extends PopoverBaseProps {
  active?: string;
  onActiveChange?: (active: string) => void;
  onSelect?: () => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-right: 0;
  --popover-left: auto;
  --popover-top: 36px;
  --popover-width: 220px;
`;

const Checkmark = styled(Icon.Checkmark)`
  margin-right: -28px;
`;

const StyledLine = styled(Line)`
  margin: 4px -4px;
  width: auto;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const StyledCaption = styled(Caption)`
  margin: 8px 0 8px 12px;
  font-size: 12px;
  text-transform: uppercase;
`;

const Note = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary.blendToBackground(900)};
  padding: 8px 12px;
  margin: 0;

  em {
    font-style: normal;
    font-weight: 500;
    color: ${({ theme }) => theme.secondary.background};
  }
`;

export const SandboxIcon = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    --icon-color: ${({ theme }) => theme.orange.background};
    --icon-border: ${({ theme }) => theme.orange.blendToBackground(1200)};
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--icon-color);
    box-shadow: 0 0 0 1px var(--icon-border) inset;
  }
`;

export const LiveIcon = styled(SandboxIcon)`
  &::after {
    --icon-color: ${({ theme }) => theme.seafoam.background};
    --icon-border: ${({ theme }) => theme.seafoam.blendToBackground(1200)};
  }
`;

export const PopoverPlatformStatus: React.FC<PopoverPlatformStatusProps> = (props) => {
  const { isSandbox, setSandbox, currentUser } = useSessionStore();

  const handleSandboxChange = (value: boolean) => {
    setSandbox(value);

    log({
      name: value ? 'Sandbox on' : 'Live on',
      context: currentUser,
    });
  };

  return (
    <Wrapper {...props}>
      <StyledCaption>Status</StyledCaption>
      <Options>
        <DropdownEntry
          onClick={() => {
            handleSandboxChange(true);
            if (props.onSelect) {
              props.onSelect();
            }
          }}
          isActive={isSandbox}
        >
          <SandboxIcon />
          <DropdownEntryText>Sandbox</DropdownEntryText>
          {isSandbox && <Checkmark />}
        </DropdownEntry>
        <DropdownEntry
          onClick={() => {
            handleSandboxChange(false);
            if (props.onSelect) {
              props.onSelect();
            }
          }}
          isActive={!isSandbox}
        >
          <LiveIcon />
          <DropdownEntryText>Live</DropdownEntryText>
          {!isSandbox && <Checkmark />}
        </DropdownEntry>
      </Options>
      <StyledLine />
      <Note>
        {isSandbox ? (
          <>
            If you would like to move real money, switch to <em>Live</em> mode.
          </>
        ) : (
          <>
            If you would like to test without moving real money, switch to <em>Sandbox</em> mode.
          </>
        )}
      </Note>
    </Wrapper>
  );
};
