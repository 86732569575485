import { client } from '../client';
import {
  WireCreateWireTransferRequest,
  WireTransfer,
  WireTransferList,
  WireTransferServiceListWireTransfersParams,
} from '~/typings/API';
import { ReviewTransferRequest } from '~/typings/enum';
import { convertValues } from '~/util';

export type CreateWireTransfer = WireCreateWireTransferRequest;
export type ReviewWireTransferRequest = ReviewTransferRequest & { id: string };

export type { WireTransfer };

const wireTransferCast = ({
  beneficiaryAccountNumber,
  senderDiRoutingNumber,
  receiverDiRoutingNumber,
}: WireTransfer) => ({
  beneficiaryAccountNumber,
  senderDiRoutingNumber,
  receiverDiRoutingNumber,
});

export class WireRepository {
  static async create({ idempotencyKey: idempotency, ...wireTransfer }: CreateWireTransfer) {
    return client
      .post<CreateWireTransfer, WireTransfer>('/transfers/wire', wireTransfer, { idempotency })
      .then((response) => convertValues<WireTransfer>(response, undefined, wireTransferCast(response)));
  }

  static async get(request: GetRequestType) {
    return client
      .get<unknown, WireTransfer>(`/transfers/wire/${request.id}`)
      .then((response) => convertValues<WireTransfer>(response, undefined, wireTransferCast(response)));
  }

  static async getAll(query?: Partial<WireTransferServiceListWireTransfersParams>) {
    return client
      .get<WireTransferServiceListWireTransfersParams, WireTransferList>('/transfers/wire', { query })
      .then((response) => ({
        transfers: Array.isArray(response?.transfers)
          ? response.transfers.map((e) => convertValues<WireTransfer>(e, undefined, wireTransferCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async review({ id, ...req }: ReviewWireTransferRequest) {
    return client
      .post<ReviewTransferRequest, WireTransfer>(`/transfers/wire/${id}/review`, req)
      .then((response) => convertValues<WireTransfer>(response, undefined, wireTransferCast(response)));
  }
}
