import { useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import { Dropdown, DropdownOption, Icon, Input } from '@column/column-ui-kit';

import { GOOGLE_MAPS_API_KEY } from '~/constants';
import { useAddress } from '~/hooks/useAddress';
import { AddressType } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';

const googleMapsLibraries: any[] = ['places'];

interface AddressProps {
  onChange?: (address: AddressType) => void;
  justUS?: boolean;
  isDisabled?: boolean;
}

const StyledDropdown = styled(Dropdown)`
  --dropdown-custom-padding: 0;
`;

export const AddressField: React.FC<AddressProps> = (props) => {
  const { states } = useAddress({ types: ['state'] });
  const addDangerNotifcation = useNotificationStore((s) => s.addDangerNotification);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const requestOptions = props.justUS
    ? {
        componentRestrictions: { country: ['us'] },
      }
    : undefined;
  const {
    init,
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions,
    debounce: 300,
    initOnMount: false,
  });

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries,
  });

  useEffect(() => {
    if (isLoaded && !ready) {
      init();
      setIsSearchDisabled(false);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (loadError) {
      addDangerNotifcation({
        content: 'Failed to load Google Maps',
      });
    }
  }, [loadError]);

  useEffect(() => {
    if (status !== 'OK') {
      return;
    }
    setOptions(
      data.map((suggestion) => {
        const {
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return {
          label: `${main_text} (${secondary_text})`,
          onClick: () => {
            setValue(suggestion.description, false);
            clearSuggestions();
            setOpen(false);

            const parameter: google.maps.places.PlaceDetailsRequest = {
              placeId: suggestion.place_id,
              fields: ['address_components'],
            };

            getDetails(parameter)
              .then((details) => {
                if (typeof details === 'string') {
                  return;
                }

                if (props.onChange && details.address_components) {
                  const state = details.address_components.find((e) => e.types[0] === 'administrative_area_level_1');
                  const stateCode = state ? parseStateDetails(state, states)?.stateCode : '';

                  const address = {
                    line1: `${
                      details.address_components.find((e: any) => e.types[0] === 'street_number')?.long_name ?? ''
                    } ${details.address_components.find((e: any) => e.types[0] === 'route')?.long_name ?? ''}`.trim(),
                    city: details.address_components.find((e: any) => e.types[0] === 'locality')?.long_name ?? '',
                    state: stateCode,
                    countryCode:
                      details.address_components.find((e: any) => e.types[0] === 'country')?.short_name ?? '',
                    postalCode:
                      details.address_components.find((e: any) => e.types[0] === 'postal_code')?.long_name ?? '',
                  };
                  props.onChange(address);
                }
              })
              .catch((e) => console.error('AddressField', e));
          },
        };
      })
    );
    if (!open) {
      setOpen(true);
    }
  }, [data]);

  return (
    <StyledDropdown
      isOpen={open}
      options={options}
      fullWidth
      customLabel={
        <Input
          value={value}
          onChange={(val: string) => setValue(val)}
          isDisabled={isSearchDisabled || !ready || props.isDisabled}
          placeholder="Search for address"
          icon={<Icon.Search />}
          onFocus={() => options.length > 0 && setOpen(true)}
          onBlur={() => setOpen(false)}
        />
      }
    />
  );
};

const parseStateDetails = (
  stateDetails: { long_name: string; short_name: string },
  allStates: State[]
): { stateCode?: string } => {
  if (!stateDetails || !stateDetails.short_name) {
    return {};
  }

  const exactMatch = allStates.find(
    (s) => s.state_code === stateDetails.short_name || s.name === stateDetails.long_name
  );
  return { stateCode: exactMatch?.state_code };
};
