import * as React from 'react';
import { useMemo } from 'react';

import { DropdownOption, Icon, Tooltip, formatNumber } from '@column/column-ui-kit';

import { useBankAccounts } from '~/hooks/useBankAccounts';
import { useSessionStore } from '~/stores/Session';
import { CustomRolePlatformLevelPermissions } from '~/typings/API';

export const useBankAccountsDropdown = ({
  permission,
  unauthorizedText,
}: {
  permission?: keyof CustomRolePlatformLevelPermissions;
  unauthorizedText: string;
}) => {
  const { isAuthorized } = useSessionStore();

  const { response: bankAccounts, setQueryParams: searchBankAccounts, isLoading } = useBankAccounts();

  const dropdownBankAccounts: DropdownOption[] = useMemo(
    () =>
      (bankAccounts?.bankAccounts ?? []).map((bankAccount) => {
        const isBankAccountAuthorized = !permission
          ? true
          : isAuthorized({
              permission,
              permissionLevel: 'write',
              bankAccount,
            });
        const accountNumberLastFour = bankAccount?.defaultAccountNumber?.slice(-4);
        const accountNumberPreview = accountNumberLastFour ? `(...${accountNumberLastFour})` : '';
        return {
          label: `${
            bankAccount?.displayName
              ? `${bankAccount.displayName} ${accountNumberPreview} ${bankAccount?.description ? ` — ${bankAccount.description}` : ''}`
              : `${bankAccount?.description || 'Unnamed'} ${accountNumberPreview}`
          }`,
          small: `${formatNumber(bankAccount?.balances?.availableAmount)}`,
          value: bankAccount.id,
          suffix: !isBankAccountAuthorized && (
            <Tooltip content={unauthorizedText} placement="right" zIndex={15}>
              <Icon.AnimationLock />
            </Tooltip>
          ),
          isDisabled: !isBankAccountAuthorized,
        };
      }),
    [bankAccounts, isAuthorized, unauthorizedText]
  );

  return { dropdownBankAccounts, isLoading, searchBankAccounts };
};
