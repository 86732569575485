import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Fade, Icon, useOutsideClick } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { SectionHeader } from '~/components';
import { UnderlineLink } from '~/elements';
import { HelpSidebarType, useHelpSidebarStore } from '~/stores/HelpSidebar';
import { Inner } from '~/styles';

const Sidebar = styled(Fade)`
  --animation-enter-x: 0px;
  --animation-exit-x: 24px;
  --animation-enter-y: 0px;
  --animation-exit-y: 0px;

  position: fixed;
  top: 56px;
  bottom: 8px;
  right: 8px;
  z-index: 11;
  width: 400px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 0px 10px 10px 0px;
  box-shadow:
    1px 0 0 ${({ theme }) => theme.style.dropdownBorderColor},
    -1px 0 0 ${({ theme }) => theme.style.dropdownBorderColor},
    0 4px 16px rgba(38 38 44 / 3%);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  overflow: auto;
`;

const Close = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  z-index: 1;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  transition: background-color 0.2s;

  svg {
    --icon-size: 20px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(100)};
  }
`;

const List = styled.div`
  display: grid;
  grid-gap: 8px;
  margin-bottom: 20px;
`;

const StyledSectionHeader = styled(SectionHeader)`
  background: ${({ theme }) => theme.background};
  position: sticky;
  top: 0;
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.secondary.blendToBackground(100)};
`;

const ListHeadline = styled.h4`
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

const ListEntries = styled.ul`
  display: grid;
  grid-gap: 4px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListEntry = styled.li``;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  padding-top: 24px;
`;

export const Line = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

interface LinkListEntry {
  label: string;
  url: string;
}

interface LinkListProps {
  title?: string;
  entries: LinkListEntry[];
}

export const LinkList: React.FC<LinkListProps> = (props) => (
  <List>
    {props.title && <ListHeadline>{props.title}</ListHeadline>}
    <ListEntries>
      {props.entries.map((entry) => (
        <ListEntry key={entry.label}>
          <UnderlineLink href={entry.url} newTab>
            {entry.label}
          </UnderlineLink>
        </ListEntry>
      ))}
    </ListEntries>
  </List>
);

interface BottomLinksProps {}

export const BottomLinks: React.FC<BottomLinksProps> = () => {
  const closeHelpSidebar = useHelpSidebarStore((state) => state.closeHelpSidebar);

  return (
    <Bottom>
      <UnderlineLink href="mailto:support@column.com">support@column.com</UnderlineLink>
      <UnderlineLink to={ROUTE.CONTACT_FORM} onClick={() => closeHelpSidebar()}>
        Contact sales
      </UnderlineLink>
    </Bottom>
  );
};

export interface HelpSidebarProps {
  helpSidebars: HelpSidebarType[];
  className?: string;
  headline?: string;
}

export const HelpSidebarBase: React.FC<PropsWithChildren<HelpSidebarProps>> = (props) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const { closeHelpSidebar, getHelpSidebarIsOpen, getHelpSidebar } = useHelpSidebarStore();

  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeHelpSidebar();
      }
    };
    window.addEventListener('keydown', keyDownListener);
    return () => window.removeEventListener('keydown', keyDownListener);
  }, []);

  useEffect(
    () =>
      useHelpSidebarStore.subscribe(
        (state) => ({
          getHelpSidebarState: state.getHelpSidebar(),
          getHelpSidebarIsOpenState: state.getHelpSidebarIsOpen(),
        }),
        ({ getHelpSidebarState, getHelpSidebarIsOpenState }) => {
          setIsHidden(!getHelpSidebarIsOpenState || !props.helpSidebars.includes(getHelpSidebarState!));
        }
      ),
    []
  );

  useEffect(() => {
    setIsHidden(!getHelpSidebarIsOpen() || !props.helpSidebars.includes(getHelpSidebar()!));
  }, [props.helpSidebars]);

  useOutsideClick(
    sidebarRef,
    () => {
      if (getHelpSidebarIsOpen()) {
        closeHelpSidebar();
      }
    },
    'learn-more'
  );

  return (
    <Sidebar ref={sidebarRef} show={!isHidden} timeoutEnter={0} className={props.className}>
      {props.headline && (
        <StyledSectionHeader text={props.headline}>
          <Close onClick={() => closeHelpSidebar()}>
            <Icon.Cross />
          </Close>
        </StyledSectionHeader>
      )}
      <Inner pt={16}>{props.children}</Inner>
    </Sidebar>
  );
};
