import { useCallback, useEffect, useRef, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';

import { Currencies, Currency } from '@column/column-ui-kit';

import { useFxRateSheet } from '../useTransfers';
import countryCodeJSON from '~/data/wires/country-codes.json';
import { FxRateSheet } from '~/repositories';

import type {
  CounterpartyRequirements,
  CountryRequirements,
  DefaultRequirements,
  PurposeCodes,
  WireRequirements,
} from './IntlWireTypes';

export interface CountryCodeEntry {
  countryCode: string;
  currencyCode: string;
  countryName: string;
}

const useIntlWireValidate = () => {
  const [countryRequirements, setCountryRequirements] = useState<{ [key: string]: CountryRequirements } | null>(null);
  const [defaultRequirements, setDefaultRequirements] = useState<DefaultRequirements | null>(null);
  const [purposeCodes, setPurposeCodes] = useState<PurposeCodes | null>(null);

  const ratesRef = useRef<FxRateSheet | null>(null);

  const { createRequest: getFxRates } = useFxRateSheet();

  useEffect(() => {
    getFxRates().then((rates) => {
      if (!rates) {
        return;
      }

      ratesRef.current = rates;
    });
  }, [getFxRates]);

  const loadJsonData = useCallback(async () => {
    if (!countryRequirements) {
      const countryReqModule = (await import('~/data/wires/country-requirements.json')).default;
      setCountryRequirements(countryReqModule.countries);
    }
    if (!defaultRequirements) {
      const defaultReqModule = (await import('~/data/wires/default-requirements.json')).default;
      setDefaultRequirements(defaultReqModule.default);
    }
    if (!purposeCodes) {
      const purposeCodesModule = (await import('~/data/wires/purpose-codes.json')).default;
      setPurposeCodes(purposeCodesModule);
    }
  }, [countryRequirements, defaultRequirements, purposeCodes]);

  const getCurrencyByCountryCode = useCallback((countryCode: string): Currency => {
    const currencyCode = countryCodeJSON.find(
      (entry: CountryCodeEntry) => entry.countryCode === countryCode
    )?.currencyCode;

    const defaultCurrency: Currency = Currencies.find(({ code }) => code === 'USD') as Currency;

    if (!currencyCode) {
      return defaultCurrency;
    }

    return Currencies.find(({ code }) => code === currencyCode) ?? defaultCurrency;
  }, []);

  const getCounterpartyRequirements = useCallback(
    async (countryKey: string): Promise<CounterpartyRequirements> => {
      await loadJsonData();
      const countryData = countryRequirements?.[countryKey];
      const defaultCounterparty = defaultRequirements?.counterparty ?? {};
      const countryCounterparty = countryData?.counterparty ?? {};

      return {
        mandatoryFields: [
          ...(defaultCounterparty.mandatoryFields ?? []),
          ...(countryCounterparty.mandatoryFields ?? []),
        ],
        highlyRecommendedFields: [
          ...(defaultCounterparty.highlyRecommendedFields ?? []),
          ...(countryCounterparty.highlyRecommendedFields ?? []),
        ],
        accountNumberRegex: countryCounterparty.accountNumberRegex ?? defaultCounterparty.accountNumberRegex,
        legalId: countryCounterparty.legalId ?? defaultCounterparty.legalId,
        localBankCode: countryCounterparty.localBankCode ?? defaultCounterparty.localBankCode,
      };
    },
    [countryRequirements, defaultRequirements, loadJsonData]
  );

  const hasCountrySpecificPurposeCode = useCallback((countryKey: string): boolean => {
    const countriesWithSpecificCodes = [
      'AE',
      'BH',
      'CL',
      'CN',
      'CO',
      'ID',
      'IN',
      'JO',
      'KG',
      'KR',
      'KZ',
      'MM',
      'MY',
      'PH',
      'PK',
      'UA',
    ];
    return countriesWithSpecificCodes.includes(countryKey);
  }, []);

  const getWireRequirements = useCallback(
    async (
      countryKey: string
    ): Promise<
      WireRequirements & {
        purposeCodeOptions?: PurposeCodes;
        hasCountrySpecificPurposeCode?: boolean;
      }
    > => {
      await loadJsonData();
      const countryData = countryRequirements?.[countryKey];
      const defaultWire = defaultRequirements?.wire ?? {};
      const countryWire = countryData?.wire ?? {};

      const wireReqs: WireRequirements = {
        mandatoryFields: [...(defaultWire.mandatoryFields ?? []), ...(countryWire.mandatoryFields ?? [])],
        highlyRecommendedFields: [
          ...(defaultWire.highlyRecommendedFields ?? []),
          ...(countryWire.highlyRecommendedFields ?? []),
        ],
        purposeCode: countryWire.purposeCode ?? defaultWire.purposeCode,
        remittanceInfo: countryWire.remittanceInfo ?? defaultWire.remittanceInfo,
      };

      return {
        ...wireReqs,
        purposeCodeOptions: purposeCodes ?? {},
        hasCountrySpecificPurposeCode: hasCountrySpecificPurposeCode(countryKey),
      };
    },
    [countryRequirements, defaultRequirements, purposeCodes, loadJsonData, hasCountrySpecificPurposeCode]
  );

  const getCountryFromAccountNumber = useCallback(
    async (accountNumber: string): Promise<string | null> => {
      await loadJsonData();
      if (!countryRequirements) return null;

      for (const [countryCode, country] of Object.entries(countryRequirements)) {
        const regex = country.counterparty.accountNumberRegex;
        if (regex && new RegExp(regex).test(accountNumber)) {
          return countryCode;
        }
      }

      return null;
    },
    [countryRequirements, loadJsonData]
  );

  const getPurposeCodeValidation = useCallback(
    (countryKey: string, currencyCode: string): Pick<RegisterOptions, 'pattern' | 'max'> => {
      if (currencyCode === 'USD') {
        return {};
      }

      const purposeCodePatterns: { [key: string]: RegExp } = {
        AE: /^[\dA-Za-z]{3}$/,
        BH: /^[\dA-Za-z]{3}$/,
        CL: /^\d{5}$/,
        CN: /^(?:CAP|GDS|SRV|CAC|FIF)$/,
        CO: /^[A-Za-z\d]+$/,
        ID: /^\d{4}$/,
        IN: /^[PS]\d{4}$/,
        JO: /^\d{4}$/,
        KG: /^\d{8}$/,
        KR: /^\d{5}$/,
        KZ: /^[\dA-Za-z]{10}$/,
        MM: /^\d{4}$/,
        MY: /^\d{5}$/,
        PH: /^[A-Za-z\d]+$/,
        PK: /^\d{4}$/,
        UA: /^[\dA-Za-z]{4}$/,
      };

      const pattern = purposeCodePatterns[countryKey];
      if (!pattern) {
        return {};
      }

      return {
        pattern: {
          value: pattern,
          message: 'Invalid purpose code format for this country',
        },
        max: {
          value: 35,
          message: 'Purpose code cannot exceed 35 characters',
        },
      };
    },
    []
  );

  const getRemittanceInfoValidation = (countryCode?: string): Pick<RegisterOptions, 'required'> => {
    if (!countryCode) return {};

    const countriesRequiringRemittance = ['MD', 'PK'];

    if (countriesRequiringRemittance.includes(countryCode)) {
      return {
        required: {
          value: true,
          message: 'Remittance information is required for this country',
        },
      };
    }

    return {};
  };

  const getMessageToBeneficiaryValidation = useCallback(
    (): Pick<RegisterOptions, 'max' | 'pattern'> => ({
      max: {
        value: 140,
        message: 'Message cannot exceed 140 characters',
      },
      pattern: {
        value: /^[\x20-\x7E]*$/,
        message: 'Message contains invalid characters',
      },
    }),
    []
  );

  const getAmountValidation = useCallback(
    (currencyCode?: string): Pick<RegisterOptions, 'required' | 'min' | 'validate'> => {
      if (!currencyCode) return {};

      const usd25Currencies = [
        'ALL',
        'AOA',
        'AMD',
        'AWG',
        'AZN',
        'BSD',
        'BBD',
        'BZD',
        'BAM',
        'BIF',
        'KMF',
        'CDF',
        'ERN',
        'FKP',
        'XPF',
        'GEL',
        'GIP',
        'GYD',
        'KRW',
        'LAK',
        'LRD',
        'LYD',
        'MGA',
        'MVR',
        'MRU',
        'MNT',
        'NAD',
        'ANG',
        'PYG',
        'MKD',
        'SHP',
        'RSD',
        'SLE',
        'SSP',
        'SRD',
        'TWD',
        'TJS',
        'YER',
        'BRL',
      ];

      // $100 minimum currencies (10000 cents)
      const usd100Currencies = [
        'BND',
        'BOB',
        'CLP',
        'CRC',
        'CVE',
        'DJF',
        'DOP',
        'ETB',
        'GMD',
        'GNF',
        'GTQ',
        'HTG',
        'JOD',
        'KGS',
        'KHR',
        'LSL',
        'MOP',
        'MWK',
        'MUR',
        'MZN',
        'NGN',
        'NIO',
        'NPR',
        'PEN',
        'PGK',
        'PKR',
        'RWF',
        'SBD',
        'SCR',
        'STN',
        'SZL',
        'TOP',
        'TZS',
        'VUV',
        'WST',
        'XAF',
        'XOF',
      ];

      let minAmount = 100;
      if (usd25Currencies.includes(currencyCode)) {
        minAmount = 2500;
      } else if (usd100Currencies.includes(currencyCode)) {
        minAmount = 10000;
      }

      return {
        required: {
          value: true,
          message: 'Amount is required',
        },
        validate: async (value: number) => {
          if (!value || value <= 0) return 'Amount must be greater than 0';

          const convert = ratesRef.current?.data.find((rate) => rate.buyCurrencyCode === currencyCode)?.rate;

          const amount = value * (Number(convert) ?? 1);

          if (amount <= minAmount) {
            return `Minimum amount for ${currencyCode} is ${minAmount / 100} USD`;
          }

          return true;
        },
      };
    },
    [ratesRef]
  );

  return {
    getCurrencyByCountryCode,
    getCounterpartyRequirements,
    getWireRequirements,
    getCountryFromAccountNumber,
    getPurposeCodeValidation,
    getRemittanceInfoValidation,
    getMessageToBeneficiaryValidation,
    getAmountValidation,
    hasCountrySpecificPurposeCode,
  };
};

export default useIntlWireValidate;
