import hljs from 'highlight.js';
import json from 'highlight.js/lib/languages/json';
import darkThemeUrl from 'highlight.js/styles/github-dark.css?url';
import lightThemeUrl from 'highlight.js/styles/github.css?url';
import { useEffect } from 'react';
import { useTheme } from 'styled-components';

const themes = {
  light: lightThemeUrl,
  dark: darkThemeUrl,
};

hljs.registerLanguage('json', json);

export const useCodeHighlighting = () => {
  const theme = useTheme();

  useEffect(() => {
    const existingLink = document.getElementById('hljs-theme');
    if (existingLink) {
      existingLink.remove();
    }

    const link = document.createElement('link');
    link.id = 'hljs-theme';
    link.rel = 'stylesheet';
    if (theme.id === 'dark') {
      link.href = themes.dark;
    } else {
      link.href = themes.light;
    }

    link.onload = () => {
      hljs.highlightAll();
    };
    document.head.appendChild(link);
  }, [theme.id]);
};
