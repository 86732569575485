import { useCallback, useEffect, useMemo } from 'react';

import { formatNumber } from '@column/column-ui-kit';

import {
  AccountNumber,
  BankAccount,
  BankAccountFilterParams,
  BankAccountListResponse,
  BankAccountRepository,
  ListBankAccountActivitiesRequest,
  ListBankAccountActivitiesResponse,
} from '~/repositories/BankAccountRepository';
import { BankAccountWithDetails } from '~/typings/API';

import { createApiHook } from './utils/createApiHook';

export type ActivityFilterType = ListBankAccountActivitiesRequest & {
  page?: number;
};

export type BankAccountFilterType = BankAccountFilterParams & {
  page?: number;
};

export const useBankAccounts = createApiHook<BankAccountListResponse, BankAccountFilterType>(
  BankAccountRepository.getAll,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
    includeQueryParams: true,
    excludeQueryParams: ['page'],
  }
);

export const useBankAccountsList = createApiHook<BankAccountListResponse, BankAccountFilterType>(
  BankAccountRepository.getAll,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
    includeQueryParams: true,
  }
);

export const useLazyBankAccounts = createApiHook<BankAccountListResponse, BankAccountFilterParams>(
  BankAccountRepository.getAll,
  {
    includeQueryParams: true,
  }
);

export const useBankAccount = createApiHook<BankAccountWithDetails, GetRequestType>(BankAccountRepository.get);

export const useAccountNumber = createApiHook<AccountNumber, GetRequestType>(BankAccountRepository.getAccountNumber);

export const useBankAccountActivity = (bankAccountId: string) =>
  createApiHook<ListBankAccountActivitiesResponse, ActivityFilterType>(
    (request) => BankAccountRepository.getActivity(bankAccountId, request),
    {
      triggerAutomatically: true,
      triggerOnSessionStoreSubscribe: true,
      includeQueryParams: true,
      addQueryParamsToUrl: true,
      excludeQueryParams: ['page'],
    }
  );

export const useBankAccountDropdown = (bankAccountId?: string, initialQueryParams?: BankAccountFilterParams) => {
  const {
    response: bankAccounts,
    setQueryParams: searchBankAccounts,
    isInitialLoad,
  } = useBankAccountsList({ initialQueryParams });
  const { response: activeBankAccount, createRequest: fetchBankAccountActive } = useBankAccount();

  const getOption = useCallback(
    (bankAccount: BankAccount | undefined) =>
      bankAccount && {
        label: `${
          bankAccount?.displayName && bankAccount?.description
            ? `${bankAccount.displayName} – ${bankAccount.description}`
            : bankAccount?.description || 'Unnamed'
        }`,
        small: `${formatNumber(bankAccount?.balances?.availableAmount)}`,
        value: bankAccount.id,
      },
    []
  );

  useEffect(() => {
    if (bankAccountId) {
      fetchBankAccountActive({ id: bankAccountId });
    }
  }, [bankAccountId, fetchBankAccountActive]);

  const options = useMemo(
    () =>
      (bankAccounts?.bankAccounts ?? [])
        .filter(Boolean)
        .map(getOption)
        .filter((option) => option !== undefined) ?? [],
    [bankAccounts, getOption]
  );

  const hiddenOptions = useMemo(
    () =>
      options.some((option) => option.value === activeBankAccount?.id)
        ? []
        : ([activeBankAccount]
            .filter(Boolean)
            .map(getOption)
            .filter((option) => option !== undefined) ?? []),
    [activeBankAccount, getOption, options]
  );

  return { options, hiddenOptions, searchBankAccounts, isLoading: isInitialLoad, activeBankAccount };
};
