import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface RDCIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const RDCIcon: FC<RDCIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase viewportSize={20}>
        <path d="M16 4.25H18C18.41 4.25 18.75 4.59 18.75 5V7" stroke="currentColor" strokeWidth="1.5" />
        <path d="M16.25 6.75H3.75V13.25H16.25V6.75Z" stroke="currentColor" strokeWidth="1.5" />
        <path d="M4 4.25H2C1.59 4.25 1.25 4.59 1.25 5V7" stroke="currentColor" strokeWidth="1.5" />
        <path d="M16 15.75H18C18.41 15.75 18.75 15.41 18.75 15V13" stroke="currentColor" strokeWidth="1.5" />
        <path d="M4 15.75H2C1.59 15.75 1.25 15.41 1.25 15V13" stroke="currentColor" strokeWidth="1.5" />
        <path d="M9 11H6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 9.5H11.5" stroke="currentColor" strokeWidth="1.5" />
      </IconBase>
    </Wrapper>
  );
};
