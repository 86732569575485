import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { Button, Dropdown, Modal, ModalSize } from '@column/column-ui-kit';

import { CheckRepository, CheckReturnReason } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { FormElement, FormLabel } from '~/styles';

interface Props {
  transferId: string;
  isOpen: boolean;
  onClose: () => void;
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledFormElement = styled(FormElement)`
  text-align: left;
`;

const returnReasons: { label: string; value: CheckReturnReason }[] = [
  {
    label: 'N: Altered/fictitious item - suspected counterfeit',
    value: 'N',
  },
  {
    label: 'Q: Not authorized',
    value: 'Q',
  },
];

export const CheckReturnModal: FC<Props> = ({ isOpen, onClose, transferId }) => {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [returnCode, setReturnCode] = useState<CheckReturnReason>();

  const { addSuccessNotification, addDangerNotification } = useNotificationStore();

  const handleSubmit = () => {
    if (!returnCode) return;

    setIsButtonLoading(true);
    CheckRepository.createReturn(transferId, {
      returnReason: returnCode,
    })
      .then(() => {
        onClose();
        addSuccessNotification({ content: 'Return initiated' });
      })
      .catch((error: Error) => {
        addDangerNotification({ content: error.message, display: 'popup' });
      })
      .finally(() => setIsButtonLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={ModalSize.Small} withClose={false}>
      <Modal.Header title="Return Transfer">
        <Modal.Close variant="subtle" size="small" onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <FormWrapper>
          <StyledFormElement>
            <FormLabel>Return Code</FormLabel>
            <Dropdown
              onChange={(value) => setReturnCode(value as CheckReturnReason)}
              active={returnCode}
              options={returnReasons}
              fullWidth
            />
          </StyledFormElement>
        </FormWrapper>
      </Modal.Content>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} isLoading={isButtonLoading} variant="danger" isDisabled={!returnCode}>
          Return
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
