export interface RedirectParams {
  searchParams?: URLSearchParams;
  trustedDomains?: string[];
  trustedURL?: string;
}

export const redirect = (target: string, params?: RedirectParams) => {
  const isTrusted = isTrustedRedirect(target, params);
  if (isTrusted) {
    const searchParams = params?.searchParams ? `?${params.searchParams.toString()}` : '';
    window.location.href = `${target}${searchParams}`;
    return true;
  }
  console.warn(`Redirect to ${target} was blocked: Untrusted origin`);
  return false;
};

export const isTrustedRedirect = (target: string, params?: Omit<RedirectParams, 'searchParams'>) => {
  if (target.startsWith('javascript:')) {
    console.error('Invalid URL provided to redirect:', target);
    return false;
  }

  let targetURL;
  try {
    targetURL = new URL(target, window.location.origin);
  } catch (e) {
    console.error('Invalid URL provided to redirect:', target);
    return false;
  }

  const currentOrigin = window.location.origin;
  const isSameOrigin = targetURL.origin === currentOrigin;

  const isTrustedDomain = (params?.trustedDomains ?? []).some((trustedDomain) => {
    let trustedHostname = trustedDomain;
    try {
      // If the domain has a protocol, parse it as a URL
      if (trustedDomain.indexOf('://') > 0) {
        const trustedDomainURL = new URL(trustedDomain);
        const trustedOrigin = trustedDomainURL.origin;

        if (trustedDomainURL.origin === trustedOrigin) {
          return true;
        }

        trustedHostname = trustedDomainURL.hostname;
      }
    } catch (e) {
      // If parsing fails, assume it's a hostname
    }

    if (targetURL.hostname === trustedHostname) {
      return true;
    }

    // Check if the target is a subdomain of the trusted domain
    if (targetURL.hostname.length > trustedDomain.length + 1) {
      const potentialSubdomain = targetURL.hostname.substring(targetURL.hostname.length - trustedDomain.length - 1);

      if (potentialSubdomain === `.${trustedDomain}`) {
        return true;
      }
    }

    return false;
  });

  const isTrustedURL = params?.trustedURL ? target.startsWith(params.trustedURL) : false;
  const isTrusted = params?.trustedURL ? isTrustedURL : isTrustedDomain;
  return isSameOrigin || isTrusted;
};
