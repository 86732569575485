import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Inner, Text } from '~/styles';

import { TimelineEvent } from './TimelineEvent';
import { TimelineEntry } from './types';

interface Props {
  entries: TimelineEntry[];
  returnEntries?: TimelineEntry[];
}

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

export const Timeline: React.FC<Props> = ({ entries, returnEntries = [] }) => {
  const allEntries = useMemo(() => {
    const combined = [...entries];
    if (returnEntries.length > 0) {
      combined.push(...returnEntries);
    }

    return combined.sort((a, b) => {
      const dateA = a.date instanceof Date ? a.date : new Date(a.date || '');
      const dateB = b.date instanceof Date ? b.date : new Date(b.date || '');
      return dateA.getTime() - dateB.getTime();
    });
  }, [entries, returnEntries]);

  return (
    <TimelineWrapper>
      <Inner>
        <Text as="h3" size={16} color="foreground" weight="medium" margin="0 0 24px">
          Timeline
        </Text>
        {allEntries.map((entry, idx) => (
          <TimelineEvent
            key={`event-${idx}`}
            amount={entry.amount}
            date={entry.date}
            label={entry.label}
            link={entry.link}
            status={entry.status}
            partyData={entry.partyData}
            returnData={entry.returnData}
            reviewData={entry.reviewData}
          />
        ))}
      </Inner>
    </TimelineWrapper>
  );
};
