import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button, Icon, formatNumber } from '@column/column-ui-kit';

import { usePinBankAccounts } from '../../BankAccounts/_hooks/usePinBankAccounts';
import { ROUTE } from '~/app/routes';
import { useNavigate } from '~/lib/navigation';
import { Card, Headline } from '~/styles';

import { PinnedBankAccountsModal } from './PinnedBankAccountsModal';

const Wrapper = styled.div`
  margin-bottom: 24px;
  position: relative;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 16px 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Controls = styled.div`
  display: flex;
`;

const Arrow = styled(Button)`
  &:first-child {
    --button-border-radius: 8px 0 0 8px;
    margin-right: -1px;
  }

  &:last-child {
    --button-border-radius: 0 8px 8px 0;
  }

  &:hover {
    z-index: 1;
  }
`;

const CardsContainer = styled.div`
  position: relative;
  padding: 0 24px;
  margin: -12px 0;
  overflow: hidden;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  box-sizing: border-box;
  mask-image: linear-gradient(to right, transparent, black 24px, black calc(100% - 24px), transparent 100%);
`;

const CardWrapper = styled.div<{ $currentIndex: number; $cardsPerView: number }>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: ${({ $cardsPerView }) => `calc((100% - ${($cardsPerView - 1) * 16}px) / ${$cardsPerView})`};
  grid-template-rows: max-content;
  gap: 16px;
  transform: translateX(
    calc(${({ $currentIndex }) => $currentIndex} * (-100% - 16px) / ${({ $cardsPerView }) => $cardsPerView})
  );
  min-width: 0;
  transition: transform 0.3s ease-in-out;
`;

const CardTitle = styled.div`
  transition: color 0.2s;
  font-size: 12px;
  max-width: calc(100% - 32px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(900)};
`;

const CardArrow = styled(Icon.ChevronRight)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(var(--card-arrow-x, 0px), -50%) translateZ(0);
  color: var(--card-arrow-color, ${({ theme }) => theme.secondary.blendToBackground(600)});
  transition:
    transform 0.2s,
    color 0.2s;
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  position: relative;
  padding: 12px;
  margin: 12px 0;
  box-sizing: border-box;
  min-height: 58px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(40)};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
  transition:
    background-color 0.2s,
    box-shadow 0.2s;

  &:hover {
    --card-arrow-x: 4px;
    --card-arrow-color: ${({ theme }) => theme.primary.background};

    background-color: ${({ theme }) => theme.background};
    box-shadow:
      ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
        ${({ theme }) => theme.style.buttonSecondaryBorderWidth}
        ${({ theme }) => theme.style.buttonSecondaryBorderColor},
      0 0 0 var(--button-outline, 0px)
        var(--button-outline-color, ${({ theme }) => theme.style.buttonFocussedOutlineColor}),
      ${({ theme }) => theme.style.buttonSecondaryShadow};

    ${CardTitle} {
      color: ${({ theme }) => theme.primary.background};
    }
  }
`;

const CardPlus = styled(Icon.Plus)`
  --icon-size: 16px;

  transition:
    transform 0.3s,
    color 0.2s;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  transform: translate(-50%, -50%) scale(var(--card-plus-scale, 1)) translateZ(0);
  position: absolute;
  top: 50%;
  left: 50%;
`;

const EmptyCard = styled(StyledCard)`
  background-color: ${({ theme }) => theme.secondary.blendToBackground(20)};
  box-shadow: none;
  user-select: none;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    border: 1px dashed ${({ theme }) => theme.secondary.blendToBackground(125)};
    transition: opacity 0.2s;
  }

  &:hover {
    --card-plus-scale: 1.3;
  }

  &:hover {
    ${CardPlus} {
      color: ${({ theme }) => theme.primary.background};
    }

    &:before {
      opacity: 0;
    }
  }
`;

const CardAmount = styled.div`
  color: ${({ theme }) => theme.foreground};
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-top: 6px;
`;

export const PinnedBankAccounts: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const navigate = useNavigate();
  const { pinnedAccounts } = usePinBankAccounts();

  const containerRef = useRef<HTMLDivElement>(null);
  const [cardsPerView, setCardsPerView] = useState(3);

  const emptyCardLabelsSet = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current.querySelectorAll('.card-label').forEach((elem) => {
      if (elem.hasAttribute('isGenerated')) {
        return;
      }

      elem.setAttribute('isGenerated', 'true');
      elem.innerHTML = `<div>${elem.textContent
        ?.trim()
        .split('')
        .map((char) => `<span${char === ' ' ? ' class="space-span"' : ''}>${char}</span>`)
        .join('')}</div>`;
    });
  }, [containerRef.current]);

  useEffect(() => {
    const updateCardsPerView = () => {
      if (!containerRef.current) {
        return;
      }

      const containerWidth = containerRef.current.offsetWidth;
      const cardWidth = 240;
      const gap = 16;
      const possibleCards = Math.floor((containerWidth + gap) / (cardWidth + gap));

      setCardsPerView(Math.max(1, possibleCards));

      setTimeout(emptyCardLabelsSet, 0);
    };

    const resizeObserver = new ResizeObserver(updateCardsPerView);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  const maxIndex = Math.max(0, pinnedAccounts.length - cardsPerView);

  const onPrev = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => Math.max(0, prev - 1));
    }
  }, [currentIndex]);

  const onNext = useCallback(() => {
    if (currentIndex < maxIndex) {
      setCurrentIndex((prev) => Math.min(maxIndex, prev + 1));
    }
  }, [currentIndex, maxIndex]);

  const onEdit = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <Wrapper>
      <PinnedBankAccountsModal isOpen={isOpen} onClose={() => setIsOpen(false)} cardsPerView={cardsPerView} />

      <Toolbar>
        <Headline size="small">Pinned Accounts</Headline>

        <ButtonGroup>
          {pinnedAccounts.length > 0 && (
            <Button variant="muted" size="small" onClick={onEdit}>
              Edit
            </Button>
          )}

          <Controls>
            <Arrow
              variant="muted"
              size="small"
              icon={<Icon.ChevronLeft />}
              onClick={onPrev}
              isDisabled={currentIndex === 0}
              isFocussed={false}
            />

            <Arrow
              variant="muted"
              size="small"
              icon={<Icon.ChevronRight />}
              onClick={onNext}
              isDisabled={currentIndex >= maxIndex}
              isFocussed={false}
            />
          </Controls>
        </ButtonGroup>
      </Toolbar>

      <CardsContainer ref={containerRef}>
        <CardWrapper $currentIndex={currentIndex} $cardsPerView={cardsPerView}>
          {pinnedAccounts.map((bankAccount) => (
            <StyledCard
              key={bankAccount.id}
              as="div"
              onClick={() => navigate(`${ROUTE.BANK_ACCOUNTS}/edit/${bankAccount.id}`)}
            >
              <CardTitle>{bankAccount.displayName || bankAccount.description}</CardTitle>
              <CardAmount>{formatNumber(bankAccount.balances?.availableAmount)}</CardAmount>
              <CardArrow />
            </StyledCard>
          ))}
          {Array.from({ length: Math.max(0, cardsPerView - pinnedAccounts.length) }).map((_, index) => (
            <EmptyCard key={`empty-${index}`} as="div" onClick={() => navigate(ROUTE.BANK_ACCOUNTS)}>
              <CardPlus />
            </EmptyCard>
          ))}
        </CardWrapper>
      </CardsContainer>
    </Wrapper>
  );
};
