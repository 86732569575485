import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Dropdown } from '@column/column-ui-kit';

import { Datepicker, DatepickerProps } from '../../Datepicker';
import { PopoverAction, PopoverBase, PopoverBaseProps } from '../Base';
import { DatePreview } from '~/elements/DatePreview';
import { FormLabel } from '~/styles';
import { getDateFormat } from '~/util';
import { daysInMonth, generateYearMonthOptions } from '~/util/dateFilterHelpers';

interface PopoverRangepickerProps
  extends PopoverBaseProps,
    Omit<DatepickerProps, 'showMonths' | 'showDate' | 'onHoverDate' | 'onDateSubmit'> {
  onSubmit?: (value: string | string[] | undefined) => void;
}

const RangePopoverBase = styled(PopoverBase)`
  --popover-padding: 0px;
  --popover-width: 740px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: auto 192px;
  padding: 20px 20px 8px 20px;
`;

const Content = styled.div`
  display: grid;
  grid-gap: 8px;
  width: 100%;
`;

const DateList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 0 0 20px;
  border-left: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const DateSelection = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const DatePresets = styled.div`
  margin-top: auto;
  display: grid;
  grid-gap: 8px;
`;

export const PopoverRangepicker: FC<PopoverRangepickerProps> = ({
  date,
  onSubmit,
  disableAfter,
  disableBefore,
  ...props
}) => {
  const [dates, setDates] = useState<string[]>([]);
  const [hoverDate, setHoverDate] = useState<string | undefined>(undefined);

  const [month, setMonth] = useState<string | undefined>(undefined);

  useEffect(() => {
    const newDates = Array.isArray(date) && (date.filter(Boolean) as string[]) ? date : [];

    if (dates !== newDates) {
      setDates(newDates);
    }
  }, [date]);

  useEffect(() => {
    if (!props.show) {
      return;
    }

    setMonth(undefined);
    setHoverDate(undefined);
  }, [props.show]);

  const handleChange = useCallback(
    (range?: 'today' | 'lastWeek' | 'lastMonth') => {
      const from = new Date();

      if (range === 'lastWeek') {
        from.setDate(from.getDate() - 7);
      }

      if (range === 'lastMonth') {
        from.setMonth(from.getMonth() - 1);
      }

      setDates([getDateFormat(from), getDateFormat(new Date())]);
    },
    [setDates]
  );

  const handleDateSubmit = useCallback(
    (value: string | string[] | undefined) => {
      setDates(Array.isArray(value) ? value : value ? [value] : []);
    },
    [setDates]
  );

  const handleResetClick = useCallback(() => {
    setDates([]);
    setMonth(undefined);

    onSubmit?.(undefined);
  }, [setDates, onSubmit]);

  const handleApplyClick = useCallback(() => {
    if (onSubmit) {
      onSubmit(dates);
    }
  }, [dates, onSubmit]);

  const handleMonthChange = useCallback(
    (value: string) => {
      setMonth(value);

      const [yearValue, monthValue] = value.split('-');

      const from = new Date();
      from.setFullYear(Number(yearValue));
      from.setMonth(Number(monthValue) - 1);
      from.setDate(1);

      const to = new Date(from);
      to.setDate(daysInMonth(Number(yearValue), Number(monthValue)));

      setDates([getDateFormat(from), getDateFormat(to)]);
    },
    [setMonth]
  );

  return (
    <RangePopoverBase {...props}>
      <Wrapper>
        <Content>
          <FormLabel>
            Date Range <DatePreview hoverDate={hoverDate} selectedDates={dates} />
          </FormLabel>
          <Datepicker
            date={dates}
            onDateSubmit={handleDateSubmit}
            showMonths={2}
            disableBefore={disableBefore ?? new Date('2022-01-01')}
            disableAfter={disableAfter ?? new Date()}
            onHoverDate={setHoverDate}
            showDate={dates[0]}
          />
        </Content>

        <DateList>
          <DateSelection>
            <Content>
              <FormLabel>Select Month</FormLabel>
              <Dropdown
                active={month ?? ''}
                onChange={handleMonthChange}
                options={generateYearMonthOptions()}
                size="small"
                variant="muted"
                placeholder="Select month..."
                renderInside
                fullWidth
              />
            </Content>
          </DateSelection>
          <DatePresets>
            <Button size="small" fullWidth variant="muted" onClick={() => handleChange('lastWeek')}>
              Last Week
            </Button>
            <Button size="small" fullWidth variant="muted" onClick={() => handleChange('lastMonth')}>
              Last Month
            </Button>
          </DatePresets>
        </DateList>
      </Wrapper>
      <PopoverAction>
        <Button size="small" type="button" variant="secondary" onClick={handleResetClick}>
          Reset
        </Button>
        <Button size="small" type="button" onClick={handleApplyClick} isDisabled={dates.length !== 2}>
          Apply
        </Button>
      </PopoverAction>
    </RangePopoverBase>
  );
};
