import React from 'react';
import styled, { css } from 'styled-components';

import { Chip, Icon } from '@column/column-ui-kit';

import { CopyRow } from '~/app/pages/Transfers/_components/CopyRow';
import { Card, Flex, Inner, Text } from '~/styles';

import { TimelineStatus } from './types';

interface TimelineEventDetailsProps {
  details?: { label: string; value: string | React.ReactNode; warning?: string }[];
  onClick?: () => void;
  icon?: React.ReactNode;
  title: string;
  secondaryTitle?: string;
  status?: TimelineStatus;
  subtitle?: string;
  variant?: 'primary' | 'secondary';
  metadata?: string;
}

const Title = styled.span`
  display: flex;
  align-items: center;

  svg {
    opacity: 0;
    transform: translateX(-4px);
    transition:
      opacity 0.2s,
      transform 0.2s;
  }
`;

const Subtitle = styled(Text)<{ status?: TimelineStatus }>`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  ${({ status }) =>
    status === 'pending' &&
    css`
      background: linear-gradient(
        to right,
        ${({ theme }) => theme.secondary.blendToBackground(100)} 20%,
        ${({ theme }) => theme.secondary.blendToBackground(400)} 40%,
        ${({ theme }) => theme.secondary.blendToBackground(400)} 60%,
        ${({ theme }) => theme.secondary.blendToBackground(100)} 80%
      );
      background-size: 200% auto;

      color: ${({ theme }) => theme.secondary.blendToBackground(800)};
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      animation: pending 2s linear infinite;
      @keyframes pending {
        to {
          background-position: -200% center;
        }
      }
    `}

  ${({ status }) =>
    status === 'success' &&
    css`
      color: ${({ theme }) => theme.success.background};
    `}
  ${({ status }) =>
    status === 'danger' &&
    css`
      color: ${({ theme }) => theme.danger.background};
      text-decoration: line-through;
    `}
`;

const Header = styled.div<{ onClick?: () => void }>`
  display: flex;
  align-items: start;
  padding: 10px 8px;
  border-radius: 5px;
  transition:
    background 0.2s,
    box-shadow 0.2s;

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.secondary.blendToBackground(25)};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};

        svg {
          opacity: 1;
          transform: translateX(0);
        }
      }

      &:active {
        ${Title} svg {
          transform: translateX(2px);
          transition: transform 0.1s;
        }
      }
    `}
`;

const StyledCard = styled(Card)`
  min-width: 248px;
`;

const StyledChip = styled(Chip)`
  margin-top: -4px;
`;

export const TimelineEventDetails: React.FC<TimelineEventDetailsProps> = ({
  details,
  icon,
  onClick,
  title,
  secondaryTitle,
  status,
  subtitle,
  variant,
  metadata,
}) => {
  return (
    <StyledCard variant={variant}>
      <Inner p={8}>
        <Header onClick={onClick}>
          <Flex gap={12} alignItems="center" auto>
            {icon}
            <Flex gap={4} flexDirection="column">
              <Title>
                <Flex gap={4} alignItems="center">
                  <Text as="h4" size={16} weight="medium">
                    {title}
                  </Text>
                  {secondaryTitle && (
                    <Text as="h4" color="light" size={16} weight="medium">
                      {secondaryTitle}
                    </Text>
                  )}
                  {onClick && <Icon.ChevronRight />}
                </Flex>
              </Title>
              {subtitle && (
                <Subtitle size={14} weight="medium" status={status} color="light">
                  {subtitle}
                </Subtitle>
              )}
            </Flex>
          </Flex>
          {metadata && <StyledChip size="large">{metadata}</StyledChip>}
        </Header>
      </Inner>
      {details &&
        details.map(({ label, value, warning }) => (
          <CopyRow key={label} label={label} value={value} warning={warning} />
        ))}
    </StyledCard>
  );
};
