import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface CheckContinueIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const CheckContinue: FC<CheckContinueIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={48}>
        <g strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor">
          <path d="M23.67 21L7 25" />
          <path d="M13.34 28L5 30" />
          <path d="M17 19.5804V31.1004C17 32.0704 17.91 32.7804 18.85 32.5604L40.47 27.3704C41.37 27.1504 42 26.3504 42 25.4304V13.9104C42 12.9404 41.09 12.2304 40.15 12.4504L18.53 17.6404C17.63 17.8604 17 18.6604 17 19.5804Z" />
        </g>
      </IconBase>
    </Wrapper>
  );
};
